import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const SidebarInnerListWithMultiplePermissions = (props) => {
  const [showOtherNavMenus, setShowOtherNavMenus] = useState(false);

  const [selectedOtherBar, setSelectedOtherBar] = useState(props.firstSelected);

  const listsItems = props?.innerLists;
  const { innerLists } = props;
  const location = useLocation();

  const permission = JSON.parse(localStorage.getItem("permissions"));

  const [permissions, setPermissions] = useState(permission);

  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);

  const handleShowOtherNavMenus = () => {
    setShowOtherNavMenus(!showOtherNavMenus);
  };

  return (
    <>
      {props.show === false ? null : (
        <div
          style={{ display: "flex", zIndex: props.zIndex }}
          className="footer_inner_lists"
        >
          <ul className="flex flex_gap_10">
            {permissions &&
              innerLists &&
              innerLists.map((item, index) => (
                <React.Fragment key={index}>
                  {item.permission.some((perm) =>
                    permission?.includes(perm)
                  ) && (
                    <li style={{ listStyle: "none" }}>
                      <Link
                        to={item.link}
                        className={`inner_list_item ${
                          location.pathname === item.link ? "active" : ""
                        }`}
                        key={index}
                      >
                        {item.name}
                      </Link>
                    </li>
                  )}
                </React.Fragment>
              ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default SidebarInnerListWithMultiplePermissions;
