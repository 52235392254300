import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import React, { useEffect, useState } from "react";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import { useNavigate, useParams } from "react-router-dom";
import {
  IndexTable,
  LegacyCard,
  useIndexResourceState,
} from "@shopify/polaris";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import StatusUpdate from "./CustomComponents/StatusUpdate";
import NoDataFound from "./CustomComponents/NoDataFound";
import { useSelector } from "react-redux";
import {
  formatIndianCurrency,
  getCurrentPropertyDateHandler,
  useCurrentTime,
} from "../utils/utils";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  WithoutBgButtonBlue,
} from "./UI/Buttons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import GoBackButtonCustom from "./GoBackButtonCustom/GoBackButtonCustom";
import UnderscoreRemoveCapitalize from "./CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import TextDateFormatter from "./Communication/TextDateFormatter/TextDateFormatter";
import Loader from "./UI/Loader/Loader";
import { getDecryptedData } from "../utils/encryptStorage";
import SidebarInnerListsWithoutPermission from "./CustomComponents/SidebarInnerListsWithoutPermission";

const AgentsPayments = () => {
  const { agentid } = useParams();

  const currentTimeToBackend = useCurrentTime("24");

  const navigate = useNavigate();
  const userToken = getDecryptedData('encryptToken');
  const permission = JSON.parse(localStorage.getItem("permissions"));

  const currentDate = new Date().toISOString().split("T")[0];

  const [orders, setOrders] = useState([]);
  const [allData, setAllData] = useState([]);
  // fetch data
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(true);

  const customers_inner_list = [
    {
      name: "Used Credit",
      link: `/credit/${agentid}`,
      permission: "read_customers",
    },
    {
      name: "Payments",
      link: `/transfer/${agentid}`,
      permission: "read_agents",
    },
    {
      name: "Added Credits",
      link: `/agents/credits/${agentid}`,
      permission: "read_agents",
    },
    // {
    //   name: "Advance Payments",
    //   link: `/agents/advance/payments/${agentid}`,
    //   permission: "read_agents",
    // },
    {
      name: "Bookings",
      link: `/agents/bookings/${agentid}`,
      permission: "read_agents",
    },
    {
      name: "Credit Finance Logs",
      link: `/agents/credit/Logs/${agentid}`,
      permission: "read_agents",
    },
    {
      name: "City Ledger Report",
      link: `/agents/city/Ledger/${agentid}`,
      permission: "read_agents",
    },
  ];

  const [addChargesPopup, setAddChargesPopup] = useState(false);
  const [addChargesData, setAddChargesData] = useState({
    date: currentDate,
    property_id: "",
    notes: "",
    notes: "",
    payment_mode: "",
    amount: "",
  });

  const paymentModeOptions = [
    { label: "Select Option" },
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    // { label: "Other", value: "other" },
  ];
  const settlementOptions = [
    { label: "Select Option" },
    { label: "Room Settlement", value: "room_settlement" },
    { label: "F & B Settlement", value: "f_and_b_settlement" },
  ];

  const paymentTypeOptions = [
    { label: "Select Option" },
    { label: "Cash", value: "cash" },
    { label: "City Ledger", value: "city_ledger" },
  ];

  const setAddChargesHandleChange = (event) => {
    if (event.target.name === "amount") {
      if (
        agentCreditLimit > 0 &&
        parseFloat(event.target.value) > agentCreditLimit
      ) {
        showErrorToast("Amount should not be greater than Agent Credit Limit");
        return;
      }
    }
    if (event.target.name === "company_name") {
      setAddChargesData({
        // ...addChargesData,
        amount: 0,
      });
    }

    setAddChargesData({
      ...addChargesData,
      [event.target.name]: event.target.value,
    });
  };

  const agentLists = [];
  const agentListOptions = [
    {
      label: "Select option",
      value: null,
    },
    ...agentLists?.map((agent) => ({
      label: agent?.company_name,
      value: agent?.agent_information,
    })),
  ];

  const [agentCreditLimit, setAgentCreditLimit] = useState("");

  // useEffect(() => {
  //   const selectedAgent = agentLists?.find(
  //     (agent) =>
  //       Number(agent?.agent_information) ===
  //       Number(addChargesData?.company_name)
  //   );

  //   if (selectedAgent && selectedAgent?.credit.length > 0) {
  //     const creditLimit = selectedAgent?.credit?.reduce(
  //       (acc, agent) => Number(acc) + Number(agent.credit_amount),
  //       0
  //     );
  //     setAgentCreditLimit(creditLimit);
  //   } else {
  //     setAgentCreditLimit(null);
  //   }

  //   setAddChargesData({
  //     ...addChargesData,
  //     amount: null,
  //   });
  // }, [addChargesData.company_name, agentLists]);

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      fetchCreditData(property_id?.id)
    }
  }, [property_id]);

  const fetchInfo = async (propertyId) => {
    try {
      setLoader(true);
      const response = await fetch(
        // dev.revcatalyst.in/api/v1/get/credit_settlement/95/4
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/credit_settlement/${agentid}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      setLoader(false);
      if (data.success) {
        setAllData(data.data);
      } else {
        setAllData([]);
      }
    } catch (error) {
      setLoader(false);
    }
  };


  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders)


  const rowMarkup = allData?.map(
    ({
      id,
      room_no,
      room_name,
      floor_no,
      status,
      credit_amount,
      balance_amount,
      index,

      payment_mode,

      date,
      payment_type,
      notes,
      amount,
    }) => {
      return (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(id)}
          position={id}
        >
          <IndexTable.Cell>
            <TextDateFormatter date={date} as={"div"} showYear={true} />
          </IndexTable.Cell>
          <IndexTable.Cell>
            <UnderscoreRemoveCapitalize text={payment_mode} />
          </IndexTable.Cell>
          <IndexTable.Cell>{notes}</IndexTable.Cell>
          <IndexTable.Cell>{formatIndianCurrency(amount)}</IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  const handleSubmitCreateFolio = (event) => {
    event.preventDefault();
   
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/pay/credit/${agentid}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addChargesData,
          property_current_date:
            propertyCurrentDate + " " + currentTimeToBackend,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setAddChargesData(false);
          // fetchFolioInfo(property_id?.id);
          setAddChargesPopup(false);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  useEffect(() => {
    if (selectedResources.length === 1) {
      // getOneData(selectedResources[0]);
      // setShowLowerMenuSingleSelect(true);
      // setShowLowerMenuMultipleSelect(false);
      // setShowLowerMenuAddMenu(false);
    } else if (selectedResources.length > 1) {
      // setShowLowerMenuMultipleSelect(true);
      // setShowLowerMenuSingleSelect(false);
      // setShowLowerMenuAddMenu(false);
    } else {
      // setAddItemData({
      //   room_name: "",
      //   room_no: "",
      //   floor_no: "",
      //   status: "clean",
      //   room_id: fetchedRoomType ? fetchedRoomType[0]?.id : "",
      //   property_id: property_id?.id,
      // });
      // setShowLowerMenuSingleSelect(false);
      // setShowLowerMenuAddMenu(false);
      // setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id?.id, userToken)
        .then((date) => setPropertyCurrentDate(date))
        .catch((error) => console.error(error));
    }
  }, [property_id]);

  // if(allData.length < 1){
  //   return <Loader />
  // }


  const [creditData,setCreditData] = useState([])

  const fetchCreditData = async (propertyId) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/company/credits/${agentid}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if(data.success){
        setCreditData(data?.data)
      }

      // setOrders(data?.data);
      // setAllData(data);

      // setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <>
      <SidebarInnerListsWithoutPermission innerLists={customers_inner_list} />
      <div
        className="booking-choice-full-parent-container-right-heading-new"
        style={{ marginTop: "10px" }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#EFF0F2",
          }}
        >
          <div className="reservation_bar_container" style={{ width: "100%" }}>
            <div style={{ marginTop: "10px" }}>
              <GoBackButtonCustom onClick={() => navigate("/agents")} />
            </div>
            <div className="reservation_total ">
              <h5 className="reservation_total_heading">
                {formatIndianCurrency(creditData?.credit_amount)}
              </h5>
              <p className="reservation_total_paragraph">Total Credit Limit</p>
            </div>
            <div className="reservation_total confirmed">
              <h5 className="reservation_total_heading">
                {formatIndianCurrency(
                  creditData.credit_amount - creditData?.used_amount
                )}
              </h5>
              <p className="reservation_total_paragraph">Available Cred. Lmt</p>
            </div>
            <div className="reservation_total confirmed">
              <h5 className="reservation_total_heading">
                {formatIndianCurrency(creditData?.used_amount)}
              </h5>
              <p className="reservation_total_paragraph">Used Cred. Lmt.</p>
            </div>
            {/* <div className="reservation_total confirmed">
              <h5 className="reservation_total_heading">
                {formatIndianCurrency(creditData.balance_amount)}
              </h5>
              <p className="reservation_total_paragraph">Total Due Amount</p>
            </div> */}
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <WithoutBgButtonBlue
          style={{ fontSize: "14px", padding: "0" }}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <g clipPath="url(#clip0_5311_4678)">
                <path
                  d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                  stroke="#3968ED"
                />
                <path
                  d="M10.5868 7H3.41357"
                  stroke="#3968ED"
                  stroke-miterlimit="10"
                />
                <path
                  d="M7 3.4132L7 10.5864"
                  stroke="#3968ED"
                  stroke-miterlimit="10"
                />
              </g>
              <defs>
                <clipPath id="clip0_5311_4678">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          }
          children={"Add"}
          onClick={() => setAddChargesPopup(!addChargesPopup)}
        />
      </div>
      <div className="room-types-full-container">
        {allData && allData.length > 0 ? (
          <>
            <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={allData.length}
                selectedItemsCount={
                  allResourcesSelected ? "All" : selectedResources.length
                }
                onSelectionChange={handleSelectionChange}
                headings={[
                  { title: "Date" },
                  { title: "Payment Mode" },
                  { title: "Notes" },
                  { title: "Amount" },
                ]}
                selectable={false}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard>
          </>
        ) : (
          <NoDataFound />
        )}
      </div>

      {addChargesPopup && (
        <div className="bg-backdrop">
          <form
            onSubmit={handleSubmitCreateFolio}
            style={{ width: "600px" }}
            className="stock-add-model-container"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div className="add-ota-model-container-heading">
                Add Payments
              </div>
              <div
                onClick={() => setAddChargesPopup(!addChargesPopup)}
                style={{ cursor: "pointer" }}
              >
                {/* <Icon source={CancelMajor} color="base" /> */}
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <VerticalInputFieldCustom
                min={propertyCurrentDate}
                  required={true}
                  focused={false}
                  name="date"
                  type="date"
                  titleName="Date"
                  onChange={setAddChargesHandleChange}
                  autoComplete="off"
                  value={
                    addChargesData.date ? addChargesData.date : currentDate
                  }
                />
              </div>
              {/* <div className="form-container-div">
                <VerticalInputSelectCustomCopy
                  options={paymentTypeOptions}
                  type="text"
                  required={true}
                  focused={false}
                  name="payment_type"
                  titleName="Payment Type"
                  onChange={setAddChargesHandleChange}
                  autoComplete="off"
                  value={addChargesData.payment_type}
                />
              </div> */}
              <div className="form-container-div">
                {/* {addChargesData.payment_type === "cash" && ( */}
                <VerticalInputSelectCustomCopy
                  options={paymentModeOptions}
                  type="text"
                  required={true}
                  focused={false}
                  name="payment_mode"
                  titleName="Payment Mode"
                  onChange={setAddChargesHandleChange}
                  autoComplete="off"
                  value={addChargesData.payment_mode}
                />
                {/* )} */}
                {addChargesData.payment_type === "city_ledger" && (
                  <VerticalInputSelectCustomCopy
                    options={agentListOptions}
                    type="text"
                    required={true}
                    focused={false}
                    name="company_name"
                    titleName="Company Name"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.company_name}
                  />
                )}
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <VerticalInputFieldCustom
                  required={true}
                  focused={false}
                  name="notes"
                  titleName="Notes"
                  onChange={setAddChargesHandleChange}
                  autoComplete="off"
                  value={addChargesData.notes}
                />
              </div>
              <div className="form-container-div">
                {agentCreditLimit < 1 &&
                addChargesData.payment_type === "city_ledger" ? (
                  <VerticalInputFieldCustom
                    type="number"
                    required={true}
                    focused={false}
                    disabled={true}
                    name="amount"
                    titleName="Amount"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.amount}
                  />
                ) : (
                  <VerticalInputFieldCustom
                    type="number"
                    required={true}
                    focused={false}
                    // disabled={true}
                    name="amount"
                    titleName="Amount"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.amount}
                  />
                )}
              </div>
              {/* <div className="form-container-div">
                    <VerticalInputSelectCustomCopy
                      options={settlementOptions}
                      type="text"
                      required={true}
                      focused={false}
                      name="settlement_name"
                      titleName="Settlement Name"
                      onChange={setAddChargesHandleChange}
                      autoComplete="off"
                      value={addChargesData.settlement_name}
                    />
                  </div> */}
            </div>

            {/* <div className="form-container-div-full">
              <>
                Credit Limit:
                <span>
                  {agentCreditLimit
                    ? formatIndianCurrency(agentCreditLimit)
                    : formatIndianCurrency(agentCreditLimit)}
                </span>
              </>
            </div> */}

            <div className="flex flex_gap_10">
              {/* {chargeAddedTransactions?.grandTotalSum -
                chargeAddedTransactions?.paidAmountSum >=
              addChargesData?.amount ? (
                <BgThemeButton children={"Add"} type={"submit"} />
              ) : (
                <BgThemeButtonDisabled children={"Add"} />
              )} */}

              <BgThemeButton children={"Add"} type={"submit"} loader={loader}/>

              <BgOutlineThemeButton
                children={"Cancel"}
                type={"button"}
                onClick={() => setAddChargesPopup(!addChargesPopup)}
              />
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default AgentsPayments;
