import React, { useEffect, useState } from "react";
import "./AgentHoldRoomsSliderSideBar.css";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
} from "../../UI/Buttons";
import VerticalInputSelectCustomCopy from "../VerticalInputSelectCustomCopy";
import CommunicationCustomDatePicker from "../CommunicationCustomDatePicker";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DateRangePickerNew from "../CreateBookingNewPage/DateRangePickerNew/DateRangePickerNew";
import {
  formatDateYYYYMMDD,
  getCurrentPropertyDate,
  getCurrentPropertyDateHandler,
} from "../../../utils/utils";

import { showErrorToast } from "../../../assets/toastUtils";
import DateRangePicker from "../../NewCustomComp/DateRangePicker/DateRangePicker";
import { getDecryptedData } from "../../../utils/encryptStorage";
import { Tooltip } from "@shopify/polaris";

const AgentHoldRoomsSliderSideBarContent = ({
  singleAgentData,
  isOpen,
  propertyCurrentDate,
  handleDelete,
  handleClose,
}) => {
  const navigate = useNavigate();
  const permission = localStorage.getItem("permissions");

  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }
  //   return () => {
  //     document.body.style.overflow = "auto";
  //   };
  // }, [isOpen]);

  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value);
  const [roomCategories, setRoomCategories] = useState([]);

  const fetchRoomCategories = async () => {
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${property_id?.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    ).then((response) => response.json());
  };

  useEffect(() => {
    fetchRoomCategories().then((data) => {
      setRoomCategories(data.rooms);
    });
  }, []);

  const [roomInsightData, setRoomInsightData] = useState("");
  const [roomId, setRoomId] = useState("");
  const handleSelectChange = (event) => {
    setRoomId(event.target.value);
  };
  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
  });

  const fetchRoomWiseData = async (
    from_date,
    to_date,
    roomId,
    agentHoldRoomId
  ) => {
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/agent/hold/room/insights/${property_id.id}?from_date=${from_date}&to_date=${to_date}&room_id=${roomId}&agentHoldRoomId=${agentHoldRoomId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    ).then((response) => response.json());
  };

  //  https://dev.revcatalyst.in/api/v1/agent/hold/room/insights/1?from_date=2024-09-11&to_date=2024-09-15&room_id=2

  const currentDate = new Date();
  const nextDate = new Date(currentDate);
  nextDate.setDate(currentDate.getDate() + 1);

  const formattedCurrentDate = formatDateYYYYMMDD(currentDate, "yyyy-MM-dd");
  const formattedNextDate = formatDateYYYYMMDD(nextDate, "yyyy-MM-dd");

  const handleDateRangeChange = (from_date, to_date) => {
    if (!roomId) {
      showErrorToast("please select room type");
      return;
    }

    console.log(from_date, to_date, "hiiiii");
    if (from_date && to_date) {
      // fetchRoomWiseData(from_date, to_date, roomId).then((data) => {
      //   setRoomInsightData(data?.data);
      // });
      setDates({
        startDate: from_date,
        endDate: to_date,
      });
    }
  };
  useEffect(() => {
    if (dates.startDate && dates.endDate && roomId) {
      fetchRoomWiseData(
        dates.startDate,
        dates.endDate,
        roomId,
        singleAgentData?.id
      ).then((data) => {
        setRoomInsightData(data?.data);
      });
    }
  }, [dates.startDate, dates.endDate, roomId]);

  return (
    <>
      <div>
        <div className="agentSidebarContentContainer flex flex_gap_10">
          {permission && permission.includes("full_access_agents_module") ? (
            <BgThemeButton
              children={"Create Booking"}
              onClick={() =>
                navigate(
                  `/agent-hold-rooms/create-booking/${singleAgentData.id}`
                )
              }
            />
          ) : (
            <Tooltip
              content="Please Contact Your Admin"
              dismissOnMouseOut
              zIndexOverride={99999999999999}
            >
              <BgThemeButtonDisabled children={"Create Booking"} />
            </Tooltip>
          )}

          {permission && permission.includes("full_access_agents_module") ? (
            <BgOutlineThemeButton
              onClick={() => {
                handleDelete(singleAgentData.id);
              }}
              children={"Delete"}
              style={{ border: "1px solid red", color: "red" }}
            />
          ) : (
            <Tooltip
              content="Please Contact Your Admin"
              dismissOnMouseOut
              zIndexOverride={99999999999999}
            >
              <BgOutlineThemeButton
                style={{ border: "1px solid red", color: "red" }}
                children={"Delete"}
                disabled
              />
            </Tooltip>
          )}
          {/* <BgOutlineThemeButton children={"More Options"} /> */}
        </div>
        {/* Agent Details */}
        <div>
          <div
            className="agentSidebarContentContainer agentHoldRoomsSidebarHeadingSmall flex flex_gap_10"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            Agent Details
          </div>
          <div className="agentSidebarContentContainer flex justify_content_between">
            <div>
              <h6 className="agentHoldRoomsSidebarHeadingSmall">
                {" "}
                Company Name
              </h6>
              <p>{singleAgentData.reference_person_name}</p>
            </div>
            <div>
              <h6 className="agentHoldRoomsSidebarHeadingSmall"> Phone </h6>
              <p>{singleAgentData.reference_person_phone || "NA"}</p>
            </div>
            <div>
              <h6 className="agentHoldRoomsSidebarHeadingSmall">Start Date</h6>
              <p>{singleAgentData.from_date || "NA"}</p>
            </div>
          </div>
          <div className="agentSidebarContentContainer agentHoldRoomsSidebarHeadingSmall flex flex_gap_10">
            <div>
              <h6 className="agentHoldRoomsSidebarHeadingSmall">End Date</h6>
              <p>{singleAgentData.to_date || "NA"}</p>
            </div>
          </div>
        </div>

        {/* Room Categories */}

        <div>
          <div
            className="agentSidebarContentContainer agentHoldRoomsSidebarHeadingSmall flex flex_gap_10"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            Room Categories
          </div>
          <div className="agentSidebarContentContainer ">
            {singleAgentData &&
              singleAgentData?.roomDetails?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex justify_content_between"
                    style={{ padding: "5px 0", borderBottom: "1px solid #333" }}
                  >
                    <span className="agentHoldRoomsSidebarHeadingSmall">
                      {item.room_name}
                    </span>
                    <span>{item.hold_rooms}</span>
                  </div>
                );
              })}
          </div>
        </div>

        {/* release restrictions */}

        {singleAgentData?.release_label && (
          <div>
            <div
              className="agentSidebarContentContainer agentHoldRoomsSidebarHeadingSmall flex flex_gap_10"
              style={{ backgroundColor: "#f5f5f5" }}
            >
              Auto-Release Restriction
            </div>
            <div className="agentSidebarContentContainer ">
              {singleAgentData &&
                singleAgentData?.release_label.map((item, index) => {
                  const percent = singleAgentData?.release_percent[index];

                  return (
                    <div
                      key={index}
                      className="flex justify_content_between"
                      style={{
                        padding: "5px 0",
                        borderBottom: "1px solid #333",
                      }}
                    >
                      <span className="agentHoldRoomsSidebarHeadingSmall">
                        {item}
                      </span>
                      <span>{percent} %</span>
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        {/*Contract insight */}
        {/* <div>
          <div className="agentSidebarContentContainer ">
            <div className="agentHoldBookingSliderContractInsight">
              <div className="flex justify_content_between align_center">
                <h5>Contract Insights</h5>
                <div>
                  <VerticalInputSelectCustomCopy
                    style={{ minHeight: "25px" }}
                  />
                </div>
              </div>
              <div
                className="flex justify_content_between align_center"
                style={{ margin: "5px 0" }}
              >
                <span>Hold Rooms</span>
                <span>200</span>
              </div>
              <div className="flex justify_content_between align_center">
                <span>Converted </span>
                <span>200</span>
              </div>
              <div
                className="flex justify_content_between align_center"
                style={{ margin: "5px 0" }}
              >
                <span>Not Used</span>
                <span>200</span>
              </div>
              <div
                className="flex justify_content_between align_center"
                style={{ margin: "5px 0" }}
              >
                <span>Remaining</span>
                <span>200</span>
              </div>
              <div className="flex justify_content_between align_center">
                <span>Converted Booking Amount</span>
                <span>₹ 200</span>
              </div>
            </div>
          </div>
        </div> */}

        {/*Rooms insight */}

        <div>
          <div className="agentSidebarContentContainer ">
            <div className="agentHoldBookingSliderContractInsight">
              <div className="flex justify_content_between align_center">
                <h4 className="agentHoldRoomsSidebarHeadingSmall    ">
                  Room Insights
                </h4>
                <div
                  className="flex flex_gap_10 align_center"
                  style={{ width: "65%" }}
                >
                  <div style={{ width: "40%" }}>
                    <select
                      className="agentHoldBookingSliderContractInsightSelect"
                      value={roomId}
                      onChange={handleSelectChange}
                    >
                      <option value={""}>select</option>
                      {roomCategories.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.room_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div style={{ width: "60%" }}>
                    <DateRangePicker
                      onChange={handleDateRangeChange}
                      size={"small"}
                      minDate={new Date(propertyCurrentDate)}
                      disabled={!roomId}
                    />
                  </div>
                </div>
              </div>
              <div
                className="flex justify_content_between align_center"
                style={{ margin: "5px 0" }}
              >
                <span>Hold Rooms</span>
                <span>
                  {(roomInsightData && roomInsightData[0]?.hold_rooms) || "NA"}
                </span>
              </div>
              <div className="flex justify_content_between align_center">
                <span>Converted </span>
                <span>
                  {(roomInsightData && roomInsightData[0]?.converted) || "NA"}
                </span>
              </div>
              <div
                className="flex justify_content_between align_center"
                style={{ margin: "5px 0" }}
              >
                <span>Not Used</span>
                <span>
                  {(roomInsightData && roomInsightData[0]?.no_used) || "NA"}
                </span>
              </div>
              <div
                className="flex justify_content_between align_center"
                style={{ margin: "5px 0" }}
              >
                <span>Remaining</span>
                <span>
                  {(roomInsightData && roomInsightData[0]?.remaining) || "NA"}
                </span>
              </div>
              {/* <div className="flex justify_content_between align_center">
                <span>Converted Booking Amount</span>
                <span>₹ 200</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentHoldRoomsSliderSideBarContent;
