import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoaderSmall from "../UI/Loader/LoaderSmall";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  CheckWithoutBgUnderlineBtn,
} from "../UI/Buttons";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { CancelMajor } from "@shopify/polaris-icons";
import {
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  Icon,
  Button,
  Tooltip,
} from "@shopify/polaris";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import ReactQuill from "react-quill";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import { getBaseUrl } from "../../utils/utils";
import { getDecryptedData } from "../../utils/encryptStorage";

const PrivacyPolicies = () => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const baseURL = getBaseUrl();
  const [addPolicyPopup, setAddPolicyPopup] = useState(false);
  const [editPrivacyPolicyPopup, setEditPrivacyPolicyPopup] = useState(false);
  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const userToken = getDecryptedData("encryptToken");

  const property_id = useSelector((state) => state.property.value);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [selectedId, setSelectedId] = useState(true);

  const [orders, setOrders] = useState([]);

  const fetchInfo = async (propertyId) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${baseURL}/api/v1/property_policy/list/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setOrders(data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const [addFolioData, setAddFolioData] = useState({
    key: "",
    value: "",
    active: 0,
  });

  useEffect(() => {
    if (property_id && userToken) {
      fetchInfo(property_id?.id);
    }
  }, [property_id, userToken]);

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(orders);

  const rowMarkup = orders.map(({ id, key, value, active, index }) => (
    <IndexTable.Row
      id={id}
      key={id}
      selected={selectedResources.includes(id)}
      position={index}
    >
      <IndexTable.Cell>{key}</IndexTable.Cell>
      <IndexTable.Cell>{value}</IndexTable.Cell>
      <IndexTable.Cell>{active}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const addFolioHandleChange = (event) => {
    setAddFolioData({
      ...addFolioData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitAddPrivacyPolicy = (event) => {
    event.preventDefault();
    fetch(`${baseURL}/api/v1/property_policy/add/${property_id?.id}`, {
      method: "POST",
      body: JSON.stringify({ ...privacyData }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setAddPolicyPopup(false);
          fetchInfo(property_id?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  const handleSubmitUpdatePrivacyPolicy = (event) => {
    event.preventDefault();
    fetch(
      `${baseURL}/api/v1/property_policy/update/${selectedId}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...privacyData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setEditPrivacyPolicyPopup(false);
          setPrivacyData({});
          fetchInfo(property_id?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  // privacy policy

  const [editorHtml, setEditorHtml] = useState("");

  const [selectedVisibility, setSelectedVisibility] = useState("");

  const [privacyData, setPrivacyData] = useState({
    policy_type: "",
    policy_data: "",
  });

  useEffect(() => {
    setPrivacyData((prevData) => ({
      ...prevData,
      policy_data: editorHtml,
    }));
  }, [selectedVisibility, editorHtml]);

  const handleValueChange = (event) => {
    setPrivacyData({
      ...privacyData,
      [event.target.name]: event.target.value,
    });
  };

  const options = [
    { label: "Travel", value: "Travel" },
    { label: "Adventure", value: "Adventure" },
    { label: "Gk", value: "Gk" },
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["align", "code-block"],
      ["link", "image"],
      ["undo", "redo"], // Add undo and redo options
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "align",
    "code-block",
    "link",
    "image",
  ];

  const handleTextChange = (value) => {
    setEditorHtml(value);
  };

  const getOneData = async (id) => {
    setSelectedId(id);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/property_policy/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      setEditPrivacyPolicyPopup(true);
      setPrivacyData(data?.data);
      setEditorHtml(data?.data?.policy_data || "");
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const removeItemConfirm = async () => {
    const response = await fetch(
      `${baseURL}/api/v1/property_policy/delete/${selectedId}/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          fetchInfo(property_id?.id);
          setShowModelMenuActive(false);
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  return (
    <>
      <div className="">
        <div className="flex justify_content_between align_items_center mb-10">
          <div class="room-type-heading">Privacy Policies</div>

          {permissions &&
          permissions.includes("full_access_property_settings") ? (
            <CheckWithoutBgUnderlineBtn
              children={"Add"}
              onClick={() => setAddPolicyPopup(!addPolicyPopup)}
            />
          ) : (
            <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
              <CheckWithoutBgUnderlineBtn children={"Add"} />
            </Tooltip>
          )}
        </div>
      </div>

      <div>
        {loader ? (
          <LoaderSmall />
        ) : (
          <div style={{ marginTop: "10px" }}>
            <div className="bg_white">
              {orders && orders.length > 0 ? (
                <>
                  {orders.map((policy) => {
                    return (
                      <div
                        style={{
                          backgroundColor: "white",
                          width: "100%",
                          padding: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{ marginBottom: "10px" }}
                          className="flex justify_content_between"
                        >
                          <div style={{ fontWeight: "bold" }}>
                            {policy?.policy_type}
                          </div>
                          <div className="flex flex_gap_10">
                            {permissions &&
                            permissions.includes(
                              "full_access_property_settings"
                            ) ? (
                              <>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    color: "rgba(57, 104, 237, 1)",
                                  }}
                                  onClick={() => getOneData(policy.id)}
                                >
                                  Edit
                                </div>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    color: "rgba(224, 56, 56, 1)",
                                  }}
                                  onClick={() => [
                                    setShowModelMenuActive(true),
                                    setSelectedId(policy.id),
                                  ]}
                                >
                                  Delete
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>

                        <div
                          style={{ paddingLeft: "20px" }}
                          dangerouslySetInnerHTML={{
                            __html: policy?.policy_data,
                          }}
                        />
                      </div>
                    );
                  })}
                </>
              ) : (
                <NoDataFoundSmall />
              )}
            </div>
          </div>
        )}
      </div>

      {addPolicyPopup && (
        <div className="bg-backdrop">
          <form
            onSubmit={handleSubmitAddPrivacyPolicy}
            style={{ width: "600px" }}
            className="stock-add-model-container"
          >
            <div>
              <GoBackButtonCustom
                buttonNextText={"Add Privacy Policy"}
                onClick={() => navigate("/websites/blogs")}
                showSvg={false}
              />
            </div>

            <div className="edit-rooms-form-wrapper-container">
              <div className="edit-rooms-form-container">
                <div className="form-container-div-full">
                  <VerticalInputFieldCustom
                    required={true}
                    focused={false}
                    name="policy_type"
                    titleName="Privacy Policy Type"
                    autoComplete="off"
                    onChange={handleValueChange}
                    value={privacyData.policy_type}
                  />
                </div>
                <div className="form-container-div-full">
                  <VerticalInputFieldCustom
                    required={true}
                    focused={false}
                    name="url"
                    titleName="Url"
                    autoComplete="off"
                    onChange={handleValueChange}
                    value={privacyData.url}
                  />
                </div>
              </div>
              <div className="editor_container">
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  value={editorHtml}
                  onChange={handleTextChange}
                  style={{ border: "1px solid #ccc" }}
                />
                {/* <CKEditor
                          editor={ClassicEditor}
                          data="<p>Hello World</p> "
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            handleEditorChange(data);
                          }}
                        /> */}
              </div>
            </div>
            <div className="flex flex_gap_10 mt-20">
              <BgThemeButton children={"Add"} type={"submit"} loader={loader} />
              <BgOutlineThemeButton
                children={"Cancel"}
                type={"button"}
                onClick={() => [
                  setSelectedId(null),
                  setPrivacyData({}),
                  setEditorHtml(null),
                  setAddPolicyPopup(!addPolicyPopup),
                ]}
              />
            </div>
          </form>
        </div>
      )}
      {editPrivacyPolicyPopup && (
        <div className="bg-backdrop">
          <form
            onSubmit={handleSubmitUpdatePrivacyPolicy}
            style={{ width: "600px" }}
            className="stock-add-model-container"
          >
            <div>
              <GoBackButtonCustom
                buttonNextText={"Edit Privacy Policy"}
                onClick={() => navigate("/websites/blogs")}
                showSvg={false}
              />
            </div>

            <div className="edit-rooms-form-wrapper-container">
              <div className="edit-rooms-form-container">
                <div className="form-container-div-full">
                  <VerticalInputFieldCustom
                    required={true}
                    focused={false}
                    name="policy_type"
                    titleName="Privacy Policy Type"
                    autoComplete="off"
                    onChange={handleValueChange}
                    value={privacyData.policy_type}
                  />
                </div>
                <div className="form-container-div-full">
                  <VerticalInputFieldCustom
                    required={true}
                    focused={false}
                    name="url"
                    titleName="URL"
                    autoComplete="off"
                    onChange={handleValueChange}
                    value={privacyData.url}
                  />
                </div>
              </div>
              <div className="editor_container">
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  value={editorHtml}
                  onChange={handleTextChange}
                  style={{ border: "1px solid #ccc" }}
                />
              </div>
            </div>
            <div className="flex flex_gap_10 mt-20">
              <BgThemeButton children={"Update"} type={"submit"} />
              <BgOutlineThemeButton
                children={"Cancel"}
                type={"button"}
                onClick={() => [
                  setSelectedId(null),
                  setPrivacyData({}),
                  setEditorHtml(null),
                  setEditPrivacyPolicyPopup(false),
                ]}
              />
            </div>
          </form>
        </div>
      )}

      {showModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={() => setShowModelMenuActive(!showModelMenuActive)}
                >
                  Discard
                </Button>
                <Button onClick={removeItemConfirm} destructive>
                  Yes, Remove
                </Button>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};
export default PrivacyPolicies;
