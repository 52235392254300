import React from "react";
import styles from "./apiSearchInput.module.scss";

const ApiSearchInput = ({ placeholder, onSearch, value}) => {
  const inputRef = React.useRef(null);

  const handleSearch = () => {
    const value = inputRef.current.value;
    if (value.trim()) {
      onSearch(value);
      if (inputRef.current) {
        inputRef.current.blur(); // Remove focus to avoid duplicate calls
      }
    }
  };

  const handleBlur = () => {
    if (document.activeElement !== inputRef.current) {
      handleSearch();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch(e);
    }
  };

  
  return (
    <div className={styles.searchContainer}>
      <span className={styles.icon}>🔍</span>
      <input
        ref={inputRef}
        value={value}
        type="text"
        placeholder={placeholder}
        // onChange={handleSearch}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        className={styles.input}
      />
    </div>
  );
};

export default ApiSearchInput;