import {
  Button,
  Icon,
  IndexTable,
  LegacyCard,
  Page,
  Select,
  TextField,
  useIndexResourceState,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { DeleteMajor } from "@shopify/polaris-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BgThemeButton, WithoutBgButtonBlue } from "./Buttons";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader/Loader";
import InnerLoader from "./Loader/innerLoader";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import LoaderSmall from "./Loader/LoaderSmall";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import ReportCityLedgerReport from "../Reports/ReportCityLedgerReport";
import SelectDateRange from "../CustomComponents/SelectDateRange/SelectDateRange";
import { getDecryptedData } from "../../utils/encryptStorage";
import SidebarInnerListsWithoutPermission from "../CustomComponents/SidebarInnerListsWithoutPermission";

const AgentCityLedgerReport = ({ setShowAddModelMenuActive }) => {
  const { agentid } = useParams();
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const currentDate = new Date().toISOString().split("T")[0];
  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [
    showAddCategoriesLowerMenuActive,
    setShowAddCategoriesLowerMenuActive,
  ] = useState(false);

  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);

  const navigate = useNavigate();
  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [fullData, setFullData] = useState([]);
  const [advancePayments, setAdvancePayments] = useState([]);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(advancePayments);

  const [addAgentCategories, setAddAgentCategories] = useState({
    catName: "",
    catDescription: "",
    catDiscount: null,
  });

  const setAddAgentCategoriesHandleChange = (event) => {
    setAddAgentCategories({
      ...addAgentCategories,
      [event.target.name]: event.target.value,
    });
  };

  const [addCompanyAdvanceState, setAddCompanyAdvanceState] = useState({
    agent_information_id: "",
    advance_amount: "",
  });

  const handleChangeCompanyCredits = (event) => {
    setAddCompanyAdvanceState({
      ...addCompanyAdvanceState,
      [event.target.name]: event.target.value,
    });
  };

  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();
  // fetch data
  const [btnStatus, setButtonStatus] = useState([]);

  const [loader, setLoader] = useState(false);

  const fetchCityLedgerReport = async (propertyId, roomIds) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/city/ledger/report/${agentid}/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&viewType=List`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setOrders(data.creditTransaction);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/city/ledger/report/${agentid}/${property_id.id}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&viewType=List`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.creditTransaction);
      setFullData(data);
      showSuccessToast(data.message);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

   useEffect(() => {
    if (property_id  && userToken)  {
      fetchInfo(property_id?.id);
    }
  }, [property_id, userToken]);

  // Fetch the room type click data
  const [agents, setAgents] = useState([]);
  const fetchAgentsInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setAgents(data.data);
        setLoader(false);
      } else {
        showErrorToast(data.message);
        setAgents([]);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchAgentsInfo(property_id.id);
    }
  }, [property_id]);

  const agentArray = [
    { label: "Select an option", value: "" },
    ...(agents?.map((agent) => ({
      label: agent.agent_name,
      value: agent.agent_information,
    })) || []),
  ];

  const [deleteId, setDeleteId] = useState("");

  const deleteData = async () => {
    const deletedIds = {
      ids: [deleteId],
    };

    const response = await fetch(
      // /api/v1/delete/agent/company/credits/10
      `${process.env.REACT_APP_BASE_URL}/api/v1/delete/agent/advance/payment/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // clearSelection();
          // setAddAgentCategories({
          //   catName: "",
          //   catDescription: "",
          //   catDiscount: "",
          // });
          fetchInfo(property_id?.id);
          clearSelection();
          setShowLowerMenuMultipleSelect(false);
          setShowModelMenuActive(false);
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit/agent/advance/payment/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.success) {
        setAddCompanyAdvanceState(data.data);
        setShowLowerMenuSingleSelect(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowAddCategoriesLowerMenuActive(false);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
      // setShowAddCategoriesLowerMenuActive(false);
    }
  }, [selectedResources]);
  const handleOpenAddCategoryClick = () => {
    clearSelection();
    setShowAddCategoriesLowerMenuActive(!showAddCategoriesLowerMenuActive);
    setShowLowerMenuMultipleSelect(false);
    setShowLowerMenuSingleSelect(false);
    setAddAgentCategories({
      catName: "",
      catDescription: "",
      catDiscount: null,
    });
  };

  const showModelMenu = (id) => {
    setDeleteId(id);
    setShowModelMenuActive(!showModelMenuActive);
  };

  const customers_inner_list = [
    {
      name: "Used Credit",
      link: `/credit/${agentid}`,
      permission: "read_customers",
    },
    {
      name: "Payments",
      link: `/transfer/${agentid}`,
      permission: "read_agents",
    },
    {
      name: "Added Credits",
      link: `/agents/credits/${agentid}`,
      permission: "read_agents",
    },
    // {
    //   name: "Advance Payments",
    //   link: `/agents/advance/payments/${agentid}`,
    //   permission: "read_agents",
    // },
    {
      name: "Bookings",
      link: `/agents/bookings/${agentid}`,
      permission: "read_agents",
    },
    {
      name: "Credit Finance Logs",
      link: `/agents/credit/Logs/${agentid}`,
      permission: "read_agents",
    },
    {
      name: "City Ledger Report",
      link: `/agents/city/Ledger/${agentid}`,
      permission: "read_agents",
    },
  ];
  const calculateToDate = (fromDate) => {
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() + 5);
    return toDate.toISOString().split("T")[0];
  };

  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: currentDate,
    to_date: calculateToDate(currentDate),
  });

  const dataFromChild = (from_date, to_date) => {
    setSelectedDatesRange({
      from_date: from_date,
      to_date: to_date,
    });
  };

  return (
    <>
      <div className="main_container">
        <SidebarInnerListsWithoutPermission innerLists={customers_inner_list} />

        <div style={{ display: "flex", gap: "10px" }}>
          <div style={{ width: "200px", marginTop: "20px" }}>
            <div style={{ marginBottom: "5px" }}>Date Range</div>
            <SelectDateRange
              dataFromChild={dataFromChild}
              defaultDate={`
              ${currentDate}
              :
              ${calculateToDate(currentDate)}
              `}
            />
          </div>
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <BgThemeButton
              children={"Apply"}
              onClick={() => fetchCityLedgerReport(property_id.id)}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px 15px 0 15px",
          }}
        >
          <div className="room-type-heading"></div>
          {/* <div>
            <WithoutBgButtonBlue
              style={{ fontSize: "14px", padding: "0" }}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g clipPath="url(#clip0_5311_4678)">
                    <path
                      d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                      stroke="#3968ED"
                    />
                    <path
                      d="M10.5868 7H3.41357"
                      stroke="#3968ED"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M7 3.4132L7 10.5864"
                      stroke="#3968ED"
                      stroke-miterlimit="10"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5311_4678">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              }
              children={"Add"}
              onClick={() => handleOpenAddCategoryClick()}
            />
          </div> */}
        </div>
        <section className="" style={{ width: "100%" }}>
          <ReportCityLedgerReport
            donShowTitle={true}
            departureReport={orders}
            fullData={fullData}
          />
        </section>
        {showModelMenuActive && (
          <>
            <div className="bg-backdrop">
              <section className="room-type-model-container ">
                <h4>Are You Sure?</h4>
                <p>You Want To Remove?</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button onClick={showModelMenu}>Discard</Button>
                  <Button onClick={() => deleteData()} destructive>
                    Yes, Remove
                  </Button>
                </div>
              </section>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AgentCityLedgerReport;
