import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import { calculateTotalWithReduce, formatIndianCurrency } from "../../utils/utils";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useState } from "react";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/svgIcons";

const ReportPaymentFoodSaleReport = ({ departureReport, loader, fullData }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [informationPopup, setInformationPopup] = useState(false);


  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell>{order.kot_date}</CustomReportTableCell>
        <CustomReportTableCell>{`KOT-` + order.kot_id}</CustomReportTableCell>

        <CustomReportTableCell>
          {" "}
          {order.kot_gst_rate ? order.kot_gst_rate + "%" : "0"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.kot_discount_rate ? order.kot_discount_rate + "%" : "0"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.kot_paid_amount
            ? formatIndianCurrency(order.kot_paid_amount)
            : "0"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.kot_balance_amount
            ? formatIndianCurrency(order.kot_balance_amount)
            : "0"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.kot_status ? (
            <UnderscoreRemoveCapitalize
              text={
                order.kot_status === "parsely_paid"
                  ? "Partially Paid"
                  : order.kot_status
              }
            />
          ) : (
            "N/A"
          )}
        </CustomReportTableCell>
      </CustomReportTableRow>
    ));


  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell colSpan={4}>{"Totals"}</CustomReportTableCell>


   <CustomReportTableCell></CustomReportTableCell>
   <CustomReportTableCell></CustomReportTableCell>
   <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "kot_paid_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "kot_balance_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>

    </CustomReportTableRow>
  );

  const extractedData = departureReport?.map((item) => ({
    kot_date: item && item?.kot_date ? item?.kot_date : "N/A",
    kot_id: item && item?.kot_id ? item?.kot_id : "N/A",
    kot_gst_rate: item && item?.kot_gst_rate ? item?.kot_gst_rate : "N/A",
    kot_discount_rate:
      item && item?.kot_discount_rate ? item?.kot_discount_rate : "N/A",
    kot_balance_amount:
      item && item?.kot_balance_amount ? item?.kot_balance_amount : "N/A",
    kot_paid_amount:
      item && item?.kot_paid_amount ? item?.kot_paid_amount : "N/A",
    kot_status: item && item?.kot_status ? item?.kot_status : "N/A",
  }));
  console.log(extractedData);
  const names = [
    {
      kot_date: "Date",
      kot_id: "KOT ID	",
      kot_gst_rate: "Gst Rate(%)	",
      kot_discount_rate: "Discount Rate (%)	",
      kot_balance_amount: "Balance Amount	",
      kot_paid_amount: "Paid Amount	",
      kot_status: "Status",
    },
  ];
  console.log(departureReport);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="room-types-full-container reports-new-background">
          <div></div>

          <div
            className="room-types-full-container-div"
            style={{ display: "block" }}
            id="table"
          >
               <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
               <div className="room-type-heading">
              Food Sales Report
              </div>
          <div
            className="hoverCursor"
            onClick={() => setInformationPopup(true)}
            id="ignoreFromPrint"
          >
           <SvgGetInfo />
          </div>
        </div>
          
              <div
                id="ignoreFromPrint"
                className="flex flex_gap_15 align_center"
                style={{ float: "right" }}
              >
                <ButtonToDownloadTablePDF
                  itemToDownloadID={"table"}
                  downloadFileName={"Food Sales Report"}
                  ignoreFromPrint={"ignoreFromPrint"}
                  pdfOrientation={"portrait"}
                  longPdf={true}
                  // pdfMargins={[0.1, 0.1, 0.1, 0.1]}
                />

                <DownloadCSVButton
                  itemToDownloadID={"table"}
                  downloadFileName={"Food Sales Report"}
                />
             
            </div>
            <div
              style={{ display: "flex", gap: "30px", margin: "10px 0 20px 0" }}
            >
              <div>
                <div>
                  {" "}
                  <strong>Subtotal</strong> :{" "}
                  {formatIndianCurrency(fullData?.sale_data?.SubTotal)}
                </div>
              </div>
              <div>
                <div>
                  {" "}
                  <strong>CGST</strong> :{" "}
                  {formatIndianCurrency(fullData?.sale_data?.CGST)}
                </div>
              </div>
              <div>
                <div>
                  {" "}
                  <strong>SGST</strong> :{" "}
                  {formatIndianCurrency(fullData?.sale_data?.SGST)}
                </div>
              </div>
              <div>
                <div>
                  {" "}
                  <strong>Total Tax Amount</strong> :{" "}
                  {formatIndianCurrency(fullData?.sale_data?.TotalTaxAmount)}
                </div>
              </div>
              <div>
                <div>
                  {" "}
                  <strong>Paid Amount</strong> :{" "}
                  {formatIndianCurrency(fullData?.sale_data?.PaidAmount)}
                </div>
              </div>
              <div>
                <div>
                  {" "}
                  <strong>Balance Amount</strong> :{" "}
                  {formatIndianCurrency(fullData?.sale_data?.BalanceAmount)}
                </div>
              </div>
              <div>
                <div>
                  {" "}
                  <strong>Net Total</strong> :{" "}
                  {formatIndianCurrency(fullData?.sale_data?.NetTotal)}
                </div>
              </div>
            </div>
            {departureReport && departureReport?.length > 0 ? (
              <>
               
                <CustomReportTable
                  headings={[
                    "Date",
                    "KOT ID",
                    "Gst Rate(%)",
                    "Discount Rate (%)",
                    "Paid Amount",
                    "Balance Amount",
                    "Status",
                  ]}
                
                  headerStyles={{
                    backgroundColor: "#dddddd",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                  tableStyles={{ textAlign: "left" }}
                >
                  {rowMarkup}
                  {calculateTotals}
                </CustomReportTable>
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      )}

{informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Food Sales Report"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is the Food Sales Report?",
                  answer: (
                    <p>
                     The <b>Food Sales Report</b> provides a detailed summary of all food-related transactions within a specified period, including details of each sale, tax amounts, discounts, and payment statuses. It records the KOT (Kitchen Order Tickets) generated, GST rates applied, total amounts paid, and outstanding balances.
                    </p>
                  ),
                },
                {
                  question:
                    "What is the Importance of the Food Sales Report?",
                  answer: (
                    <div>
                      <ul>
                        <li>
                        <b>Revenue Tracking:</b> Helps track daily food sales, ensuring accuracy in the recording of sales and payments.
                        </li>
                        <li>
                        <b>Tax Compliance: </b>Displays GST and other tax amounts, making it easier to comply with tax regulations.
                        </li>
                        <li>
                       <b> Financial Management:</b> Assists in reconciling payments, identifying unpaid or partially paid KOTs, and managing outstanding balances.
                        </li>
                        <li>
                        <b>Operational Insights: </b>Provides data for evaluating sales performance, popular menu items, and discount effectiveness, which aids in strategic planning.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportPaymentFoodSaleReport;
