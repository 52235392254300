import React from "react";
import Sidebar from "../Components/Sidebar";
import Agent from "../Components/Agent";
import Agent2 from "../Components/Agent2";
import NewAgents from "../Components/Agents/NewAgents";


const AgentPage = () => {
  return (
    <>
      <div >
        <NewAgents />
      </div>
    </>
  );
};

export default AgentPage;
