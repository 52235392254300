import React from "react";

const CustomerPageSliderCustomerDetails = ({ data }) => {
  return (
    <div>
      <div
        className="p-20"
        style={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr 1fr", // First column is wider
          gap: "16px 24px", // 16px vertical gap, 24px horizontal gap
        }}
      >
        <div className="flex flex_direction_col ">
          <span style={{ fontWeight: "600" }}>Customer Name </span>

          <span>{data?.name || "N/A"}</span>
        </div>
        <div className="flex flex_direction_col ">
          <span style={{ fontWeight: "600" }}>Email</span>
          <span>{data?.email || "N/A"}</span>
        </div>
        <div className="flex flex_direction_col ">
          <span style={{ fontWeight: "600" }}>Phone</span>
          <span>{data?.phone || "N/A"}</span>
        </div>
        <div className="flex flex_direction_col ">
          <span style={{ fontWeight: "600" }}>City</span>
          <span>{data?.city || "N/A"}</span>
        </div>
        <div className="flex flex_direction_col ">
          <span style={{ fontWeight: "600" }}> State </span>
          <span> {data?.state || "N/A"}</span>
        </div>
        <div className="flex flex_direction_col ">
          <span style={{ fontWeight: "600" }}>Country</span>
          <span>{data?.country || "N/A"}</span>
        </div>
        <div className="flex flex_direction_col ">
          <span style={{ fontWeight: "600" }}> Address</span>
          <span>{data?.address || "N/A"}</span>
        </div>
        <div className="flex flex_direction_col ">
          <span style={{ fontWeight: "600" }}>Pincode</span>
          <span> {data?.pincode || "N/A"}</span>
        </div>
        <div className="flex flex_direction_col ">
          <span style={{ fontWeight: "600" }}>Identity Proof Type</span>
          <span>{data?.identity_proof_type || "N/A"}</span>
        </div>
        <div className="flex flex_direction_col ">
          <span style={{ fontWeight: "600" }}>Identity Proof Number</span>
          <span>{data?.identity_proof_no || "N/A"}</span>
        </div>
      </div>
    </div>
  );
};

export default CustomerPageSliderCustomerDetails;
