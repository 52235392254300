import {
  ChoiceList,
  Collapsible,
  Icon,
  LegacyCard,
  LegacyStack,
  Link,
  Page,
} from "@shopify/polaris";
import React, { useRef, useState, useEffect } from "react";
import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { BgOutlineThemeButton, BgThemeButton } from "../UI/Buttons";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import VerticalTextAreaCustom from "../CustomComponents/VerticalTextAreaCustom";
import { websites_inner_lists } from "../../assets/constant";
import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";

// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { useNavigate } from "react-router";
import ReactQuill from "react-quill";
import { getDecryptedData } from "../../utils/encryptStorage";

const AddPageComp = () => {
  const permissions = localStorage.getItem("permissions");
  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);
  const [jsonData, setJsonData] = useState("");
  const [formValues, setFormValues] = useState();
  const [selectedVisibility, setSelectedVisibility] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const [editorHtml, setEditorHtml] = useState("");

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["align", "code-block"],
      ["link", "image"],
      ["undo", "redo"], // Add undo and redo options
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "align",
    "code-block",
    "link",
    "image",
  ];

  const handleTextChange = (value) => {
    setEditorHtml(value);
  };

  const [addPageData, setAddpageData] = useState({
    title: "",
    type: "page",
    content: "",
    content: "",
    status: "",
    // page_category: "",
    meta_title: "",
    meta_description: "",
    url_handle: "",
  });

  const handleValueChange = (event) => {
    setAddpageData({
      ...addPageData,
      [event.target.name]: event.target.value,
    });
  };

  const options = [
    { label: "Travel", value: "Travel" },
    { label: "Adventure", value: "Adventure" },
    { label: "Gk", value: "Gk" },
  ];

  const handleChangeVisibility = (value) => {
    setSelectedVisibility(value[0]);
  };

  useEffect(() => {
    setAddpageData({
      ...addPageData,
      content: editorHtml,
      status: selectedVisibility,
    });
  }, [selectedVisibility, editorHtml]);

  const handleSubmitAddDiscount = (e) => {
    setLoader(true)
    e.preventDefault();
    {
      addPageData.status === "" && showErrorToast("Please Select Visibility!");
    }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/store/post/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...addPageData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast("Page Created Successfully!");
          setAddpageData({
            title: "",
            type: "page",
            content: "",
            content: "",
            status: "",
            meta_title: "",
            meta_description: "",
            url_handle: "",
          });
          setLoader(false)
          navigate("/websites/pages");
        } else if (data.error) {
          setLoader(false)
          // const firstErrorKey = Object.keys(data.error)[0];
          // const firstErrorMessage = data.error[firstErrorKey][0];
          showErrorToast(data.message);
        } else {
          setLoader(false)
          showErrorToast(data.message);
        }
      });
  };

  return (
    <>
      {/* <SidebarInnerLists innerLists={websites_inner_lists} /> */}
      <form
        className="edit-rooms-full-container"
        onSubmit={(e) => handleSubmitAddDiscount(e)}
      >
        <div>
          <Page
            backAction={{ content: "Products", url: `/websites/pages` }}
            title="Add Page"
            compactTitle
          ></Page>
        </div>
        <div className="edit-rooms-full-container-div">
          <div className="edit-rooms-full-container-left">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                width: "591px",
              }}
            >
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      aria-controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    >
                      <div>Page details</div>
                    </div>
                    <div className="edit-rooms-form-wrapper-container">
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            required={true}
                            focused={false}
                            name="title"
                            titleName="Title"
                            onChange={handleValueChange}
                            value={addPageData.title}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="editor_container">
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          value={editorHtml}
                          onChange={handleTextChange}
                          style={{ border: "1px solid #ccc" }}
                        />
                      </div>
                    </div>
                  </LegacyStack>
                </LegacyCard>
              </div>
              {/* <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Search Engine Listing</div>
                    </div>

                    <p
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#888",
                      }}
                    >
                      Add a title and description to see how this Room might
                      appear
                      <br />
                      in a search engine listing
                    </p>
                    <div className="edit-rooms-form-container">
                      <div className="form-container-div-full">
                        <VerticalInputFieldCustom
                          name="meta_title"
                          value={addPageData.page_title}
                          focused={false}
                          titleName="Page Title"
                          onChange={handleValueChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-container-div-full">
                        <VerticalInputFieldCustom
                          name="meta_description"
                          value={addPageData.meta_description}
                          focused={false}
                          titleName="Meta Description"
                          onChange={handleValueChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-container-div-full">
                        <VerticalInputFieldCustom
                          name="url_handle"
                          value={window.location.href}
                          focused={false}
                          titleName="Url Handle"
                          onChange={handleValueChange}
                          autoComplete="off"
                          disabled={true}
                        />
                       
                      </div>
                    </div>
                  </LegacyStack>
                </LegacyCard>
              </div> */}
              <div className="rooms-edit-left-container-buttons">
                <div>
                  <BgThemeButton type="submit" children={"Add"} loader={loader}/>
                </div>
                <div style={{ color: "rgba(0, 122, 92, 1)" }}>
                  <BgOutlineThemeButton
                    onClick={() => navigate("/websites/pages")}
                    type="button"
                    children={"Discard"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="edit-rooms-full-container-right">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Visibility</div>
                    </div>
                    <ChoiceList
                      choices={[
                        { label: "Visible", value: "visible" },
                        { label: "Hidden", value: "hidden" },
                      ]}
                      selected={selectedVisibility}
                      onChange={handleChangeVisibility}
                    />
                  </LegacyStack>
                </LegacyCard>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddPageComp;
