import { Button, DataTable, LegacyCard, Tooltip } from "@shopify/polaris";
import { useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  CheckWithoutBgUnderlineBtn,
  WithoutBgButtonBlue,
} from "../UI/Buttons";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../CustomComponents/NoDataFound";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import Loader from "../UI/Loader/Loader";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import LoaderSmall from "../UI/Loader/LoaderSmall";
import { getDecryptedData } from "../../utils/encryptStorage";
import { Curtain } from "../NewCustomComp/Curtain/Curtain";
import StaffPermissions from "../StaffPermissions";
import { SvgAccordion } from "../../assets/svgIcons";
import SliderComponentSkeleton from "../NewCustomComp/SliderComponentSkelon/SliderComponentSkeleton";
import PermissionComponent from "./PermissionComponent/PermissionComponent";

const ToggleActiveDisable = (props) => {
  const [isToggled, setIsToggled] = useState(props.status === "enable");
  const [beddingFinalValue, setBeddingFinalValue] = useState("");

  const userToken = getDecryptedData("encryptToken");
  const role = JSON.parse(localStorage.getItem("role"));
  const user = JSON.parse(localStorage.getItem("user"));
  const property_id = useSelector((state) => state.property.value);

  const handleToggle = useCallback(() => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  }, []);

  const handleToggleStatus = async () => {
    if (isToggled) {
      // setBeddingFinalValue("enabled");
      await handleDisable();
    } else {
      await handleEnable();
      // setBeddingFinalValue("enabled");
    }
  };
  const [loader, setLoader] = useState(true);
  const currentDate = new Date().toISOString().split("T")[0];
  const handleDisable = async () => {
    const userId = props.userId;
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/account/disable/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ user_id: userId }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setIsToggled(false);
        // window.location.reload();
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const handleEnable = async () => {
    const userId = props.userId;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/account/enable/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ user_id: userId }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
        setIsToggled(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  useEffect(() => {
    if (isToggled === false) {
      setBeddingFinalValue("Disabled");
    } else {
      setBeddingFinalValue("Enabled");
    }
  }, [isToggled]);

  return (
    <div className="flex align_items_center">
      <span className="toggle_text">{beddingFinalValue}</span>
      <label className="toggle-container">
        <input
          name="extra_bedding"
          type="checkbox"
          checked={isToggled}
          onChange={handleToggle}
          className="toggle-checkbox-custom"
        />
        <span className="toggle-slider" onClick={handleToggleStatus}></span>
      </label>
    </div>
  );
};

const RolesPermissions = () => {
  const permission = JSON.parse(localStorage.getItem("permissions"));

  // const permissions = localStorage.getItem("permissions")
  const [staffs, setStaffs] = useState([]);
  const navigate = useNavigate();
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value);
  const [showAddStaffPopup, setShowAddStaffPopup] = useState(false);
  const role = JSON.parse(localStorage.getItem("role"));
  const [loader, setLoader] = useState(false);
  const [staffLimit, setStaffLimit] = useState("");
  const [features, setFeatures] = useState("");

  useEffect(() => {
    if (property_id) {
      setFeatures(property_id?.subscriptions.plan.features);
    }
    const comp =
      features &&
      features.find((feature) => feature.plan_name === "Staff Account");
    setStaffLimit(comp?.plan_limit || 0);
  }, [property_id, staffLimit]);

  const handleshowAddStaffPopup = () => {
    setShowAddStaffPopup(!showAddStaffPopup);
  };

  const [addTeamData, setAddTeamData] = useState({
    name: "",
    email: "",
    property_id: property_id?.id,
  });

  const setAddTeamDataHandleChange = (event) => {
    setAddTeamData({
      ...addTeamData,
      [event.target.name]: event.target.value,
    });
  };

  const fetchInfo = async (id) => {
    if (!id) {
      navigate("/login");
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/account/get-user/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setStaffs(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [fetchedRoles, setFetchedRoles] = useState([]);

  const fetchRoles = async (id) => {
    if (!id) {
      navigate("/login");
    }
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/property/roles/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setFetchedRoles(data.data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const [deleteStaffId, setDeleteStaffId] = useState(false);

  const deleteData = async (staffId) => {
    // "http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/assign/rooms/delete"
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/account/delete-staff-user/${staffId}/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // clearSelection();
          fetchInfo(property_id?.id);
          // clearSelection();
          // setShowLowerMenuMultipleSelect(false);
          // setShowModelMenuActive(false);
          setShowModelMenuActive(!showModelMenuActive);
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
          setShowModelMenuActive(!showModelMenuActive);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      fetchRoles(property_id?.id);
    }
  }, [property_id]);

  const [userDate, setUserData] = useState();
  const [selectedStaffName, setSelectedStaffName] = useState("");

  const combinedTableRows =
    staffs &&
    staffs?.map((staff) => [
      staff.name,
      staff.email,
      <UnderscoreRemoveCapitalize text={staff.role_name} />,
      <>
        {role === "owner" && (
          <ToggleActiveDisable
            positive={"enable"}
            negative={"disable"}
            status={staff.status}
            userId={staff.id}
          />
        )}
      </>,
      ,
      <div>
        {role === "owner" && (
          <>
            {/* {permission &&
            permission?.includes("full_access_property_settings") ? (
              <BgOutlineThemeButton
                children={"Edit Permissions"}
                // onClick={() => navigate(`/staffpermissions/${staff.id}`)}
                onClick={() => {
                  setOpenEdit(staff);
                  setShowSlider(true);
                }}
              />
            ) : (
              <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                <BgOutlineThemeButton children={"Edit Permissions"} disabled />
              </Tooltip>
            )} */}
             <BgOutlineThemeButton
                children={"Edit Permissions"}
                // onClick={() => navigate(`/staffpermissions/${staff.id}`)}
                onClick={() => {
                  setOpenEdit(staff);
                  setShowSlider(true);
                }}
              />
          </>
        )}
      </div>,
      <>
        {permission && permission.includes("full_access_property_settings") ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => [
              setDeleteStaffId(staff.id),
              setShowModelMenuActive(true),
            ]}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                fill="#E03838"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                fill="#E03838"
              />
            </svg>
          </div>
        ) : (
          <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
            <div style={{ cursor: "pointer", opacity: "0.7" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                  fill="#E03838"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                  fill="#E03838"
                />
              </svg>
            </div>
          </Tooltip>
        )}
      </>,
    ]);

  const [options, setOptions] = useState([
    {
      id: 1,
      name: "Reservations",
      permission: [
        {
          id: "create_reservations", //t
          label: "Create reservations",
          value: "create_reservations",
        },
        {
          id: "update_reservations",
          label: "Update reservations",
          value: "update_reservations",
        },
        {
          id: "delete_reservations",
          label: "Delete reservations",
          value: "delete_reservations",
        },
        {
          id: "read_reservations",
          label: "Read reservations",
          value: "read_reservations",
        },
        {
          id: "show_single_booking",
          label: "Show single booking",
          value: "show_single_booking",
        },
        {
          id: "show_preview_bill",
          label: "Show preview booking",
          value: "show_preview_bill",
        },
      ],
    },
    {
      id: 2,
      name: "Dashboard",
      permission: [
        {
          id: "create_dashboard",
          label: "Create dashboard",
          value: "create_dashboard",
        },
        {
          id: "update_dashboard",
          label: "Update dashboard",
          value: "update_dashboard",
        },
        {
          id: "delete_dashboard",
          label: "Delete dashboard",
          value: "delete_dashboard",
        },
        {
          id: "read_dashboard",
          label: "Read dashboard",
          value: "read_dashboard",
        },
      ],
    },
    {
      id: 3,
      name: "Rooms",
      permission: [
        { id: "create_rooms", label: "Create rooms", value: "create_rooms" },
        { id: "update_rooms", label: "Update rooms", value: "update_rooms" },
        { id: "delete_rooms", label: "Delete rooms", value: "delete_rooms" },
        { id: "read_rooms", label: "Read rooms", value: "read_rooms" },
      ],
    },
    {
      id: 16,
      name: "Rooms Type",
      permission: [
        {
          id: "read_room_types",
          label: "Read room types",
          value: "read_room_types",
        },
        {
          id: "update_room_types",
          label: "Update room types",
          value: "update_room_types",
        },
        {
          id: "delete_room_types",
          label: "Delete room types",
          value: "delete_room_types",
        },
        {
          id: "create_room_types",
          label: "Create room types",
          value: "create_room_types",
        },
      ],
    },
    {
      id: 4,
      name: "Customers",
      permission: [
        {
          id: "create_customers",
          label: "Create customers",
          value: "create_customers",
        },
        {
          id: "update_customers",
          label: "Update customers",
          value: "update_customers",
        },
        {
          id: "delete_customers",
          label: "Delete customers",
          value: "delete_customers",
        },
        {
          id: "read_customers",
          label: "Read customers",
          value: "read_customers",
        },
      ],
    },
    {
      id: 5,
      name: "Rooms",
      permission: [
        { id: "create_rooms", label: "Create rooms", value: "create_rooms" },
        { id: "update_rooms", label: "Update rooms", value: "update_rooms" },
        { id: "delete_rooms", label: "Delete rooms", value: "delete_rooms" },
        { id: "read_rooms", label: "Read rooms", value: "read_rooms" },
      ],
    },
    {
      id: 15,
      name: "Inventory",
      permission: [
        {
          id: "read_inventory",
          label: "Read inventory",
          value: "read_inventory",
        },
        {
          id: "read_bulk_inventory",
          label: "Read bulk_inventory",
          value: "read_bulk_inventory",
        },
        {
          id: "read_block_inventory",
          label: "Read block inventory",
          value: "read_block_inventory",
        },
        {
          id: "read_unhold_inventory",
          label: "Read unhold inventory",
          value: "read_unhold_inventory",
        },
        {
          id: "read_unblock_inventory",
          label: "Read unblock inventory",
          value: "read_unblock_inventory",
        },
        {
          id: "read_hold_inventory",
          label: "Read hold inventory",
          value: "read_hold_inventory",
        },
        {
          id: "update_hold_inventory",
          label: "Update hold inventory",
          value: "update_hold_inventory",
        },
        {
          id: "update_bulk_inventory",
          label: "Update bulk inventory",
          value: "update_bulk_inventory",
        },
        {
          id: "update_unhold_inventory",
          label: "Update unhold inventory",
          value: "update_unhold_inventory",
        },
        {
          id: "update_unblock_inventory",
          label: "Update unblock inventory",
          value: "update_unblock_inventory",
        },
        {
          id: "update_block_inventory",
          label: "Update block inventory",
          value: "update_block_inventory",
        },
      ],
    },
    {
      id: 6,
      name: "Room Rate Plans",
      permission: [
        {
          id: "create_room_rate_plans",
          label: "Create room rate_plans",
          value: "create_room_rate_plans",
        },
        {
          id: "update_room_rate_plans",
          label: "Update read room_rate_plans",
          value: "update_room_rate_plans",
        },
        {
          id: "delete_room_rate_plans",
          label: "Delete read room rate plans",
          value: "delete_room_rate_plans",
        },
        {
          id: "read_room_rate_plans",
          label: "Read room rate plans",
          value: "read_room_rate_plans",
        },
      ],
    },
    {
      id: 22,
      name: "Hold Rooms",
      permission: [
        {
          id: "create_room_rate_plans",
          label: "Create room rate_plans",
          value: "create_room_rate_plans",
        },
        {
          id: "update_room_rate_plans",
          label: "Update read room_rate_plans",
          value: "update_room_rate_plans",
        },
        {
          id: "delete_room_rate_plans",
          label: "Delete read room rate plans",
          value: "delete_room_rate_plans",
        },
        {
          id: "read_hold_plans",
          label: "Read Hotel plans",
          value: "read_hold_plans",
        },
      ],
    },
    {
      id: 7,
      name: "Room Plans",
      permission: [
        {
          id: "create_room_plans",
          label: "Create room plans",
          value: "create_room_plans",
        },
        {
          id: "update_room_plans",
          label: "Update read room plans",
          value: "update_room_plans",
        },
        {
          id: "delete_room_plans",
          label: "Delete read room plans",
          value: "delete_room_plans",
        },
        {
          id: "read_room_plans",
          label: "Read room  plans",
          value: "read_room_plans",
        },
      ],
    },
    {
      id: 8,
      name: "Room Plans",
      permission: [
        { id: "create_plans", label: "Create plans", value: "create_plans" },
        { id: "update_plans", label: "Update plans", value: "update_plans" },
        { id: "delete_plans", label: "Delete plans", value: "delete_plans" },
        { id: "read_plans", label: "Read plans", value: "read_plans" },
      ],
    },
    {
      id: 9,
      name: "Suppliers",
      permission: [
        {
          id: "create_suppliers",
          label: "Create suppliers",
          value: "create_suppliers",
        },
        {
          id: "update_suppliers",
          label: "Update suppliers",
          value: "update_suppliers",
        },
        {
          id: "delete_suppliers",
          label: "Delete suppliers",
          value: "delete_suppliers",
        },
        {
          id: "read_suppliers",
          label: "Read suppliers",
          value: "read_suppliers",
        },
      ],
    },
    {
      id: 10,
      name: "Purchases",
      permission: [
        {
          id: "create_purchases",
          label: "Create purchases",
          value: "create_purchases",
        },
        {
          id: "update_purchases",
          label: "Update purchases",
          value: "update_purchases",
        },
        {
          id: "delete_purchases",
          label: "Delete purchases",
          value: "delete_purchases",
        },
        {
          id: "read_purchases",
          label: "Read purchases",
          value: "read_purchases",
        },
        {
          id: "issue_item_purchases",
          label: "Issue item purchases",
          value: "issue_item_purchases",
        },
      ],
    },
    {
      id: 11,
      name: "Expenses",
      permission: [
        {
          id: "create_expenses",
          label: "Create expenses",
          value: "create_expenses",
        },
        {
          id: "update_expenses",
          label: "Update expenses",
          value: "update_expenses",
        },
        {
          id: "delete_expenses",
          label: "Delete expenses",
          value: "delete_expenses",
        },
        { id: "read_expenses", label: "Read expenses", value: "read_expenses" },
      ],
    },
    {
      id: 12,
      name: "KOT",
      permission: [
        { id: "create_kot", label: "Create KOT", value: "create_kot" },
        { id: "update_kot", label: "Update KOT", value: "update_kot" },
        { id: "delete_kot", label: "Delete KOT", value: "delete_kot" },
        { id: "read_kot", label: "Read KOT", value: "read_kot" },
        { id: "view_kot_bill", label: "View KOT Bill", value: "view_kot_bill" },
        {
          id: "generate_kot_bill",
          label: "Generate KOT Bill",
          value: "generate_kot_bill",
        },
        {
          id: "cancel_kot_bill",
          label: "Cancel KOT Bill",
          value: "cancel_kot_bill",
        },
      ],
    },
    {
      id: 13,
      name: "Food Sale",
      permission: [
        {
          id: "read_food_sale",
          label: "Read Food Sale",
          value: "read_food_sale",
        },
        {
          id: "create_food_sale",
          label: "Create Food Sale",
          value: "create_food_sale",
        },
        {
          id: "settle_bill_food_sale",
          label: "Settle bill food sale",
          value: "settle_bill_food_sale",
        },
        {
          id: "view_bill_food_sale",
          label: "View bill food sale",
          value: "view_bill_food_sale",
        },
        {
          id: "generate_bill_food_sale",
          label: "Generate bill food sale",
          value: "generate_bill_food_sale",
        },
      ],
    },
    {
      id: 14,
      name: "Agent",
      permission: [
        // { id: "create_agent", label: "Create Agent", value: "create_agent" },
        // { id: "update_agent", label: "Update Agent", value: "update_agent" },
        // { id: "delete_agent", label: "Delete Agent", value: "delete_agent" },
        { id: "read_agents", label: "Read Agents", value: "read_agents" },
      ],
    },
    {
      id: 20,
      name: "Items",
      permission: [
        // { id: "create_agent", label: "Create Agent", value: "create_agent" },
        // { id: "update_agent", label: "Update Agent", value: "update_agent" },
        // { id: "delete_agent", label: "Delete Agent", value: "delete_agent" },
        { id: "read_items", label: "Read Items", value: "read_items" },
      ],
    },
    {
      id: 21,
      name: "Settings",
      permission: [
        // { id: "create_agent", label: "Create Agent", value: "create_agent" },
        // { id: "update_agent", label: "Update Agent", value: "update_agent" },
        // { id: "delete_agent", label: "Delete Agent", value: "delete_agent" },
        { id: "read_settings", label: "Read Settings", value: "read_settings" },
      ],
    },
  ]);

  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (id) => {
    setActiveAccordion((prevId) => (prevId === id ? null : id));
    toggleIconRotation(id);
  };

  const toggleIconRotation = (id) => {
    const icon = document.getElementById(`icon-${id}`);
    if (icon) {
      icon.classList.toggle("rotateicon");
    }
  };

  const handleCheckboxChange = (optionId) => {
    setSelectedOptions((prevSelectedOptions) => {
      const optionSelectedPermissions = prevSelectedOptions[optionId] || [];

      // Check if all permission are already selected
      const allPermissionsSelected =
        optionSelectedPermissions.length ===
        options.find((opt) => opt.id === optionId).permission.length;

      const updatedPermissions = allPermissionsSelected
        ? [] // If all permission are already selected, clear the array
        : options
            .find((opt) => opt.id === optionId)
            .permission.map((permission) => permission.value);

      return {
        ...prevSelectedOptions,
        [optionId]: updatedPermissions,
      };
    });
  };

  const handlePermissionChange = (optionId, permissionId) => {
    setSelectedOptions((prevSelectedOptions) => {
      const optionSelectedPermissions = prevSelectedOptions[optionId] || [];
      const updatedPermissions = optionSelectedPermissions.includes(
        permissionId
      )
        ? optionSelectedPermissions.filter((perm) => perm !== permissionId)
        : [...optionSelectedPermissions, permissionId];

      return {
        ...prevSelectedOptions,
        [optionId]: updatedPermissions,
      };
    });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedOptions({});
    } else {
      const allSelected = {};
      options.forEach((option) => {
        allSelected[option.id] = option.permission.map(
          (permission) => permission.value
        );
      });
      setSelectedOptions(allSelected);
    }
    setSelectAll(!selectAll);
  };

  const sendDataToAPI = () => {
    const sendData = {};
    options.forEach((option) => {
      if (selectedOptions[option.id] && selectedOptions[option.id].length > 0) {
        sendData[option.name] = selectedOptions[option.id];
      }
    });
  };

  const [staffPermissions, setStaffPermissions] = useState();

  const sendDataInDesiredFormat = (selectedOptions) => {
    const permissionValues = [];

    for (const optionId in selectedOptions) {
      if (selectedOptions.hasOwnProperty(optionId)) {
        if (optionId !== "create_reservation") {
          const selectedPermissions = selectedOptions[optionId];
          if (Array.isArray(selectedPermissions)) {
            selectedPermissions.forEach((permission) => {
              permissionValues.push(permission);
            });
          }
        }
      }
    }

    return permissionValues;
  };

  const selectedPermissions = sendDataInDesiredFormat(selectedOptions);
  const totalItems = options.reduce(
    (total, option) => total + option.permission.length,
    0
  );

  const totalItemsSelected = Object.values(selectedOptions).reduce(
    (total, selectedPermissions) => total + selectedPermissions.length,
    0
  );

  const [permissions, setPermissions] = useState(permission);

  //
  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);

  const [selectedRole, setSelectedRole] = useState(null);
  const [selectPermissions, setSelectPermissions] = useState({});
  const handleRoleChange = (role) => {
    setSelectedRole(role);
  };

  const handleSubmitAddTeam = (e) => {
    e.preventDefault();

    if (!selectedRole) {
      showErrorToast("Please select a role first...");
      return;
    }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/account/create-user/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addTeamData,
          permission: selectedPermissions,
          role: selectedRole,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          fetchInfo(property_id?.id);
          showSuccessToast(data.message);
          setAddTeamData({
            name: "",
            email: "",
          });
          setShowAddStaffPopup(false);
        } else {
          const firstErrorKey = Object.keys(data.data)[0];
          const firstErrorMessage = data.data[firstErrorKey][0];
          showErrorToast(firstErrorMessage);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const [getPermissions, setGetPermissions] = useState([]);

  const fetchPermissionList = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get-plan-permissions/${property_id?.id}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        setGetPermissions(data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPermissionList();
  }, [userToken]);

  const [permissionAccordion, setPermissionAccordion] = useState(false);

  const handlePermissionAccordion = (index) => {
    setPermissionAccordion(permissionAccordion === index ? null : index);
  };

  const [openEdit, setOpenEdit] = useState("");
  const [showSlider, setShowSlider] = useState(false);

  console.log(openEdit, "hii");
  return (
    <>
      {showSlider && (
        <SliderComponentSkeleton
          smallTitle={`<span style="font-weight:600; font-size:16px">Permissions</span>`}
          title={
            openEdit
              ? `<span class="flex flex_direction_col"><span>${openEdit.name}</span> <span style="text-align:right">${openEdit.role_name}</span></span>`
              : ""
          }
          show={showSlider}
          setShow={setShowSlider}
        >
          <PermissionComponent
            staff={openEdit}
            show={showSlider}
            setShow={setShowSlider}
          />
        </SliderComponentSkeleton>
      )}

      <div className="setting_location_sec">
        <LegacyCard>
          <div
            style={{ paddingLeft: "15px", paddingRight: "15px" }}
            className="room-types-full-container-div"
          >
            <div style={{ marginTop: "20px" }} className="room-type-heading">
              Staff {`(${staffs?.length}/${staffLimit})`}
            </div>

            <div style={{ marginTop: "20px" }}>
              {permission &&
              permission.includes("full_access_property_settings") ? (
                <BgOutlineThemeButton
                  children={"Add Staff"}
                  onClick={handleshowAddStaffPopup}
                />
              ) : (
                <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                  <BgOutlineThemeButton disabled children={"Add Staff"} />
                </Tooltip>
              )}
            </div>
          </div>

          {loader ? (
            <LoaderSmall />
          ) : (
            <>
              {combinedTableRows && combinedTableRows.length > 0 ? (
                <DataTable
                  columnContentTypes={["text", "text", "text", "text"]}
                  headings={["Name", "Email", "Role", " ", " ", " ", " "]}
                  rows={combinedTableRows}
                  verticalAlign="center"
                />
              ) : (
                <NoDataFound />
              )}
            </>
          )}
        </LegacyCard>
      </div>

      {showAddStaffPopup && (
        <div className="bg-backdrop">
          {/* <div
            style={{ width: "700px" }}
            className="dashboard_checkin_modal_container"
          > */}
          <form
            onSubmit={(e) => e.preventDefault()}
            style={{ width: "700px" }}
            className="stock-add-model-container"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div className="add-ota-model-container-heading">Add Staff</div>
              <div style={{ cursor: "pointer" }}></div>
            </div>

            <div className="form-container-div-half">
              <div className="form-container-div">
                <VerticalInputFieldCustom
                  required={true}
                  titleName="Name"
                  name="name"
                  type="text"
                  value={addTeamData.name}
                  onChange={setAddTeamDataHandleChange}
                />
              </div>
              <div className="form-container-div">
                <VerticalInputFieldCustom
                  required={true}
                  titleName="Email"
                  name="email"
                  type="email"
                  value={addTeamData.email}
                  onChange={setAddTeamDataHandleChange}
                />
              </div>
            </div>

            {/* <div>
              <h1>Select Options</h1>
              <label>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />{" "}
                Select All
              </label>
              <br />
              <br />
              <div style={{ display: "flex", flexDirection: "column" }}>
                {options.map((option) => (
                  <div
                    key={option.id}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>
                      <input
                        type="checkbox"
                        checked={
                          selectedOptions[option.id] &&
                          selectedOptions[option.id].length ===
                            option.permission.length
                        }
                        onChange={() => handleCheckboxChange(option.id)}
                      />
                      {option.name}
                    </label>

                    {option.permission.map((permission) => (
                      <label key={permission.id}>
                        <input
                          type="checkbox"
                          checked={
                            selectedOptions[option.id] &&
                            selectedOptions[option.id].includes(
                              permission.value
                            )
                          }
                          onChange={() =>
                            handlePermissionChange(option.id, permission.value)
                          }
                        />{" "}
                        {permission.label}
                      </label>
                    ))}
                  </div>
                ))}
              </div>
              <button onClick={sendDataToAPI}>Send Data to API</button>
            </div> */}

            {/* {permissions && permissions.includes("assign_permissions") && (
              <> */}
            <div
              className="permission_container"
              style={{ borderBottom: "0.5px solid #ccc" }}
            >
              <h1 className="permission_heading" style={{ margin: "10px" }}>
                Select Roles
              </h1>
              <div
                className="permission_checkBox_container"
                style={{ paddingBottom: "0" }}
              ></div>
              <div
                style={{
                  height: "170px",
                  overflow: "scroll",
                  display: "flex",
                  gap: "10px",
                  padding: "10px",
                  // flexWrap: "wrap",
                  flexDirection: "column",
                }}
              >
                {fetchedRoles && fetchedRoles.length > 0 ? (
                  <>
                    {fetchedRoles.map((role) => {
                      return (
                        <label className="flex" style={{ gap: "3px" }}>
                          <input
                            type="radio"
                            name="role"
                            value={role.name}
                            checked={selectedRole === role.name}
                            onChange={() => handleRoleChange(role.name)}
                          />
                          <div>
                            {" "}
                            <UnderscoreRemoveCapitalize text={role.name} />
                          </div>
                        </label>
                      );
                    })}
                  </>
                ) : (
                  <div>No Roles Added.</div>
                )}
              </div>
            </div>
            {/* </>
            )} */}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  gap: "10px",
                }}
              >
                <BgThemeButton
                  onClick={(e) => handleSubmitAddTeam(e)}
                  type="submit"
                  loader={loader}
                  children={"Add"}
                />
                <Button onClick={handleshowAddStaffPopup}>Cancel</Button>
              </div>
            </div>
          </form>
        </div>
      )}

      {showModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={() => setShowModelMenuActive(!showModelMenuActive)}
                >
                  Discard
                </Button>
                <Button onClick={() => deleteData(deleteStaffId)} destructive>
                  Yes, Remove
                </Button>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};

export default RolesPermissions;
