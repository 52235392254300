import React, { useState, useCallback, useEffect } from "react";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import VerticalTextAreaCustom from "../CustomComponents/VerticalTextAreaCustom";
import { useSelector } from "react-redux";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  CheckWithoutBgUnderlineBtn,
} from "../UI/Buttons";
import Loader from "../UI/Loader/Loader";
import { Collapsible, Tag, Tooltip } from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { Icon, LegacyCard, LegacyStack } from "@shopify/polaris";
import { CancelMajor } from "@shopify/polaris-icons";
import LoaderSmall from "../../Components/UI/Loader/LoaderSmall";
import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import { fetchProperties } from "../../reduxToolkit/Slice/propertyslice";
import { getBaseUrl } from "../../utils/utils";
import { getDecryptedData } from "../../utils/encryptStorage";

const BrandSettings = () => {
  const permission = localStorage.getItem("permissions");
  const baseUrl = getBaseUrl();
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const [loaderVid, setLoaderVid] = useState(false);

  const [formData, setFromData] = useState({});

  useEffect(() => {
    if (property_id) {
      getOneData(property_id?.id);
    }
  }, [property_id]);

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/properties/edit/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        property_name: data.property.property_name,
        property_phone: data.property.property_phone,
        property_landline: data.property.property_landline,
        property_landline2: data.property.property_landline2,
        property_email: data.property.property_email,
        property_alternate_email: data.property_alternate_email,
        property_hotel_type: data.property.property_hotel_type,
        food_prefix: data.property.food_prefix,
        booking_prefix: data.property.booking_prefix,
        property_address: data.property.property_address,
        property_country: data.property.property_country,
        property_city: data.property.property_city,
        property_state: data.property.property_state,
        property_tax_rate: data.property.property_tax_rate,
        property_zip: data.property.property_zip,
        property_description: data.property.property_description,
        // ..................

        property_checkin: data.property.property_checkin,
        property_checkout: data.property.property_checkout,

        facebook_url: data.property.facebook_url,
        instagram_url: data.property.instagram_url,
        twitter_url: data.property.twitter_url,
        property_linkedin: data.property.linkedin_url,
        property_youtube: data.property.youtube_url || "",
        tripadvisor_url: data.property.tripadvisor_url || "",
        property_threads: data.property.threads,
        property_whatsapp: data.property.property_whatsapp,
        property_status: data.property.property_status,
        property_hotel_id: data.property.property_hotel_id,

        rates_and_deals_timespan: data.property.rates_and_deals_timespan,
        tentative_hold_timespan: data.property.tentative_hold_timespan,
        tentative_reconfirmation_time:
          data.property.tentative_reconfirmation_time,
        secondary_color: data.property.secondary_color,
        primary_color: data.property.primary_color,
      };
      setFromData(updatedData);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const handleBrandSettingSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    fetch(`${baseUrl}/api/v1/properties/update/${property_id?.id}`, {
      method: "POST",
      body: JSON.stringify({
        ...formData,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          showSuccessToast(data.message);
          getOneData(property_id?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleFormDataInputChange = (e) => {
    const { name, value } = e.target;
    setFromData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //  logics fro images
  const [fetchedImages, setFetchedImages] = useState([]);
  const [fetchedPropertyLogo, setFetchedPropertyLogo] = useState([]);

  const fetchPropertyImages = (property_id) => {
    const formData = new FormData();
    formData.append("media_type", "property");
    formData.append("associated_id", property_id);
    fetch(`${baseUrl}/api/v1/media/fetch/${property_id}`, {
      method: "POST",
      body: formData,
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setFetchedImages(data.media.property_images);
        setFetchedPropertyVideos(data?.media?.property_videos);
      });
  };

  const fetchPropertyLogo = (property_id) => {
    const formData = new FormData();
    formData.append("media_type", "property_logo");
    formData.append("associated_id", property_id);
    fetch(`${baseUrl}/api/v1/media/fetch/${property_id}`, {
      method: "POST",
      body: formData,
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setFetchedPropertyLogo(data?.media?.propertyLogo);
      });
  };

  useEffect(() => {
    if (property_id) {
      fetchPropertyImages(property_id?.id);
      fetchPropertyLogo(property_id?.id);
    }
  }, [property_id]);

  // handlers for images
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [propertyLogoLoader, setPropertyLogoLoader] = useState(false);
  const [uploadedLogoFile, setUploadedLogoFile] = useState(null);
  const [logoUrl, setLogoUrl] = useState([]);

  const handleFileUploadChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
    const file = files[0];
    const fileUrl = URL.createObjectURL(file);
    setUploadedFile(file);
    setLogoUrl(fileUrl);
  };

  const deleteFetchedImages = async (propertyImage) => {
    const response = await fetch(
      `${baseUrl}/api/v1/media/delete/${propertyImage}/${property_id?.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    const data = await response
      .json()
      .then((data) => {
        if (data.message) {
          showSuccessToast(data.message);
          fetchPropertyImages(property_id?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const [addPropLogo, setAddPropLogo] = useState(false);
  const addPropertyLogo = () => {
    setAddPropLogo(true);
    const formData = new FormData();
    formData.append("media_type", "property_logo");
    formData.append("associated_id", property_id?.id);
    formData.append("property_logo", uploadedLogoFile);
    fetch(`${baseUrl}/api/v1/media/upload/${property_id?.id}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          fetchPropertyLogo(property_id?.id);
          getOneData(property_id.id);
          setUploadedLogoFile(null);
        } else {
          showErrorToast("Something Went Wrong.. Please try again...");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      })
      .finally(() => {
        setAddPropLogo(false);
      });
  };

  const deletePropertyLogo = async (propertyImage) => {
    const response = await fetch(
      `${baseUrl}/api/v1/media/delete/${propertyImage}/${property_id?.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    const data = await response
      .json()
      .then((data) => {
        if (data.message) {
          showSuccessToast(data.message);
          fetchPropertyLogo(property_id?.id);
          getOneData(property_id.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleLogoFileUploadChange = (e) => {
    e.stopPropagation();
    setUploadedLogoFile(e.target.files[0]);
    setPropertyLogoLoader(false);
  };

  const addPropertyImages = (event) => {
    event.preventDefault();
    setLoader(true);
    const formData = new FormData();
    formData.append("media_type", "property");
    formData.append("associated_id", property_id?.id);
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("property_images[]", selectedFiles[i]);
    }
    fetch(`${baseUrl}/api/v1/media/upload/${property_id?.id}`, {
      method: "POST",
      body: formData,
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          fetchPropertyImages(property_id?.id);
          setSelectedFiles([]);
          setLoader(false);
          showSuccessToast(data.message);
        } else if (data.error) {
          setLoader(false);
          const firstErrorKey = Object.keys(data.error)[0];
          const firstErrorMessage = data.error[firstErrorKey][0];
          showErrorToast(firstErrorMessage);
        } else {
          setLoader(false);
          showErrorToast(data.message);
        }
      });
  };

  const [fetchedPropertyVideos, setFetchedPropertyVideos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [uploadedVideo, setUploadedVideo] = useState(null);

  const handleFileVideoUploadChange = (event) => {
    const files = event.target.files;
    setSelectedVideos([...selectedVideos, ...files]);
    const file = files[0];
    const fileUrl = URL.createObjectURL(file);
    setUploadedVideo(file);
    // setLogoUrl(fileUrl);
  };

  const addPropertyVideos = (event) => {
    event.preventDefault();
    setLoaderVid(true);
    const formData = new FormData();
    formData.append("media_type", "property");
    formData.append("associated_id", property_id?.id);
    for (let i = 0; i < selectedVideos.length; i++) {
      formData.append("property_videos[]", selectedVideos[i]);
    }
    fetch(`${baseUrl}/api/v1/media/upload/${property_id?.id}`, {
      method: "POST",
      body: formData,
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          fetchPropertyImages(property_id?.id);
          setSelectedVideos([]);
          setLoaderVid(false);
          showSuccessToast(data.message);
        } else if (data.error) {
          setLoaderVid(false);
          const firstErrorKey = Object.keys(data.error)[0];
          const firstErrorMessage = data.error[firstErrorKey][0];
          showErrorToast(firstErrorMessage);
        } else {
          setLoaderVid(false);
          showErrorToast(data.message);
        }
      });
  };

  const deleteFetchedVideos = async (propertyImage) => {
    const response = await fetch(
      `${baseUrl}/api/v1/media/delete/${propertyImage}/${property_id?.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    const data = await response
      .json()
      .then((data) => {
        console.log(data);
        if (data.message) {
          showSuccessToast(data.message);
          fetchPropertyImages(property_id?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  return (
    <>
      <form onSubmit={handleBrandSettingSubmit}>
        <div
          className="flex  justify_content_between"
          style={{ width: "100%" }}
        >
          <div style={{ width: "65%" }}>
            {/* upload logo image (single) */}
            <div className="profile_tab_form ">
              <div className="status_sec">
                <h3>Property Logo</h3>
                <div
                  className="edit-rooms-form-wrapper-container"
                  style={{ width: "100%" }}
                >
                  {propertyLogoLoader ? (
                    <LoaderSmall />
                  ) : (
                    <div style={{ position: "relative" }}>
                      {fetchedPropertyLogo &&
                      Object.keys(fetchedPropertyLogo).length > 0 ? (
                        <>
                          <div className="logoPreview_container">
                            <div style={{ width: "100%" }}>
                              <img
                                src={fetchedPropertyLogo.src}
                                alt="Property Logo"
                                height="200px"
                                width="100%"
                                style={{ objectFit: "contain" }}
                              />
                            </div>
                            <div className="featureImages_btnContainer">
                              <button
                                type="button"
                                onClick={() =>
                                  deletePropertyLogo(fetchedPropertyLogo.id)
                                }
                                className="featureImages_removeLogobtn"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 8 8"
                                  fill="none"
                                >
                                  <path d="M1 6.94752L7 1" stroke="black" />
                                  <path
                                    d="M7 6.94775L1 1.00023"
                                    stroke="black"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {uploadedLogoFile ? (
                            <>
                              <div className="logoPreview_container">
                                <div style={{ width: "100%" }}>
                                  <img
                                    src={
                                      uploadedLogoFile instanceof File
                                        ? URL.createObjectURL(uploadedLogoFile)
                                        : uploadedLogoFile.src
                                    }
                                    style={{ objectFit: "contain" }}
                                    alt="Blog Image"
                                    height="200px"
                                    width="100%"
                                  />
                                </div>
                                <div className="featureImages_btnContainer">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      deleteFetchedImages(uploadedLogoFile.id)
                                    }
                                    className="featureImages_removeLogobtn"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      viewBox="0 0 8 8"
                                      fill="none"
                                    >
                                      <path d="M1 6.94752L7 1" stroke="black" />
                                      <path
                                        d="M7 6.94775L1 1.00023"
                                        stroke="black"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="featureImages_uplaodFile_displaytext_container">
                              <p className="featured_image_text">Add Files</p>
                              <p className="featured_image_text_dragdrop">
                                or drop JPG, PNG images here
                              </p>
                              <input
                                accept="image/*"
                                type="file"
                                id="select-image"
                                onChange={handleLogoFileUploadChange}
                                className="featureImages_upload_file_input"
                              />
                            </div>
                          )}
                          {uploadedLogoFile && (
                            <>
                              {permission &&
                              permission.includes(
                                "full_access_property_settings"
                              ) ? (
                                <BgThemeButton
                                  onClick={addPropertyLogo}
                                  style={{ marginTop: "10px" }}
                                  type={"button"}
                                  loader={addPropLogo}
                                >
                                  Upload
                                </BgThemeButton>
                              ) : (
                                <Tooltip
                                  content="Please Contact Your Admin"
                                  dismissOnMouseOut
                                >
                                  <BgThemeButtonDisabled children={"Upload"} />
                                </Tooltip>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* upload property images (multiple) */}
            <div className="profile_tab_form">
              <h3>Property Images</h3>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    {loader ? (
                      <LoaderSmall />
                    ) : (
                      <>
                        <div className="flex flex_gap_10 flex_wrap">
                          <>
                            {fetchedImages.map((item, index) => (
                              <div key={index}>
                                <div className="flex flex_gap_10 justify-content-between">
                                  <div>
                                    <img
                                      src={item.src}
                                      alt={`Property Image ${index}`}
                                      height="80px"
                                      width="100px"
                                    />
                                  </div>
                                  <div className="featureImages_btnContainer">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        deleteFetchedImages(item.id)
                                      }
                                      className="featureImages_removeLogobtn"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 8 8"
                                        fill="none"
                                      >
                                        <path
                                          d="M1 6.94752L7 1"
                                          stroke="black"
                                        />
                                        <path
                                          d="M7 6.94775L1 1.00023"
                                          stroke="black"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        </div>
                        <div style={{ position: "relative" }}>
                          <input
                            accept="image/*"
                            type="file"
                            id="select-image"
                            multiple="multiple"
                            onChange={handleFileUploadChange}
                            className={`${
                              selectedFiles.length === 0
                                ? "featureImages_upload_file_input"
                                : "featureImages_upload_file_input featureImages_addzindex"
                            }`}
                          />
                          {selectedFiles && selectedFiles.length > 0 ? (
                            <div className="multiple_image_upload_container">
                              {selectedFiles.map((file, index) => (
                                <div
                                  key={index}
                                  className="multiple_image_upload_box"
                                >
                                  <div>
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt="error"
                                      height="90px"
                                      width="150px"
                                    />
                                  </div>
                                  <div className="featureImages_btnContainer">
                                    <button
                                      type="button"
                                      // onClick={() => handleRemoveLogobtn(index)}
                                      className="featureImages_removeLogobtn"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 8 8"
                                        fill="none"
                                      >
                                        <path
                                          d="M1 6.94752L7 1"
                                          stroke="black"
                                        />
                                        <path
                                          d="M7 6.94775L1 1.00023"
                                          stroke="black"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            // </div>
                            <div className="featureImages_uplaodFile_displaytext_container">
                              <p className="featured_image_text">Add Files</p>
                              <p className="featured_image_text_dragdrop">
                                or drop JPG, PNG images here
                              </p>
                            </div>
                          )}
                          {selectedFiles && selectedFiles.length ? (
                            <>
                              {permission && permission.includes("full_access_property_settings") ? (
                                <BgThemeButton
                                  type={"button"}
                                  onClick={(event) => addPropertyImages(event)}
                                  children={"Upload"}
                                />
                              ) : (
                                <Tooltip
                                  content="Please Contact Your Admin"
                                  dismissOnMouseOut
                                >
                                  <BgThemeButtonDisabled children={"Upload"} />
                                </Tooltip>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}
                  </LegacyStack>
                </LegacyCard>
              </div>
            </div>

            {/* upload property images (multiple) */}
            <div className="profile_tab_form">
              <h3>Property Videos</h3>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    {loaderVid ? (
                      <LoaderSmall />
                    ) : (
                      <>
                        <div className="flex flex_gap_10 flex_wrap">
                          <>
                            {fetchedPropertyVideos.map((item, index) => (
                              <div key={index} style={{ width: "48%" }}>
                                <div className="flex flex_gap_10 justify-content-between">
                                  <div>
                                    <video
                                      width="400"
                                      controls
                                      style={{ width: "100%" }}
                                    >
                                      <source src={item.src} type="video/mp4" />
                                      <source
                                        src="mov_bbb.ogg"
                                        type="video/ogg"
                                      />
                                      Your browser does not support HTML video.
                                    </video>
                                  </div>
                                  <div className="featureImages_btnContainer">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        deleteFetchedVideos(item.id)
                                      }
                                      className="featureImages_removeLogobtn"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 8 8"
                                        fill="none"
                                      >
                                        <path
                                          d="M1 6.94752L7 1"
                                          stroke="black"
                                        />
                                        <path
                                          d="M7 6.94775L1 1.00023"
                                          stroke="black"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        </div>
                        <div style={{ position: "relative" }}>
                          <input
                            accept="video/*" // Accept video formats
                            type="file"
                            id="select-video"
                            multiple="multiple"
                            onChange={handleFileVideoUploadChange}
                            className={`${
                              selectedVideos.length === 0
                                ? "featureImages_upload_file_input"
                                : "featureImages_upload_file_input featureImages_addzindex"
                            }`}
                          />
                          {selectedVideos && selectedVideos.length > 0 ? (
                            <div className="multiple_image_upload_container">
                              {selectedVideos.map((file, index) => (
                                <div
                                  key={index}
                                  className="multiple_image_upload_box"
                                >
                                  <div>
                                    <video width="400" controls>
                                      <source
                                        src={URL.createObjectURL(file)}
                                        type="video/mp4"
                                      />
                                      <source
                                        src={URL.createObjectURL(file)}
                                        type="video/ogg"
                                      />
                                      Your browser does not support HTML video.
                                    </video>
                                  </div>
                                  <div className="featureImages_btnContainer">
                                    <button
                                      type="button"
                                      // onClick={() => handleRemoveLogobtn(index)}
                                      className="featureImages_removeLogobtn"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 8 8"
                                        fill="none"
                                      >
                                        <path
                                          d="M1 6.94752L7 1"
                                          stroke="black"
                                        />
                                        <path
                                          d="M7 6.94775L1 1.00023"
                                          stroke="black"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            // </div>
                            <div className="featureImages_uplaodFile_displaytext_container">
                              <p className="featured_image_text">Add Files</p>
                              <p className="featured_image_text_dragdrop">
                                or drop mp4, avi, mkv videos here
                              </p>
                            </div>
                          )}
                          {selectedVideos && selectedVideos.length ? (
                            <>
                              {permission && permission.includes("full_access_property_settings") ? (
                                <BgThemeButton
                                  type={"button"}
                                  onClick={(event) => addPropertyVideos(event)}
                                  children={"Upload"}
                                />
                              ) : (
                                <Tooltip
                                  content="Please Contact Your Admin"
                                  dismissOnMouseOut
                                >
                                  <BgThemeButtonDisabled children={"Upload"} />
                                </Tooltip>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}
                  </LegacyStack>
                </LegacyCard>
              </div>
            </div>

            {/* Social Links*/}

            <div className="profile_tab_form ">
              <h3>Social Links</h3>
              <div className="flex justify_content_between flex_gap_10 ">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={false}
                    onChange={handleFormDataInputChange}
                    type={"text"}
                    name="facebook_url"
                    focused={false}
                    titleName="Facebook URL"
                    autoComplete="off"
                    value={formData.facebook_url}
                  />
                </div>

                <div className="form-container-div ">
                  <VerticalInputFieldCustom
                    required={false}
                    onChange={handleFormDataInputChange}
                    type={"text"}
                    name="instagram_url"
                    focused={false}
                    titleName="Instagram URL"
                    autoComplete="off"
                    value={formData.instagram_url}
                  />
                </div>
              </div>
              <div className="flex justify_content_between flex_gap_10 ">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={false}
                    onChange={handleFormDataInputChange}
                    type={"text"}
                    name="twitter_url"
                    focused={false}
                    titleName="Twitter URL"
                    autoComplete="off"
                    value={formData.twitter_url}
                  />
                </div>

                <div className="form-container-div ">
                  <VerticalInputFieldCustom
                    required={false}
                    onChange={handleFormDataInputChange}
                    type={"text"}
                    name="property_linkedin"
                    focused={false}
                    titleName="LinkedIn URL"
                    autoComplete="off"
                    value={formData.property_linkedin}
                  />
                </div>
              </div>
              <div className="flex justify_content_between flex_gap_10 ">
                <div className="form-container-div ">
                  <VerticalInputFieldCustom
                    required={false}
                    onChange={handleFormDataInputChange}
                    type={"text"}
                    name="property_youtube"
                    focused={false}
                    titleName="YouTube URL"
                    autoComplete="off"
                    value={formData.property_youtube}
                  />
                </div>

                <div className="form-container-div ">
                  <VerticalInputFieldCustom
                    required={false}
                    onChange={handleFormDataInputChange}
                    type={"text"}
                    name="tripadvisor_url"
                    focused={false}
                    titleName="TripAdvisor URL"
                    autoComplete="off"
                    value={formData.tripadvisor_url}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{ width: "32%" }}>
            {/* amenities */}

            {/* brand colors */}

            <div className="profile_tab_form">
              <h3>Brand Colors</h3>

              <VerticalInputFieldCustom
                // required={true}
                // onChange={setAddItemDataHandleChange}
                type={"color"}
                name="primary_color"
                focused={false}
                titleName="Primary Color"
                autoComplete="off"
                value={formData.primary_color}
                onChange={handleFormDataInputChange}
              />

              <VerticalInputFieldCustom
                // required={true}
                // onChange={setAddItemDataHandleChange}
                type={"color"}
                name="secondary_color"
                focused={false}
                titleName="Secondary Color"
                autoComplete="off"
                value={formData.secondary_color}
                onChange={handleFormDataInputChange}
              />
              <div></div>
            </div>
          </div>
        </div>

        {permission && permission.includes("full_access_property_settings") ? (
          <BgThemeButton
            children={"Save Changes"}
            type={"Submit"}
            loader={loader}
          />
        ) : (
          <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
            <BgThemeButtonDisabled children={"Save Changes"} />
          </Tooltip>
        )}
      </form>
    </>
  );
};

export default BrandSettings;
