import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import CustomIndexTable from "../Dashboard/CustomIndexTable/CustomIndexTable";
import CustomIndexTableRow from "../Dashboard/CustomIndexTable/CustomIndexTableRow";
import CustomIndexTableCell from "../Dashboard/CustomIndexTable/CustomIndexTableCell";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { useState } from "react";
import { SvgGetInfo } from "../../assets/svgIcons";

const ReportCategoryWiseReport = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [informationPopup, setInformationPopup] = useState(false);

  const rowMarkupAllFoliosCustom = departureReport?.map(
    ({ id, count, order_no, order_type, net_total, date, index }) => {
      return (
        <CustomIndexTableRow
          id={id}
          key={id}
          // selectedItems={selectedArray} // send for checking the check box is checked
          // setSelectedItems={setSelectedArray}
          showCheckbox={false} // show the check box
        >
          <CustomIndexTableCell>{id}</CustomIndexTableCell>

          <CustomIndexTableCell>{count}</CustomIndexTableCell>
          <CustomIndexTableCell>
            {formatIndianCurrency(id)}
          </CustomIndexTableCell>
        </CustomIndexTableRow>
      );
    }
  );

  

  const downloadCSV = () => {
    // Prepare CSV content
    const csvHeaders = "Category,Item,Count,Sale";
    const csvContent = `${csvHeaders}\n${departureReport
      .map((category) =>
        category.items
          .map((item) =>
            [category.category, item.item, item.count, item.sale].join(",")
          )
          .join("\n")
      )
      .join("\n")}`;

    // Create a blob with CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element to trigger download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "sales_data.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // console.log(departureReport);

  const rowMarkup = departureReport?.map(
    ({
      id,
      request_id,

      issue_item_date,
      item_category,
      department_name,
      issue_to,
      quantity,
      unique_purchase_id,
      total_amount,
      item_name,
      unit_name,
      status,
      purchase_amount,
      purchase_quantity,
      issue_amount,
      remain_amount,
    }) => (
      <CustomReportTableRow
        CustomReportTableRow
        id={id}
        key={id}
        // bodyStyles={{
        //   backgroundColor: "red",
        //   fontSize: "10px",
        // }}
      >
        <CustomReportTableCell>
          {item_category ? item_category : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(purchase_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(issue_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {" "}
          {formatIndianCurrency(remain_amount)}
        </CustomReportTableCell>
      </CustomReportTableRow>
    )
  );
  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "purchase_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "issue_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "remain_amount")
        )}
      </CustomReportTableCell>
    </CustomReportTableRow>
  );
  const reportIntro = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "purchase_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "purchase_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "purchase_amount")
        )}
      </CustomReportTableCell>
    </CustomReportTableRow>
  );
  const extractedData = departureReport?.map((item) => ({
    item_category: item && item?.item_category ? item?.item_category : "N/A",
    purchase_amount:
      item && item?.purchase_amount ? item?.purchase_amount : "N/A",
    issue_amount: item && item?.issue_amount ? item?.issue_amount : "N/A",
    remain_amount: item && item?.remain_amount ? item?.remain_amount : "N/A",
  }));

  const names = [
    {
      item_category: "Category",
      purchase_amount: "Purchased Amount (₹)	",
      issue_amount: "Issued Amount (₹)	",
      remain_amount: "Remaining Stock Amount (₹)",
    },
  ];
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div
          id="receipt"
          className="room-types-full-container reports-new-background"
        >
          <div className="room-types-full-container-div">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
                <div className="room-type-heading">Category wise Report</div>
                <div
                  className="hoverCursor"
                  onClick={() => setInformationPopup(true)}
                  id="ignoreFromPrint"
                >
                 <SvgGetInfo />
                </div>
              </div>

              {departureReport.length > 0 && (
                <div className="flex flex_gap_15 align_center" id="ignoreFromPrint">
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Category_wise_Report"}
                    ignoreFromPrint={"ignoreFromPrint"}
                    longPdf={true}
                  />
                  <DownloadCSVButton
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Category_wise_Report"}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            {departureReport && departureReport.length > 0 ? (
              <>
                {/* {departureReport &&
                  departureReport.length > 0 &&
                  departureReport.map((order) => {
                    return (
                      <div className="mt-20">
                        <div
                          className="category_name"
                          style={{ marginBottom: "10px" }}
                        >
                          {order.category}
                        </div> */}

                <CustomReportTable
                  headings={[
                    "Category",
                    "Purchased Amount (₹)",
                    "Issued Amount (₹)",
                    "Remaining Stock Amount (₹)",
                  ]}
                  // commonHeadings={[
                  //   "",
                  //   "",
                  //   "",
                  //   "",
                  //   "",
                  //   "",
                  //   "Requested by",
                  //   "Requested by",
                  //   "",
                  // ]}
                  headerStyles={{
                    backgroundColor: "#dddddd",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                  tableStyles={{ textAlign: "left" }}
                >
                  {rowMarkup}
                  {calculateTotals}
                </CustomReportTable>
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Category-Wise Report"
        >
          <div className="mb-20">
            {/* <h2 className="mb-10"> Accordion Component </h2> */}
            <AccordionComponent
              questions={[
                {
                  question: "What is a Category-Wise Report?",
                  answer: (
                    <p>
                      A <b>Category-Wise Report</b> provides a summary of
                      financial and inventory data categorized by item types
                      (e.g., Groceries, Beverages, Stationery, etc.) over a
                      selected date range. It shows the total purchased amount,
                      issued amount, and the remaining stock amount for each
                      category, offering a clear overview of how resources are
                      managed across different categories.
                    </p>
                  ),
                },
                {
                  question: "Importance of an Category-Wise Report",
                  answer: (
                    <div>
                      <p>
                        The Category-Wise Report is crucial for businesses in
                        the following ways:
                      </p>

                      <ul>
                        <li>
                          <b>Financial Tracking: </b> It gives a breakdown of
                          expenditures by category, helping businesses track how
                          much has been spent on each type of item.
                        </li>
                        <li>
                          <b>Inventory Management: </b> By showing the issued and
                          remaining stock amounts, businesses can ensure that
                          inventory levels are well-maintained, preventing
                          overstocking or shortages.
                        </li>
                        <li>
                          <b>Cost Control: </b> The report aids in monitoring and
                          controlling costs by providing insights into
                          category-wise spending, enabling more informed
                          budgeting decisions.
                        </li>
                        <li>
                          <b>Resource Allocation: </b> It helps businesses allocate
                          resources more effectively by understanding which
                          categories require more or fewer resources.
                        </li>
                        <li>
                         <b>Operational Efficiency: </b> By having a clear overview of
                          purchases and usage in each category, businesses can
                          streamline their procurement process and optimize
                          resource distribution.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportCategoryWiseReport;
