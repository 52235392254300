import React, { useEffect } from "react";
import Inventory from "../Components/Inventory";
import { useSelector } from "react-redux";
import InventoryCommon from "../Components/InventoryCommon";

const InventoryPage = () => {
  useEffect(() => {
    // Set the meta title
    document.title =
      "Inventory - RevCatalyst - Boosting Your Hotel Performance";

    // Create or update the meta description tag
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);
  const activeApp =
    useSelector((state) => state.activeApp.activeApp) ||
    localStorage.getItem("activeApp");
  return (
    <>
      {activeApp === "pms" && (
        <div className="main_container">
          <Inventory />
        </div>
      )}
      {activeApp === "booking_engine" && <InventoryCommon />}
      {activeApp === "channel_manager" && <InventoryCommon />}

    </>
  );
};

export default InventoryPage;
