import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import CustomIndexTable from "../Dashboard/CustomIndexTable/CustomIndexTable";
import CustomIndexTableRow from "../Dashboard/CustomIndexTable/CustomIndexTableRow";
import CustomIndexTableCell from "../Dashboard/CustomIndexTable/CustomIndexTableCell";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { useState } from "react";
import { SvgGetInfo } from "../../assets/svgIcons";

const ReportConsolidatedVendor = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [informationPopup, setInformationPopup] = useState(false);


  const rowMarkupAllFoliosCustom = departureReport?.map(
    ({ id, count, order_no, order_type, net_total, date, index }) => {
      return (
        <CustomIndexTableRow
          id={id}
          key={id}
          // selectedItems={selectedArray} // send for checking the check box is checked
          // setSelectedItems={setSelectedArray}
          showCheckbox={false} // show the check box
        >
          <CustomIndexTableCell>{id}</CustomIndexTableCell>

          <CustomIndexTableCell>{count}</CustomIndexTableCell>
          <CustomIndexTableCell>
            {formatIndianCurrency(id)}
          </CustomIndexTableCell>
        </CustomIndexTableRow>
      );
    }
  );


  const calculateTotal = (items, key) => {
    return 0;
  };
 

  const downloadCSV = () => {
    // Prepare CSV content
    const csvHeaders = "Category,Item,Count,Sale";
    const csvContent = `${csvHeaders}\n${departureReport
      .map((category) =>
        category.items
          .map((item) =>
            [category.category, item.item, item.count, item.sale].join(",")
          )
          .join("\n")
      )
      .join("\n")}`;

    // Create a blob with CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element to trigger download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "sales_data.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };



  const rowMarkup = departureReport?.map(
    ({
      id,
      purchase_item_date,
      invoice_no,
      item_name,
      unit,
      item_category,
      unique_purchase_id,
      quantity,
      price_unit,
      purchase_amount,
      unit_name,
      item_qty,
      item_price,
      total_amount,

      MTD_due_amount,
      MTD_paid_amount,
      MTD_purchase_amount,
      QTD_due_amount,
      QTD_paid_amount,
      QTD_purchase_amount,
      YTD_due_amount,
      YTD_paid_amount,
      YTD_purchase_amount,
      supplier_gst_no,
      supplier_name,
    }) => (
      <CustomReportTableRow
        CustomReportTableRow
        id={id}
        key={id}
      // bodyStyles={{
      //   backgroundColor: "red",
      //   fontSize: "10px",
      // }}
      >
        <CustomReportTableCell>{supplier_name}</CustomReportTableCell>
        <CustomReportTableCell>{supplier_gst_no}</CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(MTD_purchase_amount)}
        </CustomReportTableCell>

        <CustomReportTableCell>
          {formatIndianCurrency(QTD_purchase_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(YTD_purchase_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(MTD_paid_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(QTD_paid_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(YTD_paid_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(MTD_due_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(QTD_due_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(YTD_due_amount)}
        </CustomReportTableCell>
      </CustomReportTableRow>
    )
  );

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "MTD_purchase_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "QTD_purchase_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "YTD_purchase_amount")
        )}
      </CustomReportTableCell>

      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "MTD_paid_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "QTD_paid_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "YTD_paid_amount")
        )}
      </CustomReportTableCell>

      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "MTD_due_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "QTD_due_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "YTD_due_amount")
        )}
      </CustomReportTableCell>
    </CustomReportTableRow>
  );
  const extractedData = departureReport?.map(item => ({
    supplier_name: item && item?.supplier_name,
    supplier_gst_no: item && item?.supplier_gst_no ? item?.supplier_gst_no : "N/A",
    MTD_purchase_amount: item && item?.MTD_purchase_amount ? item?.MTD_purchase_amount : "N/A",
    QTD_purchase_amount: item && item?.QTD_purchase_amount ? item?.QTD_purchase_amount : "N/A",
    YTD_purchase_amount: item && item?.YTD_purchase_amount ? item?.YTD_purchase_amount : "N/A",
    MTD_paid_amount: item && item?.MTD_paid_amount ? item?.MTD_paid_amount : "N/A",
    QTD_paid_amount: item && item?.QTD_paid_amount ? item?.QTD_paid_amount : "N/A",
    YTD_paid_amount: item && item?.YTD_paid_amount ? item?.YTD_paid_amount : "N/A",
    MTD_due_amount: item && item?.MTD_due_amount ? item?.MTD_due_amount : "N/A",
    QTD_due_amount: item && item?.QTD_due_amount ? item?.QTD_due_amount : "N/A",
    YTD_due_amount: item && item?.YTD_due_amount ? item?.YTD_due_amount : "N/A",
  }));

  const names = [
    {
      supplier_name: "Supplier Name	",
      supplier_gst_no: "GST No	",
      MTD_purchase_amount: " MTD Purchase Amount (₹)",
      QTD_purchase_amount: " QTD Purchase Amount (₹)",
      YTD_purchase_amount: " YTD Purchase Amount (₹)",
      MTD_paid_amount: " MTD Paid  Amount (₹)",
      QTD_paid_amount: " QTD Paid  Amount (₹)",
      YTD_paid_amount: " YTD Paid  Amount (₹)",
      MTD_due_amount: " MTD Due  Amount (₹)",
      QTD_due_amount: " QTD Due  Amount (₹)",
      YTD_due_amount: " YTD Due  Amount (₹)",
    },
  ];
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div id="receipt" className="room-types-full-container reports-new-background">
          <div className="room-types-full-container-div">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
                <div className="room-type-heading">
                  Consolidated Vendor Finance Report{" "}
                </div>
                <div
                  className="hoverCursor"
                  onClick={() => setInformationPopup(true)}
                  id="ignoreFromPrint"
                >
                 <SvgGetInfo />
                </div>
              </div>


              {departureReport.length > 0 && (
                <div className="flex flex_gap_15 align_center" id="ignoreFromPrint">
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Consolidated_Vendor_Finance_Report"}
                    ignoreFromPrint={"ignoreFromPrint"}
                    pdfOrientation={"landscape"}
                    longPdf={true}
                  // pdfStyles={

                  // }
                  />
                  <DownloadCSVButton
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Consolidated_Vendor_Finance-Report"} />
                </div>
              )}
            </div>
          </div>
          <div>
            {departureReport && departureReport.length > 0 ? (
              <>
                {/* {departureReport &&
                  departureReport.length > 0 &&
                  departureReport.map((order) => {
                    return (
                      <div className="mt-20">
                        <div
                          className="category_name"
                          style={{ marginBottom: "10px" }}
                        >
                          {order.category}
                        </div> */}

                <CustomReportTable
                  headings={[
                    "Supplier Name",
                    "GST No",
                    "MTD",
                    "QTD",
                    "YTD",
                    "MTD",
                    "QTD",
                    "YTD",
                    "MTD",
                    "QTD",
                    "YTD",
                  ]}
                  commonHeadings={[
                    "",
                    "",
                    "Purchase Amount (₹)",
                    "Purchase Amount (₹)",
                    "Purchase Amount (₹)",
                    "Paid Amount (₹)",
                    "Paid Amount (₹)",
                    "Paid Amount (₹)",
                    "Due Amount (₹)",
                    "Due Amount (₹)",
                    "Due Amount (₹)",
                  ]}
                  headerStyles={{
                    backgroundColor: "#dddddd",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                  tableStyles={{ textAlign: "left" }}
                >
                  {rowMarkup}
                  {calculateTotals}
                </CustomReportTable>
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Consolidated Vendor Finance Report"
        >
          <div className="mb-20">
            {/* <h2 className="mb-10"> Accordion Component </h2> */}
            <AccordionComponent
              questions={[
                {
                  question: "What is the Consolidated Vendor Finance Report?",
                  answer: (
                    <p>
                     A <b>Consolidated Vendor Finance Report</b> provides a summarized view of the financial transactions between a business and multiple suppliers within a specific date range. It consolidates essential financial data, including purchase amounts, payments made, and outstanding dues, with metrics like MTD (Month-to-Date), QTD (Quarter-to-Date), and YTD (Year-to-Date). This report allows for a quick comparison and monitoring of financial engagements across various vendors.

                    </p>
                  ),
                },
                {
                  question: "Importance of a Consolidated Vendor Finance Report",
                  answer: (
                    <div>
                      <p>
                      The Consolidated Vendor Finance Report is critical for businesses as it provides:

                      </p>

                      <ul>
                        <li>
                        <b>Holistic Financial Overview:</b> Offers a comprehensive snapshot of all vendor-related financial, ensuring better financial tracking across all suppliers.
                        </li>
                        <li>
                        <b>Supplier Comparison:</b> Businesses can compare pricing and purchase trends from different suppliers, aiding in identifying the best suppliers for each item.
                        </li>
                        <li>
                        <b>Inventory Management:</b> It helps in keeping track of the quantity of items procured, ensuring effective inventory control and replenishment.
                        </li>
                        <li>
                       <b> Cost Control: </b>The report provides visibility into price variations across different timeframes, helping businesses optimize their spending on specific items.
                        </li>
                        <li>
                        <b>Audit and Compliance:</b> With detailed records of supplier details, GST numbers, and invoices, the report serves as an essential tool for audits and ensuring compliance with tax regulations.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportConsolidatedVendor;
