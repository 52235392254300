import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import {
  currentDateSingle,
  formatIndianCurrency,
  useCurrentTime,
} from "../../utils/utils";
import TextDateFormatter from "../../Components/Communication/TextDateFormatter/TextDateFormatter";
import LoaderSmall from "../UI/Loader/LoaderSmall";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useState } from "react";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/svgIcons";
const ReportCashierFinance = ({ departureReport, loader, fullData }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [informationPopup, setInformationPopup] = useState(false);

  const currentTime = useCurrentTime("12");
  const user = JSON.parse(localStorage.getItem("user"));

  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <IndexTable.Row id={order.id} key={order.id} position={order.index}>
        <IndexTable.Cell>{order.date}</IndexTable.Cell>
        <IndexTable.Cell>
          <UnderscoreRemoveCapitalize text={order.payment_mode} />{" "}
        </IndexTable.Cell>
        <IndexTable.Cell>{order.settlement_amount}</IndexTable.Cell>
      </IndexTable.Row>
    ));
  const extractedData = [
    {
      name: "F & B Subtotal",
      cgst:
        fullData &&
        fullData["financial-summary"] &&
        fullData["financial-summary"]?.CGST,
      subtotal:
        fullData &&
        fullData["financial-summary"] &&
        fullData["financial-summary"]?.SubTotal,
      sgst:
        fullData &&
        fullData["financial-summary"] &&
        fullData["financial-summary"]?.SGST,
      total_discount_amt:
        fullData &&
        fullData["financial-summary"] &&
        fullData["financial-summary"]?.TotalDiscount,
      total_tax_amt:
        fullData &&
        fullData["financial-summary"] &&
        fullData["financial-summary"]?.TotalTaxAmount,
      paid_amt:
        fullData &&
        fullData["financial-summary"] &&
        fullData["financial-summary"]?.PaidAmount,
      balance_amt:
        fullData &&
        fullData["financial-summary"] &&
        fullData["financial-summary"]?.BalanceAmount,
      net_total:
        fullData &&
        fullData["financial-summary"] &&
        fullData["financial-summary"]?.NetTotal,
    },
  ];
  const names = [
    {
      name: "Financial Summary",
      // heading: "Settlement Analysis",
      cgst: "CGST",
      sgst: "SGST",
      subtotal: "F & B Subtotal",
      total_discount_amt: "Total Discount Amt",
      total_tax_amt: "Total Tax Amt",
      paid_amt: "Paid Amt",
      balance_amt: "Balance Amt",
      net_total: "Net Total",
    },
  ];

  return (
    <div className="room-types-full-container">
      <div className="room-types-full-container-div">
        <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
          <div className="room-type-heading">Cashier Report (F & B)</div>
          <div
            className="hoverCursor"
            onClick={() => setInformationPopup(true)}
            id="ignoreFromPrint"
          >
           <SvgGetInfo />
          </div>
        </div>
        {fullData && (
          <div className="flex flex_gap_15" id="ignoreFromPrint">
            <ButtonToDownloadTablePDF
              itemToDownloadID={"receipt"}
              downloadFileName={"Cashier Report"}
              ignoreFromPrint={"ignoreFromPrint"}
              longPdf={true}
            />

            <DownloadCSVButton
              itemToDownloadID={"receipt"}
              downloadFileName={"Cashier Report"}
            />
          </div>
        )}
      </div>
      {loader ? (
        <LoaderSmall />
      ) : (
        <>
          {fullData && fullData["financial-summary"] ? (
            <div id="receipt" style={{ border: "1px solid #ddd" }}>
              <table
                id="issue_items_page_table"
                style={{
                  backgroundColor: "white",
                  padding: "10px 20px",
                  borderRadius: "10px",
                  fontFamily: "Inter",
                  width: "100%",
                  marginTop: "25px",
                  borderCollapse: "collapse", // Ensure borders collapse properly
                  // border: "1px solid #ddd" // Add a border to the table
                }}
              >
                <thead>
                  <tr
                    style={{
                      textAlign: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <th
                      style={{
                        padding: "10px 0px",
                        borderRight: "1px solid #ddd",
                        borderBottom: "1px solid #ddd",
                        backgroundColor: "#f8f8f8",
                      }}
                      colSpan={2}
                    >
                      Financial Summary
                    </th>
                    <th
                      style={{
                        padding: "10px 0px",
                        borderBottom: "1px solid #ddd",
                        backgroundColor: "#f8f8f8",
                      }}
                      colSpan={2}
                    >
                      Settlement Analysis
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        F & B Subtotal
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["financial-summary"]?.SubTotal
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        Cash
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["settlement-analysis"]["cash"]?.NetTotal
                        )}
                      </td>
                    </>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        Total Discount Amount
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["financial-summary"]?.TotalDiscount
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        Bank
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["settlement-analysis"]["bank-payment"]
                            ?.NetTotal
                        )}
                      </td>
                    </>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        Total Taxable Amount
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["financial-summary"]?.TotalTaxAmount
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        Credit Card
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["settlement-analysis"]["credit-card"]
                            ?.NetTotal
                        )}
                      </td>
                    </>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        CGST
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["financial-summary"]?.CGST
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        Posted to Room
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["settlement-analysis"]["posted-to-room"]
                            ?.NetTotal
                        )}
                      </td>
                    </>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        SGST
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["financial-summary"]?.SGST
                        )}
                      </td>

                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        Complimentary KOTs
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["complimentary"]?.NetTotal
                        )}
                      </td>
                    </>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        Paid Amount
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["financial-summary"]?.PaidAmount
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      ></td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                        }}
                      ></td>
                    </>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        Balance Amount
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["financial-summary"]?.BalanceAmount
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      ></td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                        }}
                      ></td>
                    </>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        Net Total
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["financial-summary"]?.NetTotal
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      ></td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                        }}
                      ></td>
                    </>
                  </tr>
                  {/* <tr style={{ textAlign: "center" }}>
                    <>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          backgroundColor: "#f5f5f5",
                        }}
                        colSpan={2}
                      >
                       <b>Created By :</b> {user?.name ? user.name : "N/A"}
                      </td>

                    
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          backgroundColor: "#f5f5f5",
                        }}
                        colSpan={2}
                      >
                        <b> Date: </b> { <TextDateFormatter  date={currentDateSingle} as={"span"}  showYear={true}/>} ,  <b>Time :</b>  {currentTime}
                       
                      </td>
                   
                    </>
                  </tr> */}
                </tbody>
              </table>
              <table
                id="issue_items_page_table"
                style={{
                  backgroundColor: "white",
                  padding: "10px 20px",
                  borderRadius: "10px",
                  fontFamily: "Inter",
                  width: "100%",
                  marginTop: "25px",
                  borderCollapse: "collapse", // Ensure borders collapse properly
                  // border: "1px solid #ddd" // Add a border to the table
                }}
              >
                <thead>
                  <tr
                    style={{
                      textAlign: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <th
                      style={{
                        padding: "10px 0px",
                        borderRight: "1px solid #ddd",
                        borderBottom: "1px solid #ddd",
                        backgroundColor: "#f8f8f8",
                      }}
                      colSpan={5}
                    >
                      Tax Bifurcation
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr
                    style={{
                      textAlign: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <th
                      style={{
                        padding: "10px 0px",
                        borderRight: "1px solid #ddd",
                        borderBottom: "1px solid #ddd",
                        backgroundColor: "#f8f8f8",
                      }}
                      colSpan={1}
                    >
                      {/* Financial Summary */}
                    </th>
                    <th
                      style={{
                        padding: "10px 0px",
                        borderRight: "1px solid #ddd",
                        borderBottom: "1px solid #ddd",
                        backgroundColor: "#f8f8f8",
                      }}
                      colSpan={1}
                    >
                      Taxable Amount
                    </th>
                    <th
                      style={{
                        padding: "10px 0px",
                        borderRight: "1px solid #ddd",
                        borderBottom: "1px solid #ddd",
                        backgroundColor: "#f8f8f8",
                      }}
                      colSpan={1}
                    >
                      SGST
                    </th>
                    <th
                      style={{
                        padding: "10px 0px",
                        borderRight: "1px solid #ddd",
                        borderBottom: "1px solid #ddd",
                        backgroundColor: "#f8f8f8",
                      }}
                      colSpan={1}
                    >
                      CGST
                    </th>
                    <th
                      style={{
                        padding: "10px 0px",
                        borderRight: "1px solid #ddd",
                        borderBottom: "1px solid #ddd",
                        backgroundColor: "#f8f8f8",
                      }}
                      colSpan={1}
                    >
                      Net Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        GST@5%
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["tax-summary"]["five-percent"]?.SubTotal
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["tax-summary"]["five-percent"]?.SGST
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",

                        }}
                      >
                        {formatIndianCurrency(
                          fullData["tax-summary"]["five-percent"]?.CGST
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["tax-summary"]["five-percent"]?.NetTotal
                        )}
                      </td>
                    </>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        GST@12%
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["tax-summary"]["twelve-percent"]?.SubTotal
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["tax-summary"]["twelve-percent"]?.SGST
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",

                        }}
                      >
                        {formatIndianCurrency(
                          fullData["tax-summary"]["twelve-percent"]?.CGST
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["tax-summary"]["twelve-percent"]?.NetTotal
                        )}
                      </td>
                    </>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        GST@18%
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["tax-summary"]["eighteen-percent"]?.SubTotal
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["tax-summary"]["eighteen-percent"]?.SGST
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",

                        }}
                      >
                        {formatIndianCurrency(
                          fullData["tax-summary"]["eighteen-percent"]?.CGST
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["tax-summary"]["eighteen-percent"]?.NetTotal
                        )}
                      </td>
                    </>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          backgroundColor: "#f8f8f8",
                        }}
                      >
                        Totals
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          backgroundColor: "#f8f8f8",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["tax-summary"]["eighteen-percent"]?.SubTotal + fullData["tax-summary"]["twelve-percent"]?.SubTotal + fullData["tax-summary"]["five-percent"]?.SubTotal
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          backgroundColor: "#f8f8f8",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["tax-summary"]["eighteen-percent"]?.SGST + fullData["tax-summary"]["twelve-percent"]?.SGST + fullData["tax-summary"]["five-percent"]?.SGST
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          backgroundColor: "#f8f8f8",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["tax-summary"]["eighteen-percent"]?.CGST + fullData["tax-summary"]["twelve-percent"]?.CGST + fullData["tax-summary"]["five-percent"]?.CGST
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px 0",
                          borderBottom: "1px solid #ddd",
                          backgroundColor: "#f8f8f8",
                        }}
                      >
                        {formatIndianCurrency(
                          fullData["tax-summary"]["eighteen-percent"]?.NetTotal + fullData["tax-summary"]["twelve-percent"]?.NetTotal + fullData["tax-summary"]["five-percent"]?.NetTotal
                        )}
                      </td>
                    </>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <NoDataFound />
          )}
        </>
      )}
      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of F&B Cashier Report"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is the F&B Cashier Report?",
                  answer: (
                    <p>
                      The <b>F&B Cashier Report</b> provides a detailed
                      financial summary of daily transactions related to Food &
                      Beverage (F&B) sales in the hotel. It includes key figures
                      such as total sales, taxes, discounts, and payment
                      settlements, ensuring accurate tracking of all F&B-related
                      financial activities. This report is crucial for the
                      cashier and finance team to manage daily cash flow and
                      payment reconciliation.
                    </p>
                  ),
                },
                {
                  question:
                    "What is the Importance of the F&B Cashier Report?",
                  answer: (
                    <div>
                      <ul>
                        <li>
                          <b>Revenue Overview:</b> Offers a precise snapshot of
                          the day's F&B earnings, after applying any discounts
                          and taxes.
                        </li>
                        <li>
                          <b>Tax Compliance:</b> Helps in monitoring SGST, CGST,
                          and other applicable taxes, ensuring proper tax
                          reporting and compliance.
                        </li>
                        <li>
                          <b>Payment Reconciliation: </b>Breaks down payments
                          received through various methods (cash, bank, credit
                          cards, etc.), ensuring all payments are properly
                          settled.
                        </li>
                        <li>
                          <b> Detailed Settlement:</b> Analyzes how payments
                          were settled, such as room postings, complimentary
                          items, or company credits, aiding in financial
                          transparency.
                        </li>
                        <li>
                          <b>Operational Control: </b>Tracks complimentary KOTs
                          (Kitchen Order Tickets) to balance between sales and
                          non-revenue orders.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </div>
  );
};
export default ReportCashierFinance;
