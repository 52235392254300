import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import CustomIndexTable from "../Dashboard/CustomIndexTable/CustomIndexTable";
import CustomIndexTableRow from "../Dashboard/CustomIndexTable/CustomIndexTableRow";
import CustomIndexTableCell from "../Dashboard/CustomIndexTable/CustomIndexTableCell";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useState } from "react";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/svgIcons";

const ReportItemViseSupplier = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [informationPopup, setInformationPopup] = useState(false);

  const rowMarkupAllFoliosCustom = departureReport?.map(
    ({ id, count, order_no, order_type, net_total, date, index }) => {
      return (
        <CustomIndexTableRow
          id={id}
          key={id}
          // selectedItems={selectedArray} // send for checking the check box is checked
          // setSelectedItems={setSelectedArray}
          showCheckbox={false} // show the check box
        >
          <CustomIndexTableCell>{id}</CustomIndexTableCell>

          <CustomIndexTableCell>{count}</CustomIndexTableCell>
          <CustomIndexTableCell>
            {formatIndianCurrency(id)}
          </CustomIndexTableCell>
        </CustomIndexTableRow>
      );
    }
  );

  const calculateTotal = (items, key) => {
    return 0;
  };


  const downloadCSV = () => {
    // Prepare CSV content
    const csvHeaders = "Category,Item,Count,Sale";
    const csvContent = `${csvHeaders}\n${departureReport
      .map((category) =>
        category.items
          .map((item) =>
            [category.category, item.item, item.count, item.sale].join(",")
          )
          .join("\n")
      )
      .join("\n")}`;

    // Create a blob with CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element to trigger download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "sales_data.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // console.log(departureReport);

  const rowMarkup = departureReport?.map(
    ({
      id,
      payment_status,

      item_name,
      supplier_name,
      supplier_gst_no,
      unit_name,
      MTD_quantity,
      QTD_quantity,
      YTD_quantity,
      MTD_purchase_amount,
      QTD_purchase_amount,
      YTD_purchase_amount,
      MTD_average_purchase_cost,
      QTD_average_purchase_cost,
      YTD_average_purchase_cost,
    }) => (
      <CustomReportTableRow
        CustomReportTableRow
        id={id}
        key={id}
        // bodyStyles={{
        //   backgroundColor: "red",
        //   fontSize: "10px",
        // }}
      >
        <CustomReportTableCell>{item_name}</CustomReportTableCell>
        <CustomReportTableCell>{supplier_name}</CustomReportTableCell>
        <CustomReportTableCell>{supplier_gst_no}</CustomReportTableCell>
        <CustomReportTableCell>{unit_name}</CustomReportTableCell>
        <CustomReportTableCell>{MTD_quantity}</CustomReportTableCell>
        <CustomReportTableCell>{QTD_quantity}</CustomReportTableCell>
        <CustomReportTableCell>{YTD_quantity}</CustomReportTableCell>

        <CustomReportTableCell>
          {formatIndianCurrency(MTD_purchase_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(QTD_purchase_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(YTD_purchase_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(MTD_average_purchase_cost)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(QTD_average_purchase_cost)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(YTD_average_purchase_cost)}
        </CustomReportTableCell>
      </CustomReportTableRow>
    )
  );

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>
        {calculateTotalWithReduce(departureReport, "MTD_quantity")}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {calculateTotalWithReduce(departureReport, "YTD_quantity")}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {calculateTotalWithReduce(departureReport, "YTD_quantity")}
      </CustomReportTableCell>

      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "MTD_purchase_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "QTD_purchase_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "YTD_purchase_amount")
        )}
      </CustomReportTableCell>

      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "MTD_average_purchase_cost")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "QTD_average_purchase_cost")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "YTD_average_purchase_cost")
        )}
      </CustomReportTableCell>
    </CustomReportTableRow>
  );
  const extractedData = departureReport?.map((item) => ({
    item_name: item?.item_name,
    supplier_name: item?.supplier_name,
    supplier_gst_no: item?.supplier_gst_no,
    unit_name: item?.unit_name,
    MTD_quantity: item?.MTD_quantity,
    QTD_quantity: item?.QTD_quantity,
    YTD_quantity: item?.YTD_quantity,
    MTD_purchase_amount: item?.MTD_purchase_amount,
    QTD_purchase_amount: item?.QTD_purchase_amount,
    YTD_purchase_amount: item?.YTD_purchase_amount,
    MTD_average_purchase_cost: item?.MTD_average_purchase_cost,
    QTD_average_purchase_cost: item?.QTD_average_purchase_cost,
    YTD_average_purchase_cost: item?.YTD_average_purchase_cost,
  }));

  const names = [
    {
      item_name: "Item Name	",
      supplier_name: "Supplier Name",
      supplier_gst_no: "GST No",
      unit_name: "Unit",
      MTD_quantity: "MTD	Quantity",
      QTD_quantity: " QTD	Quantity",
      YTD_quantity: "YTD Quantity",
      MTD_purchase_amount: "MTD Purchase Amount",
      QTD_purchase_amount: "QTD Purchase Amount",
      YTD_purchase_amount: "YTD Purchase Amount",
      MTD_average_purchase_cost: "MTD Average Purchase Cost",
      QTD_average_purchase_cost: "QTD Average Purchase Cost",
      YTD_average_purchase_cost: "YTD Average Purchase Cost",
    },
  ];
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div
          id="receipt"
          className="room-types-full-container reports-new-background"
        >
          <div className="room-types-full-container-div">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
                <div className="room-type-heading">
                  Item Wise Supplier Analysis Report
                </div>
                <div
                  className="hoverCursor"
                  onClick={() => setInformationPopup(true)}
                >
                 <SvgGetInfo />
                </div>
              </div>

              {departureReport.length > 0 && (
                <div className="flex flex_gap_15 align_center" id="ignoreFromPrint">
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Item Wise Supplier Analysis Report"}
                    ignoreFromPrint={"ignoreFromPrint"}
                    pdfOrientation={"landscape"}
                    longPdf={true}
                  />
                  <DownloadCSVButton
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Item Wise Supplier Analysis Report"}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            {departureReport && departureReport.length > 0 ? (
              <>
                {/* {departureReport &&
                  departureReport.length > 0 &&
                  departureReport.map((order) => {
                    return (
                      <div className="mt-20">
                        <div
                          className="category_name"
                          style={{ marginBottom: "10px" }}
                        >
                          {order.category}
                        </div> */}

                <CustomReportTable
                  headings={[
                    "Item Name",
                    "Supplier Name",
                    "GST No",
                    "Unit",
                    "MTD",
                    "QTD",
                    "YTD",
                    "MTD",
                    "QTD",
                    "YTD",
                    "MTD",
                    "QTD",
                    "YTD",
                  ]}
                  commonHeadings={[
                    "",
                    "",
                    "",
                    "",
                    "Quantity",
                    "Quantity",
                    "Quantity",
                    "Total Purchase Amount  (₹)",
                    "Total Purchase Amount  (₹)",
                    "Total Purchase Amount  (₹)",
                    "Average Purchase Cost per unit (₹)",
                    "Average Purchase Cost per unit (₹)",
                    "Average Purchase Cost per unit (₹)",
                  ]}
                  headerStyles={{
                    backgroundColor: "#dddddd",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                  tableStyles={{ textAlign: "left" }}
                >
                  {rowMarkup}
                  {calculateTotals}
                </CustomReportTable>
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Item-Wise Supplier Analysis"
        >
          <div className="mb-20">
            {/* <h2 className="mb-10"> Accordion Component </h2> */}
            <AccordionComponent
              questions={[
                {
                  question: "What is an Item-Wise Supplier Analysis?",
                  answer: (
                    <p>
                      An <b>Item-Wise Supplier Analysis report</b> provides a detailed
                      overview of procurement data for specific items from
                      multiple suppliers. This report allows users to select
                      items and track the quantities purchased, total purchase
                      amounts, and average cost per unit from each supplier. The
                      data is segmented into MTD (Month-to-Date), QTD
                      (Quarter-to-Date), and YTD (Year-to-Date), giving insights
                      into purchase trends over different time periods.
                    </p>
                  ),
                },
                {
                  question: "Importance of an Item-Wise Supplier Analysis",
                  answer: (
                    <div>
                      <p>
                        The Item-Wise Supplier Analysis is valuable for several
                        reasons:
                      </p>

                      <ul>
                        <li>
                          <b> Cost Management:</b> By comparing the average
                          purchase cost per unit from different suppliers,
                          businesses can identify opportunities for cost savings
                          and optimize procurement strategies.
                        </li>
                        <li>
                          <b>Supplier Performance Evaluation:</b> It allows
                          businesses to assess the performance of each supplier
                          in terms of pricing, reliability, and delivery
                          efficiency.
                        </li>
                        <li>
                          <b> Data-Driven Decisions:</b> The report helps in
                          making informed procurement decisions by offering a
                          clear view of supplier-wise purchasing patterns for
                          specific items over different time frames.
                        </li>
                        <li>
                          <b>Inventory Planning:</b> Understanding item-wise
                          purchasing trends helps in managing inventory
                          effectively and anticipating future needs based on
                          past patterns.
                        </li>
                        <li>
                          <b>Vendor Negotiations:</b> Businesses can leverage
                          this analysis to negotiate better terms with suppliers
                          by having transparent data on costs and purchase
                          volumes.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportItemViseSupplier;
