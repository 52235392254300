import {
  TextField,
  IndexTable,
  LegacyCard,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
  Tooltip,
} from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import { Link } from "react-router-dom";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
} from "./UI/Buttons";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import { useSelector } from "react-redux";
import Loader from "../Components/UI/Loader/Loader";
import NoDataFound from "./CustomComponents/NoDataFound";
import CustomerSelectAddBox from "./CustomComponents/CustomSelectAddBox";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import AddCompetitors from "./AddCompetitorsPopup/AddCompetitors";
import { getDecryptedData } from "../utils/encryptStorage";
const Competitors = () => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const permission = JSON.parse(localStorage.getItem("permissions"));

  const [permissions, setPermissions] = useState(permission);

  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);
  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [itemStrings, setItemStrings] = useState([
    "All",
    "Unpaid",
    "Open",
    "Closed",
    "Local delivery",
    "Local pickup",
  ]);
  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };
  const userToken = getDecryptedData("encryptToken");

  const tabs = itemStrings?.map((item, index) => ({
    content: item,
    index,
    onAction: () => {},
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
            {
              type: "rename",
              onAction: () => {},
              onPrimaryAction: async (value) => {
                const newItemsStrings = tabs.map((item, idx) => {
                  if (idx === index) {
                    return value;
                  }
                  return item.content;
                });
                await sleep(1);
                setItemStrings(newItemsStrings);
                return true;
              },
            },
            {
              type: "duplicate",
              onPrimaryAction: async (value) => {
                await sleep(1);
                duplicateView(value);
                return true;
              },
            },
            {
              type: "edit",
            },
            {
              type: "delete",
              onPrimaryAction: async () => {
                await sleep(1);
                deleteView(index);
                return true;
              },
            },
          ],
  }));

  const [selected, setSelected] = useState(0);

  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };

  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];
  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => {};

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const primaryAction =
    selected === 0
      ? {
          type: "save-as",
          onAction: onCreateNewView,
          disabled: false,
          loading: false,
        }
      : {
          type: "save",
          onAction: onHandleSave,
          disabled: false,
          loading: false,
        };
  const [accountStatus, setAccountStatus] = useState(undefined);
  const [moneySpent, setMoneySpent] = useState(undefined);
  const [taggedWith, setTaggedWith] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const handleAccountStatusChange = useCallback(
    (value) => setAccountStatus(value),
    []
  );
  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(undefined),
    []
  );
  const handleMoneySpentRemove = useCallback(
    () => setMoneySpent(undefined),
    []
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove();
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleAccountStatusRemove,
    handleMoneySpentRemove,
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  const filters = [
    {
      key: "accountStatus",
      label: "Account status",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={[
            { label: "Enabled", value: "enabled" },
            { label: "Not invited", value: "not invited" },
            { label: "Invited", value: "invited" },
            { label: "Declined", value: "declined" },
          ]}
          selected={accountStatus || []}
          onChange={handleAccountStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <TextField
          label="Tagged with"
          value={taggedWith}
          onChange={handleTaggedWithChange}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: "moneySpent",
      label: "Money spent",
      filter: (
        <RangeSlider
          label="Money spent is between"
          labelHidden
          value={moneySpent || [0, 500]}
          prefix="$"
          output
          min={0}
          max={2000}
          step={1}
          onChange={handleMoneySpentChange}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (accountStatus && !isEmpty(accountStatus)) {
    const key = "accountStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }
  if (moneySpent) {
    const key = "moneySpent";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }
  const manageButton = <BgOutlineThemeButton children={"Manage"} />;

  const [orders, setOrders] = useState([]);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  // Fetch Info
  const property_id = useSelector((state) => state.property.value);

  const [competitorsLimit, setCompetitorsLimit] = useState("");
  const [features, setFeatures] = useState("");

  useEffect(() => {
    if (property_id) {
      setFeatures(property_id.subscriptions.plan.features);
    }
    // const competitorLimit = features && features.map((feature) => {
    //   const comp = features.find
    //   return feature.plan_name === "Competitors Analysis" ? feature.plan_limit : "po"
    // })

    const comp =
      features &&
      features.find((feature) => feature.plan_name === "Competitors Analysis");
    setCompetitorsLimit(comp?.plan_limit || 0);
  }, [property_id, competitorsLimit]);

  const [competitorLimit, setCompetitorLimit] = useState("");

  const [loader, setLoader] = useState(true);

  const fetchInfo = async (propertyId) => {
    try {
      setLoader(true);
      const response = await fetch(
        //http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/competitors/99f12663-7d6d-4d28-8f08-786b7daa73f1
        `${process.env.REACT_APP_BASE_URL}/api/v1/competitors/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setOrders(data.competitor);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id && userToken) {
      fetchInfo(property_id?.id);
    }
  }, [property_id, userToken]);

  setTimeout(() => {
    setLoader(false);
  }, 2000);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
    // setShowLowerMenuSingleSelect(false);
    // setShowLowerMenuMultipleSelect(false);(!showLowerMenuAddMenu);
  };

  const removeAddModalContainer = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  // const uniqueDestinations = customers.map((customer)=>{
  //   return {id:customer.id}
  // })
  // }

  // getItemChecked(selectedResources[0]);
  const rowMarkup = orders?.map(
    ({ id, destination, competitor_name, hotel_category, index }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          {<CustomCssComponentTableText innerText={competitor_name} />}
        </IndexTable.Cell>
        {/* <IndexTable.Cell>
          {<CustomCssComponentTableText innerText={hotel_category} />}
        </IndexTable.Cell> */}
        <IndexTable.Cell>
          {<CustomCssComponentTableText innerText={destination} />}
        </IndexTable.Cell>
        {/* <IndexTable.Cell>{<CustomCssComponentTableText innerText={manageButton} />}</IndexTable.Cell> */}
      </IndexTable.Row>
    )
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  // Plan Type Options
  const hotelCategoryOptions = [
    { label: "Standard", value: "Standard" },
    { label: "Luxury", value: "Luxury" },
  ];

  const [hotelCategoryTypeSelected, setHotelCategoryPlanTypeSelected] =
    useState("EP");
  const hotelCategoryTypeHandleSelectChange = useCallback(
    (value) => setHotelCategoryPlanTypeSelected(value),
    []
  );

  // Plan Name Options
  const planNameOptions = [
    { label: "Room Only", value: "newestUpdate" },
    { label: "Room + Complimentary Breakfast", value: "oldestUpdate" },
    {
      label: "Room with Complimentary Breakfast and dinner",
      value: "mostSpent",
    },
    { label: "Breakfast + Lunch + Dinner", value: "mostOrders" },
  ];

  const [planNameSelected, setNameTypeSelected] = useState("EP");
  const planNameHandleSelectChange = useCallback(
    (value) => setNameTypeSelected(value),
    []
  );

  // Plan Name Options
  const planStatusOptions = [
    { label: "Booked", value: "Booked" },
    {
      label: "Available",
      value: "Available",
    },
  ];

  const [planStatusSelected, setPlanStatusSelected] = useState("Booked");
  const planStatusHandleSelectChange = useCallback(
    (value) => setPlanStatusSelected(value),
    []
  );

  // Plan Type Options
  const roomTypeOptions = [
    { label: "Deluxe", value: "Deluxe" },
    { label: "Super Deluxe", value: "Super Deluxe" },
    { label: "Cottage", value: "Cottage" },
    { label: "Luxury", value: "Luxury" },
    { label: "Executive", value: "Executive" },
    { label: "Family", value: "Family" },
  ];

  const [roomTypeSelected, setRoomTypeSelected] = useState("EP");
  const roomTypeHandleSelectChange = useCallback(
    (value) => setRoomTypeSelected(value),
    []
  );

  // UseState for forms
  const [competitorsData, setCompetitorsData] = useState({
    average_rate: "",
    competitor_name: "",
    hotel_category: "",
    // status: "booked",

    property_id: property_id?.id,
  });

  const [competitorsIdsArray, setCompetitorsIdsArray] = useState([]);
  // Callback function to receive data from the child component
  const handleCompetitorsIdsArrayChange = (newArray) => {
    setCompetitorsIdsArray(newArray);
  };

  const setAddItemDataHandleChange = (event) => {
    setCompetitorsData({
      ...competitorsData,
      [event.target.name]: event.target.value,
    });
  };

  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/competitors/delete/${property_id?.id}`,

      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    );

    const data = await response.json();
    clearSelection();
    showSuccessToast(data.message);
    fetchInfo(property_id.id);
    setShowLowerMenuMultipleSelect(false);
    setShowModelMenuActive(false);
  };

  const handleSubmitAddCompetitor = (event) => {
    event.preventDefault();
    const competitor = competitorsIdsArray;
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/assign/competitors/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ competitor: competitorsIdsArray }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setShowAddModelMenuActive(false);
          fetchInfo(property_id?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">
                Competitors {`(${orders.length}/${competitorsLimit})`}{" "}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                {/* {permissions && permissions.includes("create_competitors") ? (
                  <>
                    {orders.length >= competitorsLimit ? (
                      <Tooltip
                        content={`The maximum comset limit for your plan has been exceeded. Please upgrade your plan.`}
                        dismissOnMouseOut
                      >
                        <BgThemeButton
                          style={{ opacity: "0.5", cursor: "no-drop" }}
                          onClick={addModalButtonClickHandler}
                          children={"Add Competitors"}
                        />
                      </Tooltip>
                    ) : (
                      <BgThemeButton
                        onClick={addModalButtonClickHandler}
                        children={"Add Competitors"}
                      />
                    )}
                  </>
                ) : (
                  <Tooltip
                    content={`Please contact your admin.`}
                    dismissOnMouseOut
                  >
                    <BgThemeButtonDisabled
                      // onClick={addModalButtonClickHandler}
                      children={"Add Competitors"}
                    />
                  </Tooltip>
                )} */}

                {permission && permission.includes("full_access_competitor_analysis") ? (
                  <BgThemeButton
                    // style={{ opacity: "0.5", cursor: "no-drop" }}
                    onClick={addModalButtonClickHandler}
                    children={"Add Competitors"}
                  />
                ) : (
                  <Tooltip
                    content={`Please contact your admin.`}
                    dismissOnMouseOut
                  >
                    <BgThemeButtonDisabled
                      // onClick={addModalButtonClickHandler}
                      children={"Add Competitors"}
                    />
                  </Tooltip>
                )}
              </div>
            </div>

            {orders && orders.length > 0 ? (
              <LegacyCard>
                <IndexTable
                  resourceName={resourceName}
                  itemCount={orders?.length}
                  selectedItemsCount={
                    allResourcesSelected ? "All" : selectedResources.length
                  }
                  onSelectionChange={handleSelectionChange}
                  headings={[
                    { title: "Name" },
                    // { title: "Hotel Category" },
                    { title: "Destination" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>

                {/* Single select */}
                {showLowerMenuSingleSelect && (
                  <div className=" room-type-bottom-container">
                    <div className="room-type-bottom-container-div multipleroom-delete-btn-div">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          gap: "20px",
                        }}
                      >
                        {permissions &&
                        permissions.includes("full_access_competitor_analysis") ? (
                          <Button
                            plain
                            destructive
                            onClick={() => showModelMenu()}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                <Icon source={DeleteMajor} color="base" />
                              </span>
                              <span>Remove</span>
                            </div>
                          </Button>
                        ) : (
                          <Tooltip
                            content="Please Contact Your Admin"
                            dismissOnMouseOut
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                opacity: "0.4",
                                cursor: "no-drop",
                              }}
                            >
                              <span>
                                <Icon source={DeleteMajor} color="base" />
                              </span>
                              <span>Remove</span>
                            </div>
                          </Tooltip>
                        )}

                        {/* <Link
                          style={{ textDecoration: "none" }}
                          to={`/competitors/manage`}
                        >
                          <BgOutlineThemeButton children={"Manage"} />
                        </Link> */}
                      </div>
                    </div>
                  </div>
                )}

                {/* Multiple select */}
                {showLowerMenuMultipleSelect && (
                  <div className=" room-type-bottom-container">
                    <div className="room-type-bottom-container-div multipleroom-delete-btn-div">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {permissions &&
                        permissions.includes("full_access_competitor_analysis") ? (
                          <Button
                            plain
                            destructive
                            // onClick={showModelMenu}
                            onClick={() => showModelMenu()}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                <Icon source={DeleteMajor} color="base" />
                              </span>
                              <span>Remove</span>
                            </div>
                          </Button>
                        ) : (
                          <Tooltip
                            content="Please Contact Your Admin"
                            dismissOnMouseOut
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                opacity: "0.4",
                                cursor: "no-drop",
                              }}
                            >
                              <span>
                                <Icon source={DeleteMajor} color="base" />
                              </span>
                              <span>Remove</span>
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </LegacyCard>
            ) : (
              <NoDataFound />
            )}
          </div>

          {/* Add Button Click */}
          {showAddModelMenuActive && (
            <>
              <AddCompetitors
                property_id={property_id?.id}
                setShow={setShowAddModelMenuActive}
                fetchInfo={fetchInfo}
                userToken={userToken}
              />
            </>
          )}
          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    <Button onClick={deleteData} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default Competitors;
