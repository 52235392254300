import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import CustomIndexTable from "../Dashboard/CustomIndexTable/CustomIndexTable";
import CustomIndexTableRow from "../Dashboard/CustomIndexTable/CustomIndexTableRow";
import CustomIndexTableCell from "../Dashboard/CustomIndexTable/CustomIndexTableCell";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useState } from "react";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/svgIcons";

const ReportOutlet = ({ loader, data }) => {
  const [informationPopup, setInformationPopup] = useState(false);

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell colSpan={4}>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell>
        {calculateTotalWithReduce(data, "count")}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(data, "kot_total_amount")
        )}
      </CustomReportTableCell>

      <CustomReportTableCell>
        {formatIndianCurrency(calculateTotalWithReduce(data, "kot_tax_amount"))}
      </CustomReportTableCell>

      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(data, "kot_grand_total")
        )}
      </CustomReportTableCell>
    </CustomReportTableRow>
  );
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div
          id="receipt"
          className="room-types-full-container reports-new-background"
        >
          <div className="room-types-full-container-div">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
                <div className="room-type-heading">
                  Revenue Contribution Report
                </div>
                <div
                  className="hoverCursor"
                  onClick={() => setInformationPopup(true)}
                  id="ignoreFromPrint"
                >
                  <SvgGetInfo />
                </div>
              </div>

              {data && data.length > 0 && (
                <div className="flex flex_gap_15" id="qwerty">
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Outlet_reports"}
                    ignoreFromPrint={"ignoreFromPrint"}
                    longPdf={true}
                  />
                  <DownloadCSVButton
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Outlet_report"}
                  />
                </div>
              )}
            </div>
          </div>
          <div id="receipt">
            {data && data.length > 0 ? (
              <>
                <CustomReportTable
                  headerStyles={{ borderBottom: "1px solid #CCCCCC" }}
                  showCheckbox={false}
                  headings={[
                    "Sr.No",
                    // "Name",
                    "No. Of KOT's Purchased",
                    "Sub Total",
                    "Tax Amount",
                    "Revenue Contribution",
                  ]}
                >
                  {data &&
                    data.length > 0 &&
                    data?.map((items, index) => {
                      return (
                        <CustomReportTableRow>
                          <CustomReportTableCell>
                            {index + 1}
                          </CustomReportTableCell>
                          {/* <CustomReportTableCell>
                            {items.order_by}
                          </CustomReportTableCell> */}

                          <CustomReportTableCell>
                            {items.count}
                          </CustomReportTableCell>
                          <CustomReportTableCell>
                            {formatIndianCurrency(items.kot_total_amount)}
                          </CustomReportTableCell>
                          <CustomReportTableCell>
                            {formatIndianCurrency(items.kot_tax_amount)}
                          </CustomReportTableCell>
                        
                          <CustomReportTableCell>
                            {formatIndianCurrency(items.kot_grand_total)}
                          </CustomReportTableCell>
                        </CustomReportTableRow>
                      );
                    })}
                  {calculateTotals}
                </CustomReportTable>
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of F&B Revenue Contribution Report"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is the F&B Revenue Contribution Report?",
                  answer: (
                    <p>
                      The <b>F&B Revenue Contribution Report</b> is a detailed
                      document that tracks and analyzes the revenue generated by
                      individual staff members in the Food & Beverage
                      department. It provides insights into the number of
                      Kitchen Order Tickets (KOTs) punched, the total sales
                      associated with those orders, and the tax amounts applied.
                      This report allows for the selection of specific staff
                      members, enabling both single and multiple selections for
                      comprehensive analysis.
                    </p>
                  ),
                },
                {
                  question:
                    "What is the Importance of the F&B Revenue Contribution Report?",
                  answer: (
                    <div>
                      {/* <ul>
                        <li>
                          <b>Guest Preferences: </b>Identifies which meal plans guests prefer, helping hotels tailor their services accordingly.
                        </li>
                        <li>
                        <b>Revenue Insights:</b> Shows which meal plans generate the most revenue, aiding in strategic pricing and promotions.
                        </li>
                        <li>
                        <b>Operational Planning:</b> Helps in optimizing kitchen and F&B operations based on plan popularity.
                        </li>
                        <li>
                       <b> Marketing Focus:</b> Enables targeted offers and promotions for popular or underutilized meal plans to boost bookings and guest satisfaction.
                        </li>
                      </ul> */}
                      <p>
                        The F&B Revenue Contribution Report is crucial for
                        evaluating staff performance and understanding sales
                        dynamics within the team. By assessing individual
                        contributions, management can identify top performers,
                        recognize high achievers, and implement strategies to
                        improve overall sales. This report also aids in making
                        data-driven decisions, optimizing staff training, and
                        enhancing the efficiency of the F&B operations,
                        ultimately leading to increased revenue and better
                        service quality.
                      </p>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};

export default ReportOutlet;
