import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import CustomIndexTable from "../Dashboard/CustomIndexTable/CustomIndexTable";
import CustomIndexTableRow from "../Dashboard/CustomIndexTable/CustomIndexTableRow";
import CustomIndexTableCell from "../Dashboard/CustomIndexTable/CustomIndexTableCell";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";

const ReportRequisitionRequestReport = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const rowMarkupAllFoliosCustom = departureReport?.map(
    ({ id, count, order_no, order_type, net_total, date, index }) => {
      return (
        <CustomIndexTableRow
          id={id}
          key={id}
          // selectedItems={selectedArray} // send for checking the check box is checked
          // setSelectedItems={setSelectedArray}
          showCheckbox={false} // show the check box
        >
          <CustomIndexTableCell>{id}</CustomIndexTableCell>

          <CustomIndexTableCell>{count}</CustomIndexTableCell>
          <CustomIndexTableCell>
            {formatIndianCurrency(id)}
          </CustomIndexTableCell>
        </CustomIndexTableRow>
      );
    }
  );


  const calculateTotal = (items, key) => {
    return 0;
  };
  

  const downloadCSV = () => {
    // Prepare CSV content
    const csvHeaders = "Category,Item,Count,Sale";
    const csvContent = `${csvHeaders}\n${departureReport
      .map((category) =>
        category.items
          .map((item) =>
            [category.category, item.item, item.count, item.sale].join(",")
          )
          .join("\n")
      )
      .join("\n")}`;

    // Create a blob with CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element to trigger download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "sales_data.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // console.log(departureReport);

  const rowMarkup = departureReport?.map(
    ({
      id,
      request_id,

      issue_item_date,
      item_category,
      department_name,
      issue_to,
      quantity,
      unique_purchase_id,
      total_amount,
      item_name,
      unit_name,
      status,
    }) => (
      <CustomReportTableRow
        CustomReportTableRow
        id={id}
        key={id}
        // bodyStyles={{
        //   backgroundColor: "red",
        //   fontSize: "10px",
        // }}
      >
        <CustomReportTableCell>
          {request_id ? request_id : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {item_name ? item_name : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {unit_name ? unit_name : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>{quantity}</CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(total_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>{item_category}</CustomReportTableCell>
        <CustomReportTableCell>
          {department_name ? department_name : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {issue_to ? issue_to : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>{status ? status : "N/A"}</CustomReportTableCell>
      </CustomReportTableRow>
    )
  );

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Total"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>
        {calculateTotalWithReduce(departureReport, "quantity")}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "total_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
    </CustomReportTableRow>
  );
  const extractedData = departureReport?.map(item => ({
    request_id: item && item?.request_id ? item?.request_id  : 'N/A',
    item_name: item &&  item?.item_name ? item?.item_name  : 'N/A',
    unit_name: item &&  item?.unit_name ? item?.unit_name  : 'N/A',
    quantity: item &&  item?.quantity ? item?.quantity : 'N/A',
    total_amount: item &&  item?.total_amount ? item?.total_amount : 'N/A',
    item_category: item &&  item?.item_category ?  item?.item_category : 'N/A',
    department_name: item &&  item?.department_name ? item?.department_name : 'N/A',
    issue_to: item &&  item?.issue_to ? item?.issue_to : 'N/A',
    status: item &&  item?.status ?  item?.status : 'N/A',
  }));

  const names = [
    {
      request_id: "Request ID",
      item_name: "Item Name	",
      unit_name: "Unit",
      quantity:  "Qty",
      total_amount:"Amount (₹)	",
      item_category:  "Item category	",
      department_name:  "Requested by(Department Name)",
      issue_to: "Requested by(Employee Name)",
      status:  "Status",
    },
  ];
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="room-types-full-container reports-new-background"  id="receipt">
          <div className="room-types-full-container-div "  >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div className="room-type-heading">
                Requisition Request Report
              </div>
              {departureReport.length > 0 && (
                <div className="flex flex_gap_15 align_center" id="ignoreFromPrint">
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={"requisition_request_report"}
                    ignoreFromPrint={"ignoreFromPrint"}
                    longPdf={true}
                    // pdfStyles={

                    // }
                  />
                <DownloadCSVButton itemToDownloadID={"receipt"}
                    downloadFileName={"requisition_request_report"}/>
                </div>
              )}
            </div>
          </div>
          <div>
            {departureReport && departureReport.length > 0 ? (
              <>
                {/* {departureReport &&
                  departureReport.length > 0 &&
                  departureReport.map((order) => {
                    return (
                      <div className="mt-20">
                        <div
                          className="category_name"
                          style={{ marginBottom: "10px" }}
                        >
                          {order.category}
                        </div> */}

                <CustomReportTable
                  headings={[
                    "Request ID",
                    "Item Name",
                    "Unit",
                    "Qty",
                    "Amount (₹)",
                    "Item category",
                    "Department",
                    "Employee name",
                    "Status",
                  ]}
                  commonHeadings={[
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "Requested by",
                    "Requested by",
                    "",
                  ]}
                  headerStyles={{
                    backgroundColor: "#dddddd",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                  tableStyles={{ textAlign: "left" }}
                >
                  {rowMarkup}
                  {calculateTotals}
                </CustomReportTable>
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default ReportRequisitionRequestReport;
