// Polaris Imports
import {
  Button,
  Card,
  ChoiceList,
  HorizontalStack,
  Icon,
  IndexTable,
  Pagination,
  Tooltip,
  useIndexResourceState,
  Text,
} from "@shopify/polaris";
import BarCharts from "../BarCharts";
import { PolarisVizProvider } from "@shopify/polaris-viz";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  CheckWithoutBgUnderlineBtn,
  WithoutBgButtonBlue,
} from "../UI/Buttons";

import DatePicker from "react-datepicker";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import CustomProgressBar from "../CustomComponents/CustomProgressBar";
import CustomToggleButton from "../UI/CustomToggleButton";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { Page, LegacyCard, DataTable } from "@shopify/polaris";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import { useSelector } from "react-redux";
import Loader from "../UI/Loader/Loader";
import { useNavigate } from "react-router-dom";
import CompetitorsAfter from "../CompetitorsAfter";
import SelectDateRangeWithoutInput from "../CustomComponents/SelectDateRangeWithoutInput/SelectDateRangeWithoutInput";
import ShowConfirmedArrivals from "../UI/ShowConfirmedArrivals";
import ShowHouseCheckIn from "../UI/ShowHouseCheckIn";
import ShowDepartureCheckOut from "../UI/ShowDepartureCheckOut";
import NoDataFound from "../CustomComponents/NoDataFound";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import { current } from "@reduxjs/toolkit";
import LineChartDashboard from "../LineChartsDashboard";
import SelectDateRange from "../CustomComponents/SelectDateRange/SelectDateRange";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import ReactDatePicker from "react-datepicker";
import SelectDatePickerCustom from "../CustomComponents/SelectDateRange/SelectDatePickerCustom";
import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";
import {
  permission_inner_lists,
  rooms_inner_lists,
} from "../../assets/constant";
import { CSVLink } from "react-csv";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import ReportDepartureTable from "./ReportDepartureTable";
import ReportAccountBalanceTable from "./ReportAccountBalanceTable";
import ReportCancelTable from "./ReportCancelTable";
import ReportNoShowTable from "./ReportNoShowTable";
import ReportRoomAssignment from "./ReportRoomAssignment";
import CustomRoomChoiceSelect from "../CustomComponents/CustomRoomChoiceSelect/CustomRoomChoiceSelect";
import ReportPaymentReconciliation from "./ReportPaymentReconciliation";
import ReportPaymentDailyRevenue from "./ReportPaymentDailyRevenue";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import VerticalInputSelectCustomPermissions from "../CustomComponents/VerticalInputSelectCustomPermissions";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ReportArrivals from "./ReportArrivals";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/svgIcons";
import {
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import BookingDetailSliderNew from "../CustomComponents/BookingDetails/BookingDetailsSliderNew";
import { getDecryptedData } from "../../utils/encryptStorage";
import SidebarInnerListWithMultiplePermissions from "../CustomComponents/SidebarInnerListWithMultiplePermissions";

Chart.register(ArcElement);

const FrontOfficeReports = () => {
  const [informationPopup, setInformationPopup] = useState(false);

  const currentDate = new Date().toISOString().split("T")[0];
  const [fetchedData, setFetchedData] = useState(null);
  const [openDayStatus, setOpenDayStatus] = useState();
  const [showDashBoardCheckIn, setShowDashBoardCheckIn] = useState(false);
  const [showDashBoardCheckOut, setShowDashBoardCheckOut] = useState(false);
  const [showDashboardAllCheckIn, setShowDashboardAllCheckIn] = useState(false);
  const [showManualSearchInput, setShowManualSearchInput] = useState(false);
  const [showWebInput, setShowWebInput] = useState(false);
  const [showBookingDetails, setShowBookingDetails] = useState(false);

  const [showCheckOutBookingDetails, setShowCheckOutBookingDetails] =
    useState(false);
  const [
    showCheckOutIdClickBookingDetails,
    setShowCheckOutIdClickBookingDetails,
  ] = useState(false);

  const [settlementDoneCheckOut, setSettlementDoneCheckOut] = useState(false);

  const [showCheckOutSettlementDetails, setShowCheckOutSettlementDetails] =
    useState(false);
  const [openBookingDetails, setOpenBookingDetails] = useState(true);
  const userToken = getDecryptedData('encryptToken');

  const [bookingDetails, setBookingDetails] = useState([]);
  const [allSettlementsData, setAllSettlementsData] = useState([]);

  // show click arrow buttons
  const [showArrivalConfirmed, setShowArrivalConfirmed] = useState(false);
  const [showAllSettlement, setShowAllSettlement] = useState(false);
  const [showInHouseCheckIn, setShowInHouseCheckIn] = useState(false);
  const [showDepartureCheckOut, setShowDepartureCheckOut] = useState(false);

  const [checkOutBookingDetail, setCheckOutBookingDetail] = useState([]);

  const property_id = useSelector((state) => state.property.value);

  const [dayTypeFilter, setDayTypeFilter] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [valueArray, setValueArray] = useState([]);
  const accordRef = useRef(null);

  const toggleAccord = () => {
    setIsOpen(!isOpen);
    setIsOpen1(false);
    setIsOpen2(false);
  };
  const toggleAccord1 = () => {
    setIsOpen(false);
    setIsOpen1(!isOpen1);
    setIsOpen2(false);
  };
  const toggleAccord2 = () => {
    setIsOpen2(!isOpen2);
    // setIsOpen1(false);
    // setIsOpen(false);
  };
  const toggleAccord3 = () => {
    setIsOpen3(!isOpen3);
    setIsOpen1(false);
    setIsOpen(false);
  };

  const permissions = JSON.parse(localStorage.getItem("permissions"));

  // Reservations
  const dateOptions = [
    { id: 0, value: "-1", label: "Today" },
    { id: 0, value: "-2", label: "Yesterday" },
    { id: 1, value: "-7", label: "Past 7 Days" },
    { id: 2, value: "-30", label: "Past 30 Days" },
    { id: 3, value: "-90", label: "Past 90 Days" },
    { id: 4, value: "-1", label: "Last Year" },
    { id: 5, value: "-3", label: "Last 3 year" },
  ];
  // Reservations
  const dateOptions1 = [
    { id: 0, value: "revenue", label: "Revenue" },
    { id: 1, value: "profit", label: "Profit" },
    { id: 2, value: "reservation", label: "Reservation" },
    { id: 3, value: "expense", label: "Expense" },
    { id: 4, value: "event", label: "Event" },
    { id: 5, value: "f&b", label: "F&B" },
  ];

  const [allRoomTypes, setAllRoomTypes] = useState([]);

  const fetchAllRoomTypes = async (propertyId) => {
    try {
      // setLoader(true);
      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAllRoomTypes(data.rooms);

      const roomIds = data.rooms.map((room) => room.id);
      setSelectedRoomType(roomIds);
      if (property_id) {
        fetchInfo(property_id?.id, roomIds, selectedRoomType);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const choiceListRoomsArray = allRoomTypes.map((room) => {
    return { label: room.room_name, value: room.id };
  });

  const roomIdsArray = allRoomTypes.map((room) => room.id);

  const handleAccordClick = (id, label, value) => {
    setValueArray([{ id: id, label: label, value: value }]);
    setDayTypeFilter(value);
  };

  const handleShowDashBoardCheckIn = (e) => {
    e.stopPropagation();
    setShowDashBoardCheckIn(!showDashBoardCheckIn);
  };

  const handleShowDashBoardCheckOut = (e) => {
    e.stopPropagation();
    setBookingId("");
    setShowDashBoardCheckOut(!showDashBoardCheckOut);
  };

  const handleShowManualSearchInput = () => {
    {
      showWebInput === true && setShowWebInput(false);
    }
    setShowManualSearchInput(!showManualSearchInput);
  };

  const handleShowWebInput = () => {
    {
      showManualSearchInput === true && setShowManualSearchInput(false);
    }
    setShowWebInput(!showWebInput);
  };

  const [bookingOpenIndex, setOpenBookingIndex] = useState(1);
  const [bookingAccordianActive, setBookingAccordianActive] = useState("");

  const handleOpenBookigDetails = (idx) => {
    setOpenBookingIndex(idx);
    if (bookingOpenIndex === idx) {
      setBookingAccordianActive("active");
    } else {
      setBookingAccordianActive("");
    }
  };

  const handleShowDashBoardAllCheckIn = () => {
    setShowDashboardAllCheckIn(!showDashboardAllCheckIn);
  };

  const [loader, setLoader] = useState(true);

  const navigate = useNavigate();

  const [selectedDiscountMethod, setSelectedDiscountMethod] =
    useState("automatic");

  const handleDiscountMethodChange = (value) => {
    setSelectedDiscountMethod(value);
  };

  const [selectedRoomType, setSelectedRoomType] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [selectedBalanceType, setSelectedBalanceType] = useState([]);
  const [selectedBookingStatus, setSelectedBookingStatus] = useState([
    "in-house",
  ]);

  const handleBalanceTypeStatus = useCallback(
    (value) => setSelectedBalanceType(value),
    []
  );
  const handleBookingStatus = useCallback(
    (value) => setSelectedBookingStatus(value),
    []
  );

  const fetchDashboardInfo = async (propertyId, dayTypeFilter) => {
    // setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/dashboard/${property_id?.id}?dayTypeFilter=${dayTypeFilter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (!response.ok) {
        setLoader(false);
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (data.message === "Unauthenticated.") {
        navigate("/login");
      }
      // setLoader(false);
      setFetchedData(data);
    } catch (error) {
      if (error === "Unauthenticated.") {
        navigate("/login");
      }
      // setLoader(false);
      console.error(error);
    }
  };

  // const currentDate = new Date().toISOString().split("T")[0];

  const calculateToDate = (fromDate) => {
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() - 30);
    return toDate.toISOString().split("T")[0];
  };

  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: calculateToDate(currentDate),
    to_date: currentDate,
  });

  const [selectedDatesRangePaymentRec, setSelectedDatesRangePaymentRec] =
    useState({
      from_date: currentDate,
      to_date: currentDate,
    });
  const [comparisonStartDateRange, setComparisonStartDateRange] = useState({
    from_date: calculateToDate(currentDate),
    to_date: currentDate,
  });
  const [comparisonEndDateRange, setComparisonEndDateRange] = useState({
    from_date: calculateToDate(currentDate),
    to_date: currentDate,
  });

  const dataFromChild = (from_date, to_date) => {
    setSelectedDatesRange({
      from_date: from_date,
      to_date: to_date,
    });
  };

  const dataFromChild1 = (from_date, to_date) => {
    setComparisonStartDateRange({
      from_date: from_date,
      to_date: to_date,
    });
  };

  const dataFromChild2 = (from_date, to_date) => {
    setComparisonEndDateRange({
      from_date: from_date,
      to_date: to_date,
    });
  };

  useEffect(() => {
    if (property_id) {
      // fetchDashboardInfo(property_id.id, dayTypeFilter);
      fetchAllRoomTypes(property_id.id);
    }
  }, [property_id]);

  const openDayStatusHandler = useCallback((data) => {
    setOpenDayStatus(data);
  }, []);

  const chart_data = {
    datasets: [
      {
        data: [fetchedData?.total_revenue, fetchedData?.foodSale, 0],
        backgroundColor: ["#3968ED", "#15AA12", "#CCCCCC"],
        display: true,
      },
    ],
  };

  const [bookingId, setBookingId] = useState("");

  const [openAccordion, setOpenAccordion] = useState("first");

  // UseState for forms
  const [settlementAddData, setSettlementAddData] = useState({
    settlement_name: "",
    payment_mode: "",
    notes: "",
    date: "",
    amount: "",
    property_id: property_id?.id,
    other_payment_mode: "",
  });

  const paymentModeOptions = [
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    { label: "Other", value: "other" },
  ];

  const reportFilterOptions = [
    { label: "Select Option" },
    {
      label: "Expected Arrival",
      value: "arrival",
      permission: "daily_activity_arrival",
    },
    {
      label: "Departure",
      value: "departure",
      permission: "daily_activity_departure",
    },
    {
      label: "In House",
      value: "in_house",
      permission: "daily_activity_in_house",
    },
    // {
    //   label: "Account Balance",
    //   value: "account_balance",
    //   permission: "daily_activity_account_balance",
    // },
    {
      label: "Cancellation",
      value: "cancellation",
      permission: "daily_activity_cancellation",
    },
    {
      label: "No Show",
      value: "no_show",
      permission: "daily_activity_no_show",
    },
    {
      label: "Room Assignment",
      value: "room_assignment",
      permission: "daily_activity_room_assignment",
    },
    // {
    //   label: "Payment Reconciliation",
    //   value: "payment_reconciliation",
    //   permission: "daily_activity_payment_reconciliation",
    // },
    // {
    //   label: "Daily Revenue Report",
    //   value: "revenue_report",
    //   permission: "daily_activity_revenue_report",
    // },
  ];

  const renderYearContent = (year) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };

  useEffect(() => {
    document.title = "RevCatalyst - Boosting Your Hotel Performance";

    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [orders, setOrders] = useState([]);
  const [settlementOrders, setSettlementOrders] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [filterName, setFilterName] = useState("arrival");

  const generateYearOptions = (currentYear) => {
    const numYears = 3;
    const startYear = currentYear - numYears;
    const endYear = currentYear;

    const years = [];
    years.push({ label: "Select a year", value: "" });
    for (let year = startYear; year <= endYear; year++) {
      years.push({ label: year.toString(), value: year });
    }

    return years;
  };

  const data = [
    {
      name: "No. of Bookings",
      color: "#7C9CF3",
      data: chartData,
    },
  ];

  // Example usage:
  const currentYear = new Date().getFullYear();
  const yearOptions = generateYearOptions(currentYear);

  const fetchInfo = async (propertyId, roomIds) => {
    try {
      setLoader(true);
      setChartData([]);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/arrival-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${roomIds}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const [arrivalsErrors, setArrivalsErrors] = useState({
    dateRange: false,
    roomType: false,
  });

  const fetchArrivalsHandler = async (propertyId, roomIds) => {
    if (!selectedRoomType || selectedRoomType.length === 0) {
      showErrorToast("Please select the Room Type first!");
      setErrorWithTimeout(setArrivalsErrors, "roomType", 3000);
      return;
    }

    if (
      filterName === "departure" &&
      (!selectedBookingStatus || selectedBookingStatus.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "in_house" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "payment_reconciliation" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Payment Option!");
      return;
    }

    try {
      setChartData([]);

      setLoader(true);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/arrival-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${selectedRoomType}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        setLoader(false);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const [departureError, setDepartureError] = useState({
    roomType: false,
    status: false,
  });

  const fetchDepartureHandler = async (propertyId, roomIds) => {
    if (!selectedRoomType || selectedRoomType.length === 0) {
      showErrorToast("Please select the Room Type first!");

      setErrorWithTimeout(setDepartureError, "roomType", 3000);
      return;
    }

    if (
      filterName === "departure" &&
      (!selectedBookingStatus || selectedBookingStatus.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      setErrorWithTimeout(setDepartureError, "status", 3000);
      return;
    }

    if (
      filterName === "in_house" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "payment_reconciliation" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Payment Option!");
      return;
    }

    try {
      setChartData([]);
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/departure-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${selectedRoomType}&event_status=${selectedBookingStatus}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        setLoader(false);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const [inHouseError, setInHouseError] = useState({
    date: false,
    status: false,
  });

  const fetchInHouseHandler = async (propertyId, roomIds) => {
    if (!selectedRoomType || selectedRoomType.length === 0) {
      showErrorToast("Please select the Room Type first!");
      setErrorWithTimeout(setInHouseError, "status", 3000);
      return;
    }

    if (
      filterName === "departure" &&
      (!selectedBookingStatus || selectedBookingStatus.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "in_house" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      setErrorWithTimeout(setInHouseError, "status", 3000);
      return;
    }

    if (
      filterName === "payment_reconciliation" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Payment Option!");
      return;
    }

    try {
      setLoader(true);
      setChartData([]);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/in-house-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${selectedRoomType}&event_status=${selectedBookingStatus}&balance=${selectedBalanceType}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setLoader(false);
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchAccountBalanceHandler = async (propertyId, roomIds) => {
    if (!selectedRoomType || selectedRoomType.length === 0) {
      showErrorToast("Please select the Room Type first!");
      return;
    }

    if (
      filterName === "departure" &&
      (!selectedBookingStatus || selectedBookingStatus.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "in_house" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "payment_reconciliation" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Payment Option!");
      return;
    }

    setLoader(true);

    try {
      setChartData([]);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/account-balance-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${selectedRoomType}&event_status=${selectedBookingStatus}&balance=${selectedBalanceType}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchCancelationHandler = async (propertyId, roomIds) => {
    if (!selectedRoomType || selectedRoomType.length === 0) {
      showErrorToast("Please select the Room Type first!");
      return;
    }

    if (
      filterName === "departure" &&
      (!selectedBookingStatus || selectedBookingStatus.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "in_house" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "payment_reconciliation" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Payment Option!");
      return;
    }

    try {
      setChartData([]);
      setLoader(true);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/cancellation-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${selectedRoomType}&event_status=${selectedBookingStatus}&balance=${selectedBalanceType}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchNoShowHandler = async (propertyId, roomIds) => {
    if (!selectedRoomType || selectedRoomType.length === 0) {
      showErrorToast("Please select the Room Type first!");
      return;
    }

    if (
      filterName === "departure" &&
      (!selectedBookingStatus || selectedBookingStatus.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "in_house" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "payment_reconciliation" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Payment Option!");
      return;
    }

    try {
      setChartData([]);

      setLoader(true);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/no-show-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${selectedRoomType}&event_status=${selectedBookingStatus}&balance=${selectedBalanceType}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchRoomAssignmentHandler = async (propertyId, roomIds) => {
    // if (!selectedRoomType || selectedRoomType.length === 0) {
    //   showErrorToast('Please select the Room Type first!');
    //   return;
    // }

    if (
      filterName === "departure" &&
      (!selectedBookingStatus || selectedBookingStatus.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "in_house" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "payment_reconciliation" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Payment Option!");
      return;
    }

    try {
      setChartData([]);
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/room-assignment-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${selectedRoomType}&event_status=${selectedBookingStatus}&balance=${selectedBalanceType}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const [paymentError, setPaymentError] = useState({ paymentMode: false });
  const fetchPaymentReconciliation = async (propertyId) => {
    try {
      setChartData([]);
      setLoader(true);
      if (paymentOptions.length <= 0) {
        showErrorToast("Please select payment options.");
        setErrorWithTimeout(setPaymentError, "paymentMode", 3000);
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/payment-reconciliation-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            payment_type: paymentOptions,
          }),
        }
      );

      const data = await response.json();

      if (data.success) {
        const filterData = data.data.filter(
          (item) => item.payment_mode === "Null"
        );
        console.log(filterData, "filterData");
        setOrders(data.data);
        setSettlementOrders(data.foodData);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchPaymentRevenueFetch = async (propertyId, roomIds) => {
    try {
      setChartData([]);
      setLoader(true);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/revenue-report/${propertyId}?from_date=${selectedDatesRange.from_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        setLoader(false);

        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  useEffect(() => {
    setChartData(fullData?.graph_data);
  }, [orders, fullData]);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  const rowMarkup =
    orders &&
    orders.length > 0 &&
    orders?.map((order, index) => (
      <IndexTable.Row
        id={order.id}
        key={index}
        selected={selectedResources.includes(order.id)}
        position={order.index}
      >
        <IndexTable.Cell>
          {" "}
          <div
            className="pointer"
            style={{
              color: "rgba(57, 104, 237, 1)",
            }}
            onClick={() => getSingleBookingDataHandler(order.unique_booking_id)}
          >
            #{order.unique_booking_id || ""}
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {order.guest && order.guest.length > 0 && order.guest[0].guest_name}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {" "}
          <TextDateFormatter
            as={"span"}
            showYear={true}
            date={order.check_in}
          />
        </IndexTable.Cell>
        <IndexTable.Cell>
          <TextDateFormatter
            as={"span"}
            showYear={true}
            date={order?.check_out}
          />
        </IndexTable.Cell>
        <IndexTable.Cell>
          {order.guest &&
            order.guest.length > 0 &&
            order?.items.map((item, index) => (
              <div key={index}>{item.room_type_name}</div>
            ))}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {order.guest &&
            order.guest.length > 0 &&
            order?.items.map((item, index) => (
              <div key={index}>{item.no_nights}</div>
            ))}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {order.guest &&
            order.guest.length > 0 &&
            order?.items.map((item, index) => (
              <div key={index}>{item.no_adult}</div>
            ))}
        </IndexTable.Cell>
        <IndexTable.Cell>₹{order?.balance_amount}</IndexTable.Cell>
        <IndexTable.Cell>
          <StatusUpdate status={order?.status} /> {}
        </IndexTable.Cell>
      </IndexTable.Row>
    ));

  const otherMenusRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      otherMenusRef.current &&
      !otherMenusRef.current.contains(event.target)
    ) {
      setIsOpen(false);
      setIsOpen1(false);
      setIsOpen2(false);
      setIsOpen3(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getDataFromCustomRoomChoiceSelect = (data) => {
    setSelectedRoomType(data);
  };
  const getDataFromCustomRoomChoiceSelectPaymentOptions = (data) => {
    setPaymentOptions(data);
  };
  // ...................................
  // const extractedData = orders.map((item) => ({
  //   unique_booking_id: item.unique_booking_id,
  //   guest_name: item.guest[0]?.guest_name,
  //   check_in: item?.check_in,
  //   check_out: item?.check_out,
  //   room_type_name: item?.items[0]?.room_type_name,
  //   no_nights: item?.items[0]?.no_nights,
  //   no_adult: item?.items[0]?.no_adult,
  //   // assigned_room: item.assignedrooms[0].assigned_room,
  //   status: item.status.replace(/_/g, " "),
  // }));
  // console.log(extractedData);

  const names = [
    {
      unique_booking_id: "Booking ID	",
      guest_name: "Guest Name	",
      check_in: "Check In Date	",
      check_out: "Check Out Date	",
      room_type_name: "Room Type	",
      no_nights: "No Of Nights	",
      no_adult: "No Of Guests",
      assigned_room: "Balance",
      status: "Status",
    },
  ];

  const setErrorWithTimeout = (setterFunction, keyName, duration = 3000) => {
    setterFunction((prev) => ({ ...prev, [keyName]: true }));
    setTimeout(() => {
      setterFunction((prev) => ({ ...prev, [keyName]: false }));
    }, duration);
  };

  const calculateTotalsInHouse = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell colSpan={4}>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>

      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(orders, "balance_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
    </CustomReportTableRow>
  );

  const [bookingDetail, setBookingDetail] = useState(null);
  const [openSingleBookingDetails, setOpenSingleBookingDetails] =
    useState(false);

  const getSingleBookingDataHandler = (bookingId) => {
    // setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setOpenSingleBookingDetails(true);
          setLoader(false);
          setBookingDetail(data);
          // setChargesDetails(data?.charges);
          // setMasterFolioDetails(data?.masterFolio);
        } else {
          setLoader(false);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  return (
    <>
      {/* <SidebarInnerLists innerLists={permission_inner_lists} zIndex={20} /> */}
      <SidebarInnerListWithMultiplePermissions innerLists={permission_inner_lists}  zIndex={20}/>

      {/* {loader ? (
        <Loader />
      ) : (
        <> */}
      {/* <CSVLink data={orders}>Download me</CSVLink>; */}
      <section className="dashboard_page">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "15px 0",
            }}
          >
            <div style={{ fontSize: "20px" }} className="report-chart-heading">
              Daily Activity Reports
            </div>
          </div>
          <div>
            <div
              style={{
                background: "rgba(255, 255, 255, 1)",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <div title="Reservation Insights">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "10px",
                  }}
                >
                  <div className="report-chart-heading">Filters</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <div className="flex flex_gap_10">
                    <div style={{ width: "150px" }}>
                      <VerticalInputSelectCustomPermissions
                        titleName={"Filter Name"}
                        value={filterName}
                        defaultValue={"arrival"}
                        onChange={(e) => {
                          setFilterName(e.target.value);
                          // setSelectedRoomType([]);
                          setBookingDetails([]);
                          setSelectedBookingStatus([]);
                          setOrders([]);
                          // setSelectedDatesRange({
                          //   from_date: "",
                          //   to_date: "",
                          // })
                        }}
                        options={reportFilterOptions}
                        // error={true}
                      />
                    </div>
                    {filterName === "arrival" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={`${calculateToDate(
                              currentDate
                            )}:${currentDate}`}
                            minDate={new Date(currentDate)}
                          />
                        </div>

                        <div style={{ width: "210px" }}>
                          <CustomRoomChoiceSelect
                            roomChoice={choiceListRoomsArray}
                            getDataFromCustomRoomChoiceSelect={
                              getDataFromCustomRoomChoiceSelect
                            }
                            selectedRoomsIds={selectedRoomType}
                            heading="Room Type"
                            showButtons={true}
                            error={arrivalsErrors.roomType}
                          />
                        </div>
                      </>
                    )}

                    {filterName === "departure" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            defaultDate={`${calculateToDate(
                              currentDate
                            )}:${currentDate}`}
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                            error={departureError.dateRange}
                          />
                        </div>

                        <div style={{ width: "210px" }}>
                          <CustomRoomChoiceSelect
                            roomChoice={choiceListRoomsArray}
                            getDataFromCustomRoomChoiceSelect={
                              getDataFromCustomRoomChoiceSelect
                            }
                            selectedRoomsIds={selectedRoomType}
                            heading="Room Type"
                            showButtons={true}
                            error={departureError.roomType}
                          />
                        </div>

                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Status</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord3}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",

                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                                border: departureError.status
                                  ? "1px solid red"
                                  : "0.5px solid #CCC",
                                backgroundColor: departureError.status
                                  ? "#FFEAEA"
                                  : "",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBookingStatus.length} of 2 SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${
                                  isOpen ? "accordImageRotate" : null
                                } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen3 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "In House",
                                      value: "in-house",
                                    },
                                    {
                                      label: "Checked Out",
                                      value: "checked-out",
                                    },
                                  ]}
                                  selected={selectedBookingStatus}
                                  onChange={handleBookingStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {filterName === "in_house" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <VerticalInputFieldCustom
                            titleName={"Check-In Date"}
                            type={"Date"}
                            value={selectedDatesRange.from_date}
                            onChange={(e) => {
                              setSelectedDatesRange({
                                ...selectedDatesRange,
                                from_date: e.target.value,
                              });
                            }}
                            max={currentDate}
                            error={inHouseError.date}
                          />
                        </div>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Status</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord2}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                                border: inHouseError.status
                                  ? "1px solid red"
                                  : "0.5px solid #CCC",
                                backgroundColor: inHouseError.status
                                  ? "#FFEAEA"
                                  : "",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBalanceType.length} of {2} SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${
                                  isOpen ? "accordImageRotate" : null
                                } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen2 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "Balance Due",
                                      value: "balance-due",
                                    },
                                    {
                                      label: "No Balance",
                                      value: "no-balance",
                                    },
                                  ]}
                                  selected={selectedBalanceType}
                                  onChange={handleBalanceTypeStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {filterName === "rev_par" && (
                      <>
                        {/* closeOnScroll={(e) => e.target === document} */}
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>
                            Comparison Start Date
                          </div>
                          <SelectDateRange dataFromChild={dataFromChild1} />
                        </div>

                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>
                            Comparison End Date
                          </div>
                          <SelectDateRange dataFromChild={dataFromChild2} />
                        </div>

                        <div style={{ width: "210px" }}>
                          <CustomRoomChoiceSelect
                            roomChoice={choiceListRoomsArray}
                            getDataFromCustomRoomChoiceSelect={
                              getDataFromCustomRoomChoiceSelect
                            }
                            selectedRoomsIds={selectedRoomType}
                            heading="Room Type"
                            showButtons={true}
                          />
                        </div>

                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Source</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord3}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBookingStatus.length} of 4 SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${
                                  isOpen ? "accordImageRotate" : null
                                } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen3 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "Walk In Booking",
                                      value: "walk_in_booking",
                                    },
                                    {
                                      label: "Website Booking",
                                      value: "website_booking",
                                    },
                                    {
                                      label: "Channel Booking",
                                      value: "channel_booking",
                                    },
                                    {
                                      label: "Agent Booking",
                                      value: "agent_booking",
                                    },
                                  ]}
                                  selected={selectedBookingStatus}
                                  onChange={handleBookingStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {filterName === "account_balance" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                            defaultDate={`${calculateToDate(
                              currentDate
                            )}:${currentDate}`}
                          />
                        </div>

                        {/* <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Status</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord3}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBookingStatus.length} of 2 SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${isOpen ? "accordImageRotate" : null
                                  } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen3 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "In House",
                                      value: "in-house",
                                    },
                                    {
                                      label: "Checked Out",
                                      value: "checked-out",
                                    },
                                  ]}
                                  selected={selectedBookingStatus}
                                  onChange={handleBookingStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div> */}
                      </>
                    )}

                    {filterName === "cancellation" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                            defaultDate={`${calculateToDate(
                              currentDate
                            )}:${currentDate}`}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "no_show" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                            defaultDate={`${calculateToDate(
                              currentDate
                            )}:${currentDate}`}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "room_assignment" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                          />
                        </div>
                      </>
                    )}

                    {filterName === "payment_reconciliation" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={`${calculateToDate(
                              currentDate
                            )}:${currentDate}`}
                            // minDate={new Date(currentDate)}
                          />
                        </div>

                        <div style={{ width: "200px" }}>
                          <CustomRoomChoiceSelect
                            roomChoice={paymentModeOptions}
                            getDataFromCustomRoomChoiceSelect={
                              getDataFromCustomRoomChoiceSelectPaymentOptions
                            }
                            selectedRoomsIds={paymentOptions}
                            heading="Payment Options"
                            showButtons={true}
                            error={paymentError.paymentMode}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "revenue_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <VerticalInputFieldCustom
                            // disabled={true}
                            titleName={"Date"}
                            type={"Date"}
                            value={selectedDatesRange.from_date}
                            onChange={(e) => {
                              setSelectedDatesRange({
                                ...selectedDatesRange,
                                from_date: e.target.value,
                              });
                            }}
                            max={currentDate}
                          />
                        </div>

                        {/* <div style={{ width: "200px" }}>
                              <CustomRoomChoiceSelect
                                roomChoice={paymentModeOptions}
                                getDataFromCustomRoomChoiceSelect={
                                  getDataFromCustomRoomChoiceSelectPaymentOptions
                                }
                                selectedRoomsIds={paymentOptions}
                                heading="Payment Options"
                                showButtons={true}
                              />
                            </div> */}
                      </>
                    )}
                  </div>
                  <div className="flex flex_gap_10">
                    {filterName === "arrival" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchArrivalsHandler(property_id.id)}
                      />
                    )}
                    {filterName === "departure" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchDepartureHandler(property_id.id)}
                      />
                    )}
                    {filterName === "in_house" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchInHouseHandler(property_id.id)}
                      />
                    )}
                    {filterName === "account_balance" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchAccountBalanceHandler(property_id.id)
                        }
                      />
                    )}
                    {filterName === "cancellation" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchCancelationHandler(property_id.id)}
                      />
                    )}
                    {filterName === "no_show" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchNoShowHandler(property_id.id)}
                      />
                    )}
                    {filterName === "room_assignment" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchRoomAssignmentHandler(property_id.id)
                        }
                      />
                    )}

                    {filterName === "payment_reconciliation" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchPaymentReconciliation(property_id.id)
                        }
                      />
                    )}

                    {filterName === "revenue_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchPaymentRevenueFetch(property_id.id)}
                      />
                    )}
                    <BgOutlineThemeButton
                      onClick={() => {
                        setFilterName("");
                        // setSelectedRoomType([]);
                        setBookingDetails([]);
                        setSelectedBookingStatus([]);
                        setOrders([]);
                      }}
                      type={"button"}
                      children={"Clear"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* prettier-ignore */}
          {orders && orders.length > 0 && chartData && chartData.length > 0 && (
            <BarCharts loader={loader} data={data} leftTitle={"Insights"} />
          )}
          {filterName === "departure" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportDepartureTable
                  departureReport={orders}
                  loader={loader}
                  getSingleBookingDataHandler={getSingleBookingDataHandler}
                />
              )}
            </>
          )}
          {filterName === "account_balance" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportAccountBalanceTable departureReport={orders}
                getSingleBookingDataHandler={getSingleBookingDataHandler}
                />
              )}
            </>
          )}
          {filterName === "cancellation" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportCancelTable departureReport={orders}
                getSingleBookingDataHandler={getSingleBookingDataHandler}
                />
              )}
            </>
          )}
          {filterName === "no_show" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportNoShowTable departureReport={orders}
                getSingleBookingDataHandler={getSingleBookingDataHandler}
                />
              )}
            </>
          )}

          {filterName === "room_assignment" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportRoomAssignment departureReport={orders}
                getSingleBookingDataHandler={getSingleBookingDataHandler}
                />
              )}
            </>
          )}

          {filterName === "payment_reconciliation" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportPaymentReconciliation
                  departureReport={orders}
                  settlementOrders={settlementOrders}
                  loader={loader}
                />
              )}
            </>
          )}
          {filterName === "revenue_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportPaymentDailyRevenue
                  departureReport={orders}
                  loader={loader}
                />
              )}
            </>
          )}
          {filterName === "arrival" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportArrivals
                  departureReport={orders}
                  loader={loader}
                  getSingleBookingDataHandler={getSingleBookingDataHandler}
                  bookingDetail={bookingDetail}
                  openSingleBookingDetails={openSingleBookingDetails}
                />
              )}
            </>
          )}
          {filterName === "in_house" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <>
                  {orders && orders.length > 0 ? (
                    <div
                      style={{
                        background: "rgba(255, 255, 255, 1)",
                        padding: "20px",
                        borderRadius: "10px",
                      }}
                      id="in_house"
                    >
                      <div title="Reservation Insights">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingBottom: "10px",
                          }}
                        >
                          <div
                            className="flex flex_gap_5 "
                            style={{ alignItems: "unset" }}
                          >
                            <div
                              style={{ textTransform: "capitalize" }}
                              className="report-chart-heading"
                            >
                              In-House Guests Report
                            </div>
                            <div
                              className="hoverCursor"
                              onClick={() => setInformationPopup(true)}
                              id="ignoreFromPrint"
                            >
                              <SvgGetInfo />
                            </div>
                          </div>

                          {orders?.length > 0 && (
                            <div
                              className="flex flex_gap_15"
                              id="ignoreFromPrint"
                            >
                              <ButtonToDownloadTablePDF
                                itemToDownloadID={"in_house"}
                                downloadFileName={"detailed_in_house_report"}
                                ignoreFromPrint={"ignoreFromPrint"}
                                pdfOrientation={"landscape"}
                                // pageFormat={"A3"}
                              />
                              <DownloadCSVButton
                                itemToDownloadID={"in_house"}
                                downloadFileName={"detailed_in_house_report"}
                              />
                            </div>
                          )}
                        </div>
                        <div>
                          {orders && orders.length > 0 ? (
                            <LegacyCard>
                              <IndexTable
                                selectable={false}
                                resourceName={resourceName}
                                itemCount={orders.length}
                                selectedItemsCount={
                                  allResourcesSelected
                                    ? "All"
                                    : selectedResources.length
                                }
                                onSelectionChange={handleSelectionChange}
                                headings={[
                                  { title: "Booking ID" },
                                  { title: "Guest Name" },
                                  { title: "Check In Date" },
                                  { title: "Check Out Date" },
                                  { title: "Room Type" },
                                  { title: "No Of Nights" },
                                  { title: "No Of Guests" },
                                  { title: "Balance" },
                                  { title: "Status" },
                                ]}
                              >
                                {rowMarkup}
                                {calculateTotalsInHouse}
                              </IndexTable>
                            </LegacyCard>
                          ) : (
                            <NoDataFound />
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <NoDataFound />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </section>

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="In-House Guests Report"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is the In-House Guests Report?",
                  answer: (
                    <p>
                      The <b>In-House Guests Report</b> provides a detailed
                      overview of all guests currently staying at the hotel, or
                      those who were staying on a specific date. This report
                      includes a useful filter to track the payment status of
                      guests, allowing you to see whether there is a balance due
                      or no balance due for each booking.
                    </p>
                  ),
                },
                {
                  question: "Why is the In-House Guests Report Important?",
                  answer: (
                    <div>
                      <p>
                        This report is essential for managing ongoing operations
                        and ensuring a seamless guest experience. Here's why it
                        matters:
                      </p>

                      <ul>
                        <li>
                          <b>Efficient Billing Management:</b> Easily track
                          outstanding balances to ensure timely payment from
                          in-house guests.
                        </li>
                        <li>
                          <b>Optimized Guest Service:</b> Stay updated on
                          current guests and their needs to provide personalized
                          service and anticipate special requests.
                        </li>
                        <li>
                          <b>Real-Time Occupancy Overview:</b> Gain an accurate
                          snapshot of your hotel’s current occupancy, helping
                          with room allocation and resource planning.
                        </li>
                        <li>
                          <b>Improved Operational Coordination:</b> Ensure that
                          departments such as housekeeping, food and beverage,
                          and front desk are aligned with guest statuses.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}

      {openSingleBookingDetails && (
        <BookingDetailSliderNew
          data={bookingDetail?.booking}
          chargesDetails={bookingDetail?.charges}
          masterFolioDetails={bookingDetail?.masterFolio}
          setShowBookingDetails={setOpenSingleBookingDetails}
          getSingleBookingDataHandler={getSingleBookingDataHandler}
        />
      )}
    </>
  );
};

export default FrontOfficeReports;
