import React from 'react';
import PropTypes from 'prop-types';
import styles from './ToggleSwitch.module.scss';

const ToggleSwitch = ({ isOn, handleToggle, size, name }) => {
  return (
    <div className={`${styles.toggleSwitch} ${styles[size]}`}>
      <input
        checked={isOn}
        onChange={(e) => handleToggle(!isOn, name)}  // pass the name directly
        className={styles.reactSwitchCheckbox}
        id={`react-switch-${name}`}  // use name to make id unique
        type="checkbox"
        name={name}
      />
      <label
        className={styles.reactSwitchLabel}
        htmlFor={`react-switch-${name}`}
      >
        <span className={styles.reactSwitchButton} />
      </label>
    </div>
  );
};

ToggleSwitch.propTypes = {
  isOn: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  size: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ToggleSwitch;