import {
  TextField,
  IndexTable,
  LegacyCard,
  useSetIndexFiltersMode,
  useIndexResourceState,
  ChoiceList,
  RangeSlider,
  Button,
  Icon,
  Select,
  Page,
  Tooltip,
} from "@shopify/polaris";

import { DeleteMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import { Link, useNavigate } from "react-router-dom";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  WithoutBgButtonBlue,
} from "./UI/Buttons";

import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import NoDataFound from "./CustomComponents/NoDataFound";
import Loader from "./UI/Loader/Loader";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import { pms_inner_lists } from "../assets/constant";
import CustomPagination from "./CustomComponents/CustomPagination/CustomPagination";
import GoBackButtonCustom from "./GoBackButtonCustom/GoBackButtonCustom";
import HandleNaN from "./CustomComponents/HandleNaN/HandleNaN";
import SearchComponent from "./CustomComponents/SearchComponent/SearchComponent";
import BookingStatusUpdate from "./CustomComponents/BookingStatusUpdate";
import CustomPaginationArrows from "./CustomComponents/CustomPagination/CustomPaginationArrows";
import ItemIssueSearch from "./CustomComponents/ItemIssueSearch";
import { getDecryptedData } from "../utils/encryptStorage";
import SidebarInnerListWithMultiplePermissions from "./CustomComponents/SidebarInnerListWithMultiplePermissions";

const Purchase = () => {
  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const [orders, setOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [items, setItems] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  // const [paidAmount, setPaidAmount] = useState();
  const navigate = useNavigate();

  const permission = JSON.parse(localStorage.getItem("permissions"));

  const [permissions, setPermissions] = useState(permission);

  // Pagignation
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [unpaidActive, setUnpaidActive] = useState(false);

  const handlePageChange = (page, newUrl) => {
    // console.log(page, newUrl);
    setCurrentPage(page);
    if (newUrl) {
      fetchInfo(property_id?.id, newUrl);
    }
  };

  const [search, setSearch] = useState("");
  const handleChildData = (data) => {
    setSearch(data);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      if (property_id) {
        fetchInfo(property_id?.id);
      }
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrders = orders?.slice(startIndex, endIndex);

  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);

  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddStockModelMenuActive, setShowAddStockModelMenuActive] =
    useState(false);

  const [showAddItemModelMenuActive, setShowAddItemModelMenuActive] =
    useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const [accountStatus, setAccountStatus] = useState(undefined);
  const [moneySpent, setMoneySpent] = useState(undefined);
  const [taggedWith, setTaggedWith] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const [stocks, setStocks] = useState({
    supplier_id: "",
    purches_date: "",
    invoice_no: "",
    details: [],
    sub_total: "",
    due_amount: "",
    paid_amount: "",
  });

  const [itemDetails, setItemDetails] = useState([
    {
      item_name: "",
      item_price: "",
      item_weight: "",
      weight_unit: "",
      price_pre_unit: "",
      item_qty: "",
      gst: "0",
      discount: "0",
      total_amount: "",
      item_id: "",
    },
  ]);

  const handleAddStockChange = (e) => {
    const { name, value } = e.target;
    if (name === "paid_amount") {
      const newPaidAmount = parseFloat(value);
      const currentGrandTotal = parseFloat(stocks.grand_total);

      if (newPaidAmount > currentGrandTotal) {
        // Display an error message or handle the condition as per your requirement
        // For example, you could prevent setting the state and show an error message
        showErrorToast("Paid amount cannot be greater than the grand total.");
        return; // Don't update state
      }
    }

    setStocks((prevState) => ({ ...prevState, [name]: value }));
  };

  // const handleTableInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const updatedItemDetails = [...itemDetails];
  //   updatedItemDetails[index] = { ...updatedItemDetails[index], [name]: value };
  //   setItemDetails(updatedItemDetails);
  // };

  const handleChangeCategory = (e, index) => {
    const { name, value } = e.target;
    const newItems = [...itemDetails];
    const currentItem = newItems[index];

    // Update the current item's category_id
    currentItem[name] = value;
    currentItem.item_name = e.target.options[e.target.selectedIndex].text;
    setItemDetails(newItems);
  };

  const handleTableInputChange = (e, index) => {
    const { name, value } = e.target;
    const newItems = [...itemDetails];
    const currentItem = newItems[index];

    // Update the current item's property
    currentItem[name] = value;

    // Calculate the total amount
    if (
      name === "item_qty" ||
      name === "item_price" ||
      name === "gst" ||
      name === "discount"
    ) {
      const qty = parseFloat(currentItem.item_qty || 0);
      const price = parseFloat(currentItem.item_price || 0);
      const subTotal = qty * price;
      const gst = parseFloat(currentItem.gst || 0);
      const discount = parseFloat(currentItem.discount || 0);
      const taxAmount = subTotal * (gst / parseFloat("100"));
      const amountAfterTax = subTotal + taxAmount;
      const discountAmount = amountAfterTax * (discount / parseFloat("100"));
      const amountAfterDiscount = amountAfterTax - discountAmount;
      currentItem.total_amount = amountAfterDiscount.toFixed(2);
    }
    setItemDetails(newItems);
  };

  const calculateAllItemsSubTotal = () => {
    let allItemsSubTotal = 0;
    for (const item of itemDetails) {
      allItemsSubTotal += parseFloat(item.total_amount || 0);
    }
    return allItemsSubTotal.toFixed(2);
  };

  const calculateDueAmount = () => {
    const subTotalOnDueAmount = parseFloat(calculateAllItemsSubTotal());
    const paid = parseFloat(stocks.paid_amount);
    let dueAmount;

    if (isNaN(subTotalOnDueAmount) || isNaN(paid)) {
      dueAmount = "";
    } else if (subTotalOnDueAmount === paid) {
      dueAmount = 0;
    } else {
      dueAmount = subTotalOnDueAmount - paid;
      // If due amount is negative, set it to zero
      if (dueAmount < 0) {
        dueAmount = 0;
      }
    }

    return dueAmount === "" ? "" : dueAmount.toFixed(2); // Round to 2 decimal places if not empty
  };

  useEffect(() => {
    const newSubTotal = calculateAllItemsSubTotal();
    const newDeuAmount = calculateDueAmount();

    setStocks((prevStocks) => ({
      ...prevStocks,
      sub_total: newSubTotal,
      grand_total: newSubTotal,
      due_amount: newDeuAmount,
    }));
    // if (stocks.due_amount < 0) {
    //   setStocks({
    //     ...stocks,
    //     due_amount:0
    //   });
    // }
  }, [itemDetails, stocks.paid_amount, stocks.due_amount]);

  const handleRemoveStock = (index) => {
    const updatedItemDetails = [...itemDetails];
    updatedItemDetails.splice(index, 1);
    setItemDetails(updatedItemDetails);
  };

  const fetchInfo = async (id, newUrl) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/purchased/${id}${
          "/" + newUrl && newUrl
        }`,
        {
          method: "POST",
          body: JSON.stringify({ search: search }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.data.data);
      setTotalPages(data?.data?.last_page);
      setAllOrders(data.data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };
  const fetchInfoClearFunction = async (id, newUrl) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/purchased/${id}${
          "/" + newUrl && newUrl
        }`,
        {
          method: "POST",
          body: JSON.stringify({ search: "" }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.data.data);
      setTotalPages(data?.data?.last_page);
      setAllOrders(data.data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchSupplier = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/suppliers/${id}`,
        {
          method:'POST',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setSuppliers(data.supplier);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchItems = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/items/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setItems(data.Item);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const handleSubmitAddStock = async (e) => {
    e.preventDefault();

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/create/purchased/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ ...stocks }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        clearSelection();
        showSuccessToast(data.message);
        setStocks({
          supplier_id: "",
          purches_date: "",
          invoice_no: "",
          details: [],
          sub_total: "",
          due_amount: "",
          paid_amount: "",
        });
        setShowAddStockModelMenuActive(false);
        setLoader(false);
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      fetchSupplier(property_id?.id);
      fetchItems(property_id?.id);
    }
  }, [property_id]);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(currentOrders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const rowMarkup = currentOrders?.map(
    ({
      id,
      invoice_no,
      purchase_date,
      supplier_id,
      supplier_name,
      total_amount,
      grand_total,
      index,
      payment_status,
      purchased_by,
      unique_purchase_id,
    }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          {" "}
          <div
            onClick={(e) => [
              navigate(`/hotelier/purchase/edit/${id}`),
              e.stopPropagation(),
            ]}
            style={{
              margin: "7px 0",
              fontSize: "14px",
              fontWeight: "500",
              color: "rgba(57, 104, 237, 1)",
              display: "inline-flex",
              textDecoration: "underline",
            }}
          >
            {" "}
            #{unique_purchase_id}
          </div>{" "}
        </IndexTable.Cell>
        <IndexTable.Cell>{invoice_no}</IndexTable.Cell>
        <IndexTable.Cell>{purchase_date}</IndexTable.Cell>
        <IndexTable.Cell>{supplier_name}</IndexTable.Cell>
        <IndexTable.Cell>{purchased_by}</IndexTable.Cell>
        <IndexTable.Cell>
          <HandleNaN currency={"₹"} value={Math.trunc(grand_total)} />
        </IndexTable.Cell>
        <IndexTable.Cell>
          {" "}
          <BookingStatusUpdate status={payment_status} />
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit/purchased/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        supplier_id: data.Purchase.supplier_id,
        purches_date: data.Purchase.purches_date,
        invoice_no: data.Purchase.invoice_no,
        details: data.Purchase.purchase_items,
        sub_total: data.Purchase.sub_total,
        grand_total: data.Purchase.grand_total,
        due_amount: data.Purchase.due_amount,
        paid_amount: data.Purchase.paid_amount,
      };
      setStocks(updatedData);
      const itemDetailsArray = data.Purchase.purchase_items.map(
        (item, index) => ({
          item_name: item.item_name,
          item_price: item.item_price,
          item_weight: item.item_weight,
          weight_unit: item.weight_unit,
          price_pre_unit: item.item_rate,
          item_qty: item.item_qty,
          gst: item.gst,
          discount: item.discount,
          total_amount: item.total_amount,
          item_id: item.item_id,
        })
      );
      setItemDetails(itemDetailsArray);
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   getOneData(selectedResources[0]);
  // }, []);

  const deletedIds = {
    ids: selectedResources,
  };

  const removeItemConfirm = async () => {
    // setShowModelMenuActive(!showModelMenuActive);
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/purchased/delete/${property_id?.id}`,
        {
          method: "DELETE",
          body: JSON.stringify(deletedIds),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        showSuccessToast(data.message);
        clearSelection();
        setShowModelMenuActive(false);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const handleUpdateStock = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/update/purchased/${id}/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ ...stocks }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        clearSelection();
        showSuccessToast(data.message);
        setStocks({
          supplier_id: "",
          purches_date: "",
          invoice_no: "",
          details: [],
          sub_total: "",
          due_amount: "",
          paid_amount: "",
        });
        setShowEditModelMenuActive(false);
        setLoader(false);

        // window.location.reload()
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      setLoader(false);
    }
  };

  const [purchaseHeadingActiveIndex, setPurchaseHeadingActiveIndex] =
    useState(1);
  const [purchaseHeadingActive, setPurchaseHeadingActive] = useState("");

  const setPurchaseHeading = (idx) => {
    setPurchaseHeadingActiveIndex(idx);
    if (purchaseHeadingActive === idx) {
      setPurchaseHeadingActive("active");
    } else {
      setPurchaseHeadingActive("");
    }
  };

  const handleAddStockButtonClick = () => {
    setStocks({
      supplier_id: "",
      purches_date: "",
      invoice_no: "",
      details: [],
      sub_total: "",
      due_amount: "",
      paid_amount: "",
    });

    setItemDetails([
      {
        item_name: "",
        item_price: "",
        item_weight: "",
        weight_unit: "",
        price_pre_unit: "",
        item_qty: "",
        gst: "",
        discount: "",
        total_amount: "",
        category_id: "",
      },
    ]);
    clearSelection();
    setShowAddStockModelMenuActive(!showAddStockModelMenuActive);
  };

  useEffect(() => {
    setStocks((prevValue) => ({
      ...prevValue,
      details: itemDetails,
    }));
  }, [itemDetails]);

  const handleChangeItemName = (event, index) => {
    const selectedId = parseInt(event.target.value);
    if (selectedId) {
      const selectedItem = items?.find((item) => item.id === selectedId);

      if (selectedItem) {
        const updatedItemDetails = [...itemDetails];
        updatedItemDetails[index] = {
          ...updatedItemDetails[index],
          item_id: selectedId,
          item_name: selectedItem.item_name, // Set item_name
          weight_unit: selectedItem.unit_name,
        };

        setItemDetails(updatedItemDetails);
      } else {
        console.log("Selected object not found in the array.");
      }
    } else {
      const updatedItemDetails = [...itemDetails];
      updatedItemDetails[index] = {
        ...updatedItemDetails[index],
        item_id: "", // Clear item_id
        item_name: "", // Clear item_name
        weight_unit: "",
      };

      setItemDetails(updatedItemDetails);
    }
  };
  const getSelectedItem = (item, index) => {
    const updatedItemDetails = [...itemDetails];
    updatedItemDetails[index] = {
      ...updatedItemDetails[index],
      item_id: item.id,
      item_name: item.item_name,
      weight_unit: item.unit_name,
    };
    setItemDetails(updatedItemDetails);
  };

  const handleAddStock = (name) => {
    setItemDetails([
      ...itemDetails,
      {
        item_name: "",
        item_price: "",
        item_weight: "",
        weight_unit: "",
        price_pre_unit: "",
        item_qty: "",
        gst: "0",
        discount: "0",
        total_amount: "",
        category_id: "",
      },
    ]);
  };

  useEffect(() => {
    const unpaid = orders?.filter((order) => order.payment_status === "unpaid");
    if (unpaidActive) {
      setOrders(unpaid);
    } else {
      setOrders(allOrders);
    }
  }, [unpaidActive]);

  return (
    <>
      <SidebarInnerListWithMultiplePermissions innerLists={pms_inner_lists} />
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">Purchases</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <div
                  className="pointer"
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <input
                    className="pointer"
                    id="unpaid"
                    type="checkbox"
                    checked={unpaidActive === true}
                    onChange={(e) => setUnpaidActive(e.target.checked)}
                  />
                  <label className="pointer" htmlFor="unpaid">
                    Un-Paid Bills
                  </label>
                </div>

                <div
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  {permission && permission?.includes("full_access_stores_management")? (
                     <Link to="/store/requested-items">
                     <BgOutlineThemeButton children={"Requested Item"} />
                   </Link>
                  ):(
                    <Tooltip
                    content="Please Contact Your Admin"
                    dismissOnMouseOut
                  >
                    <BgOutlineThemeButton disabled children={"Requested Item"} />
                  </Tooltip>
                  )}
                 
                </div>
                {/* <div
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  {permissions &&
                  permissions.includes("issue_item_purchases") ? (
                    <Link to="/hotelier/purchase/issueitems">
                      <BgOutlineThemeButton children={"Issue Item"} />
                    </Link>
                  ) : (
                    <Tooltip
                      content="Please Contact Your Admin"
                      dismissOnMouseOut
                    >
                      <BgThemeButtonDisabled children={"Issue Item"} />
                    </Tooltip>
                  )}
                </div> */}
                <div>
                  <div style={{ textDecoration: "none" }}>
                    {permissions && permissions?.includes("full_access_stores_management") ? (
                      <BgThemeButton
                        // onClick={() => handleAddStockButtonClick()}
                        onClick={() => navigate("/hotelier/purchase/add")}
                        children={"Add Purchases"}
                      />
                    ) : (
                      <Tooltip
                        content="Please Contact Your Admin"
                        dismissOnMouseOut
                      >
                        <BgThemeButtonDisabled children={"Add Stock"} />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="booking-choice-full-parent-container-right-heading-new">
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#EFF0F2",
                }}
              >
                <div
                  className="reservation_bar_container justify_content_between"
                  style={{ width: "100%", position: "static" }}
                >
                  <div>
                    {/* <div className="reservation_total">
                  <CustomDateRangeSelector
                      onSelectedRangeChange={handleSelectedRangeChange}
                      onApplyButtonClick={handleApplyButtonClick}
                      onDateRangeSelected={handleDateRangeSelected}
                    />
                  </div> */}
                    <div className="reservation_total">
                      <h5 className="reservation_total_heading">
                        {orders && orders?.length}
                      </h5>
                      <p className="reservation_total_paragraph">
                        Total Purchases
                      </p>
                    </div>
                  </div>
                  <div className="flex  flex_gap_15">
                    <CustomPaginationArrows
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                      apiUrl={""}
                    />
                    <ItemIssueSearch
                      onKeyDown={handleKeyDown}
                      sendDataToParent={handleChildData}
                      setKotID={setSearch}
                      clearFunction={(data) => fetchInfoClearFunction(data)}
                      placeholder="Search by Purchase ID"
                    />
                  </div>
                </div>
              </div>
            </div>

            {orders && orders.length > 0 ? (
              <>
                <LegacyCard>
                  {orders && orders.length > 0 ? (
                    <IndexTable
                      resourceName={resourceName}
                      itemCount={currentOrders.length}
                      selectedItemsCount={
                        allResourcesSelected ? "All" : selectedResources.length
                      }
                      onSelectionChange={handleSelectionChange}
                      headings={[
                        { title: "Purchase Id" },
                        { title: "Invoice No" },
                        { title: "Date" },
                        { title: "Supplier Name" },
                        { title: "Purchased By" },
                        { title: "Total Amount" },
                        { title: "Status" },
                      ]}
                    >
                      {rowMarkup}
                    </IndexTable>
                  ) : (
                    <NoDataFound />
                  )}

                  {/* Single select */}
                  {showLowerMenuSingleSelect && (
                    <div className=" room-type-bottom-container">
                      <div className="room-type-bottom-container-div">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            gap: "20px",
                          }}
                        >
                          {permissions &&
                          permissions?.includes("full_access_stores_management") ? (
                            <Button
                              plain
                              destructive
                              // onClick={showModelMenu}
                              onClick={() => showModelMenu()}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span>
                                  <Icon source={DeleteMajor} color="base" />
                                </span>
                                <span>Remove</span>
                              </div>
                            </Button>
                          ) : (
                            <Tooltip
                              content="Please Contact Your Admin"
                              dismissOnMouseOut
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  opacity: "0.4",
                                  cursor: "no-drop",
                                }}
                              >
                                <span>
                                  <Icon source={DeleteMajor} color="base" />
                                </span>
                                <span>Remove</span>
                              </div>
                            </Tooltip>
                          )}

                          {permissions &&
                          permissions?.includes("full_access_stores_management") ? (
                            <BgThemeButton
                              children={"Edit"}
                              loader={loader}
                              // onClick={() =>
                              //   setShowEditModelMenuActive(
                              //     !showEditModelMenuActive
                              //   )
                              // }
                              onClick={() =>
                                navigate(
                                  `/hotelier/purchase/edit/${selectedResources[0]}`
                                )
                              }
                            />
                          ) : (
                            <Tooltip
                              content="Please Contact Your Admin"
                              dismissOnMouseOut
                            >
                              <BgThemeButtonDisabled children={"Edit"} />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Multiple select */}
                  {showLowerMenuMultipleSelect && (
                    <div className=" room-type-bottom-container">
                      <div className="room-type-bottom-container-div">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          {permissions &&
                          permissions?.includes("full_access_stores_management") ? (
                            <Button
                              plain
                              destructive
                              // onClick={showModelMenu}
                              onClick={() => showModelMenu()}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span>
                                  <Icon source={DeleteMajor} color="base" />
                                </span>
                                <span>Remove</span>
                              </div>
                            </Button>
                          ) : (
                            <Tooltip
                              content="Please Contact Your Admin"
                              dismissOnMouseOut
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  opacity: "0.4",
                                  cursor: "no-drop",
                                }}
                              >
                                <span>
                                  <Icon source={DeleteMajor} color="base" />
                                </span>
                                <span>Remove</span>
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </LegacyCard>
                <CustomPagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={orders.length}
                  onPageChange={handlePageChange}
                  clearSelection={clearSelection}
                />
              </>
            ) : (
              <NoDataFound />
            )}
          </div>

          {/* Add stock Button Click */}
          {showAddStockModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section
                  className="stock-add-model-container"
                  style={{ width: "900px" }}
                >
                  <form
                    onSubmit={(e) => handleSubmitAddStock(e)}
                    // onSubmit={(e) => e.preventDefault()}
                    style={{
                      width: "100%",
                    }}
                  >
                    <div>
                      {/* <Page
                        backAction={{
                          content: "Products",
                          url: `/hotelier/purchase`,
                        }}
                        title="Add Stock"
                        compactTitle
                      ></Page> */}
                      <GoBackButtonCustom
                        buttonNextText={`Add Stock`}
                        onClick={() => setShowAddStockModelMenuActive(false)}
                      />
                    </div>

                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <div className="form-container-div">
                          <div style={{ marginBottom: "4px" }}>
                            Supplier
                            <span style={{ color: "red" }}>*</span>
                          </div>
                          <select
                            style={{
                              width: "100%",
                              minHeight: "2.25rem",
                              border: "1px solid rgba(171, 177, 186, 1)",
                              borderRadius: "3px",
                            }}
                            required={true}
                            name="supplier_id"
                            onChange={handleAddStockChange}
                            value={stocks.supplier_id}
                          >
                            <option value="">Select Option</option>
                            {suppliers?.map((x) => {
                              return (
                                // <>
                                <option key={x.id} value={x.id}>
                                  {x.supplier_name}
                                </option>
                                // </>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          titleName={"Date"}
                          required={"true"}
                          type="date"
                          value={stocks.purches_date}
                          name="purches_date"
                          className="addStaffInputs"
                          onChange={handleAddStockChange}
                        />
                      </div>
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          titleName={"Invoice Number"}
                          type="number"
                          required={"true"}
                          value={stocks.invoice_no}
                          name="invoice_no"
                          className="addStaffInputs"
                          onChange={handleAddStockChange}
                        />
                      </div>
                    </div>
                    <div className="addStockTable">
                      <div className="addStockTableHeader">
                        <div style={{ width: "20%" }}>Item Name</div>
                        <div style={{ width: "8%" }}>Price</div>
                        <div style={{ width: "15%" }}>Weight</div>
                        <div style={{ width: "8%" }}>Price/Unit</div>
                        <div style={{ width: "8%" }}>Qty</div>
                        <div style={{ width: "8%" }}>GST %</div>
                        <div style={{ width: "10%" }}>Discount %</div>
                        <div style={{ width: "12%" }}>Total Amount</div>
                        <div style={{ width: "11%" }}> </div>
                      </div>

                      {itemDetails.map((item, index) => (
                        <div className="addStockTableContent" key={index}>
                          <div style={{ width: "20%" }}>
                            <div className="form-container-div">
                              {/* <select
                                style={{
                                  width: "100%",
                                  minHeight: "2.25rem",
                                  border: "1px solid rgba(171, 177, 186, 1)",
                                  borderRadius: "3px",
                                }}
                                required={true}
                                name="item_id"
                                onChange={(e) => handleChangeItemName(e, index)}
                                value={itemDetails[index]?.item_id}
                              >
                                <option value="">Select Option</option>
                                {items?.map((x) => {
                                  return (
                                    <option
                                      key={x.id}
                                      value={x.id}
                                      data-name={x.item_name}
                                    >
                                      {x.item_name}
                                    </option>
                                  );
                                })}
                              </select> */}
                              <input
                                type="hidden"
                                name={`item_id`}
                                value={itemDetails[index]?.item_id} // Use item_id
                              />
                              <input
                                type="hidden"
                                name={`item_name`}
                                value={itemDetails[index]?.item_name} // Use item_name
                              />
                            </div>
                            <SearchComponent
                              searchArray={items}
                              keyName={"item_name"}
                              getSelectedItem={getSelectedItem}
                              index={index}
                              selectedValue={itemDetails[index]?.item_name}
                              disabledOptions={itemDetails.map(
                                (x) => x.item_name
                              )}
                            />
                          </div>

                          <div style={{ width: "8%" }}>
                            <VerticalInputFieldCustom
                              type={"number"}
                              required={"true"}
                              name="item_price"
                              value={itemDetails[index]?.item_price}
                              onChange={(e) => handleTableInputChange(e, index)}
                            />
                          </div>

                          <div style={{ width: "15%" }}>
                            <div className="addStockTableWeightContainer">
                              <input
                                type="number"
                                value={itemDetails[index].item_weight}
                                className="addStockTableWeight"
                                name="item_weight"
                                required
                                onChange={(e) =>
                                  handleTableInputChange(e, index)
                                }
                              />
                              <select
                                className="addStockTableWeightSelect"
                                name="weight_unit"
                                onChange={(e) =>
                                  handleTableInputChange(e, index)
                                }
                                value={itemDetails[index].weight_unit}
                              >
                                <option selected value="">
                                  Select
                                </option>
                                <option value="Kg">Kg</option>
                                <option value="g">g</option>
                              </select>
                            </div>
                          </div>

                          <div style={{ width: "8%" }}>
                            <VerticalInputFieldCustom
                              // type={"number"}
                              name="price_pre_unit"
                              required={"true"}
                              value={itemDetails[index]?.price_pre_unit}
                              onChange={(e) => handleTableInputChange(e, index)}
                            />
                          </div>

                          <div style={{ width: "8%" }}>
                            <VerticalInputFieldCustom
                              // type="number"
                              value={itemDetails[index].item_qty}
                              required={"true"}
                              min={0}
                              className="addStockTablePrice"
                              name="item_qty"
                              onChange={(e) => handleTableInputChange(e, index)}
                            />
                          </div>

                          <div style={{ width: "8%" }}>
                            <VerticalInputFieldCustom
                              type="text"
                              value={itemDetails[index].gst}
                              className="addStockTablePrice"
                              name="gst"
                              required={"true"}
                              onChange={(e) => handleTableInputChange(e, index)}
                            />
                          </div>

                          <div style={{ width: "10%" }}>
                            <VerticalInputFieldCustom
                              type="number"
                              value={itemDetails[index].discount}
                              className="addStockTablePrice"
                              name="discount"
                              required={"true"}
                              onChange={(e) => handleTableInputChange(e, index)}
                            />
                          </div>

                          <div style={{ width: "12%" }}>
                            <VerticalInputFieldCustom
                              type="number"
                              value={itemDetails[index].total_amount}
                              className="addStockTableTotalPrice"
                              name="total_amount"
                              disabled={"disabled"}
                              onChange={(e) => handleTableInputChange(e, index)}
                            />
                          </div>

                          <div style={{ width: "11%" }}>
                            {itemDetails.length > 1 &&
                              index !== itemDetails.length - 1 && (
                                <>
                                  <button
                                    onClick={() => handleRemoveStock(index)}
                                    className="addStockButton"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      viewBox="0 0 15 15"
                                      fill="none"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M10.5 3H12.75C13.1642 3 13.5 3.33579 13.5 3.75L13.5 4.5H1.5L1.50002 3.75C1.50002 3.33579 1.83581 3 2.25002 3H4.50002V1.125C4.50002 0.50368 5.0037 0 5.62502 0H9.37502C9.99634 0 10.5 0.50368 10.5 1.125V3ZM6.00002 1.5V3H9.00002V1.5H6.00002Z"
                                        fill="#888888"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M2.25002 6H12.75V13.875C12.75 14.4963 12.2463 15 11.625 15H3.37502C2.7537 15 2.25002 14.4963 2.25002 13.875V6ZM5.25 8.25H3.75V12.75H5.25V8.25ZM8.25 8.25H6.75V12.75H8.25V8.25ZM9.75 8.25H11.25V12.75H9.75V8.25Z"
                                        fill="#888888"
                                      />
                                    </svg>
                                  </button>
                                </>
                              )}

                            {index === itemDetails.length - 1 && (
                              <>
                                <button
                                  onClick={handleAddStock}
                                  className="addStockButton"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                  >
                                    <g clipPath="url(#clip0_622_2184)">
                                      <path
                                        d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                        stroke="#3968ED"
                                      />
                                      <path
                                        d="M10.5868 7H3.41357"
                                        stroke="#3968ED"
                                        strokeMiterlimit="10"
                                      />
                                      <path
                                        d="M7 3.41344L7 10.5867"
                                        stroke="#3968ED"
                                        strokeMiterlimit="10"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_622_2184">
                                        <rect
                                          width="14"
                                          height="14"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  Add More
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "start",
                          gap: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "30px",
                          }}
                        >
                          <div className="flex align_items_center flex_gap_10">
                            Sub Total:
                            <VerticalInputFieldCustom
                              value={calculateAllItemsSubTotal()}
                              name={"sub_total"}
                              disabled={"disabled"}
                            />
                          </div>
                          <div className="flex align_items_center flex_gap_10">
                            Grand Total:
                            <VerticalInputFieldCustom
                              value={calculateAllItemsSubTotal()}
                              name={"grand_total"}
                              disabled={"disabled"}
                            />
                          </div>
                          <div className="flex align_items_center flex_gap_10">
                            Paid Amount:
                            <VerticalInputFieldCustom
                              name={"paid_amount"}
                              required={"true"}
                              value={stocks.paid_amount}
                              onChange={handleAddStockChange}
                            />
                          </div>
                          <div className="flex align_items_center flex_gap_10">
                            Due Amount:
                            <VerticalInputFieldCustom
                              value={calculateDueAmount()}
                              name={"due_amount"}
                              disabled={"disabled"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                        marginTop: "20px",
                      }}
                    >
                      <BgThemeButton children={"Add"} type={"submit"} />
                      <Button
                        onClick={() => setShowAddStockModelMenuActive(false)}
                      >
                        Discard
                      </Button>
                    </div>
                  </form>
                </section>
              </div>
            </>
          )}

          {/* Add Item Button Click */}
          {showAddItemModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="stock-add-model-container">
                  <div style={{ marginLeft: "-200px" }}>
                    <Page
                      backAction={{
                        content: "Products",
                        url: `/hotelier/purchase`,
                      }}
                      title="Add Issue Items"
                      compactTitle
                    ></Page>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <TextField
                        type="text"
                        className="textfield"
                        focused={false}
                        label="User Type"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <TextField
                        type="text"
                        className="textfield"
                        focused={false}
                        label="Issued To"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <TextField
                        type="date"
                        className="textfield"
                        focused={false}
                        label="Issued Date"
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <Select
                        className="textfield"
                        focused={false}
                        label="Item Name"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <Select
                        className="textfield"
                        focused={false}
                        label="Select Category"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <TextField
                        className="textfield"
                        focused={false}
                        label="Qty"
                        autoComplete="off"
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                        width: "350px",
                      }}
                      className="form-container-div"
                    >
                      <WithoutBgButtonBlue children={"Add More"} />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <Select
                        className="textfield"
                        focused={false}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <Select
                        className="textfield"
                        focused={false}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <TextField
                        className="textfield"
                        focused={false}
                        autoComplete="off"
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "350px",
                      }}
                      className="form-container-div"
                    >
                      <WithoutBgButtonBlue children={"Add More"} />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton children={"Update"} />
                    <Button
                      onClick={() => setShowAddItemModelMenuActive(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}

          {/* Edit Button Click */}
          {showEditModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="stock-add-model-container">
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    style={{
                      width: "100%",
                    }}
                  >
                    <div>
                      <GoBackButtonCustom
                        buttonNextText={"Edit Purchases"}
                        onClick={() => setShowEditModelMenuActive(false)}
                      />
                    </div>

                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <div className="form-container-div">
                          <div style={{ marginBottom: "4px" }}>
                            Supplier Name
                            <span style={{ color: "red" }}>*</span>
                          </div>
                          <select
                            style={{
                              width: "100%",
                              minHeight: "2.25rem",
                              border: "1px solid rgba(171, 177, 186, 1)",
                              borderRadius: "3px",
                            }}
                            required={true}
                            name="supplier_id"
                            onChange={handleAddStockChange}
                            value={stocks.supplier_id}
                          >
                            <option value="">Select Option</option>
                            {suppliers?.map((x) => {
                              return (
                                // <>
                                <option key={x.id} value={x.id}>
                                  {x.supplier_name}
                                </option>
                                // </>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          titleName={"Date"}
                          type="date"
                          value={stocks.purches_date}
                          name="purches_date"
                          className="addStaffInputs"
                          onChange={handleAddStockChange}
                        />
                      </div>
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          titleName={"Invoice Number"}
                          type="number"
                          value={stocks.invoice_no}
                          name="invoice_no"
                          className="addStaffInputs"
                          onChange={handleAddStockChange}
                        />
                      </div>
                    </div>
                    <div className="addStockTable">
                      <div className="addStockTableHeader">
                        <div style={{ width: "20%" }}>Item Name</div>
                        <div style={{ width: "8%" }}>Price</div>
                        <div style={{ width: "15%" }}>Weight</div>
                        <div style={{ width: "8%" }}>Price/Unit</div>
                        <div style={{ width: "8%" }}>Qty</div>
                        <div style={{ width: "8%" }}>GST %</div>
                        <div style={{ width: "10%" }}>Discount %</div>
                        <div style={{ width: "12%" }}>Total Amount</div>
                        <div style={{ width: "11%" }}> </div>
                      </div>

                      {itemDetails.map((item, index) => (
                        <div className="addStockTableContent" key={index}>
                          <div style={{ width: "20%" }}>
                            <div className="form-container-div">
                              <select
                                style={{
                                  width: "100%",
                                  minHeight: "2.25rem",
                                  border: "1px solid rgba(171, 177, 186, 1)",
                                  borderRadius: "3px",
                                }}
                                required={true}
                                name="item_id"
                                onChange={(e) => handleChangeItemName(e, index)}
                                value={itemDetails[index]?.item_id}
                              >
                                <option value="">Select Option</option>
                                {items?.map((x) => {
                                  return (
                                    <option
                                      key={x.id}
                                      value={x.id}
                                      data-name={x.item_name}
                                    >
                                      {x.item_name}
                                    </option>
                                  );
                                })}
                              </select>
                              <input
                                type="hidden"
                                name={`item_id`}
                                value={itemDetails[index]?.item_id} // Use item_id
                              />
                              <input
                                type="hidden"
                                name={`item_name`}
                                value={itemDetails[index]?.item_name} // Use item_name
                              />
                            </div>
                          </div>

                          <div style={{ width: "8%" }}>
                            <VerticalInputFieldCustom
                              type={"number"}
                              name="item_price"
                              value={itemDetails[index]?.item_price}
                              onChange={(e) => handleTableInputChange(e, index)}
                            />
                          </div>

                          <div style={{ width: "15%" }}>
                            <div className="addStockTableWeightContainer">
                              <input
                                type="number"
                                value={itemDetails[index].item_weight}
                                className="addStockTableWeight"
                                name="item_weight"
                                onChange={(e) =>
                                  handleTableInputChange(e, index)
                                }
                              />
                              <select
                                className="addStockTableWeightSelect"
                                name="weight_unit"
                                onChange={(e) =>
                                  handleTableInputChange(e, index)
                                }
                                value={itemDetails[index].weight_unit}
                              >
                                <option selected disabled>
                                  Select
                                </option>
                                <option value="kg">Kg</option>
                                <option value="g">g</option>
                              </select>
                            </div>
                          </div>

                          <div style={{ width: "8%" }}>
                            <VerticalInputFieldCustom
                              type={"number"}
                              name="price_pre_unit"
                              value={itemDetails[index]?.price_pre_unit}
                              onChange={(e) => handleTableInputChange(e, index)}
                            />
                          </div>

                          <div style={{ width: "8%" }}>
                            <VerticalInputFieldCustom
                              type="number"
                              value={itemDetails[index].item_qty}
                              className="addStockTablePrice"
                              name="item_qty"
                              onChange={(e) => handleTableInputChange(e, index)}
                            />
                          </div>

                          <div style={{ width: "8%" }}>
                            <VerticalInputFieldCustom
                              type="text"
                              value={itemDetails[index].gst}
                              className="addStockTablePrice"
                              name="gst"
                              onChange={(e) => handleTableInputChange(e, index)}
                            />
                          </div>

                          <div style={{ width: "10%" }}>
                            <VerticalInputFieldCustom
                              type="number"
                              value={itemDetails[index].discount}
                              className="addStockTablePrice"
                              name="discount"
                              onChange={(e) => handleTableInputChange(e, index)}
                            />
                          </div>

                          <div style={{ width: "12%" }}>
                            <VerticalInputFieldCustom
                              type="number"
                              value={itemDetails[index].total_amount}
                              className="addStockTableTotalPrice"
                              name="total_amount"
                              disabled={"disabled"}
                              onChange={(e) => handleTableInputChange(e, index)}
                            />
                          </div>

                          <div style={{ width: "11%" }}>
                            <button
                              onClick={handleAddStock}
                              className="addStockButton"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                              >
                                <g clipPath="url(#clip0_622_2184)">
                                  <path
                                    d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                    stroke="#3968ED"
                                  />
                                  <path
                                    d="M10.5868 7H3.41357"
                                    stroke="#3968ED"
                                    strokeMiterlimit="10"
                                  />
                                  <path
                                    d="M7 3.41344L7 10.5867"
                                    stroke="#3968ED"
                                    strokeMiterlimit="10"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_622_2184">
                                    <rect width="14" height="14" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              Add More
                            </button>

                            {index > 0 && (
                              <button
                                onClick={() => handleRemoveStock(index)}
                                className="addStockButton"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 15 15"
                                  fill="none"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M10.5 3H12.75C13.1642 3 13.5 3.33579 13.5 3.75L13.5 4.5H1.5L1.50002 3.75C1.50002 3.33579 1.83581 3 2.25002 3H4.50002V1.125C4.50002 0.50368 5.0037 0 5.62502 0H9.37502C9.99634 0 10.5 0.50368 10.5 1.125V3ZM6.00002 1.5V3H9.00002V1.5H6.00002Z"
                                    fill="#888888"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M2.25002 6H12.75V13.875C12.75 14.4963 12.2463 15 11.625 15H3.37502C2.7537 15 2.25002 14.4963 2.25002 13.875V6ZM5.25 8.25H3.75V12.75H5.25V8.25ZM8.25 8.25H6.75V12.75H8.25V8.25ZM9.75 8.25H11.25V12.75H9.75V8.25Z"
                                    fill="#888888"
                                  />
                                </svg>
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "start",
                          gap: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "30px",
                          }}
                        >
                          <div className="flex align_items_center flex_gap_10">
                            Sub Total:
                            <VerticalInputFieldCustom
                              value={stocks.sub_total}
                              name={"sub_total"}
                              disabled={"disabled"}
                            />
                          </div>
                          <div className="flex align_items_center flex_gap_10">
                            Grand Total:
                            <VerticalInputFieldCustom
                              value={stocks.grand_total}
                              name={"grand_total"}
                              disabled={"disabled"}
                            />
                          </div>
                          <div className="flex align_items_center flex_gap_10">
                            Paid Amount:
                            <VerticalInputFieldCustom
                              name={"paid_amount"}
                              value={stocks.paid_amount}
                              onChange={handleAddStockChange}
                            />
                          </div>
                          <div className="flex align_items_center flex_gap_10">
                            Due Amount:
                            <VerticalInputFieldCustom
                              value={stocks.due_amount}
                              name={"due_amount"}
                              disabled={"disabled"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                        marginTop: "20px",
                      }}
                    >
                      <BgThemeButton
                        children={"Update"}
                        type={"submit"}
                        onClick={() => handleUpdateStock(selectedResources[0])}
                      />
                      <Button onClick={() => setShowEditModelMenuActive(false)}>
                        Discard
                      </Button>
                    </div>
                  </form>
                </section>
              </div>
            </>
          )}

          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    <Button onClick={removeItemConfirm} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default Purchase;
