import React, { useRef, useEffect } from "react";
import styles from "./sliderComponentSkeleton.module.scss";

const SliderComponentSkeleton = ({
  children,
  show,
  setShow,
  title,
  smallTitle,
}) => {

  const sliderRef = useRef(null);
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show]);


  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.classList.add(styles.open);
    }
  }, []);

  const handleToggleSlider = () => {
    if (sliderRef.current) {
      // Apply the closing animation class
      sliderRef.current.classList.add(styles.close);

      // Delay the state update to allow the animation to play
      setTimeout(() => {
        setShow(!show);
        // Remove the closing animation class after state change
        if (sliderRef.current) {
          sliderRef.current.classList.remove(styles.close);
        }
      }, 600); // Match the duration of the animation
    }
  };



  return (
    <div
      className={styles.sliderComponentSkeletonContainer}
      onClick={handleToggleSlider}
    >
      <div
        ref={sliderRef}
        className={`${styles.sliderComponentSkeletonChild }  ${
          show ? "" : styles.close
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div>
          <div className={styles.sliderHeader}>
            <button onClick={handleToggleSlider}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M1 13.1501L13 0.850098"
                  stroke="#AAAAAA"
                  strokeWidth="1.2"
                />
                <path
                  d="M13 13.1501L0.999999 0.850147"
                  stroke="#AAAAAA"
                  strokeWidth="1.2"
                />
              </svg>
            </button>

            {smallTitle && (
              <p dangerouslySetInnerHTML={{ __html: smallTitle }}></p>
            )}
            <div
              className={styles.sliderTitle}
              dangerouslySetInnerHTML={{ __html: title }}
            >
              {/* {title} */}
            </div>
          </div>
          <div style={{ background: "#ccc", height: "1px" }}></div>
        </div>

        <div className={styles.sliderContent}>{children}</div>
      </div>
    </div>
  );
};

export default SliderComponentSkeleton;
