import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DistanceSelectorComponent from "../CustomComponents/DistanceSelectorComponent/DistanceSelectorComponent";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
} from "../UI/Buttons";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import NoDataFound from "../CustomComponents/NoDataFound";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import LoaderSmall from "../UI/Loader/LoaderSmall";
import { getBaseUrl } from "../../utils/utils";
import { getDecryptedData } from "../../utils/encryptStorage";
import { Tooltip } from "@shopify/polaris";
const DistanceSettings = () => {
  const permission = localStorage.getItem("permissions");
  const baseURl = getBaseUrl();
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);

  const styles = {
    parent: {
      position: "relative",
      width: "60%",
      margin: "20px auto",
    },
    child: {
      position: "absolute",
      right: "20px",
      top: "20px",
      display: "flex",
      gap: "10px",
    },
    button: {
      border: "none",
      backgroundColor: "transparent",
      cursor: "pointer",
      color: "#2B65EC",
      fontSize: "14px",
      fontWeight: "600",
    },
    addDistancePopupContainer: {
      position: "fixed",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      zIndex: "999",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    addDistancePopupCard: {
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "10px",
      width: "500px",
    },
  };

  const [distanceFrom, setDistanceFrom] = useState([]);

  useEffect(() => {
    if (property_id) {
      getDistanceData(property_id?.id);
    }
  }, [property_id]);

  const getDistanceData = async () => {
    setLoader(true);
    try {
      const response = await fetch(
        `${baseURl}/api/v1/property_distance/list/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setDistanceFrom(data?.data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const [showAddDistancePopup, setAddDistancePopup] = useState(false);

  const [additionalDistances, setAdditionalDistances] = useState({
    place: "",
    distance: "",
    unit: "  ",
  });

  const handleAddButtonClickInPopup = () => {
    if (additionalDistances.place && additionalDistances.distance) {
      const formattedPlace = additionalDistances.place
        .trim()
        .replace(/\s\s+/g, " ");
      if (
        distanceFrom
          .map((item) =>
            item.place.toLocaleLowerCase().trim().replace(/\s\s+/g, "")
          )
          .includes(formattedPlace.toLocaleLowerCase())
      ) {
        showErrorToast("Already exists");
      } else {
        setDistanceFrom([...distanceFrom, additionalDistances]);
        setAddDistancePopup(false);
        setAdditionalDistances({ place: "", distance: "" });
        showSuccessToast("Added successfully");
      }
    } else {
      showErrorToast("Please add the above fields first...!!");
    }
  };

  const unitOptions = [
    {
      label: "select",
      value: "",
    },
    {
      label: "Km",
      value: "Km",
    },
    {
      label: "m",
      value: "m",
    },
  ];

  return (
    <>
      <div>
        <div className="profile_tab_form " style={styles.parent}>
          <h3>Property Distance From</h3>
          <div style={styles.child}>
            {permission && permission.includes("full_access_property_settings") ? (
              <button
                onClick={() => setAddDistancePopup(true)}
                style={styles.button}
              >
                Add
              </button>
            ) : (
              <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                <BgThemeButtonDisabled children={"Add"} />
              </Tooltip>
            )}
          </div>

          <div className="form-container-div">
            {loader ? (
              <LoaderSmall />
            ) : (
              <>
                {distanceFrom && distanceFrom?.length === 0 ? (
                  <NoDataFoundSmall />
                ) : (
                  <>
                    {distanceFrom.map((item, index) => (
                      <div className="flex justify_content_between" key={index}>
                        <p>{item.location}</p>
                        <p>{item.distance}</p>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </div>

        {showAddDistancePopup && (
          <>
            <div style={styles.addDistancePopupContainer}>
              <div style={styles.addDistancePopupCard}>
                <div className="flex justify_content_between flex_gap_10 ">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      titleName={"Place"}
                      placeholder={"Enter place"}
                      type={"text"}
                      onChange={(e) =>
                        setAdditionalDistances({
                          ...additionalDistances,
                          place: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      titleName={"Distance"}
                      placeholder={"Enter Distance "}
                      type={"text"}
                      onChange={(e) =>
                        setAdditionalDistances({
                          ...additionalDistances,
                          distance: e.target.value,
                        })
                      }
                    />
                  </div>
                  <VerticalInputSelectCustomCopy
                    titleName={"Unit"}
                    style={{ width: "50px" }}
                    type={"text"}
                    options={unitOptions}
                    onChange={(e) =>
                      setAdditionalDistances({
                        ...additionalDistances,
                        unit: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="flex flex_gap_10" style={{ marginTop: "10px" }}>
                  {permission && permission.includes("full_access_property_settings") ? (
                    <BgThemeButton
                      children={"Add"}
                      loader={loader}
                      onClick={handleAddButtonClickInPopup}
                    />
                  ) : (
                    <Tooltip
                      content="Please Contact Your Admin"
                      dismissOnMouseOut
                    >
                      <BgThemeButtonDisabled children={"Add"} />
                    </Tooltip>
                  )}

                  {permission && permission.includes("full_access_property_settings") ? (
                    <BgOutlineThemeButton
                      children={"Cancel"}
                      onClick={() => {
                        setAddDistancePopup(false);
                        setAdditionalDistances({
                          place: "",
                          distance: "",
                          unit: "",
                        });
                      }}
                    />
                  ) : (
                    <Tooltip
                      content="Please Contact Your Admin"
                      dismissOnMouseOut
                    >
                      <BgOutlineThemeButton disabled children={"Cancel"} />
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DistanceSettings;
