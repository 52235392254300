import React, { useEffect, useState } from "react";
import SidebarInnerLists from "../../Components/CustomComponents/SidebarInnerLists";
import { websites_inner_lists } from "../../assets/constant";
import { useSelector } from "react-redux";
import {
  getBaseUrl,
  getCurrentPropertyDateHandler,
  getDateByMonth,
  getTimeFromTimeTwelveHoursFormat,
} from "../../utils/utils";
import {
  BgThemeButton,
  BgThemeButtonDisabled,
} from "../../Components/UI/Buttons";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { SvgDelete, SvgEdit } from "../../assets/svgIcons";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../../Components/CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import Loader from "../../Components/UI/Loader/Loader";
import { useNavigate } from "react-router-dom";
import ConfirmPopup from "../../Components/NewCustomComp/confirmPopup/ConfirmPopup";
import UploadFileComponent from "../../Components/NewCustomComp/UploadFileComponent/UploadFileComponent";
import styles from "./websiteEditOffers.module.scss";
import stylesTwo from "./websites.module.scss";
import NoDataFound from "../../Components/CustomComponents/NoDataFound";
import { getDecryptedData } from "../../utils/encryptStorage";
import { Tooltip } from "@shopify/polaris";

const WebsiteOffers = () => {
  const permissions = localStorage.getItem("permissions");
  const navigate = useNavigate();
  const baseUrl = getBaseUrl();
  const property_id = useSelector((state) => state.property.value)?.id;
  const userToken = getDecryptedData("encryptToken");

  const [offers, setOffers] = useState("");
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    if (property_id) {
      fetchOffers();
    }
  }, [property_id]);

  const fetchOffers = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/packages/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json().then((data) => {
        if (data.success) {
          setOffers(data.data);
          setLoader(false);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // ==== delete offers ======

  const [deleteOffersId, setDeleteOffersId] = useState("");
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);

  const handleDeleteOffers = (id) => {
    setDeleteOffersId(id);
    setShowDeleteConfirmPopup(true);
  };

  const handleConfirmDeleteClick = async () => {
    const myHeaders = new Headers();
    setLoader(true);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      ids: [deleteOffersId], // Your list of IDs to delete
    });

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/packages/${property_id}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const result = await response.text(); // Assuming the API returns text
      console.log("Delete successful:", result);
      showSuccessToast("Offers deleted successfully");
      setDeleteOffersId("");
      setShowDeleteConfirmPopup(false);
      fetchOffers();
      return result;
    } catch (error) {
      console.error("There was an error with the DELETE request:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div>
      <div>
        <div className="room-types-full-container-div mt-20 flex justify-content-between">
          <div className="room-type-heading">Offers</div>

          {permissions &&
          permissions?.includes("full_access_website_management") ? (
            <BgThemeButton onClick={() => navigate("/websites/offers/create")}>
              Create Offers
            </BgThemeButton>
          ) : (
            <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
              <BgThemeButtonDisabled children={"Add Menus"} />
            </Tooltip>
          )}
        </div>

        <div className="room-types-full-container-div mt-20">
          {/* <CustomIndex   */}
          {loader ? (
            <div className="flex justify_content_center align_items_center w-100 h-100">
              <Loader />
            </div>
          ) : (
            <>
              {offers.data.length > 0 ? (
                <>
                  <CustomShopifyIndexTable
                    showCheckbox={false}
                    headings={[
                      "Package Name",
                      "Start Date",
                      "End Date",
                      "Hotels",
                      "",
                    ]}
                  >
                    {offers &&
                      offers.data &&
                      offers.data.map((item, index) => (
                        <CustomShopifyIndexTableRow key={index}>
                          <CustomShopifyIndexTableCell>
                            {item?.package_title}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            {getDateByMonth(item?.start_date)},{" "}
                            {getTimeFromTimeTwelveHoursFormat(item?.start_time)}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            {getDateByMonth(item?.end_date)},{" "}
                            {getTimeFromTimeTwelveHoursFormat(item?.end_time)}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            <div className={stylesTwo["rooms-selected"]}>
                              {item.package_detailed_data.map((i, index) => (
                                <div key={index}>
                                  <span> {i.hotelName}</span>
                                </div>
                              ))}
                            </div>
                          </CustomShopifyIndexTableCell>

                          <CustomShopifyIndexTableCell>
                            <span className="flex flex_gap_10">
                              {permissions &&
                              permissions.includes(
                                "full_access_website_management"
                              ) ? (
                                <span
                                  onClick={() =>
                                    navigate(
                                      `/websites/offers/edit/${item?.id}`
                                    )
                                  }
                                >
                                  <SvgEdit />
                                </span>
                              ) : (
                                <Tooltip
                                  content="Please Contact Your Admin"
                                  dismissOnMouseOut
                                >
                                  <span style={{ opacity: "0.7" }}>
                                    <SvgEdit />
                                  </span>
                                </Tooltip>
                              )}

                              {permissions &&
                              permissions.includes(
                                "full_access_website_management"
                              ) ? (
                                <span
                                  onClick={() => handleDeleteOffers(item?.id)}
                                >
                                  <SvgDelete />
                                </span>
                              ) : (
                                <Tooltip
                                  content="Please Contact Your Admin"
                                  dismissOnMouseOut
                                >
                                  <span style={{ opacity: "0.7" }}>
                                    <SvgDelete />
                                  </span>
                                </Tooltip>
                              )}
                            </span>
                          </CustomShopifyIndexTableCell>
                        </CustomShopifyIndexTableRow>
                      ))}
                  </CustomShopifyIndexTable>
                </>
              ) : (
                <div className="flex justify_content_center align_items_center w-100 h-100">
                  <NoDataFound />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {showDeleteConfirmPopup && (
        <ConfirmPopup
          heading="Delete Offer"
          subHeading={"Are you sure you want to delete this offer?"}
          showPopup={showDeleteConfirmPopup}
          setShowPopup={setShowDeleteConfirmPopup}
          reasonField={false}
          handleConfirmClick={handleConfirmDeleteClick}
        />
      )}
    </div>
  );
};

export default WebsiteOffers;
