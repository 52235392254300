import {
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  Button,
  Icon,
  Tooltip,
  ChoiceList,
} from "@shopify/polaris";

import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  communication_inner_list,
  other_select_nav_items_inventory,
  rooms_inner_lists,
} from "../../assets/constant";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  BgThemeButtonFullWidth,
  CheckWithoutBgUnderlineBtn,
  WithoutBgButtonBlue,
} from "../UI/Buttons";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import Loader from "../UI/Loader/Loader";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import NoDataFound from "../CustomComponents/NoDataFound";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import VerticalTextAreaCustom from "../CustomComponents/VerticalTextAreaCustom";
import CustomToggleButton from "../UI/CustomToggleButton";
import DateRangePicker from "../CustomComponents/SelectDateRange/DateRangePicker";
import PreviewCommunicationVoucher from "./PreviewCommunicationVoucher";
import PreviewCommunicationBillCopy from "./PreviewCommunicationBillCopy";
import PaymentReceiptCommunication from "./PaymentReceiptCommunication";
import EventsCommunicationVoucher from "./EventCommunicationVoucher/EventCommunicationVoucher";
import EventCommunicationBill from "./EventCommunicationVoucher/EventCommunicationBill";
import PaymentReceiptEventCommunication from "./PaymentReceiptEventCommunication";
import RoomAvailabilityAndRatesCommunication from "./RoomAvailabilityAndRatesCommunication";
import TextDateFormatter from "./TextDateFormatter/TextDateFormatter";
import CustomPagination from "../CustomComponents/CustomPagination/CustomPagination";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import BookingStatusUpdate from "../CustomComponents/BookingStatusUpdate";
import LeadMultiStatus from "../CustomComponents/LeadMultiStatus";
import BookingStatusFilter from "../CustomComponents/BookingStatusFilter/BookingStatusFilter";
import ComunicationStatusFilter from "../CustomComponents/CommunicationStatusFilter/CommunicationStatusFilter";
import CustomSearchInput from "../CustomComponents/CustomSearchInput";
import CustomPaginationArrows from "../CustomComponents/CustomPagination/CustomPaginationArrows";
import CommunicationDraftStatus from "../CustomComponents/CommunicationDraftStatus";
import CustomDateRangeSelector from "../CustomComponents/CustomDateRangeSelector";
import CommunicationMediaSlider from "../CustomComponents/CommunicationSlider/CommunicationMediaSlider";
import CommunicationCustomerTypeFilter from "../CustomComponents/CommunicationCustomerTypeFilter/CommunicationCustomerTypeFilter";
import CommunicationSearchInput from "../CustomComponents/CommunicationTagInput/CommunicationSearchInput";
import ItemIssueSearch from "../CustomComponents/ItemIssueSearch";
import CommunicationCustomDatePicker from "../CustomComponents/CommunicationCustomDatePicker";
import CustomCheckTableCell from "../CustomComponents/customCheckTable/customCheckTableCell";
import CustomTableCheckRow from "../CustomComponents/customCheckTable/customCheckTableRow";
import CustomCheckTable from "../CustomComponents/customCheckTable/customCheckTable";
import CustomTooltip from "../CustomComponents/CustomTooltip/CustomTooltip";
import { getDecryptedData } from "../../utils/encryptStorage";
import SidebarInnerListWithMultiplePermissions from "../CustomComponents/SidebarInnerListWithMultiplePermissions";

const Communication = () => {
  const userToken = getDecryptedData("encryptToken");
  const navigate = useNavigate();
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);
  const [selectedDateFilter, setSelectedDateFilter] = useState([]);
  const [selectedBookingStatus, setSelectedBookingStatus] = useState([]);
  const [selectedCustomerStatus, setSelectedCustomerStatus] = useState([]);
  const [showHoldPopup, setShowHoldPopup] = useState(false);

  const permission = JSON.parse(localStorage.getItem("permissions"));

  const [permissions, setPermissions] = useState(permission);
  const [selectedItems, setSelectedItems] = useState([]);

  // slider

  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);
  const [communicationRoomCategory, setCommunicationRoomCategory] =
    useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);
  const [editModelMenuActive, setEditModelMenuActive] = useState(false);

  const [showLowerMenuAddMenu, setShowLowerMenuAddMenu] = useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const [customStyle, setCustomStyle] = useState(true);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [orders, setOrders] = useState([]);
  const [test, setTest] = useState([]);
  const [allOrders, setAllOrders] = useState("");

  const allItems = allOrders?.send_list?.data || [];

  //date

  const toggleAccord = () => {
    setIsOpen(!isOpen);
  };

  // Pagination
  useEffect(() => {
    const updateItemsPerPage = () => {
      const newItemsPerPage = Math.floor(window.innerHeight / 110);
      // setItemsPerPage(newItemsPerPage);
    };

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);

    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, []);

  // fetch data
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(true);

  // Pagignation
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (page, newUrl) => {
    // console.log(page, newUrl + "newURl");
    setCurrentPage(page);
    if (newUrl) {
      fetchInfo(
        property_id?.id,
        selectedDatesRange.from_date,
        selectedDatesRange.to_date,
        newUrl
      );
    }
  };

  // console.log(selectedCustomerStatus, "selectedCustomerStatus");
  // console.log(selectedBookingStatus, "selectedBookingStatus");

  // const currentOrders = orders.slice(startIndex, endIndex);

  const [bedding, setBedding] = useState("");

  const beddingFinalValue = useCallback((data) => {
    setBedding(data);
  }, []);

  const fetchInfo = async (propertyId, from_date, to_date, newUrl) => {
    const url = newUrl
      ? `${
          process.env.REACT_APP_BASE_URL
        }/api/v2/get/lead-send-message/${propertyId}${
          newUrl ? "/" + newUrl : ""
        }`
      : `${process.env.REACT_APP_BASE_URL}/api/v2/get/lead-send-message/${propertyId}`;
    console.log(url, "url");

    try {
      // setLoader(true);

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          communication_type: selectedBookingStatus,
          name: searchName,
          sender_type: selectedCustomerStatus,
          dayTypeFilter: dayTypeFilter,
          selectedRange: selectedRange,
          from_date: from_date,
          to_date: to_date,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();

      if (data.success) {
        setAllOrders(data);
        setTotalPages(data.send_list.last_page);
        // setLoader(false);
      } else {
        setAllOrders(data);
        setTotalPages(data.send_list.last_page);
        // setLoader(false);
      }
    } catch (error) {
      console.error(error);
      // setLoader(false);
    } finally {
      // setLoader(false);
    }
  };

  const fetchInfoClearFunction = async (id, newUrl) => {
    try {
      setLoader(true);
      const url = `${process.env.REACT_APP_BASE_URL}/api/v2/get/lead-send-message/${property_id?.id}`;
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          communication_type: selectedBookingStatus,
          name: null,
          sender_type: selectedCustomerStatus,
          dayTypeFilter: dayTypeFilter,
          selectedRange: selectedRange,
          // from_date: from_date,
          // to_date: to_date,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();

      if (data.success) {
        setAllOrders(data);
        setTotalPages(data.send_list.last_page);
      } else {
        setAllOrders(data);
        setTotalPages(data.send_list.last_page);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const [roomTypeRateRoomData, setRoomTypeRateRoomData] = useState([]);

  const fetchRoomRatePlans = async (id, array_id, index) => {
    try {
      const indexToUpdate = array_id - 1;

      if (index >= 0 && index < dynamicInputList.length) {
        const existingEntry = dynamicInputList[index];

        if (index >= 0 && index < dynamicInputList.length) {
          const existingEntry = dynamicInputList.find(
            (entry) => entry.room_category_id === id
          );

          if (existingEntry) {
            showErrorToast("The category is already selected..!");
            return;
          }
        }
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/rooms/rates/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.success) {
        setRoomTypeRateRoomData(data?.roomRates);
        // setSelectedMealPlan("");
        setDynamicInputList((prevInputList) => {
          const updatedInputList = [...prevInputList];

          const updatedItem = {
            ...updatedInputList[index],
            meal_plan_array: data?.roomRates,
            room_category_id: id,
            selectedMealPlans: [],
          };

          updatedInputList[index] = updatedItem;

          return updatedInputList;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCommunicationSubmitPreviewVoucher = async (
    e,
    type,
    unique_booking_id
  ) => {
    e.stopPropagation();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v2/communication/booking-data/fetch/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            template: type,
            unique_booking_id: unique_booking_id,
            status: "preview",
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success && data.html) {
        setVoucherTitle(type); // Set the voucher title, adjust as needed
        setIframeSrc(
          `data:text/html;charset=utf-8,${encodeURIComponent(data.html)}`
        );
        setShowBookingPreviewVoucherPopup(true);
        setShowIframePopup(true);
        // if (type === "voucher") {
        //   setShowBookingPreviewVoucherPopup(true);
        //   setShowBookingPreviewBillPopup(false);
        //   setShowBookingPaymentReceiptPopup(false);
        // } else if (type === "bill") {
        //   setShowBookingPreviewVoucherPopup(false);
        //   setShowBookingPreviewBillPopup(true);
        //   setShowBookingPaymentReceiptPopup(false);
        // } else if (type === "payment-receipt") {
        //   handleToggleBookingPaymentReceiptPopup();
        //   setShowBookingPreviewVoucherPopup(false);
        //   setShowBookingPreviewBillPopup(false);
        //   setShowBookingPaymentReceiptPopup(true);
        // }

        setLoader(false);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  // const handleSubmitPreviewVoucher = async (e, id) => {
  //   try {
  //     // console.log(booking_id);
  //     // console.log(bookingDataArray, leadId, "0000000");
  //     e.stopPropagation();

  //     const response = await fetch(
  //       `${process.env.REACT_APP_BASE_URL}/api/v2/communication/media/fetch/${property_id?.id}`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //         body: JSON.stringify({
  //           // data: allOrders,
  //           id: id,
  //           property_id: property_id?.id,
  //         }),
  //       }
  //     );

  //     const data = await response.text();
  //     if (data) {

  // console.log(data, 'content')
  //       setIframeContent(data.media.lead_images);
  //       setShowIframePopup(true);
  //       setIframeSrc(null);
  //     } else {
  //       console.log("error");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     showErrorToast("An error occurred while processing your request.");
  //   }
  // };

  const [mediaData, setMediaData] = useState(null);
  const [communicationTypeCheck, setCommunicationTypeCheck] = useState("");

  const handleSubmitPreviewVoucher = async (e, id, type, unique_booking_id) => {
    try {
      e.stopPropagation();

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v2/communication/media/fetch/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            id: id,
            property_id: property_id?.id,
            template: type,
            unique_booking_id: unique_booking_id,
          }),
        }
      );

      // console.log(data, "previewData")
      if (response) {
        if (type === "images") {
          const data = await response.json();
          const images = data.media;
          setMediaData(images);
          setShowImagePopup(true);
        } else {
          const data = await response.text();

          setShowIframePopup(true);
          setIframeContent(data);
          setIframeSrc(null);
        }
      } else {
        console.log("Error fetching media data");
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const [selectedMealPlan, setSelectedMealPlan] = useState(null);
  // const [selectedMealPlans, setSelectedMealPlans] = useState([]);

  useEffect(() => {
    setSelectedMealPlan(selectedMealPlan);
  }, [selectedMealPlan]);

  const handleMealPlanSelection = (billIndex) => {
    setSelectedMealPlan((prevSelectedMealPlan) =>
      prevSelectedMealPlan === billIndex ? null : billIndex
    );

    setDynamicInputList((prevDynamicInputList) => {
      return prevDynamicInputList.map((bill, index) => {
        const updatedArray = [...prevDynamicInputList];

        if (index === billIndex) {
          return {
            ...bill,
            selected: true,
          };
        } else {
          return {
            ...bill,
            selected: false,
            // selectedMealPlans:["pppp"],
          };
        }
      });
    });
  };

  // filters
  const bookingDateFilters = [
    { label: "B2B Rates", value: "b2b_rates" },
    { label: "Booking Voucher", value: "voucher" },
    { label: "Payment Receipt", value: "payment-receipt" },
    { label: "Bill", value: "bill" },
    { label: "Payment Link", value: "payment_link" },
  ];

  const customerTypeFilters = [
    { label: "Existing Agent", value: "existing_agent" },
    { label: "New Agent", value: "new_agent" },
    { label: "Existing Customer", value: "customer" },
    { label: "New Customer", value: "new_customer" },
  ];

  const getSelectedBookingStatusFiltersFromChild = (checkedValue) => {
    setSelectedBookingStatus(checkedValue);
  };
  const getSelectedCustomerStatusFiltersFromChild = (checkedValue) => {
    setSelectedCustomerStatus(checkedValue);
  };

  const clearAllFilters = () => {
    setSelectedBookingStatus([]);
    setSelectedDateFilter([]);
    selectedCustomerStatus([]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      if (property_id) {
        fetchInfo(property_id?.id);
      }
    }
  };

  const handleCheckboxChange = (billIndex, mealPlanId) => {
    setDynamicInputList((prevDynamicInputList) => {
      return prevDynamicInputList.map((bill, index) => {
        if (index === billIndex) {
          const selectedMealPlans = bill.selectedMealPlans || [];
          const updatedSelectedMealPlans = selectedMealPlans.includes(
            mealPlanId
          )
            ? selectedMealPlans.filter((id) => id !== mealPlanId)
            : [...selectedMealPlans, mealPlanId];

          return {
            ...bill,
            selectedMealPlans: updatedSelectedMealPlans,
          };
        }
        return bill;
      });
    });
  };

  const [fetchedRoomType, setFetchedRoomType] = useState([]);

  const fetchRoomType = async (propertyId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setFetchedRoomType(data.rooms);
    } catch (error) {
      console.error(error);
    }
  };

  const [allRoomTypes, setAllRoomTypes] = useState([]);

  const fetchAllRoomTypes = async (propertyId) => {
    try {
      setLoader(true);
      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAllRoomTypes(data.rooms);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const roomTypeOptions = [
    { label: "Select an option", value: "" },
    ...(allRoomTypes?.map((roomType) => ({
      label: roomType.room_name,
      value: roomType.id,
    })) || []),
  ];

  const [valueArray, setValueArray] = useState([]);
  // const accordRef = useRef(null);

  const [searchName, setSearchName] = useState("");
  const [datepickerOpened, setDatepickerOpened] = useState("");
  // console.log(searchName, "searchName");

  const handleChildData = (data) => {
    setSearchName(data);
  };
  const handleOpenDatePickerData = (data) => {
    console.log(data, "dataaa");
    // setDatepickerOpened(data);
    setDatepickerOpened(data);
  };

  const dateOptions = [
    { id: 0, value: "-1", label: "Today" },
    { id: 1, value: "-7", label: "Past 7 Days" },
    { id: 2, value: "-30", label: "Past 30 Days" },
    { id: 3, value: "-90", label: "Past 90 Days" },
    { id: 4, value: "+7", label: "Future 7 Days" },
    { id: 5, value: "+30", label: "Future 30 Days" },
    { id: 6, value: "+90", label: "Future 90 Days" },
  ];

  const [selectedRange, setSelectedRange] = useState("");

  const [dayTypeFilter, setDayTypeFilter] = useState("-1");

  const handleAccordClick = (id, label, value) => {
    setValueArray([{ id: id, label: label, value: value }]);
    setDayTypeFilter(value);
  };

  const handleSelectedRangeChange = (selectedValue) => {
    if (selectedValue) {
      setSelectedDatesRange({
        from_date: "",
        to_date: "",
      });
      setDayTypeFilter(selectedValue);
    }
  };

  const handleApplyButtonClick = ({ from_date, to_date }) => {
    setSelectedDatesRange({ from_date, to_date });
    if (from_date && to_date) {
      setDayTypeFilter("");
    }
    // console.log("apply");
    // fetchInfo(property_id?.id, selectedDatesRange.from_date, selectedDatesRange.to_date);
  };

  const handleDateRangeSelected = ({ from_date, to_date }) => {
    if (from_date && to_date) {
      setDayTypeFilter("");
    }
    fetchInfo(property_id?.id, from_date, to_date);

    // setSelectedDatesRange({ from_date, to_date });
  };

  // console.log(dayTypeFilter, "dayTypeFilter");

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);

  const accordRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (accordRef.current && !accordRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [accordRef]);

  const toggleAccord1 = () => {
    setIsOpen(false);
    setIsOpen1(!isOpen1);
    setIsOpen2(false);
  };

  const toggleAccord2 = () => {
    setIsOpen2(!isOpen2);
    // setIsOpen1(false);
    // setIsOpen(false);
  };

  const toggleAccord3 = () => {
    setIsOpen3(!isOpen3);
    setIsOpen1(false);
    setIsOpen(false);
  };

  const toggleAccord4 = () => {
    setIsOpen4(!isOpen4);
  };

  const toggleAccord5 = () => {
    setIsOpen5(!isOpen5);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (accordRef.current && !accordRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [accordRef]);

  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: "",
    to_date: "",
  });

  const handleDateRangeChange = (dates, formattedFromDate, formattedToDate) => {
    setSelectedDatesRange({
      from_date: formattedFromDate,
      to_date: formattedToDate,
    });
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(
        property_id.id,
        selectedDatesRange.from_date,
        selectedDatesRange.to_date,
        selectedDateFilter,
        selectedBookingStatus,
        selectedCustomerStatus,
        dayTypeFilter,
        selectedRange.at
      );
      fetchInfo(property_id?.id);
      fetchRoomType(property_id?.id);
      fetchAllRoomTypes(property_id?.id);
    }
  }, [
    property_id,
    selectedDateFilter,
    selectedBookingStatus,
    selectedCustomerStatus,
    dayTypeFilter,
    selectedRange,
  ]);

  // useEffect(() => {
  //   if (property_id) {
  //     fetchInfo(property_id?.id);
  //     fetchInfoOrder(property_id?.id);
  //     fetchRoomType(property_id?.id);
  //     fetchAllRoomTypes(property_id?.id);

  //   }
  // }, [property_id]);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(allOrders?.send_list?.data);

  const [eventId, setEventId] = useState("");
  const [showBookingDetails, setShowBookingDetails] = useState(false);
  const [showEventDetails, setShowEventDetails] = useState(false);
  const [eventDetails, setEventDetails] = useState(false);

  const [openAccordion, setOpenAccordion] = useState("first");

  const [roomAvailableData, setRoomAvailableData] = useState(null);

  const toggleAccordion = (accordion) => {
    if (openAccordion === accordion) {
      setOpenAccordion(null); // Close the currently open accordion
    } else {
      setOpenAccordion(accordion); // Open the clicked accordion
    }
  };

  const [bookingDetails, setBookingDetails] = useState([]);

  const onKeyPressHandler = (e) => {
    setSearchName(e.target.value);
  };

  const submitBookingData = (e) => {
    if (e) {
      e.preventDefault();
    }
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${searchName}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setShowBookingDetails(true);
          setBookingDetails(data.booking);
          // showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const submitEventData = (e) => {
    if (e) {
      e.preventDefault();
    }
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/event/${eventId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setShowEventDetails(true);
          setEventDetails(data.event);
          // showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  useEffect(() => {
    if (selectedResources.length === 1) {
      // getOneData(selectedResources[0]);
      setShowLowerMenuMultipleSelect(true);

      // setShowLowerMenuSingleSelect(true);
      // setShowLowerMenuMultipleSelect(false);
      setShowLowerMenuAddMenu(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuAddMenu(false);
    } else {
      // setAddItemData({
      //   room_name: "",
      //   room_no: "",
      //   floor_no: "",
      //   status: "clean",
      //   room_id: fetchedRoomType ? fetchedRoomType[0]?.id : "",
      //   property_id: property_id?.id,
      // });
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuAddMenu(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const [update, setUpdate] = useState("");

  useState(() => {
    setUpdate(update);
  }, [update]);

  const [getClickedEditId, setGetClickedEditId] = useState("");

  useState(() => {
    setGetClickedEditId(getClickedEditId);
  }, [getClickedEditId]);

  const getOneData = async (id, status) => {
    setUpdate(status);
    setGetClickedEditId(id);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit_communication/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json().then((data) => {
        if (data.success) {
          setCommunicationOn(data.data.communication_on);
          setCommunicationType(data.data.communication_type);
          setSearchName(data.data.booking_event_id);
          setEventId(data.data.booking_event_id);

          const updatedData = {
            customer_type: data.data.customer_type,
            name: data.data.name,
            email: data.data.email,
            email_sub: data.data.email_sub,
            template: data.data.template,
            room_category_id: data.data.room_id,

            extra_child: data.data.extra_child,
            extra_bed: data.data.extra_bed,
            extra_adult: data.data.extra_adult,
          };

          if (communicationOn === "bookings") {
            submitBookingData();
          }

          if (communicationOn === "events") {
            submitEventData();
          }

          setAddItemData(updatedData);
          addModalButtonClickHandler("edit");
        } else {
          console.log("error");
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const rowMarkup = allOrders ? (
    allOrders?.send_list?.data?.map(
      ({
        id,
        communication_type,
        name,
        status,
        send_by,
        email,
        created_at,
        guest,
        sender_type,
        unique_booking_id,
      }) => {
        const firstGuestName = guest && guest.length > 0 ? guest[0].name : "NA";
        const remainingGuests = guest && guest.length > 1 ? guest.slice(1) : [];
        const firstGuestEmail =
          guest && guest.length > 0 ? guest[0].email : "NA";
        const firstGuestPhone =
          guest && guest.length > 0 ? guest[0].phone : "NA";

        return (
          <IndexTable.Row
            id={id}
            key={id}
            selected={selectedResources?.includes(id)}
            position={id}
          >
            <IndexTable.Cell>
              <CustomCssComponentTableText>
                <div style={{ fontSize: "12px", fontWeight: "500" }}>
                  {<UnderscoreRemoveCapitalize />}
                </div>
                <div style={{ fontSize: "11px", fontWeight: "500" }}>
                  <p>
                    <BookingStatusUpdate status={communication_type} />
                  </p>
                  <div
                    style={{ display: "flex", gap: "5px", fontSize: "10px" }}
                  >
                    <div>Sent on: </div>
                    <div>
                      <TextDateFormatter
                        style={{ fontSize: "10px", fontWeight: "500" }}
                        as={`div`}
                        date={created_at.slice(0, 10)}
                        showYear={true}
                      />
                    </div>
                  </div>
                </div>
              </CustomCssComponentTableText>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <div style={{ fontSize: "12px", fontWeight: "500" }}>
                {firstGuestName}
                {remainingGuests.length > 0 && (
                  <Tooltip
                    preferredPosition="below"
                    content={
                      <div>
                        {remainingGuests.map((g) => (
                          <div style={{ fontSize: "12px" }} key={g.id}>
                            {g.name}
                          </div>
                        ))}
                      </div>
                    }
                  >
                    <button
                      style={{
                        cursor: "no-drop",
                        border: "none",
                        padding: "2px 4px ",
                        margin: "auto 7px",
                        fontSize: "10px",
                        borderRadius: "4px",
                        color: "#000",
                        backgroundColor: "rgb(225 226 229)",
                      }}
                    >
                      +{remainingGuests.length}
                    </button>
                  </Tooltip>
                )}
              </div>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <div style={{ fontSize: "12px", fontWeight: "500" }}>
                {firstGuestEmail}
                {remainingGuests.length > 0 && (
                  <Tooltip
                    preferredPosition="below"
                    content={
                      <div>
                        {remainingGuests.map((g) => (
                          <div style={{ fontSize: "12px" }} key={g.id}>
                            {g.email}
                          </div>
                        ))}
                      </div>
                    }
                  >
                    <button
                      style={{
                        cursor: "pointer",
                        border: "none",
                        padding: "2px 4px",
                        margin: "auto 7px",
                        fontSize: "10px",
                        borderRadius: "4px",
                        color: "#000",
                        backgroundColor: "rgb(225 226 229)",
                      }}
                    >
                      +{remainingGuests.length}
                    </button>
                  </Tooltip>
                )}

                <div style={{ display: "flex", gap: "5px", fontSize: "10px" }}>
                  <div>
                    {" "}
                    {firstGuestPhone}
                    {remainingGuests.length > 0 && (
                      <Tooltip
                        preferredPosition="below"
                        content={
                          <div>
                            {remainingGuests.map((g) => (
                              <div style={{ fontSize: "12px" }} key={g.id}>
                                {g.phone}
                              </div>
                            ))}
                          </div>
                        }
                      >
                        <button
                          style={{
                            cursor: "pointer",
                            border: "none",
                            padding: "2px 4px",
                            margin: "auto 7px",
                            fontSize: "10px",
                            borderRadius: "4px",
                            color: "#000",
                            backgroundColor: "rgb(225 226 229)",
                          }}
                        >
                          +{remainingGuests.length}
                        </button>
                      </Tooltip>
                    )}{" "}
                  </div>
                </div>
              </div>
            </IndexTable.Cell>

            <IndexTable.Cell>
              <div style={{ fontSize: "12px", fontWeight: "500" }}>
                {sender_type
                  ? sender_type === "customer"
                    ? "Existing Customer"
                    : sender_type
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (c) => c.toUpperCase())
                  : "NA"}
              </div>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <div style={{ fontSize: "12px", fontWeight: "500" }}>
                {send_by ? send_by : "NA"}
              </div>
              <TextDateFormatter
                style={{ fontSize: "10px", fontWeight: "500" }}
                as={`div`}
                date={created_at.slice(0, 10)}
                showYear={true}
              />
            </IndexTable.Cell>
            <IndexTable.Cell>
              {status === "sent" && (
                <StatusUpdate status={status}></StatusUpdate>
              )}
              {status === "draft" && (
                <CommunicationDraftStatus
                  status={status}
                  messageId={id}
                  communication_type
                  fetchInfo={fetchInfo}
                  clearSelection={clearSelection}
                />
              )}
            </IndexTable.Cell>
            <IndexTable.Cell>
              {status === "sent" ? (
                <>
                  {/* <div onClick={(e) => handleSubmitPreviewVoucher(e, id, "images")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 12 10"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.0673 4.80967C9.9757 2.07317 7.87646 0.826174 5.77812 0.935011C3.70396 1.04259 1.74696 2.47251 0.91742 4.82943L0.857422 4.9999L0.91742 5.17037C1.74696 7.52729 3.70396 8.9572 5.77812 9.06478C7.87646 9.17362 9.9757 7.92662 11.0673 5.19012L11.1431 4.9999L11.0673 4.80967ZM5.99609 7.56089C7.40656 7.56089 8.54996 6.41429 8.54996 4.9999C8.54996 3.5855 7.40656 2.43891 5.99609 2.43891C4.58563 2.43891 3.44222 3.5855 3.44222 4.9999C3.44222 6.41429 4.58563 7.56089 5.99609 7.56089Z"
                      fill="#3968ED"
                    ></path>
                    <path
                      d="M7.52842 4.9999C7.52842 5.84853 6.84237 6.53649 5.99609 6.53649C5.14981 6.53649 4.46377 5.84853 4.46377 4.9999C4.46377 4.15126 5.14981 3.4633 5.99609 3.4633C6.84237 3.4633 7.52842 4.15126 7.52842 4.9999Z"
                      fill="#3968ED"
                    ></path>
                  </svg>
                </div> */}

                  {communication_type === "voucher" ||
                  communication_type === "bill" ||
                  communication_type === "payment-receipt" ? (
                    <div
                      onClick={(e) =>
                        handleCommunicationSubmitPreviewVoucher(
                          e,
                          communication_type,
                          unique_booking_id
                        )
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 12 10"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.0673 4.80967C9.9757 2.07317 7.87646 0.826174 5.77812 0.935011C3.70396 1.04259 1.74696 2.47251 0.91742 4.82943L0.857422 4.9999L0.91742 5.17037C1.74696 7.52729 3.70396 8.9572 5.77812 9.06478C7.87646 9.17362 9.9757 7.92662 11.0673 5.19012L11.1431 4.9999L11.0673 4.80967ZM5.99609 7.56089C7.40656 7.56089 8.54996 6.41429 8.54996 4.9999C8.54996 3.5855 7.40656 2.43891 5.99609 2.43891C4.58563 2.43891 3.44222 3.5855 3.44222 4.9999C3.44222 6.41429 4.58563 7.56089 5.99609 7.56089Z"
                          fill="#3968ED"
                        ></path>
                        <path
                          d="M7.52842 4.9999C7.52842 5.84853 6.84237 6.53649 5.99609 6.53649C5.14981 6.53649 4.46377 5.84853 4.46377 4.9999C4.46377 4.15126 5.14981 3.4633 5.99609 3.4633C6.84237 3.4633 7.52842 4.15126 7.52842 4.9999Z"
                          fill="#3968ED"
                        ></path>
                      </svg>
                    </div>
                  ) : communication_type === "payment_link" ? (
                    <div
                      onClick={(e) =>
                        handleSubmitPreviewVoucher(e, id, "iframe")
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 12 10"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.0673 4.80967C9.9757 2.07317 7.87646 0.826174 5.77812 0.935011C3.70396 1.04259 1.74696 2.47251 0.91742 4.82943L0.857422 4.9999L0.91742 5.17037C1.74696 7.52729 3.70396 8.9572 5.77812 9.06478C7.87646 9.17362 9.9757 7.92662 11.0673 5.19012L11.1431 4.9999L11.0673 4.80967ZM5.99609 7.56089C7.40656 7.56089 8.54996 6.41429 8.54996 4.9999C8.54996 3.5855 7.40656 2.43891 5.99609 2.43891C4.58563 2.43891 3.44222 3.5855 3.44222 4.9999C3.44222 6.41429 4.58563 7.56089 5.99609 7.56089Z"
                          fill="#3968ED"
                        ></path>
                        <path
                          d="M7.52842 4.9999C7.52842 5.84853 6.84237 6.53649 5.99609 6.53649C5.14981 6.53649 4.46377 5.84853 4.46377 4.9999C4.46377 4.15126 5.14981 3.4633 5.99609 3.4633C6.84237 3.4633 7.52842 4.15126 7.52842 4.9999Z"
                          fill="#3968ED"
                        ></path>
                      </svg>
                    </div>
                  ) : (
                    <div
                      onClick={(e) =>
                        handleSubmitPreviewVoucher(e, id, "images")
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 12 10"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.0673 4.80967C9.9757 2.07317 7.87646 0.826174 5.77812 0.935011C3.70396 1.04259 1.74696 2.47251 0.91742 4.82943L0.857422 4.9999L0.91742 5.17037C1.74696 7.52729 3.70396 8.9572 5.77812 9.06478C7.87646 9.17362 9.9757 7.92662 11.0673 5.19012L11.1431 4.9999L11.0673 4.80967ZM5.99609 7.56089C7.40656 7.56089 8.54996 6.41429 8.54996 4.9999C8.54996 3.5855 7.40656 2.43891 5.99609 2.43891C4.58563 2.43891 3.44222 3.5855 3.44222 4.9999C3.44222 6.41429 4.58563 7.56089 5.99609 7.56089Z"
                          fill="#3968ED"
                        ></path>
                        <path
                          d="M7.52842 4.9999C7.52842 5.84853 6.84237 6.53649 5.99609 6.53649C5.14981 6.53649 4.46377 5.84853 4.46377 4.9999C4.46377 4.15126 5.14981 3.4633 5.99609 3.4633C6.84237 3.4633 7.52842 4.15126 7.52842 4.9999Z"
                          fill="#3968ED"
                        ></path>
                      </svg>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {unique_booking_id ? (
                    <Link
                      to={`/leads/communicationUpdatemessage/${id}/${unique_booking_id}`}
                    >
                      <div
                        onClick={() => setShowHoldPopup(true)}
                        className="booking-third-row-one-full-div-child"
                        style={{
                          width: "50px",
                          cursor: "pointer",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 12 14"
                          fill="none"
                        >
                          <path
                            d="M0.293176 0.132132C0.522048 -0.0314557 0.827164 -0.044187 1.06908 0.0997561L11.6573 6.39975C11.8699 6.52626 12 6.75412 12 7C12 7.24588 11.8699 7.47374 11.6573 7.60025L1.06908 13.9002C0.827164 14.0442 0.522048 14.0315 0.293176 13.8679C0.0643041 13.7043 -0.0443613 13.4213 0.0168402 13.1481L1.23771 7.7L4.94704 7.7C5.33364 7.7 5.64704 7.3866 5.64704 7C5.64704 6.6134 5.33364 6.3 4.94704 6.3L1.23771 6.3L0.0168402 0.851852C-0.0443613 0.57874 0.0643041 0.295719 0.293176 0.132132Z"
                            fill="#3968ED"
                          />
                        </svg>
                      </div>
                    </Link>
                  ) : (
                    <Link to={`/leads/communicationUpdatemessage/${id}`}>
                      <div
                        onClick={() => setShowHoldPopup(true)}
                        className="booking-third-row-one-full-div-child"
                        style={{
                          width: "50px",
                          cursor: "pointer",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 12 14"
                          fill="none"
                        >
                          <path
                            d="M0.293176 0.132132C0.522048 -0.0314557 0.827164 -0.044187 1.06908 0.0997561L11.6573 6.39975C11.8699 6.52626 12 6.75412 12 7C12 7.24588 11.8699 7.47374 11.6573 7.60025L1.06908 13.9002C0.827164 14.0442 0.522048 14.0315 0.293176 13.8679C0.0643041 13.7043 -0.0443613 13.4213 0.0168402 13.1481L1.23771 7.7L4.94704 7.7C5.33364 7.7 5.64704 7.3866 5.64704 7C5.64704 6.6134 5.33364 6.3 4.94704 6.3L1.23771 6.3L0.0168402 0.851852C-0.0443613 0.57874 0.0643041 0.295719 0.293176 0.132132Z"
                            fill="#3968ED"
                          />
                        </svg>
                      </div>
                    </Link>
                  )}
                </>
              )}
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      }
    )
  ) : (
    <p>No orders available</p>
  );

  // const rowMarkupNew= allOrders?.send_list?.data?.length >0 && (
  //   allOrders?.send_list?.data?.map(
  //     ({
  //       id,
  //       communication_type,
  //       name,
  //       communication_on,
  //       customer_type,
  //       status,
  //       send_by,
  //       email,
  //       template,
  //       index,
  //       created_at,
  //     }) => {
  //       return (
  //         <IndexTable.Row
  //           id={id}
  //           key={id}
  //           selected={selectedResources?.includes(id)}
  //           position={id}
  //         >
  //           <IndexTable.Cell>
  //             <CustomCssComponentTableText>
  //               <div style={{ fontSize: "12px", fontWeight: "500" }}>
  //                 {<UnderscoreRemoveCapitalize text={communication_on} />}
  //               </div>
  //               <div style={{ fontSize: "11px", fontWeight: "500" }}>
  //                 <p> {communication_type}</p>
  //                 <span> Sent on:</span>
  //               </div>
  //             </CustomCssComponentTableText>
  //           </IndexTable.Cell>
  //           <IndexTable.Cell>
  //             <div style={{ fontSize: "12px", fontWeight: "500" }}>
  //               {name}
  //             </div>
  //           </IndexTable.Cell>
  //           <IndexTable.Cell>
  //             <div style={{ fontSize: "12px", fontWeight: "500" }}>
  //               {email}
  //             </div>
  //           </IndexTable.Cell>
  //           <IndexTable.Cell>
  //             <div style={{ fontSize: "12px", fontWeight: "500" }}>{send_by}</div>
  //             <TextDateFormatter
  //               style={{ fontSize: "11px", fontWeight: "500" }}
  //               as={`div`}
  //               date={created_at.slice(0, 10)}
  //               showYear={true}
  //             />
  //           </IndexTable.Cell>
  //           <IndexTable.Cell>
  //             <StatusUpdate status={status}></StatusUpdate>
  //           </IndexTable.Cell>
  //           <IndexTable.Cell>
  //             <div
  //               onClick={() => setShowHoldPopup(true)}
  //               className="booking-third-row-one-full-div-child"
  //               style={{
  //                 width: "50px",
  //                 cursor: "pointer",
  //               }}
  //             >
  //               <svg
  //                 xmlns="http://www.w3.org/2000/svg"
  //                 width="12"
  //                 height="14"
  //                 viewBox="0 0 12 14"
  //                 fill="none"
  //               >
  //                 <path
  //                   d="M0.293176 0.132132C0.522048 -0.0314557 0.827164 -0.044187 1.06908 0.0997561L11.6573 6.39975C11.8699 6.52626 12 6.75412 12 7C12 7.24588 11.8699 7.47374 11.6573 7.60025L1.06908 13.9002C0.827164 14.0442 0.522048 14.0315 0.293176 13.8679C0.0643041 13.7043 -0.0443613 13.4213 0.0168402 13.1481L1.23771 7.7L4.94704 7.7C5.33364 7.7 5.64704 7.3866 5.64704 7C5.64704 6.6134 5.33364 6.3 4.94704 6.3L1.23771 6.3L0.0168402 0.851852C-0.0443613 0.57874 0.0643041 0.295719 0.293176 0.132132Z"
  //                   fill="#3968ED"
  //                 />
  //               </svg>
  //             </div>
  //           </IndexTable.Cell>
  //         </IndexTable.Row>
  //       );
  //     }
  //   )
  // )

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  // const roomTypeOptions = fetchedRoomType?.map((roomType) => {
  //   return { label: roomType.room_name, value: roomType.id };
  // });

  const statusOptions = [
    { label: "Clean", value: "clean" },
    { label: "Dirty", value: "dirty" },
    { label: "Inspected", value: "inspected" },
    { label: "Out of order", value: "out_of_order" },
    { label: "Occupied", value: "occupied" },
  ];

  const roomStatusOptions = [
    { label: "Select An Option ", value: "" },
    { label: "Available ", value: "available " },
    { label: "Not Available", value: "not_available" },
  ];

  // const mealPlanOptions = [
  //   { label: "Select An Option ", value: "" },
  //   ...

  //   { label: "EP", value: "EP" },
  //   { label: "CP", value: "CP" },
  //   { label: "MAP", value: "MAP" },
  //   { label: "AP", value: "AP" },
  // ];

  const mealPlanOptions = [
    { label: "Select an option", value: "" }, // Your first option
    { label: "All Meal Plan", value: "all" },
    ...(roomTypeRateRoomData?.map((roomTypeRatePlan) => ({
      label: roomTypeRatePlan.rate_plan_type,
      value: roomTypeRatePlan.id,
    })) || []),
  ];

  // UseState for forms
  const [addItemData, setAddItemData] = useState({
    customer_type: "",
    name: "",
    email: "",
    email_sub: "",
    template: "",
    room_category_id: "",
    no_of_rooms: "0",
    no_of_adults: "",
    meal_plan: "",
    room_status: "",
    child_with_bed: "0",
    child_without_bed: "0",
    extra_child: "0",
    extra_bed: "0",
    extra_adult: "0",

    no_of_adult: "0",
    extra_adult: "0",
    discount: "0",
    additional_notes: "",
  });

  const [communicationOn, setCommunicationOn] = useState("");
  const [communicationType, setCommunicationType] = useState("");

  // Conditional Rendering
  const [communicationDetails, setCommunicationDetails] = useState(false);
  const [communicationTemplateDetails, setCommunicationTemplateDetails] =
    useState(false);

  const handleCreateMessageFirstClick = () => {
    if (update === "edit") {
      if (communicationOn === "bookings") {
        submitBookingData();
      }

      if (communicationOn === "events") {
        submitEventData();
      }

      if (communicationOn !== "" && communicationType !== "") {
        setCommunicationDetails(true);
        setShowAddModelMenuActive(false);
      } else {
        showErrorToast("Please select the above options...!");
      }
    } else {
      if (communicationOn !== "" && communicationType !== "") {
        setCommunicationDetails(true);
        setShowAddModelMenuActive(false);
      } else {
        showErrorToast("Please select the above options...!");
      }
    }
  };

  const handleBookingDetailNextTemplateShowClick = () => {
    // if (communicationOn !== "" && communicationType !== "") {
    setCommunicationDetails(false);
    setShowAddModelMenuActive(false);
    setCommunicationTemplateDetails(true);

    // } else {
    //   showErrorToast("Please select the above options...!");
    // }
  };

  const handleBookingDetailNextRoomCategoryShowClick = () => {
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    if (addItemData.name !== "" && addItemData.email !== "") {
      if (isValidEmail(addItemData.email)) {
        setCommunicationDetails(false);
        setShowAddModelMenuActive(false);
        setCommunicationTemplateDetails(false);
        setCommunicationRoomCategory(true);
      } else {
        showErrorToast("Please enter a valid email address!");
      }
    } else {
      showErrorToast("Please enter the above fields...!");
    }
  };

  const templateOptions = [
    { label: "Select Option" },
    { label: "Voucher", value: "voucher" },
    { label: "Bill", value: "bill" },
    { label: "Payment Receipt", value: "payment-receipt" },
  ];

  const bookingsTemplateOptions = [
    { label: "Select Option" },
    { label: "Voucher", value: "voucher" },
    { label: "Bill", value: "bill" },
    { label: "Payment Receipt", value: "payment-receipt" },
    { label: "Booking Confirmation", value: "booking-confirmation" },
    { label: "Booking Cancelation", value: "booking-cancellation" },
    { label: "Payment Cancelation", value: "payment-cancellation" },
    { label: "Payment Conformation", value: "payment-confirmation" },
    { label: "Check In", value: "check_in" },
    { label: "Check Out", value: "check_out" },
  ];

  const handleCommunicationOnChange = (e) => {
    const value = e.target.value;
    setCommunicationOn(value);
  };

  const handleCommunicationTypeChange = (e) => {
    const value = e.target.value;
    setCommunicationType(value);
  };

  const setAddItemDataHandleChange = (event) => {
    if (event.target.name === "room_category_id") {
      fetchRoomRatePlans(event.target.value, event.target.name);
    }

    setAddItemData({
      ...addItemData,
      [event.target.name]: event.target.value,
    });
  };

  const deletedIds = {
    deletedIds: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v2/delete/lead-send-message/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          clearSelection();
          fetchInfo(property_id?.id);
          clearSelection();
          setShowLowerMenuMultipleSelect(false);
          setShowModelMenuActive(false);
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleSubmitAddRooms = (event, statuses) => {
    if (communicationOn === "rate_and_deals") {
    } else if (addItemData.template === "") {
      showErrorToast("Please select template..");
      return;
    }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/communication/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addItemData,
          communication_on: communicationOn,
          communication_type: communicationType,
          booking_event_id: eventDetails.id || bookingDetails.id,
          booking_event_unique_id: searchName || eventId,
          from_date: selectedDatesRange.from_date,
          to_date: selectedDatesRange.to_date,
          // addItemData.template,
          status: statuses,
          // template: 'availability'
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          // navigate("/communication");
          setShowBookingDetails({});
          setShowEventDetails({});
          setCommunicationDetails(false);
          setShowAddModelMenuActive(false);
          setCommunicationTemplateDetails(false);
          setShowAddModelMenuActive(false);
          setCommunicationRoomCategory(false);
          // setShow(false);
          showSuccessToast(data.message);
          fetchInfo(property_id?.id);
          setAddItemData({});
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleSubmitRateAndDeal = (event, statuses) => {
    if (
      selectedDatesRange.from_date === "" &&
      selectedDatesRange.from_date === ""
    ) {
      showErrorToast("Please select the date range..");
      return;
    }

    if (
      dynamicInputList[0]?.room_category_id === "" ||
      dynamicInputList[0]?.selectedMealPlans?.length < 1
    ) {
      showErrorToast(
        "Please select any one of the room category with meal plan.."
      );
      return;
    }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/communication/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addItemData,
          communication_on: communicationOn,
          communication_type: communicationType,
          booking_event_id: eventDetails.id || bookingDetails.id,

          booking_event_unique_id: searchName || eventId,
          from_date: selectedDatesRange.from_date,
          to_date: selectedDatesRange.to_date,
          // addItemData.template,
          status: statuses,
          // template: 'availability'
          selectedRoomCategory: dynamicInputList,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          // navigate("/communication");
          setShowBookingDetails({});
          setShowEventDetails({});
          setCommunicationDetails(false);
          setShowAddModelMenuActive(false);
          setCommunicationTemplateDetails(false);
          setShowAddModelMenuActive(false);
          setCommunicationRoomCategory(false);
          // setShow(false);
          showSuccessToast(data.message);
          fetchInfo(property_id?.id);
          setAddItemData({});
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleSubmitRateAndDeals = (event, statuses) => {
    event.preventDefault();

    // if (addItemData.room_category_id === "") {
    //   showErrorToast("Please Select Room Category First !..");
    //   return;
    // }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/room-availability-rate/${property_id?.id}/${addItemData.room_category_id}/${selectedDatesRange.from_date}/${selectedDatesRange.from_date}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setRoomAvailableData(data.data);
          setShowBookingDetails({});
          setShowEventDetails({});
          setCommunicationDetails(false);
          setShowAddModelMenuActive(false);
          setCommunicationTemplateDetails(false);
          setShowAddModelMenuActive(false);
          setCommunicationRoomCategory(false);
          setShowRatesAndDealsReviewVoucherPopup(true);

          showSuccessToast(data.message);
          fetchInfo(property_id?.id);
          // setAddItemData({});
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const updateFunction = (id, statuses) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/update_communication/${id}/${property_id?.id}`,
      {
        method: "POST",

        body: JSON.stringify({
          ...addItemData,
          communication_on: communicationOn,
          communication_type: communicationType,
          booking_event_id: eventDetails.id || bookingDetails.id,
          booking_event_unique_id: searchName || eventId,
          from_date: selectedDatesRange.from_date,
          to_date: selectedDatesRange.to_date,
          // addItemData.template,
          status: statuses,
          // template: 'availability'
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          fetchInfo(property_id?.id);
          clearSelection();
          showSuccessToast(data.message);
          setCommunicationTemplateDetails(false);
          setCommunicationRoomCategory(false);
          setCommunicationOn("");
          setCommunicationType("");
          setAddItemData({});
          setSearchName("");
          setEventId("");
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const updateCommunication = (id) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/update_communication/${id}/${property_id?.id}`,
      {
        method: "POST",

        body: JSON.stringify({ ...addItemData, status: addItemData.status }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          fetchInfo(property_id?.id);
          clearSelection();
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const addModalButtonClickHandler = (showAdd) => {
    setUpdate(showAdd);
    setShowAddModelMenuActive(!showAddModelMenuActive);
    clearSelection();
  };

  const editButtonClickHandler = () => {
    setEditModelMenuActive(!editModelMenuActive);
    clearSelection();
  };

  // POP UP STATES
  const [showIframePopup, setShowIframePopup] = useState(false);
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [iframeContent, setIframeContent] = useState("");
  const [showBookingPreviewVoucherPopup, setShowBookingPreviewVoucherPopup] =
    useState(false);

  const [showBookingPreviewBillPopup, setShowBookingPreviewBillPopup] =
    useState(false);
  const [showBookingPaymentReceiptPopup, setShowBookingPaymentReceiptPopup] =
    useState(false);

  const [showEventsPreviewVoucherPopup, setShowEventsPreviewVoucherPopup] =
    useState(false);
  const [showEventsPreviewBillPopup, setShowEventsPreviewBillPopup] =
    useState(false);
  const [showEventsPaymentReceiptPopup, setShowEventsPaymentReceiptPopup] =
    useState(false);
  const [
    showRatesAndDealsReviewVoucherPopup,
    setShowRatesAndDealsReviewVoucherPopup,
  ] = useState(false);

  const handleToggleBookingPreviewVoucherPopup = () => {
    // if (addItemData.name !== "" && addItemData.email !== "") {
    setShowBookingPreviewVoucherPopup(!showBookingPreviewVoucherPopup);
    // } else {
    // showErrorToast("Please Enter the above fields...!");
    // }
  };

  const handleToggleBookingPreviewVoucherPopupWithoutEmailName = () => {
    if (addItemData.template !== "") {
      setShowBookingPreviewVoucherPopup(!showBookingPreviewVoucherPopup);
    } else {
      showErrorToast("Please Select the template..!");
    }
  };

  const handleToggleBookingPreviewBillPopup = () => {
    // if (addItemData.name !== "" && addItemData.email !== "") {
    setShowBookingPreviewBillPopup(!showBookingPreviewBillPopup);
    // } else {
    // showErrorToast("Please Enter the above fields...!");
    // }
  };

  // console.log(iframeContent, "iframcontent");

  // const handleToggleBookingPaymentReceiptPopup = () => {
  //   // if (addItemData.name !== "" && addItemData.email !== "") {
  //   setShowBookingPaymentReceiptPopup(!showBookingPaymentReceiptPopup);
  //   // } else {
  //   // showErrorToast("Please Enter the above fields...!");
  //   // }
  // };
  const [bookingPaymentData, setBookingPaymentData] = useState(null);

  const handleToggleBookingPaymentReceiptPopup = () => {
    // if (addItemData.name !== "" && addItemData.email !== "") {

    const response = fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/success/payment-receipt/booking/${searchName}/${property_id.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setBookingPaymentData(data.message);
          setShowBookingPaymentReceiptPopup(true);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleToggleEventsPreviewVoucherPopup = () => {
    // if (addItemData.name !== "" && addItemData.email !== "") {
    setShowEventsPreviewVoucherPopup(!showEventsPreviewVoucherPopup);
    // } else {
    // showErrorToast("Please Enter the above fields...!");
    // }
  };

  const handleToggleEventsPreviewBillPopup = () => {
    // if (addItemData.name !== "" && addItemData.email !== "") {
    setShowEventsPreviewBillPopup(!showEventsPreviewBillPopup);
    // } else {
    // showErrorToast("Please Enter the above fields...!");
    // }
  };

  const handleToggleRaatesDealsPreviewVoucherPopup = () => {
    setShowRatesAndDealsReviewVoucherPopup(
      !showRatesAndDealsReviewVoucherPopup
    );
    setCommunicationRoomCategory(true);
  };

  // useEffect(() => {
  //   fetchCalculatedRoomRatePlans(
  //     addItemData.no_of_rooms,
  //     1,
  //     1,
  //     addItemData.child_with_bed,
  //     addItemData.no_of_adults,
  //     addItemData.room_category_id,
  //     addItemData.child_without_bed
  //   );
  // }, [
  //   addItemData.child_with_bed,
  //   addItemData.child_without_bed,
  //   addItemData.no_of_adults,
  // ]);

  const fetchCalculatedRoomRatePlans = async (
    no_of_rooms,
    rate_bar_price,
    totalFoodCost,
    no_of_child,
    no_of_adult,
    roomId,
    child_without_bed
  ) => {
    const noOfRooms = no_of_rooms || 1;
    const totalPrice = +rate_bar_price;
    const mealCharges = totalFoodCost;
    const noOfChild = no_of_child || 0;
    const noOfAdult = no_of_adult || 0;
    const childWithoutBed = child_without_bed || 0;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/calculate/rooms/rates/${property_id?.id}?room_id=${roomId}&roomRate_id=${addItemData?.meal_plan}&no_of_rooms=${noOfRooms}&total_price=1&meal_charges=1&no_of_child=${noOfChild}&no_of_adult=${noOfAdult}&check_in=${selectedDatesRange.from_date}&check_out=${selectedDatesRange.to_date}&child_without_bed=${childWithoutBed}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      // setRoomTotalPrice(data.total_including_meals);
      setAddItemData({
        ...addItemData,
        no_of_rooms: data.no_of_rooms,
      });
      // setChildRoomCost(data.childRoomCost);
      // setExtraAdultNumber(data.extra_adult_no);
      // setAvailableCheckedRooms(data.available_rooms_dates);
      // setExtraChildNumber(data.extra_child_no);
      // setAdultRoomCost(data.adultRoomCost);
    } catch (error) {
      console.error(error);
    }
  };

  const [eventPaymentData, setEventPaymentData] = useState(null);
  const handleToggleEventsPaymentReceiptPopup = () => {
    // if (addItemData.name !== "" && addItemData.email !== "") {
    const response = fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/success/payment-receipt/event/${eventId}/${property_id.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setEventPaymentData(data.message);
          setShowEventsPaymentReceiptPopup(true);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  // Preview Receipt
  const handleToggleRatesAndDealsReviewVoucherPopup = () => {
    if (addItemData.name !== "" && addItemData.email !== "") {
      setShowRatesAndDealsReviewVoucherPopup(
        !showRatesAndDealsReviewVoucherPopup
      );
    } else {
      showErrorToast("Please Enter the above fields...!");
    }
  };

  const [isDraftChecked, setIsDraftChecked] = useState(false);

  const handleDraftCheckboxChange = () => {
    setIsDraftChecked((prevIsDraftChecked) => !prevIsDraftChecked);

    const filteredOrders = !isDraftChecked
      ? orders.filter((order) => order.status === "draft")
      : allOrders;

    setOrders(filteredOrders);
    setCurrentPage(orders);
  };

  const div = <div>Hello</div>;

  const [dynamicInputList, setDynamicInputList] = useState([
    {
      id: 0,
      room_category_id: "",
      meal_plan_array: [],
      selectedMealPlans: [],
    },
  ]);

  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setSelectedMealPlan("");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleBookingStatus = useCallback(
    (value) => setSelectedBookingStatus(value),
    []
  );

  const handleAddClick = (id) => {
    setSelectedMealPlan("");
    const lastItem = dynamicInputList[dynamicInputList.length - 1];
    if (
      (lastItem && lastItem.room_category_id === "") ||
      lastItem.selectedMealPlans.length < 1
    ) {
      showErrorToast("Please add the above fields first...!!");
    } else {
      setDynamicInputList([
        ...dynamicInputList,
        {
          id: id + 1,
          room_category_id: "",
          meal_plan_array: [],
          selectedMealPlans: [],
          selected: false,
        },
      ]);
    }
  };

  const handleRemoveClick = (idToRemove) => {
    const updatedList = dynamicInputList.filter(
      (item) => item.id !== idToRemove
    );
    setDynamicInputList(updatedList);
  };

  const dynamicPreviewBillHandleChange = (e, index) => {
    const { name, value } = e.target;

    const isDuplicate = dynamicInputList.some(
      (item, i) =>
        i !== index &&
        item.room_category_id === value &&
        name === "room_category_id"
    );

    if (isDuplicate) {
      showErrorToast("Duplicate entry.");
      return;
    }

    setDynamicInputList((prevInputList) => {
      const updatedInputList = [...prevInputList];
      updatedInputList[index][name] = value;
      return updatedInputList;
    });
  };

  const [platesCost, setPlatesCost] = useState(null);

  useEffect(() => {
    const FoodCost = addItemData.no_of_plates * addItemData.per_plate_cost;
    setPlatesCost(FoodCost);
  }, [addItemData.no_of_plates, addItemData.per_plate_cost]);

  // calculate the total addon cost
  const [totalAddonCost, setTotalAddonCost] = useState(null);
  useEffect(() => {
    const totalAddonCost = dynamicInputList.reduce(
      (prev, curr, index, array) => +prev + +curr.meal_plan_array,
      0
    );
    setTotalAddonCost(totalAddonCost, "totalAddonCost");
  }, [dynamicInputList]);

  const [iframeSrc, setIframeSrc] = useState("");
  const [voucherTitle, setVoucherTitle] = useState("");

  const handleSubmitPreview = async (event, statuses, vouchertitle) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/preview/template/${
          property_id?.id
        }?communication_type=${communicationType}&communication_on=${communicationOn}&template=${
          addItemData.template
        }&status=${statuses}&booking_event_id=${
          eventDetails.id || bookingDetails.id
        }&from_date=${selectedDatesRange.from_date}&end_date=${
          selectedDatesRange.to_date
        }&name=${addItemData.name}&customer_email=${
          addItemData.email
        }&additional_notes=${addItemData.additional_notes}&email_sub=${
          addItemData.email_sub
        }&extra_adult&extra_child&extra_bed&no_of_rooms&discount&email=${
          addItemData.email
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (data.success && data.html) {
        setVoucherTitle(vouchertitle);
        setIframeSrc(
          `data:text/html;charset=utf-8,${encodeURIComponent(data.html)}`
        );
        setShowIframePopup(!showIframePopup);
        setLoader(false);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="communicaiton_lead_container">
        <SidebarInnerListWithMultiplePermissions
          innerLists={communication_inner_list}
          index="20"
        />
      </div>

      <div>
        <div className="mt-20 ">
          <div
            style={{ display: "flex", justifyContent: "end", margin: "10px" }}
          >
            {permission &&
            permission.includes("full_access_lead_management_system") ? (
              <Link to={`/communication/createmessage`}>
                <WithoutBgButtonBlue
                  style={{ fontSize: "14px", padding: "0" }}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_5311_4678)">
                        <path
                          d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                          stroke="#3968ED"
                        />
                        <path
                          d="M10.5868 7H3.41357"
                          stroke="#3968ED"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M7 3.4132L7 10.5864"
                          stroke="#3968ED"
                          stroke-miterlimit="10"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5311_4678">
                          <rect width="14" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  }
                  children={"Add"}
                  // onClick={() => setAddLeadsPopUp(!addLeadsPopUp)}
                />
              </Link>
            ) : (
              <Tooltip
              content="Please Contact Your Admin" 
              dismissOnMouseOut
              >
                 <WithoutBgButtonBlue
                 disabled
                  style={{ fontSize: "14px", padding: "0" }}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_5311_4678)">
                        <path
                          d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                          stroke="#3968ED"
                        />
                        <path
                          d="M10.5868 7H3.41357"
                          stroke="#3968ED"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M7 3.4132L7 10.5864"
                          stroke="#3968ED"
                          stroke-miterlimit="10"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5311_4678">
                          <rect width="14" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  }
                  children={"Add"}
                />
              </Tooltip>
            )}
          </div>
          <div style={{ display: "flex", gap: "15px" }}>
            <div className="booking-choice-full-parent-container-left">
              {/* <div
                className="booking-choice-full-parent-container-left-heading"
                style={{ marginBottom: "15px" }}
              >
                Leads
              </div> */}
              <div className="booking-choice-full-container">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <ComunicationStatusFilter
                    title={"Communication of"}
                    bookingFIlterData={bookingDateFilters}
                    getSelectedBookingFiltersFromChild={
                      getSelectedBookingStatusFiltersFromChild
                    }
                    clearAllFilters={clearAllFilters}
                  />
                </div>
                <div className="booking-choice-full-container-div">
                  <CommunicationCustomerTypeFilter
                    title={"Customer Type"}
                    bookingFIlterData={customerTypeFilters}
                    getSelectedBookingFiltersFromChild={
                      getSelectedCustomerStatusFiltersFromChild
                    }
                    clearAllFilters={clearAllFilters}
                  />
                </div>

                <div></div>
              </div>
            </div>
            <div className="booking-choice-full-parent-container-right">
              <div className="booking-choice-full-parent-container-right-heading-new">
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#EFF0F2",
                  }}
                >
                  <div
                    className="reservation_bar_container"
                    style={{ width: "100%" }}
                  >
                    <div>
                      <CommunicationCustomDatePicker
                        onSelectedRangeChange={handleSelectedRangeChange}
                        onApplyButtonClick={handleApplyButtonClick}
                        onDateRangeSelected={handleDateRangeSelected}
                        customStyle={customStyle}
                        sendDataToParent={handleOpenDatePickerData}
                      />
                    </div>

                    {/* popup  */}
                    {/* {showHoldPopup && (
            <>
              <div
                className="createBookingTableNewPopupContainer"
                onClick={() => setShowHoldPopup(false)}
              >
                <div
                  className="createBookingTableNewPopupContent"
                  onClick={(e) => e.stopPropagation()}
                  style={{ width: "400px" }}
                >
                  <h3 style={{ textAlign: "center" }}>Hold Date & Time</h3>


                  <div className="createBookingTableNewPopupFooter">
                    <BgThemeButton
                      // type={"submit"}
                      children={"Assign"}
                      style={{ background: "#BD941B", width: "48%" }}
                      // onClick={handleAssignButtonClick}
                    />
                    <BgOutlineThemeButton
                      //  type={"submit"}
                      children={"Discard"}
                      onClick={() => setShowHoldPopup(false)}
                      style={{ width: "48%" }}
                    />
                  </div>
                </div>
              </div>
            </>
          )} */}

                    {/* {dateRangeShowCustom && (
                    <div
                      style={{
                        position: "absolute",
                        top: "20px",
                        zIndex: "999",
                      }}
                    >
                      <SelectDateRangeWithoutInput
                        dataFromChild={dataFromChild}
                      />
                    </div>
                  )} */}

                    <div
                      className="reservation_total "
                      style={{ padding: "10px 15px 10px 0px" }}
                    >
                      <h5 className="reservation_total_heading">
                        {/* {bookingOuterDetails?.total_reservations} */}
                      </h5>
                      <p
                        className="reservation_total_paragraph"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span
                          className="reservation_total_leads"
                          style={{
                            color: "#333",
                            textAlign: "center",
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                          }}
                        >
                          {" "}
                          {allOrders?.total || 0}
                        </span>
                        Total Messages Sent
                      </p>
                    </div>
                    <div
                      className="reservation_total "
                      style={{ padding: "10px 15px 10px 0px" }}
                    >
                      <h5 className="reservation_total_heading ">
                        {/* {bookingOuterDetails?.total_reservations} */}
                      </h5>
                      <p
                        className="reservation_total_paragraph"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span
                          className="reservation_total_leads"
                          style={{
                            color: "#333",
                            textAlign: "center",
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                          }}
                        >
                          {" "}
                          {allOrders?.draft || 0}
                        </span>
                        Draft
                      </p>
                    </div>
                    {/* <div className="reservation_total confirmed">
                      <h5 className="reservation_total_heading">
                        {bookingOuterDetails?.checkin_bookings}
                      </h5>
                      <p className="reservation_total_paragraph">
                        {" "}
                        <span className="reservation_total_leads">
                          {" "}
                          {allLeads.converted}
                        </span>
                        Converted
                      </p>
                    </div>
                    <div className="reservation_total confirmed">
                      <h5 className="reservation_total_heading">
                        {bookingOuterDetails?.checkout_bookings}
                      </h5>
                      <p className="reservation_total_paragraph">
                        {" "}
                        <span className="reservation_total_leads">
                          {" "}
                          {allLeads.dropped}
                        </span>
                        Dropped
                      </p>
                    </div> */}

                    <div
                      className="reservation_downloadImage"
                      style={{ marginRight: "0" }}
                    >
                      {/* <CommunicationSearchInput
  onKeyDown={handleKeyDown}
  sendDataToParent={handleChildData}
  setKotID={setSearchName}
  clearFunction={() => {
    setSearchName("");
    fetchInfo(property_id?.id, selectedDatesRange.from_date, selectedDatesRange.to_date, "");
  }}
  placeholder="Customer/company"
/> */}

                      <ItemIssueSearch
                        onKeyDown={handleKeyDown}
                        sendDataToParent={handleChildData}
                        setKotID={setSearchName}
                        clearFunction={(data) => fetchInfoClearFunction(data)}
                        placeholder="Customer/Company"
                      />
                    </div>

                    <div className="communicaition_pagination">
                      <CustomPaginationArrows
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        apiUrl={""}
                      />
                    </div>

                    {/* <div className="communicaition_pagination">
                      <CustomPaginationArrows
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        apiUrl={""}
                      />
                    </div> */}

                    {/* <div className="reservation_downloadImage">
                    <CustomPaginationArrows
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                      apiUrl={""}
                    />
                    </div> */}
                    {/* <button className="reservation_downloadImage_button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                          >
                            <path
                              d="M15.0001 18.6429L10.4465 14.0894L11.7215 12.7688L14.0894 15.1367V7.71436H15.9108V15.1367L18.2786 12.7688L19.5536 14.0894L15.0001 18.6429ZM9.53579 22.2858C9.03489 22.2858 8.60595 22.1073 8.24895 21.7503C7.89195 21.3933 7.71375 20.9646 7.71436 20.4644V17.7322H9.53579V20.4644H20.4644V17.7322H22.2858V20.4644C22.2858 20.9652 22.1073 21.3942 21.7503 21.7512C21.3933 22.1082 20.9646 22.2864 20.4644 22.2858H9.53579Z"
                              fill="#888888"
                            />
                            <rect
                              x="0.25"
                              y="0.25"
                              width="29.5"
                              height="29.5"
                              rx="3.75"
                              stroke="#CCCCCC"
                              strokeWidth="0.5"
                            />
                          </svg>
                        </button> */}
                  </div>
                </div>
              </div>

              <>
                <CustomCheckTable
                  allItems={allItems}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  showCheckbox={false}
                  datePickerOpened={datepickerOpened}
                  headings={[
                    "Communication",
                    "Customer/Company",
                    "Phone/Email",
                    "Customer Type",
                    "Sent/Drafted by",
                    "Status",
                    "",
                  ]}
                  loading={loader}
                >
                  {allOrders && allOrders.send_list.data.length > 0 ? (
                    allItems.map((order) => {
                      const {
                        id,
                        communication_type,
                        name,
                        status,
                        send_by,
                        email,
                        created_at,
                        guest,
                        sender_type,
                        unique_booking_id,
                      } = order;
                      const firstGuestName =
                        guest && guest.length > 0 ? guest[0].name : "NA";
                      const remainingGuests =
                        guest && guest.length > 1 ? guest.slice(1) : [];
                      const firstGuestEmail =
                        guest && guest.length > 0 ? guest[0].email : "NA";
                      const firstGuestPhone =
                        guest && guest.length > 0 ? guest[0].phone : "NA";

                      return (
                        <CustomTableCheckRow
                          id={id}
                          key={id}
                          selectedItems={selectedItems}
                          setSelectedItems={setSelectedItems}
                          showCheckbox={false}
                        >
                          <CustomCheckTableCell>
                            <div
                              style={{ fontSize: "12px", fontWeight: "500" }}
                            >
                              {<UnderscoreRemoveCapitalize />}
                            </div>
                            <div
                              style={{ fontSize: "11px", fontWeight: "500" }}
                            >
                              <p>
                                <BookingStatusUpdate
                                  status={communication_type}
                                />
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  fontSize: "10px",
                                }}
                              >
                                <div>Sent on: </div>
                                <div>
                                  <TextDateFormatter
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: "500",
                                    }}
                                    as={`div`}
                                    date={created_at.slice(0, 10)}
                                    showYear={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </CustomCheckTableCell>

                          <CustomCheckTableCell>
                            <div
                              style={{ fontSize: "12px", fontWeight: "500" }}
                            >
                              {firstGuestName}
                              {remainingGuests.length > 0 && (
                                <CustomTooltip
                                  content={
                                    <div>
                                      {remainingGuests.map((g) => (
                                        <div
                                          style={{ fontSize: "12px" }}
                                          key={g.id}
                                        >
                                          {g.name}
                                        </div>
                                      ))}
                                    </div>
                                  }
                                >
                                  <button
                                    style={{
                                      cursor: "pointer",
                                      border: "none",
                                      padding: "2px 4px",
                                      margin: "auto 7px",
                                      fontSize: "10px",
                                      borderRadius: "4px",
                                      color: "#000",
                                      backgroundColor: "rgb(225 226 229)",
                                    }}
                                  >
                                    +{remainingGuests.length}
                                  </button>
                                </CustomTooltip>
                              )}
                            </div>
                          </CustomCheckTableCell>

                          <CustomCheckTableCell>
                            <div
                              style={{ fontSize: "12px", fontWeight: "500" }}
                            >
                              {firstGuestEmail}
                              {remainingGuests.length > 0 && (
                                <CustomTooltip
                                  content={
                                    <div>
                                      {remainingGuests.map((g) => (
                                        <div
                                          style={{ fontSize: "12px" }}
                                          key={g.id}
                                        >
                                          {g.email}
                                        </div>
                                      ))}
                                    </div>
                                  }
                                >
                                  <button
                                    style={{
                                      cursor: "pointer",
                                      border: "none",
                                      padding: "2px 4px",
                                      margin: "auto 7px",
                                      fontSize: "10px",
                                      borderRadius: "4px",
                                      color: "#000",
                                      backgroundColor: "rgb(225 226 229)",
                                    }}
                                  >
                                    +{remainingGuests.length}
                                  </button>
                                </CustomTooltip>
                              )}

                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  fontSize: "10px",
                                }}
                              >
                                <div>
                                  {" "}
                                  {firstGuestPhone}
                                  {remainingGuests.length > 0 && (
                                    <CustomTooltip
                                      preferredPosition="below"
                                      content={
                                        <div>
                                          {remainingGuests.map((g) => (
                                            <div
                                              style={{ fontSize: "12px" }}
                                              key={g.id}
                                            >
                                              {g.phone}
                                            </div>
                                          ))}
                                        </div>
                                      }
                                    >
                                      <button
                                        style={{
                                          cursor: "pointer",
                                          border: "none",
                                          padding: "2px 4px",
                                          margin: "auto 7px",
                                          fontSize: "10px",
                                          borderRadius: "4px",
                                          color: "#000",
                                          backgroundColor: "rgb(225 226 229)",
                                        }}
                                      >
                                        +{remainingGuests.length}
                                      </button>
                                    </CustomTooltip>
                                  )}{" "}
                                </div>
                              </div>
                            </div>
                          </CustomCheckTableCell>

                          <CustomCheckTableCell>
                            <div
                              style={{ fontSize: "12px", fontWeight: "500" }}
                            >
                              {sender_type
                                ? sender_type === "customer"
                                  ? "Existing Customer"
                                  : sender_type
                                      .replace(/_/g, " ")
                                      .replace(/\b\w/g, (c) => c.toUpperCase())
                                : "NA"}
                            </div>
                          </CustomCheckTableCell>

                          <CustomCheckTableCell>
                            <div
                              style={{ fontSize: "12px", fontWeight: "500" }}
                            >
                              {send_by ? send_by : "NA"}
                            </div>
                            <TextDateFormatter
                              style={{ fontSize: "10px", fontWeight: "500" }}
                              as={`div`}
                              date={created_at.slice(0, 10)}
                              showYear={true}
                            />
                          </CustomCheckTableCell>

                          <CustomCheckTableCell>
                            {status === "sent" && (
                              <StatusUpdate status={status}></StatusUpdate>
                            )}
                            {status === "draft" && (
                              <CommunicationDraftStatus
                                status={status}
                                messageId={id}
                                communication_type
                                fetchInfo={fetchInfo}
                                clearSelection={clearSelection}
                              />
                            )}
                          </CustomCheckTableCell>

                          <CustomCheckTableCell>
                            {status === "sent" ? (
                              <>
                                {communication_type === "voucher" ||
                                communication_type === "bill" ||
                                communication_type === "payment-receipt" ? (
                                  <div
                                    onClick={(e) =>
                                      handleCommunicationSubmitPreviewVoucher(
                                        e,
                                        communication_type,
                                        unique_booking_id
                                      )
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="17"
                                      height="17"
                                      viewBox="0 0 12 10"
                                      fill="none"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M11.0673 4.80967C9.9757 2.07317 7.87646 0.826174 5.77812 0.935011C3.70396 1.04259 1.74696 2.47251 0.91742 4.82943L0.857422 4.9999L0.91742 5.17037C1.74696 7.52729 3.70396 8.9572 5.77812 9.06478C7.87646 9.17362 9.9757 7.92662 11.0673 5.19012L11.1431 4.9999L11.0673 4.80967ZM5.99609 7.56089C7.40656 7.56089 8.54996 6.41429 8.54996 4.9999C8.54996 3.5855 7.40656 2.43891 5.99609 2.43891C4.58563 2.43891 3.44222 3.5855 3.44222 4.9999C3.44222 6.41429 4.58563 7.56089 5.99609 7.56089Z"
                                        fill="#3968ED"
                                      ></path>
                                      <path
                                        d="M7.52842 4.9999C7.52842 5.84853 6.84237 6.53649 5.99609 6.53649C5.14981 6.53649 4.46377 5.84853 4.46377 4.9999C4.46377 4.15126 5.14981 3.4633 5.99609 3.4633C6.84237 3.4633 7.52842 4.15126 7.52842 4.9999Z"
                                        fill="#3968ED"
                                      ></path>
                                    </svg>
                                  </div>
                                ) : communication_type === "payment_link" ? (
                                  <div
                                    onClick={(e) =>
                                      handleSubmitPreviewVoucher(
                                        e,
                                        id,
                                        "iframe"
                                      )
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="17"
                                      height="17"
                                      viewBox="0 0 12 10"
                                      fill="none"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M11.0673 4.80967C9.9757 2.07317 7.87646 0.826174 5.77812 0.935011C3.70396 1.04259 1.74696 2.47251 0.91742 4.82943L0.857422 4.9999L0.91742 5.17037C1.74696 7.52729 3.70396 8.9572 5.77812 9.06478C7.87646 9.17362 9.9757 7.92662 11.0673 5.19012L11.1431 4.9999L11.0673 4.80967ZM5.99609 7.56089C7.40656 7.56089 8.54996 6.41429 8.54996 4.9999C8.54996 3.5855 7.40656 2.43891 5.99609 2.43891C4.58563 2.43891 3.44222 3.5855 3.44222 4.9999C3.44222 6.41429 4.58563 7.56089 5.99609 7.56089Z"
                                        fill="#3968ED"
                                      ></path>
                                      <path
                                        d="M7.52842 4.9999C7.52842 5.84853 6.84237 6.53649 5.99609 6.53649C5.14981 6.53649 4.46377 5.84853 4.46377 4.9999C4.46377 4.15126 5.14981 3.4633 5.99609 3.4633C6.84237 3.4633 7.52842 4.15126 7.52842 4.9999Z"
                                        fill="#3968ED"
                                      ></path>
                                    </svg>
                                  </div>
                                ) : (
                                  <div
                                    onClick={(e) =>
                                      handleSubmitPreviewVoucher(
                                        e,
                                        id,
                                        "images"
                                      )
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="17"
                                      height="17"
                                      viewBox="0 0 12 10"
                                      fill="none"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M11.0673 4.80967C9.9757 2.07317 7.87646 0.826174 5.77812 0.935011C3.70396 1.04259 1.74696 2.47251 0.91742 4.82943L0.857422 4.9999L0.91742 5.17037C1.74696 7.52729 3.70396 8.9572 5.77812 9.06478C7.87646 9.17362 9.9757 7.92662 11.0673 5.19012L11.1431 4.9999L11.0673 4.80967ZM5.99609 7.56089C7.40656 7.56089 8.54996 6.41429 8.54996 4.9999C8.54996 3.5855 7.40656 2.43891 5.99609 2.43891C4.58563 2.43891 3.44222 3.5855 3.44222 4.9999C3.44222 6.41429 4.58563 7.56089 5.99609 7.56089Z"
                                        fill="#3968ED"
                                      ></path>
                                      <path
                                        d="M7.52842 4.9999C7.52842 5.84853 6.84237 6.53649 5.99609 6.53649C5.14981 6.53649 4.46377 5.84853 4.46377 4.9999C4.46377 4.15126 5.14981 3.4633 5.99609 3.4633C6.84237 3.4633 7.52842 4.15126 7.52842 4.9999Z"
                                        fill="#3968ED"
                                      ></path>
                                    </svg>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {unique_booking_id ? (
                                  <Link
                                    to={`/leads/communicationUpdatemessage/${id}/${unique_booking_id}`}
                                  >
                                    <div
                                      onClick={() => setShowHoldPopup(true)}
                                      className="booking-third-row-one-full-div-child"
                                      style={{
                                        width: "50px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17"
                                        height="17"
                                        viewBox="0 0 12 14"
                                        fill="none"
                                      >
                                        <path
                                          d="M0.293176 0.132132C0.522048 -0.0314557 0.827164 -0.044187 1.06908 0.0997561L11.6573 6.39975C11.8699 6.52626 12 6.75412 12 7C12 7.24588 11.8699 7.47374 11.6573 7.60025L1.06908 13.9002C0.827164 14.0442 0.522048 14.0315 0.293176 13.8679C0.0643041 13.7043 -0.0443613 13.4213 0.0168402 13.1481L1.23771 7.7L4.94704 7.7C5.33364 7.7 5.64704 7.3866 5.64704 7C5.64704 6.6134 5.33364 6.3 4.94704 6.3L1.23771 6.3L0.0168402 0.851852C-0.0443613 0.57874 0.0643041 0.295719 0.293176 0.132132Z"
                                          fill="#3968ED"
                                        />
                                      </svg>
                                    </div>
                                  </Link>
                                ) : (
                                  <Link
                                    to={`/leads/communicationUpdatemessage/${id}`}
                                  >
                                    <div
                                      onClick={() => setShowHoldPopup(true)}
                                      className="booking-third-row-one-full-div-child"
                                      style={{
                                        width: "50px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17"
                                        height="17"
                                        viewBox="0 0 12 14"
                                        fill="none"
                                      >
                                        <path
                                          d="M0.293176 0.132132C0.522048 -0.0314557 0.827164 -0.044187 1.06908 0.0997561L11.6573 6.39975C11.8699 6.52626 12 6.75412 12 7C12 7.24588 11.8699 7.47374 11.6573 7.60025L1.06908 13.9002C0.827164 14.0442 0.522048 14.0315 0.293176 13.8679C0.0643041 13.7043 -0.0443613 13.4213 0.0168402 13.1481L1.23771 7.7L4.94704 7.7C5.33364 7.7 5.64704 7.3866 5.64704 7C5.64704 6.6134 5.33364 6.3 4.94704 6.3L1.23771 6.3L0.0168402 0.851852C-0.0443613 0.57874 0.0643041 0.295719 0.293176 0.132132Z"
                                          fill="#3968ED"
                                        />
                                      </svg>
                                    </div>
                                  </Link>
                                )}
                              </>
                            )}
                          </CustomCheckTableCell>
                        </CustomTableCheckRow>
                      );
                    })
                  ) : (
                    <div>
                      <NoDataFound />
                    </div>
                  )}
                </CustomCheckTable>
              </>
            </div>

            {/* Add Button Click */}
            {showAddModelMenuActive && (
              <>
                <div className="bg-backdrop">
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    style={{ width: "400px" }}
                    className="stock-add-model-container"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "15px",
                      }}
                    >
                      <div className="add-ota-model-container-heading">
                        Create Message
                      </div>
                      <div
                        onClick={() => {
                          setShowAddModelMenuActive(false);
                          setCommunicationDetails(false);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon source={CancelMajor} color="base" />
                      </div>
                    </div>

                    <div className="form-container-div-full">
                      <div
                        style={{
                          display: "flex",
                          gap: "14px",
                          color: "#333",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "400",
                          margin: "15px 0",
                          marginTop: "20px",
                        }}
                      >
                        Communication on:
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "13px",
                          color: "#333",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        <label
                          style={{ display: "flex", gap: "6px" }}
                          htmlFor="bookings"
                        >
                          <input
                            type="radio"
                            name="bookings"
                            value="bookings"
                            onChange={handleCommunicationOnChange}
                            checked={communicationOn === "bookings"}
                          />
                          <div>Bookings</div>
                        </label>

                        <label style={{ display: "flex", gap: "6px" }}>
                          <input
                            type="radio"
                            name="events"
                            value="events"
                            onChange={handleCommunicationOnChange}
                            checked={communicationOn === "events"}
                          />
                          <div>Events</div>
                        </label>

                        <label
                          style={{ display: "flex", gap: "6px" }}
                          htmlFor="rate_and_deals"
                        >
                          <input
                            type="radio"
                            name="rate_and_deals"
                            value="rate_and_deals"
                            onChange={handleCommunicationOnChange}
                            checked={communicationOn === "rate_and_deals"}
                          />
                          <div> Rates and Deals</div>
                        </label>
                      </div>
                    </div>

                    <div className="form-container-div-full">
                      <div
                        style={{
                          display: "flex",
                          gap: "14px",
                          color: "#333",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "400",
                          margin: "15px 0",
                          marginTop: "20px",
                        }}
                      >
                        Communication Type:
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "13px",
                          color: "#333",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        <label style={{ display: "flex", gap: "6px" }}>
                          <input
                            type="radio"
                            name="email"
                            value="email"
                            onChange={handleCommunicationTypeChange}
                            checked={communicationType === "email"}
                          />
                          <div>Email</div>
                        </label>
                        {/* <label style={{ display: "flex", gap: "6px" }}>
                        <input
                          type="radio"
                          name="whatsapp"
                          value="whatsapp"
                          onChange={handleCommunicationTypeChange}
                          checked={communicationType === "whatsapp"}
                        />
                        <div>Whatsapp</div>
                      </label>
                      <label style={{ display: "flex", gap: "6px" }}>
                        <input
                          type="radio"
                          name="both"
                          value="both"
                          onChange={handleCommunicationTypeChange}
                          checked={communicationType === "both"}
                        />
                        <div>Both</div>
                      </label> */}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        style={{ width: "100%" }}
                        onClick={handleCreateMessageFirstClick}
                        type="submit"
                        children={"Next"}
                      />
                    </div>
                  </form>
                </div>
              </>
            )}
            {/* Edit Button Click */}
            {/* {showAddModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "400px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Create Message
                    </div>
                    <div
                      onClick={() => {
                        setShowAddModelMenuActive(false);
                        setCommunicationDetails(false);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-full">
                    <div
                      style={{
                        display: "flex",
                        gap: "14px",
                        color: "#333",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "400",
                        margin: "15px 0",
                        marginTop: "20px",
                      }}
                    >
                      Communication on:
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "13px",
                        color: "#333",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      <label
                        style={{ display: "flex", gap: "6px" }}
                        htmlFor="bookings"
                      >
                        <input
                          type="radio"
                          name="bookings"
                          value="bookings"
                          onChange={handleCommunicationOnChange} 
                          checked={communicationOn === "bookings"}
                        />
                        <div>Bookings</div>
                      </label>

                      <label style={{ display: "flex", gap: "6px" }}>
                        <input
                          type="radio"
                          name="events"
                          value="events"
                          onChange={handleCommunicationOnChange}
                          checked={communicationOn === "events"}
                        />
                        <div>Events</div>
                      </label>

                      <label
                        style={{ display: "flex", gap: "6px" }}
                        htmlFor="rate_and_deals"
                      >
                        <input
                          type="radio"
                          name="rate_and_deals"
                          value="rate_and_deals"
                          onChange={handleCommunicationOnChange}
                          checked={communicationOn === "rate_and_deals"}
                        />
                        <div> Rates and Deals</div>
                      </label>
                    </div>
                  </div>

                  <div className="form-container-div-full">
                    <div
                      style={{
                        display: "flex",
                        gap: "14px",
                        color: "#333",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "400",
                        margin: "15px 0",
                        marginTop: "20px",
                      }}
                    >
                      Communication Type:
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "13px",
                        color: "#333",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      <label style={{ display: "flex", gap: "6px" }}>
                        <input
                          type="radio"
                          name="email"
                          value="email"
                          onChange={handleCommunicationTypeChange}
                          checked={communicationType === "email"}
                        />
                        <div>Email</div>
                      </label>
                    
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton
                      style={{ width: "100%" }}
                      onClick={handleCreateMessageFirstClick}
                      type="submit"
                      children={"Next"}
                    />
                  </div>
                </form>
              </div>
            </>
          )} */}

            {/* For Create Communication first click */}
            {communicationDetails &&
              communicationOn === "bookings" &&
              communicationType !== "" && (
                <>
                  <div className="bg-backdrop">
                    <form
                      onSubmit={submitBookingData}
                      style={{ width: "400px" }}
                      className="stock-add-model-container"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "15px",
                        }}
                      >
                        <div className="add-ota-model-container-heading">
                          <div
                            onClick={() => {
                              setCommunicationDetails(false);
                              setShowAddModelMenuActive(true);
                            }}
                            style={{
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                            >
                              <rect
                                x="29.75"
                                y="29.75"
                                width="29.5"
                                height="29.5"
                                rx="5.75"
                                transform="rotate(180 29.75 29.75)"
                                stroke="#666666"
                                strokeWidth="0.5"
                              />
                              <line
                                x1="21"
                                y1="15.043"
                                x2="9"
                                y2="15.043"
                                stroke="#666666"
                              />
                              <path
                                d="M13.6287 19.2578L9.00009 14.6292L13.6287 10.0007"
                                stroke="#666666"
                                strokeWidth="1.2"
                              />
                            </svg>
                          </div>
                          Create Message
                        </div>
                        <div
                          onClick={() => setCommunicationDetails(false)}
                          style={{ cursor: "pointer" }}
                        >
                          <Icon source={CancelMajor} color="base" />
                        </div>
                      </div>

                      <div className="form-container-div-full">
                        <VerticalInputFieldCustom
                          disabled={update === "edit" ? true : false}
                          titleName={"Enter Booking ID:"}
                          placeholder={"Enter Booking ID"}
                          onChange={(e) => onKeyPressHandler(e)}
                          value={searchName}
                          required
                        />
                      </div>

                      {showBookingDetails && (
                        <>
                          <div className="booking_details_child">
                            {bookingDetails && bookingDetails !== null && (
                              <>
                                <div className="booking_details_child_main">
                                  <div
                                    className="booking_details_child_heading"
                                    onClick={() => toggleAccordion("first")}
                                  >
                                    <h5>
                                      <strong>General Info</strong>
                                    </h5>
                                    {openAccordion === "first" ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="7"
                                        viewBox="0 0 10 7"
                                        fill="none"
                                      >
                                        <path
                                          d="M9 6L5 2L1 6"
                                          stroke="#333333"
                                          strokeWidth="1.5"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="7"
                                        viewBox="0 0 10 7"
                                        fill="none"
                                      >
                                        <path
                                          d="M1 1L5 5L9 1"
                                          stroke="#333333"
                                          strokeWidth="1.5"
                                        />
                                      </svg>
                                    )}
                                  </div>

                                  {openAccordion === "first" && (
                                    <div className="booking_details_child_list">
                                      <li className="booking_list_view">
                                        <h4>Customer Name</h4>
                                        <h5>
                                          {bookingDetails &&
                                            bookingDetails?.guest[0]
                                              ?.guest_name}
                                        </h5>
                                      </li>
                                      <li className="booking_list_view">
                                        <h4>Phone Number</h4>
                                        <h5>
                                          {(bookingDetails &&
                                            bookingDetails?.guest[0]
                                              ?.guest_phone) ||
                                            ""}
                                        </h5>
                                      </li>
                                      <li className="booking_list_view">
                                        <h4>Email</h4>
                                        <h5>
                                          {(bookingDetails &&
                                            bookingDetails?.guest[0]
                                              ?.guest_email) ||
                                            ""}
                                        </h5>
                                      </li>
                                      <li className="booking_list_view">
                                        <h4>Check-In Date</h4>
                                        <h5>
                                          {(bookingDetails &&
                                            bookingDetails?.check_in) ||
                                            ""}
                                        </h5>
                                      </li>
                                      {/* <li className="booking_list_view">
                      <h4>No of Nights</h4>
                      <h5>jk</h5>
                    </li> */}
                                      <li className="booking_list_view">
                                        <h4>Booking Source</h4>
                                        <h5>
                                          {(bookingDetails &&
                                            bookingDetails?.booking_source) ||
                                            ""}
                                        </h5>
                                      </li>
                                      <li className="booking_list_view">
                                        <h4>Booking By</h4>
                                        <h5>
                                          {(bookingDetails &&
                                            bookingDetails?.booked_by) ||
                                            ""}
                                        </h5>
                                      </li>
                                      <li className="booking_list_view">
                                        <h4>Booking Status</h4>
                                        <h5>
                                          <span>
                                            {(bookingDetails &&
                                              bookingDetails?.status) ||
                                              ""}
                                          </span>
                                        </h5>
                                      </li>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          <BgThemeButton
                            style={{ width: "100%" }}
                            onClick={handleBookingDetailNextTemplateShowClick}
                            type="button"
                            children={"Next"}
                          />
                        </>
                      )}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      ></div>
                    </form>
                  </div>
                </>
              )}

            {/* For Create Communication first click */}
            {communicationDetails &&
              communicationOn === "events" &&
              communicationType !== "" && (
                <>
                  <div className="bg-backdrop">
                    <form
                      onSubmit={submitEventData}
                      style={{ width: "400px" }}
                      className="stock-add-model-container"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "15px",
                        }}
                      >
                        <div className="add-ota-model-container-heading">
                          <div
                            onClick={() => {
                              setCommunicationDetails(false);
                              setShowAddModelMenuActive(true);
                              setEventDetails({});
                              setEventId("");
                            }}
                            style={{
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                            >
                              <rect
                                x="29.75"
                                y="29.75"
                                width="29.5"
                                height="29.5"
                                rx="5.75"
                                transform="rotate(180 29.75 29.75)"
                                stroke="#666666"
                                strokeWidth="0.5"
                              />
                              <line
                                x1="21"
                                y1="15.043"
                                x2="9"
                                y2="15.043"
                                stroke="#666666"
                              />
                              <path
                                d="M13.6287 19.2578L9.00009 14.6292L13.6287 10.0007"
                                stroke="#666666"
                                strokeWidth="1.2"
                              />
                            </svg>
                          </div>
                          Create Message
                        </div>
                        <div
                          onClick={() => setCommunicationDetails(false)}
                          style={{ cursor: "pointer" }}
                        >
                          <Icon source={CancelMajor} color="base" />
                        </div>
                      </div>

                      <div className="form-container-div-full">
                        <VerticalInputFieldCustom
                          disabled={update === "edit" ? true : false}
                          titleName={"Enter Event ID:"}
                          placeholder={"Enter Event ID"}
                          onChange={(e) => setEventId(e.target.value)}
                          value={eventId}
                          required
                        />
                      </div>

                      {showEventDetails && (
                        <>
                          <div className="booking_details_child">
                            {showEventDetails && showEventDetails !== null && (
                              <>
                                <div
                                  onClick={() => toggleAccordion("first")}
                                  className="booking_details_child_main"
                                >
                                  <div className="booking_details_child_heading">
                                    <h5>
                                      <strong>General Info</strong>
                                    </h5>
                                    {openAccordion === "first" ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="7"
                                        viewBox="0 0 10 7"
                                        fill="none"
                                      >
                                        <path
                                          d="M9 6L5 2L1 6"
                                          stroke="#333333"
                                          strokeWidth="1.5"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="7"
                                        viewBox="0 0 10 7"
                                        fill="none"
                                      >
                                        <path
                                          d="M1 1L5 5L9 1"
                                          stroke="#333333"
                                          strokeWidth="1.5"
                                        />
                                      </svg>
                                    )}
                                  </div>

                                  {openAccordion === "first" && (
                                    <div className="booking_details_child_list">
                                      <li className="booking_list_view">
                                        <h4>Customer Name</h4>
                                        <h5>
                                          {eventDetails &&
                                            eventDetails?.client_name}
                                        </h5>
                                      </li>
                                      <li className="booking_list_view">
                                        <h4>Phone Number</h4>
                                        <h5>
                                          {eventDetails &&
                                            eventDetails?.client_phone}
                                        </h5>
                                      </li>
                                      <li className="booking_list_view">
                                        <h4>Email</h4>
                                        <h5>
                                          {eventDetails &&
                                            eventDetails?.client_email}
                                        </h5>
                                      </li>
                                      <li className="booking_list_view">
                                        <h4>Event Name</h4>
                                        <h5>
                                          {eventDetails &&
                                            eventDetails?.event_name}
                                        </h5>
                                      </li>
                                      {/* <li className="booking_list_view">
                      <h4>No of Nights</h4>
                      <h5>jk</h5>
                    </li> */}
                                      {/* <li className="booking_list_view">
                                      <h4>Booking Source</h4>
                                      <h5>
                                        {(bookingDetails &&
                                          bookingDetails?.booking_source) ||
                                          ""}
                                      </h5>
                                    </li> */}
                                      {/* <li className="booking_list_view">
                                      <h4>Booking By</h4>
                                      <h5>
                                        {(bookingDetails &&
                                          bookingDetails?.booked_by) ||
                                          ""}
                                      </h5>
                                    </li> */}
                                      <li className="booking_list_view">
                                        <h4>Booking Status</h4>
                                        <h5>
                                          <span>
                                            {eventDetails &&
                                              eventDetails?.status}
                                          </span>
                                        </h5>
                                      </li>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          <BgThemeButton
                            style={{ width: "100%" }}
                            onClick={handleBookingDetailNextTemplateShowClick}
                            type="button"
                            children={"Next"}
                          />
                        </>
                      )}

                      {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        style={{ width: "100%" }}
                        onClick={handleBookingDetailNextTemplateShowClick}
                        type="button"
                        children={"Next"}
                      />
                    </div> */}
                    </form>
                  </div>
                </>
              )}

            {/* For Create Communication first click */}
            {communicationDetails &&
              communicationOn === "rate_and_deals" &&
              communicationType !== "" && (
                <>
                  <div className="bg-backdrop">
                    <form
                      onSubmit={(e) => e.preventDefault()}
                      style={{ width: "400px" }}
                      className="stock-add-model-container"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "15px",
                        }}
                      >
                        <div className="add-ota-model-container-heading">
                          <div
                            onClick={() => {
                              setCommunicationDetails(false);
                              setShowAddModelMenuActive(true);
                            }}
                            style={{
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                            >
                              <rect
                                x="29.75"
                                y="29.75"
                                width="29.5"
                                height="29.5"
                                rx="5.75"
                                transform="rotate(180 29.75 29.75)"
                                stroke="#666666"
                                strokeWidth="0.5"
                              />
                              <line
                                x1="21"
                                y1="15.043"
                                x2="9"
                                y2="15.043"
                                stroke="#666666"
                              />
                              <path
                                d="M13.6287 19.2578L9.00009 14.6292L13.6287 10.0007"
                                stroke="#666666"
                                strokeWidth="1.2"
                              />
                            </svg>
                          </div>
                          Create Message
                        </div>
                        <div
                          onClick={() => setCommunicationDetails(false)}
                          style={{ cursor: "pointer" }}
                        >
                          <Icon source={CancelMajor} color="base" />
                        </div>
                      </div>

                      <div className="form-container-div-full">
                        <VerticalInputFieldCustom
                          titleName={"Enter Name"}
                          required={true}
                          placeholder={"Enter Name"}
                          value={addItemData.name}
                          name={"name"}
                          onChange={setAddItemDataHandleChange}
                        />
                      </div>
                      {/* {communicationType === "emails" && ( */}
                      <div className="form-container-div-full">
                        <VerticalInputFieldCustom
                          titleName={"Enter Email"}
                          required={true}
                          placeholder={"Enter Email"}
                          value={addItemData.email}
                          name={"email"}
                          onChange={setAddItemDataHandleChange}
                        />
                      </div>
                      {/* )} */}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <BgThemeButton
                          style={{ width: "100%" }}
                          onClick={handleBookingDetailNextRoomCategoryShowClick}
                          type="submit"
                          children={"Next"}
                        />
                      </div>
                    </form>
                  </div>
                </>
              )}

            {/* Select Room Category */}
            {communicationRoomCategory &&
              communicationOn !== "" &&
              communicationType !== "" && (
                // <>
                //   <div className="bg-backdrop">
                //     <form
                //       onSubmit={(e) => e.preventDefault()}
                //       style={{ width: "500px", padding: '16px 10px' }}
                //       className="stock-add-model-container"
                //     >
                //       <div
                //         style={{
                //           display: "flex",
                //           justifyContent: "space-between",
                //           alignItems: "center",
                //           marginBottom: "10px",
                //         }}
                //       >
                //         <GoBackButtonCustom
                //           style={{ margin: '0' }}
                //           buttonNextText={`Create Message R`}
                //           onClick={() => [
                //             setCommunicationTemplateDetails(false),
                //             setCommunicationDetails(true),
                //             setCommunicationRoomCategory(false),
                //           ]}
                //         />

                //         <div
                //           onClick={() => setCommunicationRoomCategory(false)}
                //           style={{ cursor: "pointer" }}
                //         >
                //           <Icon source={CancelMajor} color="base" />
                //         </div>
                //       </div>

                //       <div className="form-container-div-full">
                //         <div>Select Date Range</div>
                //         <DateRangePicker
                //           selectedDatesRange={selectedDatesRange}
                //           onDateRangeChange={handleDateRangeChange}
                //         />
                //       </div>

                //       <div>
                //         <select
                //           name="room_category_id"
                //           style={{
                //             width: "100%",
                //             minHeight: "2.25rem",
                //             border: "1px solid #888888",
                //             borderRadius: "3px",
                //             marginBottom: "7px",
                //           }}
                //           value={setAddItemData.room_category_id}
                //           onChange={(event) =>
                //             fetchRoomRatePlansforRates(
                //               event.target.value,
                //             )
                //           }
                //         >
                //           {roomTypeOptions?.map((x, i) => {
                //             return (
                //               <>
                //                 <option
                //                   key={x.value}
                //                   value={x.value}
                //                 >
                //                   {x.label}
                //                 </option>
                //               </>
                //             );
                //           })}
                //         </select>
                //       </div>
                //     </form>
                //   </div>
                // </>

                // modal
                <>
                  <div className="bg-backdrop">
                    <form
                      onSubmit={(e) => e.preventDefault()}
                      style={{ width: "400px" }}
                      className="stock-add-model-container"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "15px",
                        }}
                      >
                        <div className="add-ota-model-container-heading">
                          <div
                            onClick={() => {
                              setCommunicationTemplateDetails(false);
                              setCommunicationDetails(false);
                              setCommunicationRoomCategory(false);
                            }}
                            style={{
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                            >
                              <rect
                                x="29.75"
                                y="29.75"
                                width="29.5"
                                height="29.5"
                                rx="5.75"
                                transform="rotate(180 29.75 29.75)"
                                stroke="#666666"
                                strokeWidth="0.5"
                              />
                              <line
                                x1="21"
                                y1="15.043"
                                x2="9"
                                y2="15.043"
                                stroke="#666666"
                              />
                              <path
                                d="M13.6287 19.2578L9.00009 14.6292L13.6287 10.0007"
                                stroke="#666666"
                                strokeWidth="1.2"
                              />
                            </svg>
                          </div>
                          Create Message
                        </div>
                        <div
                          onClick={() => setCommunicationRoomCategory(false)}
                          style={{ cursor: "pointer" }}
                        >
                          <Icon source={CancelMajor} color="base" />
                        </div>
                      </div>

                      <div className="form-container-div-full">
                        <div>Select Date Range</div>
                        <DateRangePicker
                          selectedDatesRange={selectedDatesRange}
                          onDateRangeChange={handleDateRangeChange}
                        />
                      </div>

                      <div className="form-container-div-half">
                        <table
                          ref={wrapperRef}
                          style={{
                            backgroundColor: "white",
                            padding: "0 10px 20px 0px",
                            borderCollapse: "collapse",
                            borderRadius: "10px",
                            fontFamily: "Inter",
                            width: "100%",
                          }}
                        >
                          <tr
                            style={{
                              textAlign: "left",
                            }}
                          >
                            <th style={{ padding: "5px 0", width: "60%" }}>
                              Select Room Category
                            </th>
                            <th style={{ padding: "0px" }}>
                              {dynamicInputList[0].meal_plan_array.length >
                                0 && <span>Meal Plan</span>}
                            </th>

                            <th
                              style={{
                                width: "10px",
                                padding: "10px",
                              }}
                            ></th>
                          </tr>

                          {dynamicInputList &&
                            dynamicInputList.map((bill, index) => {
                              return (
                                <tr>
                                  <>
                                    <td style={{ paddingRight: "10px" }}>
                                      <select
                                        name="room_category_id"
                                        style={{
                                          width: "100%",
                                          minHeight: "2.25rem",
                                          border: "1px solid #888888",
                                          borderRadius: "3px",
                                          marginBottom: "7px",
                                        }}
                                        value={
                                          dynamicInputList[index]
                                            ?.room_category_id
                                        }
                                        onChange={(event) =>
                                          fetchRoomRatePlans(
                                            event.target.value,
                                            bill.id,
                                            index
                                          )
                                        }
                                      >
                                        {roomTypeOptions?.map((x, i) => {
                                          return (
                                            <>
                                              <option
                                                key={x.value}
                                                value={x.value}
                                              >
                                                {x.label}
                                              </option>
                                            </>
                                          );
                                        })}
                                      </select>
                                    </td>

                                    {bill &&
                                      bill.meal_plan_array.length > 0 && (
                                        <td>
                                          <div>
                                            <div
                                              style={{
                                                border: "1px solid #888888",
                                                cursor: "pointer",
                                                position: "relative",
                                                height: "36px",
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "0 5px",
                                                fontSize: "13px",
                                                borderRadius: "3px",
                                                marginBottom: "7px",
                                                width: "107px",
                                              }}
                                            >
                                              <div
                                                onClick={() =>
                                                  handleMealPlanSelection(
                                                    bill.room_category_id
                                                  )
                                                }
                                              >
                                                {bill?.selectedMealPlans.length}{" "}
                                                of{" "}
                                                {bill?.meal_plan_array.length}{" "}
                                                selected
                                              </div>
                                              {selectedMealPlan ===
                                                bill.room_category_id && (
                                                <div
                                                  style={{
                                                    position: "absolute",
                                                    top: "36px",
                                                    background: "white",
                                                    width: "100%",
                                                    left: "0",
                                                    boxShadow:
                                                      "rgb(0 0 0 / 23%) 0px 0px 1px 1px",
                                                    padding: "2px",
                                                    zIndex: "21",
                                                  }}
                                                >
                                                  {bill.meal_plan_array.map(
                                                    (mealPlan) => (
                                                      <div
                                                        key={mealPlan.id}
                                                        style={{
                                                          display: "flex",
                                                          gap: "4px",
                                                        }}
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          id={mealPlan.id}
                                                          name="meal_plan"
                                                          checked={bill?.selectedMealPlans?.includes(
                                                            mealPlan.id
                                                          )}
                                                          onChange={() =>
                                                            handleCheckboxChange(
                                                              index,
                                                              mealPlan.id
                                                            )
                                                          }
                                                        />
                                                        <label
                                                          htmlFor={mealPlan.id}
                                                          style={{
                                                            display: "block",
                                                            width: "100%",
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          {
                                                            mealPlan.rate_plan_type
                                                          }
                                                        </label>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </td>
                                      )}

                                    <td
                                      style={{
                                        padding: "10px 5px",
                                        width: "10px",
                                      }}
                                    >
                                      {
                                        // dynamicInputList.length === index
                                        dynamicInputList.length > 1 ? (
                                          <div
                                            className="flex"
                                            style={{ gap: "10px" }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleAddClick(bill.id)
                                              }
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                              >
                                                <g clipPath="url(#clip0_1270_978)">
                                                  <path
                                                    d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                    stroke="#3968ED"
                                                  />
                                                  <path
                                                    d="M10.5866 7H3.41333"
                                                    stroke="#3968ED"
                                                    strokeMiterlimit="10"
                                                  />
                                                  <path
                                                    d="M7 3.41344L7 10.5867"
                                                    stroke="#3968ED"
                                                    strokeMiterlimit="10"
                                                  />
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_1270_978">
                                                    <rect
                                                      width="14"
                                                      height="14"
                                                      fill="white"
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </div>
                                            <div
                                              onClick={() =>
                                                handleRemoveClick(bill.id)
                                              }
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                              >
                                                <g clipPath="url(#clip0_1270_876)">
                                                  <path
                                                    d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                    stroke="#3968ED"
                                                  />
                                                  <path
                                                    d="M10.5866 7H3.41333"
                                                    stroke="#3968ED"
                                                    strokeMiterlimit="10"
                                                  />
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_1270_876">
                                                    <rect
                                                      width="14"
                                                      height="14"
                                                      fill="white"
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleAddClick(bill.id)
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="14"
                                              height="14"
                                              viewBox="0 0 14 14"
                                              fill="none"
                                            >
                                              <g clipPath="url(#clip0_1270_978)">
                                                <path
                                                  d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                  stroke="#3968ED"
                                                />
                                                <path
                                                  d="M10.5866 7H3.41333"
                                                  stroke="#3968ED"
                                                  strokeMiterlimit="10"
                                                />
                                                <path
                                                  d="M7 3.41344L7 10.5867"
                                                  stroke="#3968ED"
                                                  strokeMiterlimit="10"
                                                />
                                              </g>
                                              <defs>
                                                <clipPath id="clip0_1270_978">
                                                  <rect
                                                    width="14"
                                                    height="14"
                                                    fill="white"
                                                  />
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </div>
                                        )
                                      }
                                    </td>
                                  </>
                                </tr>
                              );
                            })}
                        </table>
                      </div>

                      <div className="form-container-div-half">
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            name="no_of_adult"
                            value={addItemData.no_of_adult}
                            focused={false}
                            titleName="No. of adult"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            name="child_with_bed"
                            focused={false}
                            titleName="Child with Bed"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                            value={addItemData.child_with_bed}
                          />
                        </div>
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            name="child_without_bed"
                            value={addItemData.child_without_bed}
                            focused={false}
                            titleName="Child w/o Bed"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="form-container-div-half">
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            name="extra_adult"
                            value={addItemData.extra_adult}
                            focused={false}
                            titleName="Extra Adult"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            name="no_of_rooms"
                            value={addItemData.no_of_rooms}
                            focused={false}
                            titleName="No. of Rooms"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            name="discount"
                            value={addItemData.discount}
                            focused={false}
                            titleName="Discount (%)"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      <div
                        className="form-container-div-full"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <BgOutlineThemeButton
                          style={{ width: "100%" }}
                          onClick={(e) => handleSubmitAddRooms(e, "draft")}
                          type="submit"
                          children={"Save as Draft"}
                        />
                      </div>

                      <div className="form-container-div-full">
                        <div className="flex flex_gap_10">
                          {communicationOn === "rate_and_deals" &&
                            addItemData.room_category_id !== "" &&
                            selectedDatesRange.from_date !== "" &&
                            selectedDatesRange.to_date !== "" && (
                              <BgOutlineThemeButton
                                onClick={handleSubmitRateAndDeals}
                                style={{
                                  fontSize: "14px",
                                  width: "100%",
                                }}
                                children={"Preview"}
                              />
                            )}

                          {update === "add" &&
                            communicationOn != "rate_and_deals" && (
                              <BgOutlineThemeButton
                                style={{ width: "100%" }}
                                onClick={(e) => handleSubmitAddRooms(e, "send")}
                                type="submit"
                                children={"Send"}
                              />
                            )}
                          {update === "edit" && (
                            <BgOutlineThemeButton
                              style={{ width: "100%" }}
                              onClick={(e) =>
                                updateFunction(getClickedEditId, "send")
                              }
                              type="submit"
                              children={"Send"}
                            />
                          )}

                          {communicationOn === "rate_and_deals" && (
                            <BgThemeButton
                              style={{ width: "100%" }}
                              onClick={(e) =>
                                handleSubmitRateAndDeal(e, "send")
                              }
                              type="submit"
                              children={"Send Rates and deals"}
                            />
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}

            {/* Single select */}
            {showLowerMenuSingleSelect && (
              <div className=" room-type-bottom-container">
                <div className="room-type-bottom-container-div">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      gap: "20px",
                    }}
                  >
                    <Button plain destructive onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    </Button>
                    <BgThemeButton
                      // onClick={editModalButtonClickHandler}
                      children={"Edit"}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Multiple select */}
            {showLowerMenuMultipleSelect && (
              <div className="room-type-bottom-container">
                <div className="room-type-bottom-container-div">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Button plain destructive onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {/* Select template */}
            {communicationTemplateDetails &&
              communicationOn !== "" &&
              communicationType !== "" && (
                <>
                  <div className="bg-backdrop">
                    <form
                      onSubmit={(e) => e.preventDefault()}
                      style={{ width: "400px" }}
                      className="stock-add-model-container"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "15px",
                        }}
                      >
                        <div className="add-ota-model-container-heading">
                          <div
                            onClick={() => {
                              setCommunicationTemplateDetails(false);
                              setCommunicationDetails(true);
                            }}
                            style={{
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                            >
                              <rect
                                x="29.75"
                                y="29.75"
                                width="29.5"
                                height="29.5"
                                rx="5.75"
                                transform="rotate(180 29.75 29.75)"
                                stroke="#666666"
                                strokeWidth="0.5"
                              />
                              <line
                                x1="21"
                                y1="15.043"
                                x2="9"
                                y2="15.043"
                                stroke="#666666"
                              />
                              <path
                                d="M13.6287 19.2578L9.00009 14.6292L13.6287 10.0007"
                                stroke="#666666"
                                strokeWidth="1.2"
                              />
                            </svg>
                          </div>
                          Create Message
                        </div>
                        <div
                          onClick={() => setCommunicationTemplateDetails(false)}
                          style={{ cursor: "pointer" }}
                        >
                          <Icon source={CancelMajor} color="base" />
                        </div>
                      </div>

                      <div className="form-container-div-full">
                        <VerticalInputFieldCustom
                          titleName={"Enter Name"}
                          placeholder={"Enter Name"}
                          value={addItemData.name}
                          name={"name"}
                          onChange={setAddItemDataHandleChange}
                        />
                      </div>
                      {/* {communicationType === "emails" && ( */}
                      <div className="form-container-div-full">
                        <VerticalInputFieldCustom
                          titleName={"Enter Email"}
                          type={"email"}
                          placeholder={"Enter Email"}
                          value={addItemData.email}
                          name={"email"}
                          onChange={setAddItemDataHandleChange}
                        />
                      </div>

                      <div className="form-container-div-full">
                        {communicationOn === "bookings" ? (
                          <VerticalInputSelectCustomCopy
                            titleName={"Select Template"}
                            options={templateOptions}
                            name={"template"}
                            value={addItemData.template}
                            required={"true"}
                            onChange={setAddItemDataHandleChange}
                          />
                        ) : (
                          <VerticalInputSelectCustomCopy
                            titleName={"Select Template"}
                            options={templateOptions}
                            name={"template"}
                            value={addItemData.template}
                            required={"true"}
                            onChange={setAddItemDataHandleChange}
                          />
                        )}
                      </div>

                      {addItemData.template !== "" && (
                        <>
                          <div className="form-container-div-full">
                            <VerticalTextAreaCustom
                              titleName={"Subject (optional)"}
                              cols={6}
                              name={"email_sub"}
                              value={addItemData.email_sub}
                              onChange={setAddItemDataHandleChange}
                            />
                          </div>
                          <div className="form-container-div-full">
                            <VerticalTextAreaCustom
                              titleName={"Notes (optional)"}
                              cols={6}
                              name={"additional_notes"}
                              value={addItemData.additional_notes}
                              onChange={setAddItemDataHandleChange}
                            />
                          </div>
                        </>
                      )}

                      <div
                        className="form-container-div-full"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {update === "edit" ? (
                          <BgOutlineThemeButton
                            style={{ width: "100%" }}
                            onClick={(e) =>
                              updateFunction(getClickedEditId, "draft")
                            }
                            type="submit"
                            children={"Save as Draft"}
                          />
                        ) : (
                          <BgOutlineThemeButton
                            style={{ width: "100%" }}
                            onClick={(e) => handleSubmitAddRooms(e, "draft")}
                            type="submit"
                            children={"Save as Draft"}
                          />
                        )}

                        {communicationOn === "bookings" &&
                          addItemData.template === "voucher" && (
                            <BgOutlineThemeButton
                              style={{ width: "100%" }}
                              // onClick={
                              //   handleToggleBookingPreviewVoucherPopupWithoutEmailName
                              // }
                              onClick={(e) =>
                                handleSubmitPreview(
                                  e,
                                  "preview",
                                  "Booking Voucher"
                                )
                              }
                              children={"Preview Voucher"}
                            />
                          )}
                        {communicationOn === "bookings" &&
                          addItemData.template === "bill" && (
                            <BgOutlineThemeButton
                              // onClick={handleToggleBookingPreviewBillPopup}
                              onClick={(e) =>
                                handleSubmitPreview(
                                  e,
                                  "preview",
                                  "Preview Bill"
                                )
                              }
                              style={{
                                fontSize: "14px",
                                width: "100%",
                              }}
                              children={"Preview Bill"}
                            />
                          )}
                        {communicationOn === "bookings" &&
                          addItemData.template === "payment-receipt" && (
                            <BgOutlineThemeButton
                              // onClick={handleToggleBookingPaymentReceiptPopup}
                              onClick={(e) =>
                                handleSubmitPreview(
                                  e,
                                  "preview",
                                  "Payment Receipt"
                                )
                              }
                              style={{
                                fontSize: "14px",
                                width: "100%",
                              }}
                              children={"Payment Receipt"}
                            />
                            // </Link>
                          )}

                        {communicationOn === "events" &&
                          addItemData.template === "voucher" && (
                            <BgOutlineThemeButton
                              style={{ width: "100%" }}
                              children={"Preview Voucher"}
                              onClick={(e) =>
                                handleSubmitPreview(
                                  e,
                                  "preview",
                                  "Event Voucher"
                                )
                              }

                              // onClick={handleToggleEventsPreviewVoucherPopup}
                            />
                          )}
                        {communicationOn === "events" &&
                          addItemData.template === "bill" && (
                            <BgOutlineThemeButton
                              // onClick={handleToggleEventsPreviewBillPopup}
                              onClick={(e) =>
                                handleSubmitPreview(e, "preview", "Event Bill")
                              }
                              style={{
                                fontSize: "14px",
                                width: "100%",
                              }}
                              children={"Preview Bill"}
                            />
                          )}
                        {communicationOn === "events" &&
                          addItemData.template === "payment-receipt" && (
                            <BgOutlineThemeButton
                              onClick={(e) =>
                                handleSubmitPreview(
                                  e,
                                  "preview",
                                  "Event Payment Receipt"
                                )
                              }
                              // onClick={handleToggleEventsPaymentReceiptPopup}
                              style={{
                                fontSize: "14px",
                                width: "100%",
                              }}
                              children={"Preview Receipt"}
                            />
                          )}
                      </div>
                      <div className="form-container-div-full">
                        {update === "edit" ? (
                          <BgThemeButton
                            style={{ width: "100%" }}
                            onClick={(e) =>
                              updateFunction(getClickedEditId, "send")
                            }
                            type="submit"
                            children={"Send"}
                          />
                        ) : (
                          <BgThemeButton
                            style={{ width: "100%" }}
                            onClick={(e) => handleSubmitAddRooms(e, "send")}
                            type="submit"
                            children={"Send"}
                          />
                        )}
                      </div>
                    </form>
                  </div>
                </>
              )}

            {/* Show Booking Preview Voucher Popup */}
            {showBookingPreviewVoucherPopup && (
              <>
                <div className="bg-backdrop">
                  <section
                    className="communication_iframe_container"
                    // style={{ pointerEvents: "none" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "15px",
                        padding: "0 25px",
                      }}
                    >
                      <div className="add-ota-model-container-heading">
                        {/* Booking Voucher */}
                      </div>
                      <div
                        onClick={handleToggleBookingPreviewVoucherPopup}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon source={CancelMajor} color="base" />
                      </div>
                    </div>
                    <iframe
                      title="Preview"
                      src={iframeSrc}
                      width="100%"
                      height="100%"
                    />
                    {/* <PreviewCommunicationVoucher uniquesearchName={searchName} /> */}
                  </section>
                </div>
              </>
            )}

            {/* Show Booking Preview Bill PopUp */}
            {showBookingPreviewBillPopup && (
              <>
                <div className="bg-backdrop">
                  <section className="communication_preview_voucher_popup_container">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "15px",
                        padding: "0 25px",
                      }}
                    >
                      <div className="add-ota-model-container-heading">
                        Booking Bill
                      </div>
                      <div
                        onClick={handleToggleBookingPreviewBillPopup}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon source={CancelMajor} color="base" />
                      </div>
                    </div>
                    <PreviewCommunicationBillCopy
                      uniquesearchName={searchName}
                    />
                  </section>
                </div>
              </>
            )}

            {/* Show Booking Payment Receipt PopUp */}
            {showBookingPaymentReceiptPopup && (
              <>
                <div className="bg-backdrop">
                  <section className="communication_preview_voucher_popup_container">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "15px",
                        padding: "0 25px",
                      }}
                    >
                      <div className="add-ota-model-container-heading">
                        Booking Payment Receipt
                      </div>
                      <div
                        onClick={() => setShowBookingPaymentReceiptPopup(false)}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon source={CancelMajor} color="base" />
                      </div>
                    </div>
                    <PaymentReceiptCommunication
                      uniquesearchName={searchName}
                      data={bookingDetails}
                    />
                  </section>
                </div>
              </>
            )}

            {/* Show Events Preview Coucher Popup */}
            {showEventsPreviewVoucherPopup && (
              <>
                <div className="bg-backdrop">
                  <section className="communication_preview_voucher_popup_container">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "15px",
                        padding: "0 25px",
                      }}
                    >
                      <div className="add-ota-model-container-heading">
                        Event Voucher
                      </div>
                      <div
                        onClick={handleToggleEventsPreviewVoucherPopup}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon source={CancelMajor} color="base" />
                      </div>
                    </div>
                    <EventsCommunicationVoucher eventid={eventDetails?.id} />
                  </section>
                </div>
              </>
            )}

            {/* Show Events Preview Coucher Popup */}
            {showEventsPreviewBillPopup && (
              <>
                <div className="bg-backdrop">
                  <section className="communication_preview_voucher_popup_container">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "15px",
                        padding: "0 25px",
                      }}
                    >
                      <div className="add-ota-model-container-heading">
                        Event Voucher
                      </div>
                      <div
                        onClick={handleToggleEventsPreviewBillPopup}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon source={CancelMajor} color="base" />
                      </div>
                    </div>
                    <EventCommunicationBill eventid={eventDetails?.id} />
                  </section>
                </div>
              </>
            )}

            {/* Show Events Preview Voucher Popup */}
            {showEventsPaymentReceiptPopup && eventPaymentData && (
              <>
                <div className="bg-backdrop">
                  <section className="communication_preview_voucher_popup_container">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "15px",
                        padding: "0 25px",
                      }}
                    >
                      <div className="add-ota-model-container-heading">
                        Events Payment Receipt
                      </div>
                      <div
                        onClick={() => setShowEventsPaymentReceiptPopup(false)}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon source={CancelMajor} color="base" />
                      </div>
                    </div>
                    <PaymentReceiptEventCommunication
                      eventid={eventDetails?.event_id}
                      data={eventPaymentData}
                    />
                  </section>
                </div>
              </>
            )}

            {/* Show Events Preview Coucher Popup */}
            {showRatesAndDealsReviewVoucherPopup && (
              <>
                <div className="bg-backdrop">
                  <section className="communication_preview_voucher_popup_container">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "15px",
                        padding: "0 25px",
                      }}
                    >
                      <div className="add-ota-model-container-heading">
                        Rate and Availability
                      </div>
                      <div
                        onClick={handleToggleRaatesDealsPreviewVoucherPopup}
                        // onClick={() =>
                        //   setShowRatesAndDealsReviewVoucherPopup(false)
                        // }
                        style={{ cursor: "pointer" }}
                      >
                        <Icon source={CancelMajor} color="base" />
                      </div>
                    </div>
                    <RoomAvailabilityAndRatesCommunication
                      data={roomAvailableData}
                      name={addItemData.name}
                    />
                  </section>
                </div>
              </>
            )}

            {showModelMenuActive && (
              <>
                <div className="bg-backdrop">
                  <section className="room-type-model-container ">
                    <h4>Are You Sure?</h4>
                    <p>You Want To Remove?</p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Button
                        onClick={() =>
                          setShowModelMenuActive(!showModelMenuActive)
                        }
                      >
                        Discard
                      </Button>
                      <Button onClick={deleteData} destructive>
                        Yes, Remove
                      </Button>
                    </div>
                  </section>
                </div>
              </>
            )}
            {showImagePopup && (
              <>
                <div className="bg-backdrop">
                  <section
                    className="communication_iframe_container-2"
                    style={{
                      boxShadow: "none",
                      backgroundColor: "transparent",
                      borderRadius: "8px",
                      backdropFilter: "none",
                      flexDirection: "column",
                      marginLeft: "auto",
                      marginRight: "auto",
                      // padding: "25px 0",
                      transition: "all 1s",
                      // width: "90%",
                      // height: "100%",
                      overflow: "hidden",
                    }}
                  >
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "15px",
                        padding: "0 25px",
                      }}
                    >
                      <div
                        className="add-ota-model-container-heading"
                        style={{ display: "flex", gap: "10px" }}
                      ></div>
                      <div
                        onClick={() => setShowImagePopup(false)}
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          top: "30px",
                          left: "4px",
                        }}
                      >
                        <svg
                          data-v-a0e00534=""
                          style={{ fill: "#fff" }}
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          className="close-icon"
                        >
                          <path d="M6.41408 5.00002C6.0236 4.60953 5.3905 4.60953 5.00002 5.00002C4.60953 5.3905 4.60953 6.0236 5.00002 6.41408L10.586 12L5.00002 17.586C4.60953 17.9764 4.60953 18.6095 5.00002 19C5.3905 19.3905 6.0236 19.3905 6.41408 19L12 13.4141L17.586 19C17.9764 19.3905 18.6095 19.3905 19 19C19.3905 18.6095 19.3905 17.9764 19 17.586L13.4141 12L19 6.41408C19.3905 6.0236 19.3905 5.3905 19 5.00002C18.6095 4.60953 17.9764 4.60953 17.586 5.00002L12 10.586L6.41408 5.00002Z"></path>
                        </svg>
                      </div>
                    </div> */}

                    <div
                      onClick={() => setShowImagePopup(false)}
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "70px",
                        zIndex: "99999",
                        right: "40px",
                      }}
                    >
                      <svg
                        data-v-a0e00534=""
                        style={{ fill: "#fff" }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        className="close-icon"
                      >
                        <path d="M6.41408 5.00002C6.0236 4.60953 5.3905 4.60953 5.00002 5.00002C4.60953 5.3905 4.60953 6.0236 5.00002 6.41408L10.586 12L5.00002 17.586C4.60953 17.9764 4.60953 18.6095 5.00002 19C5.3905 19.3905 6.0236 19.3905 6.41408 19L12 13.4141L17.586 19C17.9764 19.3905 18.6095 19.3905 19 19C19.3905 18.6095 19.3905 17.9764 19 17.586L13.4141 12L19 6.41408C19.3905 6.0236 19.3905 5.3905 19 5.00002C18.6095 4.60953 17.9764 4.60953 17.586 5.00002L12 10.586L6.41408 5.00002Z"></path>
                      </svg>
                    </div>

                    {mediaData && mediaData.length > 0 ? (
                      !mediaData.some((item) => item.src.endsWith(".pdf")) ? (
                        <CommunicationMediaSlider mediaData={mediaData} />
                      ) : (
                        mediaData.map((item, index) => (
                          <div
                            key={index}
                            className="slide"
                            style={{
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              overflow: "hidden",
                            }}
                          >
                            {item.src.endsWith(".pdf") ? (
                              // <embed
                              //   src={`${item.src}#toolbar=0`}
                              //   type="application/pdf"
                              //   width="80%"
                              //   height="80%"
                              //   // style={{display: 'block',    maxWidth: '60%',maxHeight: '80%',overflow: 'hidden',     margin: "60px auto"}}
                              // />
                              <object
                                class="pdf"
                                data={`${item.src}#toolbar=0`}
                                width="800"
                                height="500"
                              ></object>
                            ) : (
                              <img src={item.src} alt={`Slide ${index}`} />
                            )}
                          </div>
                        ))
                      )
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          fontSize: "40px",
                          color: "#fff",
                        }}
                      >
                        No Data Found
                      </div>
                    )}
                  </section>
                </div>
              </>
            )}

            {iframeContent && showIframePopup && (
              <>
                <div className="bg-backdrop">
                  <section
                    className="communication_iframe_container"
                    style={{ width: "50%", overflow: "hidden" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "15px",
                        padding: "0 25px",
                      }}
                    >
                      <div
                        className="add-ota-model-container-heading"
                        style={{ display: "flex", gap: "10px" }}
                      ></div>
                      <div
                        onClick={() => setShowIframePopup(false)}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon source={CancelMajor} color="base" />
                      </div>
                    </div>
                    {/* <iframe
                title="Preview"
                src={iframeSrc}
                width="100%"
                height="100%"
              /> */}
                    <iframe
                      srcDoc={iframeContent}
                      id="receipt"
                      // style={{ display: "none" }}
                      title="Receipt"
                    />
                    {/* <PreviewCommunicationVoucher uniquebookingid={bookingId} /> */}
                  </section>
                </div>
              </>
            )}

            {/* {showIframePopup && (
                      <>
                        <div className="bg-backdrop">
                          <section className="communication_iframe_container">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "15px",
                                padding: "0 25px",
                              }}
                            >
                              <div className="add-ota-model-container-heading">
                                {voucherTitle && voucherTitle}
                              </div>
                              <div
                                onClick={() => setShowIframePopup(false)}
                                style={{ cursor: "pointer" }}
                              >
                                <Icon source={CancelMajor} color="base" />
                              </div>
                            </div>
                            <iframe
                              title="Preview"
                              src={iframeSrc}
                              width="100%"
                              height="100%"
                            />
                           
                          </section>
                        </div>
                      </>
                    )} */}
            {/* </> */}
            {/* )} */}
          </div>
        </div>
      </div>

      {/* </div> */}
    </>
  );
};
export default Communication;

// <>
//   <div className="bg-backdrop">
//     <form
//       onSubmit={(e) => e.preventDefault()}
//       style={{ width: "400px" }}
//       className="stock-add-model-container"
//     >
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//           marginBottom: "15px",
//         }}
//       >
//         <div className="add-ota-model-container-heading">
//           <div
//             onClick={() => {
//               setCommunicationTemplateDetails(false);
//               setCommunicationDetails(false);
//               setCommunicationRoomCategory(false);
//             }}
//             style={{ marginRight: "10px", cursor: "pointer" }}
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="30"
//               height="30"
//               viewBox="0 0 30 30"
//               fill="none"
//             >
//               <rect
//                 x="29.75"
//                 y="29.75"
//                 width="29.5"
//                 height="29.5"
//                 rx="5.75"
//                 transform="rotate(180 29.75 29.75)"
//                 stroke="#666666"
//                 strokeWidth="0.5"
//               />
//               <line
//                 x1="21"
//                 y1="15.043"
//                 x2="9"
//                 y2="15.043"
//                 stroke="#666666"
//               />
//               <path
//                 d="M13.6287 19.2578L9.00009 14.6292L13.6287 10.0007"
//                 stroke="#666666"
//                 strokeWidth="1.2"
//               />
//             </svg>
//           </div>
//           Create Message
//         </div>
//         <div
//           onClick={() => setCommunicationRoomCategory(false)}
//           style={{ cursor: "pointer" }}
//         >
//           <Icon source={CancelMajor} color="base" />
//         </div>
//       </div>

//       <div className="form-container-div-full">
//         <div>Select Date Range</div>
//         <DateRangePicker
//           selectedDatesRange={selectedDatesRange}
//           onDateRangeChange={handleDateRangeChange}
//         />
//       </div>

//       <div className="form-container-div-half">
//         <table
//           ref={wrapperRef}
//           style={{
//             backgroundColor: "white",
//             padding: "0 10px 20px 0px",
//             borderCollapse: "collapse",
//             borderRadius: "10px",
//             fontFamily: "Inter",
//             width: "100%",
//           }}
//         >
//           <tr
//             style={{
//               textAlign: "left",
//             }}
//           >
//             <th style={{ padding: "5px 0", width: "60%" }}>
//               Select Room Category
//             </th>
//             <th style={{ padding: "0px" }}>
//               {dynamicInputList[0].meal_plan_array.length > 0 && (
//                 <span>Meal Plan</span>
//               )}
//             </th>

//             <th
//               style={{
//                 width: "10px",
//                 padding: "10px",
//               }}
//             ></th>
//           </tr>

//           {dynamicInputList &&
//             dynamicInputList.map((bill, index) => {
//               return (
//                 <tr>
//                   <>
//                     <td style={{ paddingRight: "10px" }}>
//                       <select
//                         name="room_category_id"
//                         style={{
//                           width: "100%",
//                           minHeight: "2.25rem",
//                           border: "1px solid #888888",
//                           borderRadius: "3px",
//                           marginBottom: "7px",
//                         }}
//                         value={
//                           dynamicInputList[index]
//                             ?.room_category_id
//                         }
//                         onChange={(event) =>
//                           fetchRoomRatePlans(
//                             event.target.value,
//                             bill.id,
//                             index
//                           )
//                         }
//                       >
//                         {roomTypeOptions?.map((x, i) => {
//                           return (
//                             <>
//                               <option
//                                 key={x.value}
//                                 value={x.value}
//                               >
//                                 {x.label}
//                               </option>
//                             </>
//                           );
//                         })}
//                       </select>
//                     </td>

//                     {bill && bill.meal_plan_array.length > 0 && (
//                       <td>
//                         <div>
//                           <div
//                             style={{
//                               border: "1px solid #888888",
//                               cursor: "pointer",
//                               position: "relative",
//                               height: "36px",
//                               display: "flex",
//                               alignItems: "center",
//                               padding: "0 5px",
//                               fontSize: "13px",
//                               borderRadius: "3px",
//                               marginBottom: "7px",
//                               width: "107px",
//                             }}
//                           >
//                             <div
//                               onClick={() =>
//                                 handleMealPlanSelection(
//                                   bill.room_category_id
//                                 )
//                               }
//                             >
//                               {bill?.selectedMealPlans.length} of{" "}
//                               {bill?.meal_plan_array.length}{" "}
//                               selected
//                             </div>
//                             {selectedMealPlan ===
//                               bill.room_category_id && (
//                                 <div
//                                   style={{
//                                     position: "absolute",
//                                     top: "36px",
//                                     background: "white",
//                                     width: "100%",
//                                     left: "0",
//                                     boxShadow:
//                                       "rgb(0 0 0 / 23%) 0px 0px 1px 1px",
//                                     padding: "2px",
//                                     zIndex: "11",
//                                   }}
//                                 >
//                                   {bill.meal_plan_array.map(
//                                     (mealPlan) => (
//                                       <div
//                                         key={mealPlan.id}
//                                         style={{
//                                           display: "flex",
//                                           gap: "4px",
//                                         }}
//                                       >
//                                         <input
//                                           type="checkbox"
//                                           id={mealPlan.id}
//                                           name="meal_plan"
//                                           checked={bill?.selectedMealPlans?.includes(
//                                             mealPlan.id
//                                           )}
//                                           onChange={() =>
//                                             handleCheckboxChange(
//                                               index,
//                                               mealPlan.id
//                                             )
//                                           }
//                                         />
//                                         <label
//                                           htmlFor={mealPlan.id}
//                                           style={{
//                                             display: "block",
//                                             width: "100%",
//                                             cursor: "pointer",
//                                           }}
//                                         >
//                                           {mealPlan.rate_plan_type}
//                                         </label>
//                                       </div>
//                                     )
//                                   )}
//                                 </div>
//                               )}
//                           </div>
//                         </div>
//                       </td>
//                     )}

//                     <td
//                       style={{
//                         padding: "10px 5px",
//                         width: "10px",
//                       }}
//                     >
//                       {dynamicInputList.length === index + 1 ? (
//                         <div
//                           style={{
//                             display: "flex",
//                             justifyContent: "center",
//                             alignItems: "center",
//                             cursor: "pointer",
//                           }}
//                           onClick={() => handleAddClick(bill.id)}
//                         >
//                           <svg
//                             xmlns="http://www.w3.org/2000/svg"
//                             width="14"
//                             height="14"
//                             viewBox="0 0 14 14"
//                             fill="none"
//                           >
//                             <g clipPath="url(#clip0_1270_978)">
//                               <path
//                                 d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
//                                 stroke="#3968ED"
//                               />
//                               <path
//                                 d="M10.5866 7H3.41333"
//                                 stroke="#3968ED"
//                                 strokeMiterlimit="10"
//                               />
//                               <path
//                                 d="M7 3.41344L7 10.5867"
//                                 stroke="#3968ED"
//                                 strokeMiterlimit="10"
//                               />
//                             </g>
//                             <defs>
//                               <clipPath id="clip0_1270_978">
//                                 <rect
//                                   width="14"
//                                   height="14"
//                                   fill="white"
//                                 />
//                               </clipPath>
//                             </defs>
//                           </svg>
//                         </div>
//                       ) : (
//                         <div
//                           onClick={() =>
//                             handleRemoveClick(bill.id)
//                           }
//                           style={{
//                             display: "flex",
//                             justifyContent: "center",
//                             alignItems: "center",
//                             cursor: "pointer",
//                           }}
//                         >
//                           <svg
//                             xmlns="http://www.w3.org/2000/svg"
//                             width="14"
//                             height="14"
//                             viewBox="0 0 14 14"
//                             fill="none"
//                           >
//                             <g clipPath="url(#clip0_1270_876)">
//                               <path
//                                 d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
//                                 stroke="#3968ED"
//                               />
//                               <path
//                                 d="M10.5866 7H3.41333"
//                                 stroke="#3968ED"
//                                 strokeMiterlimit="10"
//                               />
//                             </g>
//                             <defs>
//                               <clipPath id="clip0_1270_876">
//                                 <rect
//                                   width="14"
//                                   height="14"
//                                   fill="white"
//                                 />
//                               </clipPath>
//                             </defs>
//                           </svg>
//                         </div>
//                       )}
//                     </td>
//                   </>
//                 </tr>
//               );
//             })}
//         </table>
//       </div>

//       <div className="form-container-div-half">
//         <div className="form-container-div">
//           <VerticalInputFieldCustom
//             name="child_with_bed"
//             focused={false}
//             titleName="Child with Bed"
//             onChange={setAddItemDataHandleChange}
//             autoComplete="off"
//             value={addItemData.child_with_bed}
//           />
//         </div>
//         <div className="form-container-div">
//           <VerticalInputFieldCustom
//             name="child_without_bed"
//             value={addItemData.child_without_bed}
//             focused={false}
//             titleName="Child w/o Bed"
//             onChange={setAddItemDataHandleChange}
//             autoComplete="off"
//           />
//         </div>
//         <div className="form-container-div">
//           <VerticalInputFieldCustom
//             name="no_of_rooms"
//             value={addItemData.no_of_rooms}
//             focused={false}
//             titleName="No. of Rooms"
//             onChange={setAddItemDataHandleChange}
//             autoComplete="off"
//           />
//         </div>
//       </div>
//       <div className="form-container-div-half">
//         <div className="form-container-div">
//           <VerticalInputFieldCustom
//             name="no_of_adult"
//             value={addItemData.no_of_adult}
//             focused={false}
//             titleName="No. of adult"
//             onChange={setAddItemDataHandleChange}
//             autoComplete="off"
//           />
//         </div>
//         <div className="form-container-div">
//           <VerticalInputFieldCustom
//             name="extra_adult"
//             value={addItemData.extra_adult}
//             focused={false}
//             titleName="Extra Adult"
//             onChange={setAddItemDataHandleChange}
//             autoComplete="off"
//           />
//         </div>
//       </div>

//       <div
//         className="form-container-div-full"
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//           gap: "10px",
//         }}
//       >
//         <BgOutlineThemeButton
//           style={{ width: "100%" }}
//           onClick={(e) => handleSubmitAddRooms(e, "draft")}
//           type="submit"
//           children={"Save as Draft"}
//         />
//       </div>

//       <div className="form-container-div-full">
//         <div className="flex flex_gap_10">
//           {communicationOn === "rate_and_deals" &&
//             addItemData.room_category_id !== "" &&
//             selectedDatesRange.from_date !== "" &&
//             selectedDatesRange.to_date !== "" && (
//               <BgOutlineThemeButton
//                 onClick={handleSubmitRateAndDeals}
//                 style={{ fontSize: "14px", width: "100%" }}
//                 children={"Preview"}
//               />
//             )}

//           {update === "add" &&
//             communicationOn != "rate_and_deals" && (
//               <BgOutlineThemeButton
//                 style={{ width: "100%" }}
//                 onClick={(e) => handleSubmitAddRooms(e, "send")}
//                 type="submit"
//                 children={"Send"}
//               />
//             )}
//           {update === "edit" && (
//             <BgOutlineThemeButton
//               style={{ width: "100%" }}
//               onClick={(e) =>
//                 updateFunction(getClickedEditId, "send")
//               }
//               type="submit"
//               children={"Send"}
//             />
//           )}

//           {communicationOn === "rate_and_deals" && (
//             <BgThemeButton
//               style={{ width: "100%" }}
//               onClick={(e) => handleSubmitRateAndDeal(e, "send")}
//               type="submit"
//               children={"Send Rates and deals"}
//             />
//           )}
//         </div>
//       </div>
//     </form>
//   </div>
// </>
{
  /* <div className="form-container-div-full">
                      <VerticalInputSelectCustomCopy
                        titleName={"Select Template"}
                        options={templateOptions}
                        name={"template"}
                        value={addItemData.template}
                        required={"true"}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div> */
}
