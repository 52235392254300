import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import CustomIndexTable from "../Dashboard/CustomIndexTable/CustomIndexTable";
import CustomIndexTableRow from "../Dashboard/CustomIndexTable/CustomIndexTableRow";
import CustomIndexTableCell from "../Dashboard/CustomIndexTable/CustomIndexTableCell";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useState } from "react";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/svgIcons";

const ReportInvoiceSettlement = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [informationPopup, setInformationPopup] = useState(false);

  const rowMarkupAllFoliosCustom = departureReport?.map(
    ({ id, count, order_no, order_type, net_total, date, index }) => {
      return (
        <CustomIndexTableRow
          id={id}
          key={id}
          // selectedItems={selectedArray} // send for checking the check box is checked
          // setSelectedItems={setSelectedArray}
          showCheckbox={false} // show the check box
        >
          <CustomIndexTableCell>{id}</CustomIndexTableCell>

          <CustomIndexTableCell>{count}</CustomIndexTableCell>
          <CustomIndexTableCell>
            {formatIndianCurrency(id)}
          </CustomIndexTableCell>
        </CustomIndexTableRow>
      );
    }
  );

  const calculateTotal = (items, key) => {
    return 0;
  };
  

  const downloadCSV = () => {
    // Prepare CSV content
    const csvHeaders = "Category,Item,Count,Sale";
    const csvContent = `${csvHeaders}\n${departureReport
      .map((category) =>
        category.items
          .map((item) =>
            [category.category, item.item, item.count, item.sale].join(",")
          )
          .join("\n")
      )
      .join("\n")}`;

    // Create a blob with CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element to trigger download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "sales_data.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // console.log(departureReport);

  const rowMarkup = departureReport?.map(
    ({
      id,

      MTD_due_amount,
      MTD_paid_amount,
      MTD_purchase_amount,
      QTD_due_amount,
      QTD_paid_amount,
      QTD_purchase_amount,
      YTD_due_amount,
      YTD_paid_amount,
      YTD_purchase_amount,
      supplier_gst_no,
      supplier_name,
      invoice_no,
      purchase_date,
      grand_total,
      paid_amount,
      due_amount,
      payment_status,
    }) => (
      <CustomReportTableRow
        CustomReportTableRow
        id={id}
        key={id}
      // bodyStyles={{
      //   backgroundColor: "red",
      //   fontSize: "10px",
      // }}
      >
        <CustomReportTableCell>{invoice_no}</CustomReportTableCell>
        <CustomReportTableCell>{supplier_name}</CustomReportTableCell>
        <CustomReportTableCell>{supplier_gst_no}</CustomReportTableCell>
        <CustomReportTableCell>
          {
            <TextDateFormatter
              date={purchase_date}
              as={"div"}
              showYear={true}
            />
          }
        </CustomReportTableCell>

        <CustomReportTableCell>
          {formatIndianCurrency(grand_total)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(paid_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(due_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {<StatusUpdate status={payment_status} />}
        </CustomReportTableCell>
      </CustomReportTableRow>
    )
  );

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "grand_total")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "paid_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "due_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
    </CustomReportTableRow>
  );
  // ........................
  const extractedData = departureReport?.map((item) => ({
    invoice_no: item && item?.invoice_no ? item?.invoice_no : "N/A",
    supplier_name: item && item?.supplier_name ? item?.supplier_name : "N/A",
    supplier_gst_no:
      item && item?.supplier_gst_no ? item?.supplier_gst_no : "N/A",
    purchase_date: item && item?.purchase_date ? item?.purchase_date : "N/A",
    grand_total: item && item?.grand_total ? item?.grand_total : "N/A",
    paid_amount: item && item?.paid_amount ? item?.paid_amount : "N/A",
    due_amount: item && item?.due_amount ? item?.due_amount : "N/A",
    payment_status: item && item?.payment_status ? item?.payment_status : "N/A",
  }));

  const names = [
    {
      invoice_no: "Invoice No.",
      supplier_name: "Supplier Name",
      supplier_gst_no: "GST No",
      purchase_date: "Date Of Purchase	",
      grand_total: "Purchase Amount (₹)	",
      paid_amount: "Paid Amount (₹)	",
      due_amount: "Due Amount (₹)	",
      payment_status: "Settlement Status",
    },
  ];
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div
          id="receipt"
          className="room-types-full-container reports-new-background"
        >
          <div className="room-types-full-container-div">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
                <div className="room-type-heading">
                  Invoice Settlement Report{" "}
                </div>
                <div
                  className="hoverCursor"
                  onClick={() => setInformationPopup(true)}
                  id="ignoreFromPrint"
                >
                  <SvgGetInfo />
                </div>
              </div>

              {departureReport.length > 0 && (
                <div className="flex flex_gap_15 align_center" id="ignoreFromPrint">
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Invoice Settlement Report"}
                    ignoreFromPrint={"ignoreFromPrint"}
                    longPdf={true}
                  // pageFormat={'A3'}
                  // pdfStyles={

                  // }
                  />
                  {/* <DownloadCsvTables tableData={extractedData} downloadCSV={downloadCSV} names={names} /> */}
                  <DownloadCSVButton
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Invoice_Settlement_Report"}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            {departureReport && departureReport.length > 0 ? (
              <>
                {/* {departureReport &&
                  departureReport.length > 0 &&
                  departureReport.map((order) => {
                    return (
                      <div className="mt-20">
                        <div
                          className="category_name"
                          style={{ marginBottom: "10px" }}
                        >
                          {order.category}
                        </div> */}

                <CustomReportTable
                  headings={[
                    "Invoice Number",
                    "Supplier Name",
                    "GST No",
                    "Date Of Purchase",
                    "Purchase Amount (₹)",
                    "Paid Amount (₹)",
                    "Due Amount (₹)",
                    "Settlement Status",
                  ]}
                  // commonHeadings={[
                  //   "",
                  //   "",
                  //   "Purchase Amount (₹)",
                  //   "Purchase Amount (₹)",
                  //   "Purchase Amount (₹)",
                  //   "Paid Amount (₹)",
                  //   "Paid Amount (₹)",
                  //   "Paid Amount (₹)",
                  //   "Due Amount (₹)",
                  //   "Due Amount (₹)",
                  //   "Due Amount (₹)",
                  // ]}
                  headerStyles={{
                    backgroundColor: "#dddddd",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                  tableStyles={{ textAlign: "left" }}
                >
                  {rowMarkup}
                  {calculateTotals}
                </CustomReportTable>
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Invoice Settlement Report"
        >
          <div className="mb-20">
            {/* <h2 className="mb-10"> Accordion Component </h2> */}
            <AccordionComponent
              questions={[
                {
                  question: "What is an Invoice Settlement Report?",
                  answer: (
                    <p>
                      An <b>Invoice Settlement Report</b> is a detailed document
                      that tracks the settlement status of invoices issued to
                      suppliers over a selected date range. It includes key data
                      such as invoice numbers, supplier details, purchase
                      amounts, payments made, outstanding dues, and the overall
                      settlement status (e.g., paid, unpaid, or partially paid).
                      The report offers a clear view of financial obligations
                      and settlement progress with each supplier.
                    </p>
                  ),
                },
                {
                  question: "Importance of an Invoice Settlement Report",
                  answer: (
                    <div>
                      <p>
                        The Invoice Settlement Report is crucial for businesses
                        for several reasons:
                      </p>

                      <ul>
                        <li>
                          <b>Accurate Tracking:</b> It ensures precise
                          monitoring of invoice payments, helping businesses
                          stay aware of what’s paid, unpaid, or partially paid,
                          improving financial control.
                        </li>
                        <li>
                          <b>Improved Cash Flow:</b> Helps businesses manage
                          cash flow by tracking which invoices are still
                          outstanding and when payments are due.
                        </li>
                        <li>
                          <b> Transparency:</b> Provides clear documentation for
                          both businesses and suppliers, reducing the risk of
                          disputes or misunderstandings regarding payments.
                        </li>
                        <li>
                          <b> Compliance:</b> Helps in ensuring tax and legal
                          compliance by maintaining accurate records of supplier
                          transactions, including GST details.
                        </li>
                        <li>
                          <b>Vendor Relationship Management:</b> It strengthens
                          vendor relationships by enabling timely payments and
                          clear communication on outstanding dues.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportInvoiceSettlement;
