import {
  TextField,
  IndexTable,
  LegacyCard,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
  Tooltip,
} from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import { BgOutlineThemeButton, BgThemeButton, BgThemeButtonDisabled } from "./UI/Buttons";
import Loader from "./UI/Loader/Loader";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import { useSelector } from "react-redux";
import {
  other_select_nav_items_inventory,
  rooms_inner_lists,
} from "../assets/constant";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import NoDataFound from "./CustomComponents/NoDataFound";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import { useNavigate } from "react-router-dom";
import CustomPagination from "./CustomComponents/CustomPagination/CustomPagination";
import { getDecryptedData } from "../utils/encryptStorage";
import SidebarInnerListWithMultiplePermissions from "./CustomComponents/SidebarInnerListWithMultiplePermissions";

const RoomRatePlans = () => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const permissions = JSON.parse(localStorage.getItem("permissions"));

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);
  const [loader, setLoader] = useState(true);

  const [orders, setOrders] = useState([]);

  // Pagignation
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(orders.length / itemsPerPage);
    setCurrentPage(newPage > totalPages ? totalPages : newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrders = orders.slice(startIndex, endIndex);

  const property_id = useSelector((state) => state.property.value);

  const navigate = useNavigate();

  const [fetchedRoomType, setFetchedRoomType] = useState([]);
  const fetchRoomsHandleSelect = async (propertyId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setFetchedRoomType(data.rooms);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const [fetchedMealPlans, setFetchedMealPlans] = useState([]);

  const fetchedMealPlansHandleSelect = async (propertyId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/mealPlans/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setFetchedMealPlans(data.plans);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      fetchRoomsHandleSelect(property_id.id);
      fetchedMealPlansHandleSelect(property_id.id);
    }
  }, [property_id]);

  const room_name = [
    { label: "Select Option", value: "" },
    ...fetchedRoomType?.map((roomType) => {
      return { label: roomType.room_name, value: roomType.id };
    }),
  ];

  const meal_plans = [
    { label: "Select Option", value: "Select Option" },
    ...fetchedMealPlans?.map((roomType) => {
      return { label: roomType.meal_plan_name, value: roomType.id };
    }),
  ];

  // fetch data
  // http://phplaravel-1082347-3787133.cloudwaysapps.com/api/v1/rooms/rates/99f12663-7d6d-4d28-8f08-786b7daa73f1

  const fetchInfo = async (property_id) => {
    setLoader(true); // Set loading before sending API request
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/rates/${property_id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setOrders(data.roomRates);
        } else {
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });

    // setLoader(false); // Stop loading
  };

  // useEffect(() => {
  //   fetchInfo(id);
  // }, []);
  // fetchUserData()
  setTimeout(() => {
    setLoader(false);
  }, 1000);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(currentOrders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setAddRoomRates({
        rate_plan_name: "",
        rate_plan_type: "",
        rate_rack_price: "",
        rate_bar_price: "",
        extra_child: "",
        extra_adult: "",
        extra_bed: "",
        rate_plan_status: 1,
        breakfast: "",
        lunch: "",
        dinner: "",
        rate_plan_action: null,
        base_category: "no",
      });
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
    clearSelection();
  };

  const editModalButtonClickHandler = () => {
    setShowEditModelMenuActive(!showEditModelMenuActive);
  };

  const removeAddModalContainer = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  const getItemChecked = (id) => {};

  // getItemChecked(selectedResources[0]);

  // getData
  // http://phplaravel-1082347-3787133.cloudwaysapps.com/api/v1/rooms/rates/edit/${id}
  const getOneData = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/rates/edit/${id}/${property_id?.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // if (data.success) {
        const updatedData = {
          property_id: data.roomRate.property_id,
          rate_plan_name: data.roomRate.rate_plan_name,
          rate_plan_type: data.roomRate.rate_plan_type,
          rate_rack_price: data.roomRate.rate_rack_price,
          rate_bar_price: data.roomRate.rate_bar_price,
          extra_child: data.roomRate.extra_child,
          extra_adult: data.roomRate.extra_adult,
          extra_bed: data.roomRate.extra_bed,
          rate_plan_status: data.roomRate.rate_plan_status,
          breakfast: data.roomRate.breakfast,
          lunch: data.roomRate.lunch,
          dinner: data.roomRate.dinner,
          rate_plan_action: data.roomRate.rate_plan_action,
          base_category: data.roomRate.base_category,
          mealplan_id: data.roomRate.mealplan_id,
        };

        setAddRoomRates(updatedData);
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const rowMarkup = currentOrders?.map(
    ({
      id,
      rate_plan_type,
      rate_plan_name,
      rate_bar_price,
      room_name,
      planType,
      planName,
      price,
      index,
    }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={room_name}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={rate_plan_type}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={rate_plan_name}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={"₹ " + rate_bar_price}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  // Plan Type Options
  const planTypeOptions = [
    { label: "Ep", value: "Ep" },
    { label: "CP", value: "CP" },
    { label: "MAP", value: "MAP" },
    { label: "AP", value: "AP" },
  ];

  const [planTypeSelected, setPlanTypeSelected] = useState("EP");
  const planTypeHandleSelectChange = useCallback(
    (value) => setPlanTypeSelected(value),
    []
  );

  // Plan Name Options
  const planNameOptions = [
    { label: "Room Only", value: "newestUpdate" },
    { label: "Room + Complimentary Breakfast", value: "oldestUpdate" },
    {
      label: "Room with Complimentary Breakfast and dinner",
      value: "mostSpent",
    },
    { label: "Breakfast + Lunch + Dinner", value: "mostOrders" },
  ];

  const [planNameSelected, setNameTypeSelected] = useState("EP");
  const planNameHandleSelectChange = useCallback(
    (value) => setNameTypeSelected(value),
    []
  );

  // Plan Name Options
  const planStatusOptions = [
    { label: "Booked", value: "Booked" },
    {
      label: "Available",
      value: "Available",
    },
  ];

  const [planStatusSelected, setPlanStatusSelected] = useState("Booked");
  const planStatusHandleSelectChange = useCallback(
    (value) => setPlanStatusSelected(value),
    []
  );

  // Plan Type Options
  const roomTypeOptions = [
    { label: "Select Option" },
    { label: "Deluxe", value: "Deluxe" },
    { label: "Super Deluxe", value: "Super Deluxe" },
    { label: "Cottage", value: "Cottage" },
    { label: "Luxury", value: "Luxury" },
    { label: "Executive", value: "Executive" },
    { label: "Family", value: "Family" },
  ];

  const [roomTypeSelected, setRoomTypeSelected] = useState("EP");
  const roomTypeHandleSelectChange = useCallback(
    (value) => setRoomTypeSelected(value),
    []
  );

  const [addRoomRates, setAddRoomRates] = useState({
    room_id: "",
    mealplan_id: "",
    rate_plan_name: "",
    // rate_plan_type: "",
    rate_rack_price: "",
    rate_bar_price: "",
    extra_child: "",
    extra_adult: "",
    extra_bed: "",
    rate_plan_status: 1,
    breakfast: "",
    lunch: "",
    dinner: "",
    rate_plan_action: null,
    base_category: "no",
    room_name: "",
  });

  const fetchRoomData = (roomNo) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/edit/${roomNo}/${property_id?.id}`
    )
      .then((response) => response.json())
      .then((data) => {
        setAddRoomRates((prevState) => ({
          ...prevState,
          rate_rack_price: data.room.rack_price,
        }));
      })
      .catch((error) => {
        console.error("Error fetching room data:", error);
      });
  };

  const setAddItemDataHandleChange = (event) => {
    let newValue = event.target.value;

    setAddRoomRates((prevRoomRates) => {
      // Update the state for the changed input
      const updatedRoomRates = {
        ...prevRoomRates,
        [event.target.name]: newValue,
      };

      // Calculate the total price and update the rate_bar_price
      const total = (
        parseFloat(updatedRoomRates.rate_rack_price) +
        parseFloat(updatedRoomRates.breakfast || 0) +
        parseFloat(updatedRoomRates.lunch || 0) +
        parseFloat(updatedRoomRates.dinner || 0)
      ).toFixed(2);

      return {
        ...updatedRoomRates,
        // rate_bar_price: total,
      };
    });

    const setAddItemDataHandleChange = (event) => {
      let newValue = event.target.value;

      setAddRoomRates((prevRoomRates) => {
        // Update the state for the changed input
        const updatedRoomRates = {
          ...prevRoomRates,
          [event.target.name]: newValue,
        };

        // Calculate the total price
        const total = (
          parseFloat(updatedRoomRates.rate_rack_price) +
          parseFloat(updatedRoomRates.breakfast || 0) +
          parseFloat(updatedRoomRates.lunch || 0) +
          parseFloat(updatedRoomRates.dinner || 0)
        ).toFixed(2);

        // Update the state with the calculated total
        return {
          ...updatedRoomRates,
          rate_bar_price: total,
        };
      });
    };

    if (event.target.name === "room_id") {
      fetchRoomData(event.target.value);
    }
    if (event.target.name === "mealplan_id") {
      fetchDataForMealPlan(event.target.value);
    }
  };
  const [selectedPlanType, setSelectedPlanType] = useState([]);

  const fetchDataForMealPlan = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/mealPlans/edit/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setSelectedPlanType(data?.mealPlan);
    } catch (error) {
      console.error(error);
    }
  };

  // delete
  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();

    // "http://phplaravel-1082347-3787133.cloudwaysapps.com/api/v1/rooms/rates/delete"
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/rates/delete/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          clearSelection();
          fetchInfo(property_id?.id);
          clearSelection();
          setShowLowerMenuMultipleSelect(false);
          setShowModelMenuActive(false);
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const [buttonLoader, setButtonLoader] = useState(false);

  const updateFunction = (id) => {
    const findOrder = orders.find((user) => user.id === id);
    // http://phplaravel-1082347-3787133.cloudwaysapps.com/api/v1/rooms/rates/update/${id}
    setButtonLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/rates/update/${id}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify(addRoomRates),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setButtonLoader(false);
          fetchInfo(property_id?.id);
          clearSelection();
          setShowEditModelMenuActive(false);
          showSuccessToast(data.message);
        } else {
          setButtonLoader(false);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        setButtonLoader(false);
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const addRoomRatePlanFunction = (e) => {
    e.preventDefault();
    setButtonLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/rates/store/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify(addRoomRates),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setButtonLoader(false);
          setShowAddModelMenuActive(false);
          fetchInfo(property_id?.id);
          showSuccessToast(data.message);
          navigate("/roomsrateplans");
          setAddRoomRates({
            rate_plan_name: "",
            rate_plan_type: "",
            rate_rack_price: "",
            rate_bar_price: "",
            extra_child: "",
            extra_adult: "",
            extra_bed: "",
            rate_plan_status: 1,
            breakfast: "",
            lunch: "",
            dinner: "",
            rate_plan_action: null,
            base_category: "no",
            room_name: "",
          });
        } else {
          setButtonLoader(false);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        setButtonLoader(false);
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });

    // setAddRoomRates({
    //   rate_plan_name: "",
    //   rate_plan_type: "",
    //   rate_rack_price: "",
    //   rate_bar_price: "",
    //   extra_child: "",
    //   extra_adult: "",
    //   extra_bed: "",
    //   rate_plan_status: 1,
    //   breakfast: "",
    //   lunch: "",
    //   dinner: "",
    //   rate_plan_action: null,
    //   base_category: "no",
    //   room_name: "",
    // })
    // );
    // clearSelection();
    // setShowEditModelMenuActive(false);
  };

  const userToken = getDecryptedData("encryptToken");

  return (
    <>
      <SidebarInnerListWithMultiplePermissions
        innerLists={rooms_inner_lists}
        selectAtLast={true}
        other_select_nav_items_inventory={other_select_nav_items_inventory}
        firstSelected={"Inventory"}
        firstSelectedPermission={"read_inventory"}
        firstSelectedLink={"/inventory"}
        zIndex="1"
      />

      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">Room Rate Plans</div>

              {permissions && permissions.includes("full_access_ari_module") ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // width: "100%",
                    gap: "20px",
                  }}
                >
                  <BgThemeButton
                    onClick={addModalButtonClickHandler}
                    children={" Add Rate Plan"}
                  />
                </div>
              ) : (
                <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                  <BgThemeButtonDisabled
                    children={"Add Rate Plan"}
                   
                  />
                </Tooltip>
              )}
            </div>

            {/* Single select */}
            {showLowerMenuSingleSelect && (
              <div className=" room-type-bottom-container">
                <div className="room-type-bottom-container-div">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      gap: "20px",
                    }}
                  >

                    <Button plain destructive disabled={permissions && !permissions.includes("full_access_ari_module")} onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    </Button>


                    <BgThemeButton disabled={permissions && !permissions.includes("full_access_ari_module")}
                      onClick={editModalButtonClickHandler}
                      children={"Edit"}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Multiple select */}
            {showLowerMenuMultipleSelect && (
              <div className="room-type-bottom-container">
                <div className="room-type-bottom-container-div">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Button plain destructive onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {orders && orders.length > 0 ? (
              <>
                <LegacyCard>
                  <IndexTable
                    resourceName={resourceName}
                    itemCount={currentOrders?.length}
                    selectedItemsCount={
                      allResourcesSelected ? "All" : selectedResources.length
                    }
                    onSelectionChange={handleSelectionChange}
                    headings={[
                      { title: "Room Name" },
                      { title: "Plan Type" },
                      { title: "Plan Name" },
                      { title: "Price" },
                    ]}
                  >
                    {rowMarkup}
                  </IndexTable>
                </LegacyCard>
                <CustomPagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={orders.length}
                  onPageChange={handlePageChange}
                  clearSelection={clearSelection}
                />
              </>
            ) : (
              <NoDataFound />
            )}
          </div>

          {/* Add Button Click */}
          {showAddModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => addRoomRatePlanFunction(e)}
                  className="room-rate-plan-edit-model-container"
                >
                  <h4>Add Rate Plan</h4>
                  <div className="edit-rooms-form-container">
                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <VerticalInputSelectCustomCopy
                          required={true}
                          options={room_name}
                          onChange={setAddItemDataHandleChange}
                          name="room_id"
                          type="number"
                          titleName="Room Type"
                          value={addRoomRates.room_id}
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-container-div">
                        <VerticalInputSelectCustomCopy
                          required={true}
                          options={meal_plans}
                          onChange={setAddItemDataHandleChange}
                          name="mealplan_id"
                          type="number"
                          titleName="Plan Type"
                          value={addRoomRates.mealplan_id}
                          autoComplete="off"
                        />
                      </div>
                      {/* <div className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          name="rate_plan_name"
                          onChange={setAddItemDataHandleChange}
                          focused={false}
                          titleName="Plan Name"
                          value={addRoomRates.rate_plan_name}
                          autoComplete="off"
                        />
                      </div> */}
                    </div>
                  </div>
                  <div
                    className="form-container-div-half"
                    style={{ flexWrap: "wrap" }}
                  >
                    <div
                      className="form-container-div"
                      style={{ width: "31%" }}
                    >
                      <VerticalInputFieldCustom
                        required={true}
                        disabled={true}
                        name="rate_rack_price"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        titleName="Rack Price"
                        value={addRoomRates.rate_rack_price}
                        autoComplete="off"
                        type="number"
                      />
                    </div>

                    {selectedPlanType &&
                      selectedPlanType.breakfast === "included" && (
                        <div
                          className="form-container-div"
                          style={{ width: "31%" }}
                        >
                          <VerticalInputFieldCustom
                            name="breakfast"
                            onChange={setAddItemDataHandleChange}
                            focused={false}
                            titleName="Breakfast"
                            value={addRoomRates.breakfast}
                            autoComplete="off"
                            type="number"
                          />
                        </div>
                      )}

                    {selectedPlanType &&
                      selectedPlanType.lunch === "included" && (
                        <div
                          className="form-container-div"
                          style={{ width: "31%" }}
                        >
                          <VerticalInputFieldCustom
                            name="lunch"
                            onChange={setAddItemDataHandleChange}
                            focused={false}
                            titleName="Lunch"
                            value={addRoomRates.lunch}
                            autoComplete="off"
                            type="number"
                          />
                        </div>
                      )}

                    {selectedPlanType &&
                      selectedPlanType.dinner === "included" && (
                        <div
                          className="form-container-div"
                          style={{ width: "31%" }}
                        >
                          <VerticalInputFieldCustom
                            name="dinner"
                            onChange={setAddItemDataHandleChange}
                            focused={false}
                            titleName="Dinner"
                            value={addRoomRates.dinner}
                            autoComplete="off"
                            type="number"
                          />
                        </div>
                      )}

                    <div
                      className="form-container-div"
                      style={{ width: "31%" }}
                    >
                      <VerticalInputFieldCustom
                        required={true}
                        // disabled={true}
                        name="rate_bar_price"
                        onChange={(e) =>
                          setAddRoomRates({
                            ...addRoomRates,
                            rate_bar_price: e.target.value,
                          })
                        }
                        focused={false}
                        titleName="Bar Price"
                        value={addRoomRates.rate_bar_price}
                        autoComplete="off"
                        type="number"
                      />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div
                      className="form-container-div"
                      style={{ width: "31%" }}
                    >
                      <VerticalInputFieldCustom
                        required={true}
                        name="extra_adult"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        titleName="Extra Adults"
                        value={addRoomRates.extra_adult}
                        autoComplete="off"
                        type="number"
                      />
                    </div>
                    <div
                      className="form-container-div"
                      style={{ width: "31%" }}
                    >
                      <VerticalInputFieldCustom
                        required={true}
                        name="extra_bed"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        titleName="Child With Bed"
                        value={addRoomRates.extra_bed}
                        autoComplete="off"
                        type="number"
                      />
                    </div>
                    <div
                      className="form-container-div"
                      style={{ width: "31%" }}
                    >
                      <VerticalInputFieldCustom
                        required={true}
                        name="extra_child"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        titleName="Child Without Bed"
                        value={addRoomRates.extra_child}
                        autoComplete="off"
                        type="number"
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton
                      type={"submit"}
                      loader={buttonLoader}
                      children={"Add"}
                    />
                    <BgOutlineThemeButton
                      children={"Discard"}
                      onClick={() => setShowAddModelMenuActive(false)}
                    />
                  </div>
                </form>
              </div>
            </>
          )}

          {/* Edit Button Click */}
          {showEditModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-rate-plan-edit-model-container">
                  <h4>Edit Rate Plan</h4>
                  <div className="edit-rooms-form-container">
                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          disabled={true}
                          required={true}
                          onChange={setAddItemDataHandleChange}
                          name="rate_plan_name"
                          // type="number"
                          titleName="Plan Name"
                          value={addRoomRates.rate_plan_name}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        disabled={true}
                        required={true}
                        options={planTypeOptions}
                        onChange={setAddItemDataHandleChange}
                        name="rate_plan_type"
                        // type="number"
                        titleName="Plan Type"
                        value={addRoomRates.rate_plan_type}
                        autoComplete="off"
                      />
                    </div>

                    {/* </div>
                  <div className="form-container-div-half"> */}
                    {addRoomRates.breakfast !== null &&
                      addRoomRates.breakfast !== 0 && (
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            required={true}
                            name="breakfast"
                            onChange={setAddItemDataHandleChange}
                            focused={false}
                            titleName="Breakfast"
                            value={addRoomRates.breakfast}
                            autoComplete="off"
                            type="number"
                          />
                        </div>
                      )}

                    {addRoomRates.lunch !== null &&
                      addRoomRates.lunch !== 0 && (
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            required={true}
                            name="lunch"
                            onChange={setAddItemDataHandleChange}
                            focused={false}
                            titleName="Lunch"
                            value={addRoomRates.lunch}
                            autoComplete="off"
                            type="number"
                          />
                        </div>
                      )}

                    {addRoomRates.dinner !== null &&
                      addRoomRates.dinner !== 0 && (
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            required={true}
                            name="dinner"
                            onChange={setAddItemDataHandleChange}
                            focused={false}
                            titleName="Dinner"
                            value={addRoomRates.dinner}
                            autoComplete="off"
                            type="number"
                          />
                        </div>
                      )}

                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        name="rate_bar_price"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        titleName="Bar Price"
                        value={addRoomRates.rate_bar_price}
                        autoComplete="off"
                        type="number"
                      />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    {/* {addRoomRates.rate_plan_type === "EP" && ( */}
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        name="extra_adult"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        titleName="Extra Adults"
                        value={addRoomRates.extra_adult}
                        autoComplete="off"
                        type="number"
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        name="extra_bed"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        titleName="Child With Bed"
                        value={addRoomRates.extra_bed}
                        autoComplete="off"
                        type="number"
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        name="extra_child"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        titleName="Child Without Bed"
                        value={addRoomRates.extra_child}
                        autoComplete="off"
                        type="number"
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton
                      onClick={() => updateFunction(selectedResources[0])}
                      children={"Update"}
                      loader={buttonLoader}
                    />
                    <BgOutlineThemeButton
                      children={"Discard"}
                      onClick={() => setShowEditModelMenuActive(false)}
                    />
                  </div>
                </section>
              </div>
            </>
          )}

          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    <Button onClick={() => deleteData()} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default RoomRatePlans;
