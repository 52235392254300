import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import CustomIndexTable from "../Dashboard/CustomIndexTable/CustomIndexTable";
import CustomIndexTableRow from "../Dashboard/CustomIndexTable/CustomIndexTableRow";
import CustomIndexTableCell from "../Dashboard/CustomIndexTable/CustomIndexTableCell";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useState } from "react";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/svgIcons";

const ReportItemViseConsolidatedFinance = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [informationPopup, setInformationPopup] = useState(false);

  const rowMarkupAllFoliosCustom = departureReport?.map(
    ({ id, count, order_no, order_type, net_total, date, index }) => {
      return (
        <CustomIndexTableRow
          id={id}
          key={id}
          // selectedItems={selectedArray} // send for checking the check box is checked
          // setSelectedItems={setSelectedArray}
          showCheckbox={false} // show the check box
        >
          <CustomIndexTableCell>{id}</CustomIndexTableCell>

          <CustomIndexTableCell>{count}</CustomIndexTableCell>
          <CustomIndexTableCell>
            {formatIndianCurrency(id)}
          </CustomIndexTableCell>
        </CustomIndexTableRow>
      );
    }
  );

  const calculateTotal = (items, key) => {
    return 0;
  };
 

  const downloadCSV = () => {
    // Prepare CSV content
    const csvHeaders = "Category,Item,Count,Sale";
    const csvContent = `${csvHeaders}\n${departureReport
      .map((category) =>
        category.items
          .map((item) =>
            [category.category, item.item, item.count, item.sale].join(",")
          )
          .join("\n")
      )
      .join("\n")}`;

    // Create a blob with CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element to trigger download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "sales_data.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // console.log(departureReport);

  const rowMarkup = departureReport?.map(
    ({
      id,
      issue_item_date,
      item_category,
      request_id,
      department_name,
      issue_to,
      quantity,
      unique_purchase_id,
      total_amount,
      item_name,
      unit_name,

      purchased_quantity,
      issued_quantity,
      remaining_quantity,
      remaining_amount,
      issued_amount,
    }) => (
      <CustomReportTableRow
        CustomReportTableRow
        id={id}
        key={id}
        // bodyStyles={{
        //   backgroundColor: "red",
        //   fontSize: "10px",
        // }}
      >
        <CustomReportTableCell>{item_name}</CustomReportTableCell>
        <CustomReportTableCell>
          {item_category ? item_category : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {unit_name ? unit_name : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>{purchased_quantity}</CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(total_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {issued_quantity ? issued_quantity : 0}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(issued_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {remaining_quantity ? remaining_quantity : 0}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(remaining_amount)}
        </CustomReportTableCell>
      </CustomReportTableRow>
    )
  );

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>
        {calculateTotalWithReduce(departureReport, "purchased_quantity")}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "total_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {calculateTotalWithReduce(departureReport, "issued_quantity")}
      </CustomReportTableCell>

      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "issued_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {calculateTotalWithReduce(departureReport, "remaining_quantity")}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "remaining_amount")
        )}
      </CustomReportTableCell>
    </CustomReportTableRow>
  );
  const extractedData = departureReport?.map((item) => ({
    item_name: item && item?.item_name ? item?.item_name : "N/A",
    item_category: item && item?.item_category ? item?.item_category : "N/A",
    unit_name: item && item?.unit_name ? item?.unit_name : "N/A",
    purchased_quantity:
      item && item?.purchased_quantity ? item?.purchased_quantity : "N/A",
    remaining_amount:
      item && item?.remaining_amount ? item?.remaining_amount : "N/A",
    issued_quantity:
      item && item?.issued_quantity ? item?.issued_quantity : "N/A",
    issued_amount: item && item?.issued_amount ? item?.issued_amount : "N/A",
    item_qty: item && item?.item_qty ? item?.item_qty : "N/A",
    total_amount: item && item?.total_amount ? item?.total_amount : "N/A",
  }));

  const names = [
    {
      item_name: "Item Name	",
      item_category: "Category",
      unit_name: "Unit",
      purchased_quantity: "Purchased Quantity",
      remaining_amount: "Purchased Amount (₹)	",
      issued_quantity: "Issued Quantity",
      issued_amount: "Issued Amount (₹)",
      item_qty: "Stock Quantity",
      total_amount: "Stock Amount (₹)",
    },
  ];
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div
          id="receipt"
          className="room-types-full-container reports-new-background"
        >
          <div className="room-types-full-container-div">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
                <div className="room-type-heading">
                  Item Wise Consolidated Finance Report
                </div>
                <div
                  className="hoverCursor"
                  onClick={() => setInformationPopup(true)}
                  id="ignoreFromPrint"
                >
                  <SvgGetInfo />
                </div>
              </div>

              {departureReport.length > 0 && (
                <div className="flex flex_gap_15 align_center" id="ignoreFromPrint">
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Item Wise Consolidated Finance Report"}
                    ignoreFromPrint={"ignoreFromPrint"}
                    longPdf={true}
                  />
                  <DownloadCSVButton
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Item Wise Consolidated Finance Report"}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            {departureReport && departureReport.length > 0 ? (
              <>
                {/* {departureReport &&
                  departureReport.length > 0 &&
                  departureReport.map((order) => {
                    return (
                      <div className="mt-20">
                        <div
                          className="category_name"
                          style={{ marginBottom: "10px" }}
                        >
                          {order.category}
                        </div> */}

                <CustomReportTable
                  headings={[
                    "Item Name",
                    "Category",
                    "Unit",
                    "Quantity",
                    "Amount (₹)",
                    "Quantity",
                    "Amount (₹)",
                    "Quantity",
                    "Amount (₹)",
                  ]}
                  commonHeadings={[
                    "",
                    "",
                    "",
                    "Purchased",
                    "Purchased",
                    "Issued",
                    "Issued",
                    "Stock",
                    "Stock",
                  ]}
                  headerStyles={{
                    backgroundColor: "#dddddd",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                  tableStyles={{ textAlign: "left" }}
                >
                  {rowMarkup}
                  {calculateTotals}
                </CustomReportTable>
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Item-Wise Consolidated Finance Report"
        >
          <div className="mb-20">
            {/* <h2 className="mb-10"> Accordion Component </h2> */}
            <AccordionComponent
              questions={[
                {
                  question: "What is an Item-Wise Consolidated Finance Report?",
                  answer: (
                    <p>
                      An <b>Item-Wise Consolidated Finance Report</b> is a
                      summary report that provides an overview of financial and
                      inventory movements for specific items over a selected
                      date range. It consolidates details such as the item name,
                      category, units, quantity and amount of items purchased,
                      issued, and the current stock levels, giving businesses a
                      comprehensive view of their inventory and related
                      finances.
                    </p>
                  ),
                },
                {
                  question:
                    "Importance of an Item-Wise Consolidated Finance Report",
                  answer: (
                    <div>
                      <p>
                        The Item-Wise Consolidated Finance Report is beneficial
                        for businesses in several ways:
                      </p>

                      <ul>
                        <li>
                          <b>Inventory Management: </b> By showing the quantity
                          purchased, issued, and current stock, the report helps
                          businesses track inventory levels and avoid stock
                          shortages or overstocking.
                        </li>
                        <li>
                          <b>Cost Control: </b> It provides insights into the
                          financial aspect of inventory, helping businesses keep
                          a close eye on the total purchase and issue amounts,
                          allowing for better financial planning.
                        </li>
                        <li>
                          <b>Transparency: </b> Consolidating item-wise data
                          offers a clear and organized view of financial
                          activity, which enhances transparency in procurement
                          and inventory management.
                        </li>
                        <li>
                          <b>Budget Planning: </b> The report aids in
                          forecasting future procurement needs by analyzing past
                          purchase and issue patterns, helping with budgeting
                          and resource allocation.
                        </li>
                        <li>
                          <b>Operational Efficiency: </b>With a clear overview
                          of stock levels and financial impact, businesses can
                          streamline operations by aligning purchasing decisions
                          with stock requirements.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportItemViseConsolidatedFinance;
