import React, { useEffect } from "react";
import RoomRatePlans from "../Components/RoomRatePlans";
import RoomRatePlansCommon from "../Components/RoomRatePlansCommon";
import { useSelector } from "react-redux";

const RoomsRatePlanPage = () => {

  useEffect(() => {
    // Set the meta title
    document.title = 'Room Rate Plan - RevCatalyst - Boosting Your Hotel Performance';

    // Create or update the meta description tag
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement('meta');
      metaDescription.name = 'description';
      document.head.appendChild(metaDescription);
    }
    metaDescription.content = "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);
  const activeApp = useSelector((state) => state.activeApp.activeApp) || localStorage.getItem("activeApp");
  return (
    <>
      {
        activeApp === "pms" && (<div className="main_container">
          <RoomRatePlans />
        </div>)
      }
      {activeApp === "booking_engine" && <RoomRatePlansCommon />}
      {activeApp === "channel_manager" && <RoomRatePlansCommon />}
    </>
  );
};

export default RoomsRatePlanPage;
