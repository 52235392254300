import React from "react";
import Customers from "../Components/Customers";
import NavTabs from "../Components/NewCustomComp/NavTabs/NavTabs";
import { Outlet } from "react-router-dom";

const CustomersPage = () => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  const mainData = [
    {
      label: "Customers",
      to: "/customers",
      permission: ["read_customer_management","full_access_customer_management"],
      check_permission: true,
    },
    {
      label: "Agents",
      to: "/agents",
      permission: ["full_access_agents_module","read_agents_module"],
      check_permission: true,
    },
    {
      label: "Agent Hold Rooms",
      to: "/agent-hold-rooms",
      permission: ["full_access_agents_module","read_agents_module"],
      check_permission: true,
    },
    {
      label: "Contracts",
      to: "/agent-contracts",
      permission: ["full_access_agents_module","read_agents_module"],
      check_permission: true,
    },
  ];

  const filteredData = mainData
    .filter((item) => {
      if (!item.check_permission) return true;
      return item.permission.some((perm) => permissions?.includes(perm));
    })
    .map(({ label, to }) => ({ label, to }));

  return (
    <>
      <div className="main_container">
        <NavTabs mainData={filteredData} />

        <Outlet />
      </div>
    </>
  );
};

export default CustomersPage;
