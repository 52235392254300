import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import CustomIndexTable from "../Dashboard/CustomIndexTable/CustomIndexTable";
import CustomIndexTableRow from "../Dashboard/CustomIndexTable/CustomIndexTableRow";
import CustomIndexTableCell from "../Dashboard/CustomIndexTable/CustomIndexTableCell";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useState } from "react";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/svgIcons";

const ReportDetailedIssueItem = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [informationPopup, setInformationPopup] = useState(false);

  const rowMarkupAllFoliosCustom = departureReport?.map(
    ({ id, count, order_no, order_type, net_total, date, index }) => {
      return (
        <CustomIndexTableRow
          id={id}
          key={id}
          // selectedItems={selectedArray} // send for checking the check box is checked
          // setSelectedItems={setSelectedArray}
          showCheckbox={false} // show the check box
        >
          <CustomIndexTableCell>{id}</CustomIndexTableCell>

          <CustomIndexTableCell>{count}</CustomIndexTableCell>
          <CustomIndexTableCell>
            {formatIndianCurrency(id)}
          </CustomIndexTableCell>
        </CustomIndexTableRow>
      );
    }
  );

  const calculateTotal = (items, key) => {
    return 0;
  };
 

  const downloadCSV = () => {
    // Prepare CSV content
    const csvHeaders = "Category,Item,Count,Sale";
    const csvContent = `${csvHeaders}\n${departureReport
      .map((category) =>
        category.items
          .map((item) =>
            [category.category, item.item, item.count, item.sale].join(",")
          )
          .join("\n")
      )
      .join("\n")}`;

    // Create a blob with CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element to trigger download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "sales_data.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // console.log(departureReport);

  const rowMarkup = departureReport?.map(
    ({
      id,
      issue_item_date,
      item_category,
      request_id,
      department_name,
      issue_to,
      quantity,
      unique_purchase_id,
      total_amount,
      item_name,
      unit_name,
    }) => (
      <CustomReportTableRow
        CustomReportTableRow
        id={id}
        key={id}
        // bodyStyles={{
        //   backgroundColor: "red",
        //   fontSize: "10px",
        // }}
      >
        <CustomReportTableCell>{item_name}</CustomReportTableCell>
        <CustomReportTableCell>
          {item_category ? item_category : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {
            <TextDateFormatter
              date={issue_item_date}
              as={"div"}
              showYear={true}
            />
          }
        </CustomReportTableCell>
        <CustomReportTableCell>
          {request_id ? request_id : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>#{unique_purchase_id}</CustomReportTableCell>
        <CustomReportTableCell>
          {department_name ? department_name : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {issue_to ? issue_to : "N/A"}
        </CustomReportTableCell>

        <CustomReportTableCell>{quantity}</CustomReportTableCell>
        <CustomReportTableCell>
          {unit_name ? unit_name : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(total_amount)}
        </CustomReportTableCell>
      </CustomReportTableRow>
    )
  );

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>
        {calculateTotalWithReduce(departureReport, "quantity")}
      </CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "total_amount")
        )}
      </CustomReportTableCell>
    </CustomReportTableRow>
  );
  const extractedData = departureReport?.map((item) => ({
    item_name: item && item?.item_name ? item?.item_name : "N/A",
    item_category: item && item?.item_category ? item?.item_category : "N/A",
    issue_item_date:
      item && item?.issue_item_date ? item?.issue_item_date : "N/A",
    request_id: item && item?.request_id ? item?.request_id : "N/A",
    unique_purchase_id:
      item && item?.unique_purchase_id ? item?.unique_purchase_id : "N/A",
    department_name:
      item && item?.department_name ? item?.department_name : "N/A",
    issue_to: item && item?.issue_to ? item?.issue_to : "N/A",
    quantity: item && item?.quantity ? item?.quantity : "N/A",
    unit_name: item && item?.unit_name ? item?.unit_name : "N/A",
    total_amount: item && item?.total_amount ? item?.total_amount : "N/A",
  }));

  const names = [
    {
      item_name: "Item Name	",
      item_category: "Category",
      issue_item_date: "Date Of Issue",
      request_id: "Request ID	",
      unique_purchase_id: "Purchase ID",
      department_name: "Issued To (Dept Name)",
      issue_to: "Issued To (Person Name)	",
      quantity: "Issued Qty	",
      unit_name: "Unit",
      total_amount: "Issued Amount (₹)",
    },
  ];
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div
          id="receipt"
          className="room-types-full-container reports-new-background"
        >
          <div className="room-types-full-container-div">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
                <div className="room-type-heading">
                  Detailed Issue Item Report
                </div>
                <div
                  className="hoverCursor"
                  onClick={() => setInformationPopup(true)}
                  id="ignoreFromPrint"
                >
                  <SvgGetInfo />
                </div>
              </div>

              {departureReport.length > 0 && (
                <div className="flex flex_gap_15 align_items_center" id="ignoreFromPrint">
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Detailed_Issue_Item_Report"}
                    ignoreFromPrint={"ignoreFromPrint"}
                    longPdf={true}
                  />
                  <DownloadCSVButton
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Detailed_Issue_Item_Report"}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            {departureReport && departureReport.length > 0 ? (
              <>
                {/* {departureReport &&
                  departureReport.length > 0 &&
                  departureReport.map((order) => {
                    return (
                      <div className="mt-20">
                        <div
                          className="category_name"
                          style={{ marginBottom: "10px" }}
                        >
                          {order.category}
                        </div> */}

                <CustomReportTable
                  headings={[
                    "Item Name",
                    "Category",
                    "Date Of Issue",
                    "Request ID",
                    "Purchase ID",
                    "Issued To (Dept Name)",
                    "Issued To (Person Name)",
                    "Issued Qty",
                    "Unit",
                    "Issued Amount (₹)",
                  ]}
                  // commonHeadings={[
                  //   "",
                  //   "",
                  //   "",
                  //   "",
                  //   "Quantity",
                  //   "Quantity",
                  //   "Quantity",
                  //   "Total Purchase Amount  (₹)",
                  //   "Total Purchase Amount  (₹)",
                  //   "Total Purchase Amount  (₹)",
                  //   "Average Purchase Cost per unit (₹)",
                  //   "Average Purchase Cost per unit (₹)",
                  //   "Average Purchase Cost per unit (₹)",
                  // ]}
                  headerStyles={{
                    backgroundColor: "#dddddd",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                  tableStyles={{ textAlign: "left" }}
                >
                  {rowMarkup}
                  {calculateTotals}
                </CustomReportTable>
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Detailed Issued Items Report"
        >
          <div className="mb-20">
            {/* <h2 className="mb-10"> Accordion Component </h2> */}
            <AccordionComponent
              questions={[
                {
                  question: "What is a Detailed Issued Items Report?",
                  answer: (
                    <p>
                      A <b>Detailed Issued Items Report</b> is a comprehensive
                      record of items that have been issued from inventory
                      within a selected date range. It includes detailed
                      information such as item name, category, date of issue,
                      request ID, purchase ID, the department and person to whom
                      the items were issued, the issued quantity, unit, and the
                      total issued amount.
                    </p>
                  ),
                },
                {
                  question: "Importance of a Detailed Issued Items Report",
                  answer: (
                    <div>
                      <p>
                        The Detailed Issued Items Report plays a crucial role in
                        inventory management by offering:
                      </p>

                      <ul>
                        <li>
                          <b>Inventory Control:</b> It helps businesses track
                          the movement of items from inventory, ensuring
                          accurate stock levels and minimizing discrepancies.
                        </li>
                        <li>
                          <b>Departmental Accountability:</b> By documenting
                          which department or person received the issued items,
                          the report ensures transparency and accountability
                          within the organization.
                        </li>
                        <li>
                          <b>Cost Tracking:</b> The issued amount helps in
                          monitoring the financial impact of inventory usage,
                          assisting in cost control and budget management.
                        </li>
                        <li>
                          <b> Operational Efficiency:</b> This report helps
                          streamline operations by providing real-time data on
                          item issuance, ensuring that departments have the
                          resources they need without delays.
                        </li>
                        <li>
                          <b>Audit and Compliance:</b> It serves as an essential
                          document for auditing purposes, providing a clear
                          trail of inventory issuance that can be reviewed for
                          compliance and internal controls.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportDetailedIssueItem;
