import React, { useState, useEffect, useCallback } from "react";
import SidebarInnerLists from "../../Components/CustomComponents/SidebarInnerLists";
import { websites_inner_lists } from "../../assets/constant";
import { Link, useNavigate, useParams } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import styles from "./websites.module.scss";
import {
  BgThemeButton,
  BgThemeButtonDisabled,
} from "../../Components/UI/Buttons";
import { useSelector } from "react-redux";
import {
  getBaseUrl,
  getCurrentPropertyDateHandler,
  getDateByMonth,
} from "../../utils/utils";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../../Components/CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import { SvgDelete, SvgEdit } from "../../assets/svgIcons";
import CreateWebsiteDiscount from "./CreateWebsiteDiscount";
import ToggleSwitch from "../../Components/NewCustomComp/Switch/ToggleSwitch";
import NoDataFound from "../../Components/CustomComponents/NoDataFound";
import Loader from "../../Components/UI/Loader/Loader";
import Pagination from "../../Components/NewCustomComp/pagination/Pagination";
import { getDecryptedData } from "../../utils/encryptStorage";
import { Tooltip } from "@shopify/polaris";

const WebsitesDiscounts = () => {
  const permissions = localStorage.getItem("permissions");
  const baseUrl = getBaseUrl();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userToken = getDecryptedData("encryptToken");

  const [rooms, setRooms] = useState([]);
  const [loader, setLoader] = useState(false);
  const property_id = useSelector((state) => state.property.value)?.id;

  useEffect(() => {
    if (property_id) {
      fetchRooms();
    }
  }, [property_id]);

  const fetchRooms = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/v1/rooms/${property_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json().then((data) => {
        if (data.success) {
          setRooms(data.rooms);
          setNewPromotionData((prevData) => ({
            ...prevData,
            all_conditions: {
              ...prevData.all_conditions,
              selected_rooms: data.rooms.map((room) => room.id),
            },
          }));
          setLoader(false);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [newPromotionData, setNewPromotionData] = useState({
    name: "PMS Discounts",
    type: "percent",
    method: "code",
    discount_code: "",
    amount: "",
    start_date: "",
    end_date: "",
    start_time: "00:00:00",
    end_time: "00:00:00",
    status: "enable",
    all_conditions: {
      type: "percent",
      rate_plans: "all",
      selected_rate_plans: [],
      rooms: "all",
      selected_rooms: [],
      discount: "",
      stay_dates: [],
      promotion_name: "",
      booking_period: "days",
      booking_period_value: "",
      certain_hours: "",
    },
  });

  const key = JSON.parse(localStorage.getItem("selectedProperty"));

  // ========== get booking discounts =======////

  const [discounts, setDiscounts] = useState([]);

  const fetchDiscounts = async (page) => {
    setLoader(true);
    try {
      const url = page
        ? `${baseUrl}/api/v1/booking-engine/discounts/list/${property_id}?page=${page}`
        : `${baseUrl}/api/v1/booking-engine/discounts/list/${property_id}`;

      const response = await fetch(
        url,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
            // key: key.token,
          },
        }
      );
      const data = await response.json().then((data) => {
        if (data.success) {
          setDiscounts(data.discounts);
          setLoader(false);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchDiscounts();
    }
  }, [property_id]);

  const [show, setShow] = useState(false);

  // ===========  delete api call  =======================
  const handleDiscountDelete = async (id) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/discounts/delete/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
            // key: key.token,
          },
          method: "DELETE",
          body: JSON.stringify({
            ids: [id],
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        fetchDiscounts();
      } else {
        showErrorToast("Something went wrong...");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ============   enable disable =====================

  const handleToggle = async (isOn, item) => {
    const postData = {
      ...item,
      status: isOn ? "enable" : "disable",
    };

    try {
      setLoader(true);

      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/discounts/status/update/${item.id}/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${userToken}`,
            key: key.token,
          },
          method: "POST",
          body: JSON.stringify({
            status: isOn ? "enable" : "disable",
          }),
        }
      );

      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setShow(false);
        fetchDiscounts();
      } else {
        showErrorToast(data.message || "Failed to update stay restrictions.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showErrorToast("An error occurred while updating stay restrictions.");
    } finally {
      setLoader(false);
    }
  };

  //============== edit api call =======================

  const [editData, setEditData] = useState(null);
  const handleDiscountEdit = async (id) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/discounts/list/${property_id}?id=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setEditData(data?.discounts);
        setNewPromotionData(data?.discounts);
        setShow(true);
      } else {
        showErrorToast("Something went wrong...");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //=============  pagination code =============

  const handlePageChange = (page) => {
    fetchDiscounts(page);
  };

  // =========== handle create discount ==========

  const handleCreateDiscount = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/v1/rooms/${property_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json().then((data) => {
        if (data.success) {
          setEditData("");
          setRooms(data.rooms);
          setNewPromotionData((prevData) => ({
            ...prevData,
            all_conditions: {
              ...prevData.all_conditions,
              selected_rooms: data.rooms.map((room) => room.id),
            },
          }));
          setLoader(false);
          setShow(true);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id, userToken)
        .then((date) => {
          setPropertyCurrentDate(date);
        })
        .catch((error) => console.error(error));
    }
  }, [property_id]);

  return (
    <div>
      {show && (
        <>
          <div className="global_popup_container_background">
            <div className="global_popup_container" style={{ width: "700px" }}>
              <CreateWebsiteDiscount
                show={show}
                setShow={setShow}
                fetchDiscounts={fetchDiscounts}
                rooms={rooms}
                newPromotionData={newPromotionData}
                setNewPromotionData={setNewPromotionData}
                editData={editData}
                propertyCurrentDate={propertyCurrentDate}
              />
            </div>
          </div>
        </>
      )}

      <div className={styles.website_discount_container}>
        <div className="room-types-full-container-div mt-20 flex justify-content-between">
          <div className="room-type-heading">Added Discounts</div>

          {permissions &&
          permissions.includes("full_access_website_management") ? (
            <BgThemeButton onClick={handleCreateDiscount}>
              Create Discount
            </BgThemeButton>
          ) : (
            <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
              <BgThemeButtonDisabled children={"Add Menus"} />
            </Tooltip>
          )}
        </div>
        <div className="mt-20">
          {/* <CustomIndex   */}
          {loader ? (
            <div
              style={{ width: "100%", height: "100%" }}
              className="flex  justify_content_center align_items_center"
            >
              <Loader />
            </div>
          ) : (
            <>
              {discounts?.data?.length > 0 ? (
                <>
                  <CustomShopifyIndexTable
                    showCheckbox={false}
                    headings={[
                      "Discount Name",
                      "Discount Type",
                      "Discount Method",
                      "Discount Code",
                      "Amount",
                      "Rooms",
                      "Meal Plans",
                      "Start Date",
                      "End Date",
                      "Status",
                      "Edit/Delete",
                    ]}
                  >
                    {discounts?.data?.map((item, index) => {
                      const rooms_selected =
                        item?.all_conditions?.rooms === "all"
                          ? "All"
                          : item?.all_conditions?.selected_rooms?.map(
                              (roomId) => {
                                const room = rooms?.find(
                                  (r) => r.id === roomId
                                ); // Find the room object by ID
                                return room ? (
                                  <p>{room?.room_name}</p>
                                ) : (
                                  "Unknown"
                                ); // Correctly accessing room_name
                              }
                            );

                      const meal_plans =
                        item?.all_conditions?.rate_plans === "all"
                          ? "All"
                          : item?.all_conditions?.selected_rate_plans
                              ?.map((plan) => plan)
                              ?.join(", ");

                      return (
                        <CustomShopifyIndexTableRow
                          key={index}
                          style={{
                            borderBottom:
                              index === discounts?.length - 1
                                ? "none"
                                : "1px solid #E8E8E8",
                          }}
                        >
                          <CustomShopifyIndexTableCell>
                            {item?.name}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            {item?.type}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            {item?.method ? item?.method : "NA"}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            {item?.discount_code ? item?.discount_code : "NA"}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            {item?.amount}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            <div className={styles["rooms-selected"]}>
                              {rooms_selected ? rooms_selected : "NA"}
                            </div>
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            {meal_plans ? meal_plans : "NA"}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            {getDateByMonth(item?.start_date)}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            {getDateByMonth(item?.end_date)}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            <span className="flex flex_gap_15 align_items_center">
                              <span>
                                <ToggleSwitch
                                  size={"small"}
                                  isOn={
                                    item?.status === "enable" ? true : false
                                  }
                                  handleToggle={(isOn) =>
                                    handleToggle(isOn, item)
                                  }
                                />
                              </span>
                            </span>
                          </CustomShopifyIndexTableCell>

                          <CustomShopifyIndexTableCell>
                            <span className="flex flex_gap_10">
                              {permissions &&
                              permissions.includes(
                                "full_access_website_management"
                              ) ? (
                                <span
                                  onClick={() => handleDiscountEdit(item?.id)}
                                >
                                  <SvgEdit />
                                </span>
                              ) : (
                                <Tooltip
                                  content="Please Contact Your Admin"
                                  dismissOnMouseOut
                                >
                                  <span style={{ opacity: "0.7" }}>
                                    <SvgEdit />
                                  </span>
                                </Tooltip>
                              )}

                              {permissions &&
                              permissions.includes(
                                "full_access_website_management"
                              ) ? (
                                <span
                                  onClick={() => handleDiscountDelete(item?.id)}
                                >
                                  <SvgDelete />
                                </span>
                              ) : (
                                <Tooltip
                                  content="Please Contact Your Admin"
                                  dismissOnMouseOut
                                >
                                  <span style={{ opacity: "0.7" }}>
                                    <SvgDelete />
                                  </span>
                                </Tooltip>
                              )}
                            </span>
                          </CustomShopifyIndexTableCell>
                        </CustomShopifyIndexTableRow>
                      );
                    })}
                  </CustomShopifyIndexTable>

                  <div style={{ width: "fit-content", margin: "20px auto" }}>
                    <Pagination
                      totalPages={discounts.last_page}
                      currentPage={discounts.current_page}
                      type={"advance"}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </>
              ) : (
                <div className="flex justify_content_center align_items_center w-100 h-100">
                  <NoDataFound />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default WebsitesDiscounts;
