import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import { formatDate, formatIndianCurrency } from "../../utils/utils";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useSelector } from "react-redux";
import { useState } from "react";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/svgIcons";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
const ReportOtaBusinessReport = ({
  departureReport,
  settlementOrders,
  selectedDatesRange,
  loader,
  setLoader,
  propertyId,
}) => {
  const [informationPopup, setInformationPopup] = useState(false);

  const property_id = useSelector((state) => state.property.value);
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const names = [
    {
      date: "Date",
      payment_mode: "Payment Mode",
      settlement_amount: "Settlement Amount",
    },
  ];

  const handleSinglePaymentClick = async (name, payment_type) => {
    // singlePaymentDetails(
    //   new Date(date).toISOString().split("T")[0],
    //   payment_type
    // );
    // if (!selectedDatesRange.from_date || !selectedDatesRange.to_date) {
    //   showErrorToast("Please select all the filters");
    //   return;
    // }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/singl-ota-business/1`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            source: name,
            from_date: selectedDatesRange.from_date,
            to_date: selectedDatesRange.to_date,
          }),
        }
      );

      const data = await response.json();
      if (data.success) {
        // setOrders(data.data);
        // setFullData(data);
        // showSuccessToast(data.message);
      } else {
        // setOrders(data.data);
        // showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <IndexTable.Row id={order.id} key={order.id} position={order.index}>
        <IndexTable.Cell>
          {" "}
          {/* <span
            onClick={() => handleSinglePaymentClick(order.booking_source_name)}
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          > */}
            {" "}
            {order.booking_source_name}
          {/* </span> */}
          {}
        </IndexTable.Cell>
        <IndexTable.Cell>{order.no_of_bookings}</IndexTable.Cell>
        <IndexTable.Cell>{order.no_of_rooms}</IndexTable.Cell>
        <IndexTable.Cell>{order.no_of_nights}</IndexTable.Cell>
        <IndexTable.Cell>
          {formatIndianCurrency(order.grand_total)}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {formatIndianCurrency(order.net_amount)}
        </IndexTable.Cell>
      </IndexTable.Row>
    ));
  const foodSettlementRowMarkup =
    settlementOrders &&
    settlementOrders?.length > 0 &&
    settlementOrders?.map((order) => (
      <IndexTable.Row id={order.id} key={order.id} position={order.index}>
        <IndexTable.Cell>{order.date}</IndexTable.Cell>
        <IndexTable.Cell>
          <UnderscoreRemoveCapitalize text={order.payment_mode} />{" "}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {formatIndianCurrency(order.settlement_amount)}
        </IndexTable.Cell>
      </IndexTable.Row>
    ));

  const [showPaymentPopup, setPaymentPopup] = useState(false);
  const [singlePaymentData, setSinglePaymentData] = useState();
  const singlePaymentHeadings = [
    "Date",
    "Booking ID",
    "Payment Type",
    "Settlement Amount (₹)",
  ];

  const singlePaymentDetails = async (date, payment_type) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/details-payment-reconciliation-report/${property_id.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            date: date,
            payment_type: payment_type,
          }),
        }
      );

      const data = await response.json();

      if (data.success) {
        setSinglePaymentData(data.data);
        setPaymentPopup(true);
      } else {
        setSinglePaymentData(data.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div style={{ display: "flex", gap: "10px" }} id="room-types">
            <div
              style={{ width: "100%" }}
              className="room-types-full-container"
            >
              <div className="room-types-full-container-div">
                <div
                  className="flex flex_gap_5 "
                  style={{ alignItems: "unset" }}
                >
                  <div
                    className="flex flex_gap_5 "
                    style={{ alignItems: "unset" }}
                  >
                    <div className="room-type-heading">OTA Business Report</div>
                    <div
                      className="hoverCursor"
                      onClick={() => setInformationPopup(true)}
                      id="ignoreFromPrint"
                    >
                      <SvgGetInfo />
                    </div>
                  </div>
                </div>
                {departureReport?.length > 0 && (
                  <div
                    className="flex flex_gap_15 align_center"
                    id="ignoreFromPrint"
                  >
                    {/* <DownloadPdfTables
                      tableData={departureReport}
                      tableNames={names}
                    />
                    <DownloadCsvTables tableData={departureReport} names={names}/> */}
                    <ButtonToDownloadTablePDF
                      downloadFileName={"ota_business_report"}
                      itemToDownloadID={"room-types"}
                      ignoreFromPrint={"ignoreFromPrint"}
                      // pageFormat={"A3"}
                      longPdf={true}
                    />
                    <DownloadCSVButton
                      downloadFileName={"ota_business_report"}
                      itemToDownloadID={"room-types"}
                    />
                  </div>
                )}
              </div>

              {departureReport && departureReport?.length > 0 ? (
                <>
                  <LegacyCard>
                    <IndexTable
                      resourceName={resourceName}
                      itemCount={departureReport.length}
                      selectable={false}
                      headings={[
                        { title: "OTA Name" },
                        { title: "No. of Bookings" },
                        { title: "No. of Rooms" },
                        { title: "No. of Nights" },
                        { title: "Total Booking Amount (₹)" },
                        { title: "Net Booking Amount (₹)(w/o commissions)" },
                      ]}
                    >
                      {rowMarkup}
                    </IndexTable>
                  </LegacyCard>
                </>
              ) : (
                <NoDataFound />
              )}
            </div>

            {/* <div style={{ width: "100%" }} className="room-types-full-container">
              {
                settlementOrders && settlementOrders.length > 0 && (
                  <>
                    <div className="room-types-full-container-div">
                      <div className="room-type-heading">Food Ledger</div>
                    </div>
                    <LegacyCard>
                      <IndexTable
                        resourceName={resourceName}
                        itemCount={departureReport.length}
                        selectable={false}
                        headings={[
                          { title: "Date" },
                          { title: "Payment Method" },
                          { title: "Settlement Amount (₹)" },
                        ]}
                      >
                        {foodSettlementRowMarkup}
                      </IndexTable>
                    </LegacyCard>
                  </>
                )
                // : (
                //   <NoDataFound />
                // )
              }
            </div> */}
          </div>

          {/* <div>
            <>
              <h1>Summary Report</h1>
              <LegacyCard>
                <IndexTable
                  resourceName={resourceName}
                  itemCount={departureReport.length}
                  selectable={false}
                  headings={[
                    { title: "Date" },
                    { title: "Payment Method" },
                    { title: "Settlement Amount (₹)" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>
              </LegacyCard>
            </>
          </div> */}
        </>
        // </div>
      )}

      {showPaymentPopup && (
        <>
          <div
            className="global_popup_container_background"
            onClick={() => setPaymentPopup(false)}
          >
            <div
              className="global_popup_container"
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="flex justify_content_between align_center"
                style={{ marginBottom: "10px" }}
              >
                <h4 className="room-type-heading"> Single Payment Details</h4>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  onClick={() => setPaymentPopup(false)}
                  style={{ margin: "0px 0 0 auto", cursor: "pointer" }}
                >
                  <path
                    d="M1 13.1501L13 0.850098"
                    stroke="#AAAAAA"
                    strokeWidth="1.2"
                  />
                  <path
                    d="M13 13.1501L0.999999 0.850147"
                    stroke="#AAAAAA"
                    strokeWidth="1.2"
                  />
                </svg>
              </div>

              <CustomReportTable
                headings={singlePaymentHeadings}
                headerStyles={{
                  padding: "10px 5px",
                  fontWeight: "bold",
                  backgroundColor: "#f2f2f2",
                  borderBottom: "1px solid #CCC",
                }}
              >
                {singlePaymentData.map((data, index) => (
                  <CustomReportTableRow key={index}>
                    <CustomReportTableCell>
                      {formatDate(new Date(data?.date))}
                    </CustomReportTableCell>
                    <CustomReportTableCell>
                      #{data?.unique_booking_id}
                    </CustomReportTableCell>
                    <CustomReportTableCell>
                      {data?.payment_mode.replaceAll("_", " ")}
                    </CustomReportTableCell>
                    <CustomReportTableCell>
                      {formatIndianCurrency(data?.amount)}
                    </CustomReportTableCell>
                  </CustomReportTableRow>
                ))}
              </CustomReportTable>
            </div>
          </div>
        </>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of OTA Business Report"
        >
          <div className="mb-20">
            {/* <h2 className="mb-10"> Accordion Component </h2> */}
            <AccordionComponent
              questions={[
                {
                  question: "What is the OTA Business Report?",
                  answer: (
                    <p>
                      The <b>OTA Business Report</b>, provides a detailed
                      summary of credit transactions related to bookings managed
                      through various agents. It includes essential data such as
                      invoice dates, invoice numbers, opening and closing
                      balances, booking IDs, folio numbers, KOT (Kitchen Order
                      Ticket) numbers, and credit or debit amounts. This report
                      is vital for tracking financial transactions, managing
                      agent credits, and ensuring accurate billing.
                    </p>
                  ),
                },
                {
                  question:
                    "What is the Importance of the OTA Business Report?",
                  answer: (
                    <div>
                      <p>
                        The OTA Business Report is important for several
                        reasons:
                      </p>

                      <ul>
                        <li>
                          <b>Financial Oversight: </b>It allows management to
                          monitor outstanding balances and transactions
                          associated with agents, ensuring financial accuracy.
                        </li>
                        <li>
                          <b>Transaction Tracking: </b>By detailing credit
                          amounts, debits, and available credits, the report
                          helps in managing credit limits effectively, reducing
                          the risk of overdue payments.
                        </li>
                        <li>
                          <b>Credit Management: </b>It provides a comprehensive
                          view of all transactions, aiding in audits and
                          ensuring compliance with financial regulations.
                        </li>
                        <li>
                          <b>Operational Efficiency: </b>Understanding credit
                          usage and transaction types enables better resource
                          allocation and enhances operational processes.
                        </li>
                        <li>
                          <b>Improved Cash Flow Management: </b>The report helps
                          in forecasting cash flow needs by analyzing credit and
                          debit activities, allowing for more effective
                          financial planning.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportOtaBusinessReport;
