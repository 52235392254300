// import { IndexTable, LegacyCard } from "@shopify/polaris";
// import NoDataFound from "../CustomComponents/NoDataFound";
// import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
// import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
// import StatusUpdate from "../CustomComponents/StatusUpdate";
// import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
// import Loader from "../UI/Loader/Loader";
// import { formatIndianCurrency } from "../../utils/utils";
// import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
// import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
// import CustomIndexTable from "../Dashboard/CustomIndexTable/CustomIndexTable";

// const ReportItemizedFoodSaleReport = ({ departureReport, loader }) => {
//   const resourceName = {
//     singular: "order",
//     plural: "orders",
//   };

//   const rowMarkup =
//     departureReport &&
//     departureReport.length > 0 &&
//     departureReport?.map((order) => (
//       <IndexTable.Row id={order.id} key={order.id} position={order.index}>
//         <IndexTable.Cell>{order.kot_date}</IndexTable.Cell>
//         <IndexTable.Cell>{`KOT-` + order.kot_id}</IndexTable.Cell>

//         <IndexTable.Cell>
//           {" "}
//           {order.kot_gst_rate ? order.kot_gst_rate + "%" : "0"}
//         </IndexTable.Cell>
//         <IndexTable.Cell>
//           {order.kot_discount_rate ? order.kot_discount_rate + "%" : "0"}
//         </IndexTable.Cell>
//         <IndexTable.Cell>
//           {order.kot_balance_amount
//             ? formatIndianCurrency(order.kot_balance_amount)
//             : "0"}
//         </IndexTable.Cell>
//         <IndexTable.Cell>
//           {order.kot_paid_amount
//             ? formatIndianCurrency(order.kot_paid_amount)
//             : "0"}
//         </IndexTable.Cell>
//         <IndexTable.Cell>
//           {order.kot_status ? (
//             <UnderscoreRemoveCapitalize
//               text={
//                 order.kot_status === "parsely_paid"
//                   ? "Partially Paid"
//                   : order.kot_status
//               }
//             />
//           ) : (
//             "N/A"
//           )}
//         </IndexTable.Cell>
//       </IndexTable.Row>
//     ));

//   const names = [
//     {
//       agent_id: "Agent ID",
//       company_name: "Name",
//       current_date_amount: "Amount",
//       current_date_nights: "Nights",
//       mtd_amount: "MTD Amt.",
//       mtd_nights: "MTD Nts.",
//       ytd_amount: "YTD Amt.",
//       ytd_nights: "YTD Nts.",
//     },
//   ];

//   const calculateTotal = (items, key) => {
//     return items.reduce((total, item) => +total + +item[key], 0);
//   };
//   const handleDownload = () => {
//   
//     const originalTable = document.getElementById("receipt");
//     const clonedTable = originalTable.cloneNode(true);

//     clonedTable.style.fontSize = "12px";
//     clonedTable.style.fontFamily = "Arial, sans-serif";
//     clonedTable.style.lineHeight = "1.5";
//     clonedTable.style.margin = "auto";

//     const tempContainer = document.createElement("div");
//     tempContainer.appendChild(clonedTable);

//     html2pdf()
//       .from(tempContainer)
//       .set({
//         margin: [0.5, 0.5, 0.5, 0.5], // Adjust margin as needed
//         filename: "receipt.pdf",
//         image: { type: "jpeg", quality: 5 }, // Use higher quality for images
//         html2canvas: { scale: 1 }, // Increase scale for higher resolution
//         jsPDF: { unit: "in", format: "letter", orientation: "portrait" }, // Adjust PDF format and orientation as needed
//       })
//       .save();
//   };

//   const downloadCSV = () => {
//     // Prepare CSV content
//     const csvHeaders = "Category,Item,Count,Sale";
//     const csvContent = `${csvHeaders}\n${departureReport
//       .map((category) =>
//         category.items
//           .map((item) =>
//             [category.category, item.item, item.count, item.sale].join(",")
//           )
//           .join("\n")
//       )
//       .join("\n")}`;

//     // Create a blob with CSV content
//     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
//     const url = URL.createObjectURL(blob);

//     // Create a temporary link element to trigger download
//     const link = document.createElement("a");
//     link.setAttribute("href", url);
//     link.setAttribute("download", "sales_data.csv");
//     document.body.appendChild(link);
//     link.click();

//     // Clean up
//     document.body.removeChild(link);
//     URL.revokeObjectURL(url);
//   };

//   return (
//     <>
//       {loader ? (
//         <Loader />
//       ) : (
//         <div className="room-types-full-container">
//           <div className="room-types-full-container-div">
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 width: "100%",
//                 marginBottom: "10px",
//               }}
//             >
//               <div className="room-type-heading">Itemized Sales Report </div>
//               {departureReport.length > 0 && (
//                 <div className="flex flex_gap_15">
//                   <ButtonToDownloadTablePDF
//                     itemToDownloadID={"receipt"}
//                     downloadFileName={"sales report"}
//                     // pdfStyles={

//                     // }
//                   />
//                   {/* <DownloadCsvTables tableData={departureReport} downloadCSV={downloadCSV} /> */}
//                 </div>
//               )}
//             </div>
//           </div>
//           <div id="receipt">
//             {departureReport && departureReport.length > 0 ? (
//               <>
//                 {departureReport &&
//                   departureReport.length > 0 &&
//                   departureReport.map((order) => {
//                     return (
//                       <div className="mt-20">
//                         <div
//                           className="category_name"
//                           style={{ marginBottom: "10px" }}
//                         >
//                           {order.category}
//                         </div>

//                         <LegacyCard>
//                           <IndexTable
//                             resourceName={resourceName}
//                             itemCount={order.items.length}
//                             selectable={false}
//                             headings={[
//                               { title: "Item" },
//                               { title: "Count" },
//                               { title: "Sale" },
//                             ]}
//                           >
//                             <>
//                               {order.items.map((order) => (
//                                 <>
//                                   <IndexTable.Row
//                                     id={order.id}
//                                     key={order.id}
//                                     position={order.index}
//                                   >
//                                     <IndexTable.Cell>
//                                       <div style={{ width: "200px" }}>
//                                         {order.item}
//                                       </div>
//                                     </IndexTable.Cell>
//                                     <IndexTable.Cell>
//                                       <div style={{ width: "200px" }}>
//                                         {order.count}
//                                       </div>
//                                     </IndexTable.Cell>
//                                     <IndexTable.Cell>
//                                       <div style={{ width: "200px" }}>
//                                         {formatIndianCurrency(order.sale)}
//                                       </div>
//                                     </IndexTable.Cell>
//                                   </IndexTable.Row>
//                                 </>
//                               ))}
//                               <IndexTable.Row>
//                                 <IndexTable.Cell>
//                                   {" "}
//                                   <div style={{ textAlign: "right" }}>
//                                     {" "}
//                                     <b>Total</b>
//                                   </div>
//                                 </IndexTable.Cell>
//                                 <IndexTable.Cell>
//                                   <b>{calculateTotal(order.items, "count")}</b>
//                                 </IndexTable.Cell>
//                                 <IndexTable.Cell>
//                                   <b>
//                                     {formatIndianCurrency(
//                                       calculateTotal(order.items, "sale")
//                                     )}
//                                   </b>
//                                 </IndexTable.Cell>
//                               </IndexTable.Row>

//                               {/* <div style={{ display: "flex" }}>
//                               {order.summary.map((item) => (
//                                 <div style={{ display: "flex" }}>
//                                   {item.saleTotal}
//                                 </div>
//                               ))}
//                             </div> */}
//                             </>
//                           </IndexTable>
//                         </LegacyCard>
//                       </div>
//                     );
//                   })}
//               </>
//             ) : (
//               <NoDataFound />
//             )}
//           </div>
//         </div>
//       )}
//     </>
//   );
// };
// export default ReportItemizedFoodSaleReport;
import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import Loader from "../UI/Loader/Loader";
import { formatIndianCurrency } from "../../utils/utils";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import CustomIndexTable from "../Dashboard/CustomIndexTable/CustomIndexTable";
import CustomIndexTableRow from "../Dashboard/CustomIndexTable/CustomIndexTableRow";
import CustomIndexTableCell from "../Dashboard/CustomIndexTable/CustomIndexTableCell";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { useState } from "react";
import { SvgGetInfo } from "../../assets/svgIcons";

const ReportItemizedFoodSaleReport = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [informationPopup, setInformationPopup] = useState(false);

  const rowMarkupAllFoliosCustom = departureReport?.map(
    ({ id, count, order_no, order_type, net_total, date, index }) => {
      return (
        <CustomIndexTableRow
          id={id}
          key={id}
          // selectedItems={selectedArray} // send for checking the check box is checked
          // setSelectedItems={setSelectedArray}
          showCheckbox={false} // show the check box
        >
          <CustomIndexTableCell>{id}</CustomIndexTableCell>

          <CustomIndexTableCell>{count}</CustomIndexTableCell>
          <CustomIndexTableCell>
            {formatIndianCurrency(id)}
          </CustomIndexTableCell>
        </CustomIndexTableRow>
      );
    }
  );

  const names = [
    {
      agent_id: "Agent ID",
      company_name: "Name",
      current_date_amount: "Amount",
      current_date_nights: "Nights",
      mtd_amount: "MTD Amt.",
      mtd_nights: "MTD Nts.",
      ytd_amount: "YTD Amt.",
      ytd_nights: "YTD Nts.",
    },
  ];

  const calculateTotal = (items, key) => {
    return items.reduce((total, item) => +total + +item[key], 0);
  };
  

  const downloadCSV = () => {
    // Prepare CSV content
    const csvHeaders = "Category,Item,Count,Sale";
    const csvContent = `${csvHeaders}\n${departureReport
      .map((category) =>
        category.items
          .map((item) =>
            [category.category, item.item, item.count, item.sale].join(",")
          )
          .join("\n")
      )
      .join("\n")}`;

    // Create a blob with CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element to trigger download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "sales_data.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div
          id="receipt"
          className="room-types-full-container reports-new-background"
        >
          <div className="room-types-full-container-div">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
                <div className="room-type-heading">Itemized Sales Report </div>
                <div
                  className="hoverCursor"
                  onClick={() => setInformationPopup(true)}
                  id="ignoreFromPrint"
                >
                 <SvgGetInfo />
                </div>
              </div>

              {departureReport.length > 0 && (
                <div className="flex flex_gap_15" id="ignoreFromPrint">
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Itemized_Sales_Report"}
                    ignoreFromPrint={"ignoreFromPrint"}
                    longPdf={true}
                  />
                  <DownloadCSVButton
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Itemized_Sales_Report"}
                  />
                </div>
              )}
            </div>
          </div>
          <div id="receipt">
            {departureReport && departureReport.length > 0 ? (
              <>
                {departureReport &&
                  departureReport.length > 0 &&
                  departureReport.map((order) => {
                    return (
                      <div className="mt-20">
                        <div
                          className="category_name"
                          style={{ marginBottom: "10px" }}
                        >
                          {order.category}
                        </div>

                        <CustomReportTable
                          allItems={order.items} // table data
                          // selectedItems={selectedArray} //selected items from table
                          // setSelectedItems={setSelectedArray} // state from parent component to update table
                          showCheckbox={false}
                          headings={["Item", "Count", "Sale"]}
                          headerStyles={{
                            backgroundColor: "#dddddd",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                          tableStyles={{ textAlign: "left" }}
                        >
                          {order.items?.map(
                            ({
                              id,
                              count,
                              item,
                              sale,
                              net_total,
                              date,
                              index,
                            }) => {
                              return (
                                <CustomReportTableRow
                                  id={id}
                                  key={id}
                                  // selectedItems={selectedArray} // send for checking the check box is checked
                                  // setSelectedItems={setSelectedArray}
                                  showCheckbox={false} // show the check box
                                >
                                  <CustomReportTableCell>
                                    {item}
                                  </CustomReportTableCell>

                                  <CustomReportTableCell>
                                    {count}
                                  </CustomReportTableCell>
                                  <CustomReportTableCell>
                                    {formatIndianCurrency(sale)}
                                  </CustomReportTableCell>
                                </CustomReportTableRow>
                              );
                            }
                          )}

                          <CustomReportTableRow>
                            <CustomReportTableCell>
                              {" "}
                              <div style={{ textAlign: "right" }}>
                                {" "}
                                <b>Total</b>
                              </div>
                            </CustomReportTableCell>
                            <CustomReportTableCell>
                              <b>{calculateTotal(order.items, "count")}</b>
                            </CustomReportTableCell>
                            <CustomReportTableCell>
                              <b>
                                {formatIndianCurrency(
                                  calculateTotal(order.items, "sale")
                                )}
                              </b>
                            </CustomReportTableCell>
                          </CustomReportTableRow>
                        </CustomReportTable>
                      </div>
                    );
                  })}
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Itemized Sale Report"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is the Itemized Sale Report?",
                  answer: (
                    <p>
                      An <b>Itemized Sales Report</b> provides a detailed
                      breakdown of sales by individual items. For each item, it
                      lists the quantity sold and the total revenue generated.
                      In this specific case, the report focuses on drink sales,
                      showing how many units of each drink were sold and the
                      corresponding revenue.
                    </p>
                  ),
                },
                {
                  question:
                    "What is the Importance of the Itemized Sale Report?",
                  answer: (
                    <div>
                      <ul>
                        <li>
                          <b> Detailed Sales Tracking:</b> It helps track the
                          performance of individual items, identifying
                          best-sellers and low-performing products.
                        </li>
                        <li>
                          <b>Inventory Management:</b> Offers insight into
                          consumption patterns, allowing better inventory
                          control and reducing waste.
                        </li>
                        <li>
                          <b>Revenue Analysis: </b> By analyzing the revenue
                          generated from each item, businesses can optimize
                          pricing and focus on high-profit items.
                        </li>
                        <li>
                          <b> Operational Efficiency:</b> It supports
                          decision-making for menu adjustments and promotional
                          strategies, improving overall business performance.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportItemizedFoodSaleReport;
