import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./WebsiteMenus.css";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import VerticalInputFieldCustom from "../../Components/CustomComponents/VerticalInputFieldCustom";
import { Button, Tooltip } from "@shopify/polaris";
import GoBackButtonCustom from "../../Components/GoBackButtonCustom/GoBackButtonCustom";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
} from "../../Components/UI/Buttons";
import { useNavigate } from "react-router-dom";
import SidebarInnerLists from "../../Components/CustomComponents/SidebarInnerLists";
import { websites_inner_lists } from "../../assets/constant";
import NoDataFoundSmall from "../../Components/CustomComponents/NoDataFoundSmall";

const WebsiteMenus = () => {
  const permissions = localStorage.getItem("permissions")
  const userToken = localStorage.getItem("token");
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(true);

  const navigate = useNavigate();

  const [addMenuPopup, setAddMenuPopup] = useState(false);
  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const deleteData = async () => {
    // const response = await fetch(
    //   // http://127.0.0.1:8000/api/v1/delete/main/menu/5/10
    //   `${process.env.REACT_APP_BASE_URL}/api/v1/delete/main/menu/${deleteId}/${property_id?.id}`,
    //   {
    //     method: "DELETE",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${userToken}`,
    //     },
    //     body: JSON.stringify("deletedIds"),
    //   }
    // );
    // const data = await response.json();
    // if (data.success) {
    //   getAllMenusHandler();
    //   showSuccessToast(data.message);
    //   setShowModelMenuActive(false);
    // } else {
    //   showErrorToast(data.message);
    // }
  };

  //add menus start
  const [selectTagData, setSelectTagData] = useState([]);

  const getAllMenusHandler = (id) => {
    // setLoader(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/menu/locations/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // setOpenSingleBookingDetails(true);
          setLoader(false);
          setSelectTagData(data.data);
        } else {
          setLoader(false);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        // setLoader(false);
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  useEffect(() => {
    if (property_id) {
      getAllMenusHandler(property_id.id);
    }
  }, [property_id]);

  const handleSelectChange = (e) => {
    setDataForBackEnd({
      ...dataForBackEnd,
      menu_location_id: e.target.value,
    });
  };

  const [dataForBackEnd, setDataForBackEnd] = useState({
    menu_label: "",
    menu_url: "",
    menu_location_id: "",
    menu_items: [{ menu_label: "", menu_url: "" }],
  });
  const handleMenuLabelChange = (e) => {
    const { name, value } = e.target;
    setDataForBackEnd((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePopUpAddClick = () => {
    setDataForBackEnd((prev) => ({
      ...prev,
      menu_items: [
        ...prev.menu_items,
        {
          menu_label: "",
          menu_url: "",
        },
      ],
    }));
  };

  const handlePopUpRemoveClick = (index) => {
    setDataForBackEnd((prev) => ({
      ...prev,
      menu_items: prev.menu_items.filter((_, i) => i !== index),
    }));
  };
  const handleTableInputChange = (e, index) => {
    const { name, value } = e.target;

    setDataForBackEnd((prev) => ({
      ...prev,
      menu_items: prev.menu_items.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      ),
    }));
  };

  const handlePopTableDataAddButtonClick = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/create/main/menu/${property_id.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(dataForBackEnd),
        }
      );

      const data = await response.json();

      if (data.success) {
        setLoader(false);
        getMenusItemData();
        showSuccessToast(data.message);
        setAddMenuPopup(false);
      } else {
        // setLoader(false);
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // add menus end

  // main data in the table start

  const [tableSlugData, setTableSlugData] = useState([]);

  const getMenusItemData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/show/main/menu/${property_id.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setLoader(false);
        setTableSlugData(data.data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    if (property_id) {
      getMenusItemData();
    }
  }, [property_id]);

  // main data in the table end

  // edit the table data pop up start

  const handleTableLinksDelete = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/delete/main/menu/${id}/${property_id.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setLoader(false);
        // setTableSlugData(data.data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //edit single links
  const [showEditLinksPopUp, setShowEditLinksPopUp] = useState(false);
  const [editTableLinkPopup, setEditablePopup] = useState({});
  const handleTableLinksEdit = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit/main/menu/${id}/${property_id.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setLoader(false);

        setShowEditLinksPopUp(true);
        setEditablePopup(data.data);
        // set;
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const handleEditLinkInputChange = (e) => {
  //   setEditablePopup((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  // };
  // const handleEditLinkChildInputChange = (e, id) => {
  //   const { name, value } = e.target;

  //   setEditablePopup((prev) => ({
  //     ...prev,
  //     items: prev?.items.map((item, index) =>
  //       item.id === id ? { ...item, [name]: value } : item
  //     ),
  //   }));
  // };

  const handleEditLinkInputChange = (e) => {
    setEditablePopup((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleEditLinkChildInputChange = (e, id) => {
    const { name, value } = e.target;

    setEditablePopup((prev) => ({
      ...prev,
      items: prev?.items.map((item, index) =>
        item.id === id ? { ...item, [name]: value } : item
      ),
    }));
  };

  const handlePopUpUpdateClick = async () => {
    try {
      // Rename key "items" to "menu_terms"
      const updatedData = {
        ...editTableLinkPopup,
        menu_items: editTableLinkPopup.items,
      };
      delete updatedData.items;

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/update/main/menu/${editTableLinkPopup.id}/${property_id.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(updatedData),
        }
      );

      const data = await response.json();

      if (data.success) {
        setLoader(false);
        setShowEditLinksPopUp(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <div className="websiteMenusContainer">
        <div className="websiteMenusSmallContainer">
          <h4 className="websiteMenusHeading">Menu</h4>
          <p className="websiteMenusText">
            Menus, or link lists, help your customers navigate around your
            online store. You can also create nested menus to display drop-down
            menus, and group products or pages together.
          </p>

          <div className="websiteMenusItems">
            <table className="websiteMenusItemsTable">
              <thead>
                <tr style={{ borderBottom: "1px solid #CCC" }}>
                  <th className="websiteMenusItemsTableHeading">Menu-Items</th>
                  <th className="websiteMenusItemsTableHeading">Slug</th>
                  <th
                    className="websiteMenusItemsTableHeading"
                    style={{ textAlign: "right" }}
                  >
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                     {permissions && permissions?.includes("full_access_website_management") ? (
                      <BgOutlineThemeButton
                        onClick={() => setAddMenuPopup(true)}
                        children={"Add Menus"}
                        style={{ height: "33px" }}
                      />
                     ):(
                      <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                      <BgThemeButtonDisabled children={"Add Menus"} />
                    </Tooltip>
                     )}
                      
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {tableSlugData && tableSlugData.length > 0 ? (
                  <>
                    {tableSlugData.map((menu, index) => (
                      <React.Fragment key={index}>
                        <tr style={{ fontWeight: "bold" }}>
                          <td>{menu.menu_label}</td>
                          <td>{menu.menu_url}</td>
                          <td
                            className="flex flex_gap_10"
                            style={{
                              height: "30px",
                              alignItems: "flex-end",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div
                              onClick={() => handleTableLinksDelete(menu.id)}
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                                  fill="#E03838"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                                  fill="#E03838"
                                />
                              </svg>
                            </div>
                            <div onClick={() => handleTableLinksEdit(menu.id)}>
                              <svg
                                width="18px"
                                height="18px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                stroke="#2f59cb"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                                <g
                                  id="SVGRepo_tracerCarrier"
                                  strokeLinecap="round"
                                  stroke-linejoin="round"
                                />

                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M20.8477 1.87868C19.6761 0.707109 17.7766 0.707105 16.605 1.87868L2.44744 16.0363C2.02864 16.4551 1.74317 16.9885 1.62702 17.5692L1.03995 20.5046C0.760062 21.904 1.9939 23.1379 3.39334 22.858L6.32868 22.2709C6.90945 22.1548 7.44285 21.8693 7.86165 21.4505L22.0192 7.29289C23.1908 6.12132 23.1908 4.22183 22.0192 3.05025L20.8477 1.87868ZM18.0192 3.29289C18.4098 2.90237 19.0429 2.90237 19.4335 3.29289L20.605 4.46447C20.9956 4.85499 20.9956 5.48815 20.605 5.87868L17.9334 8.55027L15.3477 5.96448L18.0192 3.29289ZM13.9334 7.3787L3.86165 17.4505C3.72205 17.5901 3.6269 17.7679 3.58818 17.9615L3.00111 20.8968L5.93645 20.3097C6.13004 20.271 6.30784 20.1759 6.44744 20.0363L16.5192 9.96448L13.9334 7.3787Z"
                                    fill="#2f59cb"
                                  />{" "}
                                </g>
                              </svg>
                            </div>
                          </td>
                        </tr>
                        {menu.items &&
                          menu.items.length > 0 &&
                          menu.items.map((item, idx) => (
                            <tr key={`${item.id}-${idx}`}>
                              <td>{item.menu_label}</td>
                              <td>{item.menu_url}</td>
                              <td>
                                {/* <button
                                  onClick={() => handleTableLinksDelete(item.id)}
                                >
                                  delete
                                </button>
                                <button
                                  onClick={() => handleTableLinksEdit(item.id)}
                                >
                                  Edit
                                </button> */}
                              </td>
                            </tr>
                          ))}
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <td colSpan={3}>
                    {/* <NoDataFound /> */}
                    <NoDataFoundSmall />
                  </td>
                )}
              </tbody>
            </table>
          </div>

          {/* working here */}

          {addMenuPopup && (
            <div className="bg-backdrop">
              <section
                className="stock-add-model-container"
                style={{ width: "700px" }}
              >
                <div>
                  <GoBackButtonCustom
                    onClick={() => setAddMenuPopup(false)}
                    buttonNextText={"Menus"}
                  />
                </div>

                <div className="edit-rooms-form-container">
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <div style={{ marginBottom: "0.25rem" }}>
                        <label htmlFor="standard-select"> Title </label>
                      </div>
                      <div className="select">
                        <select
                          className="select-custom"
                          style={{
                            width: "100%",
                            minHeight: "2.25rem",
                            border: "0.0625rem solid #888",
                            borderRadius: "0.25rem",
                            paddingLeft: "5px",
                          }}
                          onChange={handleSelectChange}
                        >
                          <option value={""}>select</option>

                          {selectTagData &&
                            selectTagData.length > 0 &&
                            selectTagData?.map((option, index) => {
                              return (
                                <>
                                  <option
                                    key={index}
                                    value={option.id}
                                    onChange={handleSelectChange}
                                  >
                                    {" "}
                                    {option.location}{" "}
                                  </option>
                                  ;
                                </>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "700",
                      margin: "21px 8px 10px 0",
                    }}
                  >
                    Menu Items
                  </div>
                  {/* menu items: start */}
                  <div
                    className="flex flex_gap_10 "
                    style={{ marginBottom: "10px" }}
                  >
                    <div style={{ width: "45%" }}>
                      <VerticalInputFieldCustom
                        titleName={"Menu Label"}
                        value={dataForBackEnd.menu_label}
                        name={"menu_label"}
                        onChange={handleMenuLabelChange}
                      />
                    </div>
                    <div style={{ width: "45%" }}>
                      <VerticalInputFieldCustom
                        titleName={"Slug"}
                        value={dataForBackEnd.menu_url}
                        name={"menu_url"}
                        onChange={handleMenuLabelChange}
                      />
                    </div>
                  </div>
                  {/* menu items: end */}

                  <div className="form-container-div-half">
                    <table
                      style={{
                        backgroundColor: "white",
                        padding: "0 10px 20px 0px",
                        borderCollapse: "collapse",
                        borderRadius: "10px",
                        fontFamily: "Inter",
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr
                          style={{
                            textAlign: "left",
                          }}
                        >
                          <th style={{ padding: "0px", fontWeight: "400" }}>
                            Name
                          </th>
                          <th style={{ padding: "0px", fontWeight: "400" }}>
                            Slug
                          </th>
                          {/* <th style={{ padding: "0px" }}>Select Pages</th> */}
                          <th
                            style={{
                              width: "10px",
                              padding: "10px",
                            }}
                          ></th>
                        </tr>
                      </thead>

                      <tbody>
                        {dataForBackEnd.menu_items.map((item, index) => (
                          <tr key={index}>
                            <td style={{ paddingRight: "10px" }}>
                              <VerticalInputFieldCustom
                                name="menu_label"
                                onChange={(e) =>
                                  handleTableInputChange(e, index)
                                }
                              />
                            </td>
                            <td style={{ paddingRight: "10px" }}>
                              <VerticalInputFieldCustom
                                name="menu_url"
                                onChange={(e) =>
                                  handleTableInputChange(e, index)
                                }
                              />
                            </td>

                            {/* buttons for the add and deleteRow */}
                            <td
                              style={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "flex-end",
                                height: "40px",
                                paddingRight: "10px",
                              }}
                            >
                              {/* add button */}
                              {index ===
                              dataForBackEnd.menu_items.length - 1 ? (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handlePopUpAddClick(index)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                    >
                                      <g clipPath="url(#clip0_1270_978)">
                                        <path
                                          d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                          stroke="#3968ED"
                                        />
                                        <path
                                          d="M10.5866 7H3.41333"
                                          stroke="#3968ED"
                                          strokeMiterlimit="10"
                                        />
                                        <path
                                          d="M7 3.41344L7 10.5867"
                                          stroke="#3968ED"
                                          strokeMiterlimit="10"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_1270_978">
                                          <rect
                                            width="14"
                                            height="14"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </div>
                                </>
                              ) : null}

                              {/* remove button */}

                              <div
                                onClick={() => handlePopUpRemoveClick(index)}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                >
                                  <g clipPath="url(#clip0_1270_876)">
                                    <path
                                      d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                      stroke="#3968ED"
                                    />
                                    <path
                                      d="M10.5866 7H3.41333"
                                      stroke="#3968ED"
                                      strokeMiterlimit="10"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1270_876">
                                      <rect
                                        width="14"
                                        height="14"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              {/*end remove button */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* {editMenuPopup ? (
                    <BgThemeButton
                      onClick={handleUpdateMenu}
                      type="submit"
                      children={"Update"}
                    />
                  ) : ( */}
                  <BgThemeButton
                    onClick={handlePopTableDataAddButtonClick}
                    type="submit"
                    loader={loader}
                    children={"Add"}
                  />
                  {/* )} */}
                </div>
              </section>
            </div>
          )}

          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    <Button onClick={deleteData} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}

          {showEditLinksPopUp && (
            <>
              <div className="global_popup_container_background">
                <div className="global_popup_container">
                  <div className="flex flex_gap_10">
                    <VerticalInputFieldCustom
                      titleName={"Menu Label"}
                      name="menu_label"
                      value={editTableLinkPopup.menu_label}
                      onChange={handleEditLinkInputChange}
                    />
                    <VerticalInputFieldCustom
                      titleName={"Menu Slug"}
                      value={editTableLinkPopup.menu_url}
                      name="menu_url"
                      onChange={handleEditLinkInputChange}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>Items :-</div>

                  {editTableLinkPopup &&
                    editTableLinkPopup?.items &&
                    editTableLinkPopup?.items?.length > 0 &&
                    editTableLinkPopup?.items?.map((item, index) => (
                      <div key={index} className="flex flex_gap_10">
                        <VerticalInputFieldCustom
                          value={item.menu_label}
                          onChange={(e) =>
                            handleEditLinkChildInputChange(e, item.id)
                          }
                          name={"menu_label"}
                        />
                        <VerticalInputFieldCustom
                          value={item.menu_url}
                          name={"menu_url"}
                          onChange={(e) =>
                            handleEditLinkChildInputChange(e, item.id)
                          }
                        />
                      </div>
                    ))}
                  <div
                    className="flex flex_gap_10"
                    style={{ marginTop: "20px" }}
                  >
                    <BgThemeButton
                      children={"Update"}
                      onClick={handlePopUpUpdateClick}
                    />
                    <BgOutlineThemeButton
                      children={"Cancel"}
                      onClick={() => setShowEditLinksPopUp(false)}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default WebsiteMenus;
