import { Icon, Tooltip, useIndexResourceState } from "@shopify/polaris";
import {
  other_select_nav_items_inventory,
  room_view_inner_list,
  rooms_inner_lists,
} from "../../assets/constant";
import "./RoomViewNewUi.css";
import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextDateFormatter from "../../Components/Communication/TextDateFormatter/TextDateFormatter";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import BookingDetailSliderNew from "../../Components/CustomComponents/BookingDetails/BookingDetailsSliderNew";
import { Button } from "@shopify/polaris";
import VerticalInputFieldCustom from "../../Components/CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../../Components/CustomComponents/VerticalInputSelectCustomCopy";
import { BgThemeButton } from "../../Components/UI/Buttons";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import CustomDateRangeSelector from "../../Components/CustomComponents/CustomDateRangeSelector";
import { currentDateSingle, getBaseUrl } from "../../utils/utils";
import Loader from "../../Components/UI/Loader/Loader";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import SidebarInnerLists from "../../Components/CustomComponents/SidebarInnerLists";
import { stay_reservation_views } from "../../assets/constant";
import CommunicationCustomDatePicker from "../../Components/CustomComponents/CommunicationCustomDatePicker";
import { getDecryptedData } from "../../utils/encryptStorage";
import SidebarInnerListWithMultiplePermissions from "../../Components/CustomComponents/SidebarInnerListWithMultiplePermissions";
import { SvgPlus } from "../../assets/svgIcons";
const RoomViewNewUi = () => {
  const baseURL = getBaseUrl();
  const activeApp = localStorage.getItem("activeApp");
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [roomCount, setRoomCount] = useState(null);
  const popupRef = useRef(null);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value);
  const [roomView, setRoomView] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [openSingleBookingDetails, setOpenSingleBookingDetails] =
    useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [chargesDetails, setChargesDetails] = useState(null);
  const [masterFolioDetails, setMasterFolioDetails] = useState(null);
  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);
  const [showLowerMenuAddMenu, setShowLowerMenuAddMenu] = useState(false);
  const [editRooms, setEditRooms] = useState(false);
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);
  const [orders, setOrders] = useState([]);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [buttonLoader, setButtonLoader] = useState(false);
  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  // .............................................
  const [addItemData, setAddItemData] = useState({
    service: "",
    description: "",
    rate: "",
    quantity: "",
    total_amount: "",
    sub_total: "",
    discount: "",
    gst_type: "",
    gst: "",
  });

  const setAddItemDataHandleChange = (event) => {
    setAddItemData({
      ...addItemData,
      [event.target.name]: event.target.value,
    });
  };

  const permissions = JSON.parse(localStorage.getItem("permissions"));

  const getOneData = async (id, uniqueId) => {
    setSelectedRoom(uniqueId);
    try {
      const response = await fetch(
        `${baseURL}/api/v1/assign/rooms/edit/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        room_name: data.room.room_name,
        room_no: data.room.room_no,
        floor_no: data.room.floor_no,
        status: data.room.status,
        room_id: data.room.room_id,
        property_id: data.room.property_id,
      };
      setEditRooms(true);
      setAddItemData(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  const sortMethods = {
    none: { method: (a, b) => null },
    ascendingRooms: { method: undefined },
    sortedRooms: {
      method: (a, b) => {
        const nameComparison = a.room_name.localeCompare(b.room_name);

        if (nameComparison !== 0) {
          return nameComparison;
        }
        return a.room_no - b.room_no;
      },
    },
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [selectedRange, setSelectedRange] = useState("");
  const [selectedDateFilter, setSelectedDateFilter] = useState([]);
  const [dayTypeFilter, setDayTypeFilter] = useState("-1");
  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: "",
    to_date: "",
  });
  const handleSelectedRangeChange = (selectedValue) => {
    if (selectedValue) {
      setSelectedDatesRange({
        from_date: "",
        to_date: "",
      });
      setDayTypeFilter(selectedValue);
    }
  };
  const [datepickerOpened, setDatepickerOpened] = useState("");
  const [customStyle, setCustomStyle] = useState(true);

  const handleOpenDatePickerData = (data) => {
    console.log(data, "dataaa");
    setDatepickerOpened(data);
  };

  const fetchInfo = async (property_id, from_date, to_date) => {
    if (!property_id) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${baseURL}/api/v1/get/roomView/${property_id}`,
        {
          method: "POST",
          // body: JSON.stringify({
          //   date_filters: selectedDateFilter,
          //   dayTypeFilter: dayTypeFilter,
          //   selectedRange: selectedRange,
          //   from_date: from_date,
          //   to_date: to_date,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setRoomView(data?.data);
      setRoomCount(data?.StatusCounts);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  function getBackgroundColor(RoomStatus) {
    switch (RoomStatus) {
      case "occupied":
        return "#BD941B";
      case "dirty":
        return "#E03838";
      case "clean":
        return "#15AA12";
      default:
        return "#ccc";
    }
  }

  const currentOrders =
    orders && orders.length > 0
      ? orders
          .sort(sortMethods["sortedRooms"].method)
          .slice(startIndex, endIndex)
      : [];

  // console.log(roomCount.clean);
  const [fetchedRoomType, setFetchedRoomType] = useState([]);

  const fetchRoomType = async (propertyId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(`${baseURL}/api/v1/rooms/${propertyId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setFetchedRoomType(data.rooms);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(
        property_id.id,
        selectedDatesRange.from_date,
        selectedDatesRange.to_date,
        selectedDateFilter,
        // // selectedBookingStatus,
        // // selectedCustomerStatus,
        dayTypeFilter,
        selectedRange
      );
      fetchRoomType(property_id?.id);
    }
  }, [property_id, selectedDateFilter, dayTypeFilter, selectedRange]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPopupVisible(false); // Close the popup
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const viewDetailsOccupied = async (id, uniqueId) => {
    // setEditId(uniqueId);

    try {
      const response = await fetch(
        `${baseURL}/api/v1/room_view/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.success) {
        setBookingDetails(data?.booking);
        setMasterFolioDetails(data?.masterFolio);
        setChargesDetails(data?.charges);

        setOpenSingleBookingDetails(true);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(currentOrders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
      setShowLowerMenuAddMenu(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuAddMenu(false);
    } else {
      setAddItemData({
        room_name: "",
        room_no: "",
        floor_no: "",
        status: "clean",
        room_id: fetchedRoomType ? fetchedRoomType[0]?.id : "",
        property_id: property_id?.id,
      });
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuAddMenu(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const deleteData = async () => {
    clearSelection();
    const response = await fetch(
      `${baseURL}/api/v1/assign/rooms/delete/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ ids: [selectedRoom] }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          fetchInfo(property_id?.id);
          clearSelection();
          setShowLowerMenuMultipleSelect(false);
          setShowModelMenuActive(false);
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const roomTypeOptions = fetchedRoomType?.map((roomType) => {
    return { label: roomType.room_name, value: roomType.id };
  });
  const statusOptions = [
    { label: "Select Option", value: "" },
    { label: "Clean", value: "clean" },
    { label: "Dirty", value: "dirty" },
    { label: "Inspected", value: "inspected" },
    { label: "Out of order", value: "out_of_order" },
    // { label: "Occupied", value: "occupied" },
  ];

  const handleSubmitAddRooms = (event) => {
    setButtonLoader(true);
    fetch(`${baseURL}/api/v1/assign/rooms/store/${property_id?.id}`, {
      method: "POST",
      body: JSON.stringify({ ...addItemData }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          setButtonLoader(false);
          // navigate("/rooms");
          showSuccessToast(data.message);
          setShowAddModelMenuActive(false);
          fetchInfo(property_id?.id);
          setAddItemData({
            room_id: "",
            room_name: "",
            room_no: "",
            floor_no: "",
            status: "clean",
          });
        } else {
          showErrorToast(data.message);
          setButtonLoader(false);
        }
      })
      .catch((error) => {
        setButtonLoader(false);
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const updateFunction = (id) => {
    setButtonLoader(true);
    fetch(`${baseURL}/api/v1/assign/rooms/update/${id}/${property_id?.id}`, {
      method: "POST",

      body: JSON.stringify({ ...addItemData, status: addItemData.status }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setEditRooms(false);
        if (data.message) {
          setButtonLoader(false);
          fetchInfo(property_id?.id);
          clearSelection();
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
          setButtonLoader(false);
        }
      })
      .catch((error) => {
        setButtonLoader(false);
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  // ........date filter...........

  const handleDateRangeSelected = ({ from_date, to_date }) => {
    if (from_date && to_date) {
      setDayTypeFilter("");
    }
    fetchInfo(property_id?.id, from_date, to_date);

    // setSelectedDatesRange({ from_date, to_date });
  };

  const handleApplyButtonClick = ({ from_date, to_date }) => {
    setSelectedDatesRange({ from_date, to_date });
    if (from_date && to_date) {
      setDayTypeFilter("");
    }
    // console.log("apply");
    // fetchInfo(property_id?.id, selectedDatesRange.from_date, selectedDatesRange.to_date);
  };

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
    clearSelection();
  };
  return (
    <div>
      {activeApp === "pms" ? (
        <SidebarInnerListWithMultiplePermissions
          innerLists={rooms_inner_lists}
          selectAtLast={true}
          other_select_nav_items_inventory={other_select_nav_items_inventory}
          firstSelected={"Inventory"}
          firstSelectedPermission={"read_inventory"}
          firstSelectedLink={"/inventory"}
        />
      ) : null}
      {/* 
      <div
        style={{
          display: "flex",
          zIndex: 20,
          justifyContent: "space-between",
        }}
        className="footer_inner_lists">
        <ul className="flex flex_gap_10">
          {permissions &&
            stay_reservation_views &&
            stay_reservation_views.map((item, index) => (
              <React.Fragment key={index}>
                {permissions && permissions.includes(item.permission) && (
                  <li style={{ listStyle: "none" }}>
                    <Link
                      to={item.link}
                      className={`inner_list_item ${location.pathname === item.link ? "active" : ""
                        }`}
                      key={index}
                    >
                      {item.name}
                    </Link>
                  </li>
                )}
              </React.Fragment>
            ))}
        </ul>
      </div> */}

      <div
        className="flex justify_content_end align_items_center"
        style={{ margin: "10px" }}
      >
        {permissions && permissions?.includes("full_access_ari_module") ? (
          <div
            onClick={addModalButtonClickHandler}
            className="flex align_items_center"
            style={{ cursor: "pointer", gap: "10px", color: "#3968ED" }}
          >
           <SvgPlus/>
            <p
              className="reservation_total_paragraph thisMonth"
              style={{ color: "#3968ED", fontSize: "14px" }}
            >
              Add Room
            </p>
          </div>
        ) : (
          <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
            <div
          
            className="flex align_items_center"
            style={{ cursor: "pointer", gap: "10px", color: "#3968ED" ,opacity:"0.7" }}
          >
           <SvgPlus/>
            <p
              className="reservation_total_paragraph thisMonth"
              style={{ color: "#3968ED", fontSize: "14px" }}
            >
              Add Room
            </p>
          </div>
          </Tooltip>
        )}
      </div>

      <div
        style={{
          width: "100%",
          backgroundColor: "#EFF0F2",
          margin: "20px 0px",
        }}
      >
        <div className="reservation_bar_container" style={{ width: "100%" }}>
          {/* <div
            style={{ position: "relative", paddingRight: "14px" }}
            className="reservation_dateRange toggleAccord"
          >
            <CommunicationCustomDatePicker
              onSelectedRangeChange={handleSelectedRangeChange}
              onApplyButtonClick={handleApplyButtonClick}
              onDateRangeSelected={handleDateRangeSelected}
              customStyle={customStyle}
              sendDataToParent={handleOpenDatePickerData}
            />
          </div> */}
          <>
            <div className="reservation_total ">
              <h5 className="reservation_total_heading">{roomCount?.total}</h5>
              <p className="reservation_total_paragraph">All</p>
            </div>
            <div className="reservation_total ">
              <h5 className="reservation_total_heading">
                {roomCount?.occupied !== undefined ? roomCount?.occupied : 0}
              </h5>
              <p className="reservation_total_paragraph">Occupied </p>
            </div>

            <div className="reservation_total">
              <h5 className="reservation_total_heading">
                {roomCount?.clean !== undefined ? roomCount?.clean : 0}
              </h5>
              <p className="reservation_total_paragraph">Clean</p>
            </div>
            <div className="reservation_total pending">
              <h5 className="reservation_total_heading">
                {roomCount?.dirty !== undefined ? roomCount.dirty : 0}
              </h5>
              <p className="reservation_total_paragraph">Dirty</p>
            </div>
          </>
        </div>
      </div>
      {loader ? (
        <Loader />
      ) : (
        <div className="meal_plan_main_container">
          <div className="booking-table-full-container">
            <div style={{ padding: "10px" }}>
              <div style={{ gap: "10px", flexWrap: "wrap" }}>
                {roomView?.map((room) => (
                  <div>
                    <div
                      className="room-type-heading"
                      style={{ padding: "10px 0px" }}
                    >
                      {room.Category}
                    </div>
                    <div
                      className="flex room_view_container_parent"
                      style={{ flexWrap: "wrap", gap: "10px", width: "100%" }}
                    >
                      {room.Rooms.map((singleRoom) => (
                        <div
                          className="room_view_container"
                          key={singleRoom.RoomId}
                          // onClick={() => handleRoomClick(singleRoom)}
                          onClick={() => [
                            setSelectedRoom(singleRoom.RoomId),
                            setPopupVisible(true),
                          ]}
                          style={{
                            backgroundColor: getBackgroundColor(
                              singleRoom?.RoomStatus
                            ),
                          }}
                        >
                          <h5>{singleRoom?.RoomNo}</h5>
                          <p>
                            {singleRoom?.ReservationDetails[0]?.CheckIn && (
                              <TextDateFormatter
                                as={"span"}
                                date={
                                  singleRoom?.ReservationDetails[0]?.CheckIn
                                }
                                showYear={true}
                              />
                            )}
                            {singleRoom?.ReservationDetails[0]?.CheckIn &&
                              singleRoom?.ReservationDetails[0]?.CheckOut && (
                                <span> - </span>
                              )}

                            {singleRoom?.ReservationDetails[0]?.CheckOut && (
                              <TextDateFormatter
                                as={"span"}
                                date={
                                  singleRoom?.ReservationDetails[0]?.CheckOut
                                }
                                showYear={true}
                              />
                            )}
                          </p>
                          {/* <p>{singleRoom.ReservationDetails[0]?.BookingID}</p> */}
                          <p>{singleRoom?.ReservationDetails[0]?.GuestName}</p>

                          {selectedRoom === singleRoom?.RoomId &&
                            popupVisible && (
                              <div className="room_status_popup" ref={popupRef}>
                                <button
                                  onClick={() =>
                                    getOneData(
                                      singleRoom?.RoomId,
                                      singleRoom?.RoomId
                                    )
                                  }
                                >
                                  Edit
                                </button>

                                {singleRoom?.RoomStatus === "occupied" && (
                                  <button
                                    onClick={() =>
                                      viewDetailsOccupied(
                                        singleRoom?.RoomNo,
                                        singleRoom?.RoomNo
                                      )
                                    }
                                  >
                                    View Details
                                  </button>
                                )}
                                <button
                                  style={{ color: "red" }}
                                  onClick={() => setShowModelMenuActive(true)}
                                >
                                  Delete
                                </button>
                              </div>
                            )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* <div className="flex flex_gap_10 meal_plan_view" style={{ justifyContent: 'end' }}>
                    <div className="flex flex_gap_10 align_items_center">
                        <svg
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="4" cy="4" r="4" fill="#BD941B" />
                        </svg>
                        Occupied
                    </div>
                    <div className="flex flex_gap_10 align_items_center">
                        <svg
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="4" cy="4" r="4" fill="#E03838" />
                        </svg>
                        Dirty
                    </div>
                    <div className="flex flex_gap_10 align_items_center">
                        <svg
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="4" cy="4" r="4" fill="#15AA12" />
                        </svg>
                        Clean
                    </div>
                </div> */}
        </div>
      )}
      {/* Add Button Click */}
      {showAddModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <form
              onSubmit={(e) => e.preventDefault()}
              style={{ width: "700px" }}
              className="stock-add-model-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div className="add-ota-model-container-heading">Add Rooms</div>
                <div
                  onClick={() => setShowAddModelMenuActive(false)}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Room No."
                    name="room_no"
                    type="number"
                    value={addItemData.room_no}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
                <div className="form-container-div">
                  <VerticalInputSelectCustomCopy
                    options={roomTypeOptions}
                    titleName="Room Type"
                    name="room_id"
                    value={addItemData.room_id}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Floor No."
                    name="floor_no"
                    value={addItemData.floor_no}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>

                <div style={{ width: "100%" }} className="form-container-div">
                  <VerticalInputSelectCustomCopy
                    options={statusOptions}
                    titleName="Status"
                    name="status"
                    value={addItemData.status}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    gap: "10px",
                  }}
                >
                  <BgThemeButton
                    onClick={(e) => handleSubmitAddRooms(e)}
                    type="submit"
                    children={"Add"}
                    loader={buttonLoader}
                  />
                  <Button onClick={() => setShowAddModelMenuActive(false)}>
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </>
      )}

      {showAddModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <form
              onSubmit={(e) => e.preventDefault()}
              style={{ width: "700px" }}
              className="stock-add-model-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div className="add-ota-model-container-heading">Add Rooms</div>
                <div
                  onClick={() => setShowAddModelMenuActive(false)}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Room No."
                    name="room_no"
                    type="number"
                    value={addItemData.room_no}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
                <div className="form-container-div">
                  <VerticalInputSelectCustomCopy
                    options={roomTypeOptions}
                    titleName="Room Type"
                    name="room_id"
                    value={addItemData.room_id}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Floor No."
                    name="floor_no"
                    value={addItemData.floor_no}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>

                <div style={{ width: "100%" }} className="form-container-div">
                  <VerticalInputSelectCustomCopy
                    options={statusOptions}
                    titleName="Status"
                    name="status"
                    value={addItemData.status}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    gap: "10px",
                  }}
                >
                  <BgThemeButton
                    onClick={(e) => handleSubmitAddRooms(e)}
                    type="submit"
                    children={"Add"}
                    loader={buttonLoader}
                  />
                  <Button onClick={() => setShowAddModelMenuActive(false)}>
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </>
      )}

      {/* booking details */}

      {openSingleBookingDetails && (
        <BookingDetailSliderNew
          data={bookingDetails}
          chargesDetails={chargesDetails}
          masterFolioDetails={masterFolioDetails}
          setShowBookingDetails={setOpenSingleBookingDetails}
          getSingleBookingDataHandler={viewDetailsOccupied}
        />
      )}

      {showModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={() => setShowModelMenuActive(!showModelMenuActive)}
                >
                  Discard
                </Button>
                <Button onClick={deleteData} destructive>
                  Yes, Remove
                </Button>
              </div>
            </section>
          </div>
        </>
      )}

      {editRooms && (
        <>
          <div className="bg-backdrop">
            <form
              onSubmit={(e) => e.preventDefault()}
              style={{ width: "700px" }}
              className="stock-add-model-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div className="add-ota-model-container-heading">
                  Edit Rooms
                </div>
                <div
                  onClick={() => setEditRooms(false)}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Room No."
                    name="room_no"
                    type="number"
                    value={addItemData.room_no}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
                <div className="form-container-div">
                  <VerticalInputSelectCustomCopy
                    options={roomTypeOptions}
                    titleName="Room Type"
                    name="room_id"
                    value={addItemData.room_id}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Floor No."
                    name="floor_no"
                    value={addItemData.floor_no}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>

                <div style={{ width: "100%" }} className="form-container-div">
                  {addItemData?.status === "occupied" ? (
                    <VerticalInputSelectCustomCopy
                      titleName={"Status"}
                      disabled={true}
                      options={statusOptions}
                      name="status"
                      type="text"
                      value={addItemData.status}
                      onChange={setAddItemDataHandleChange}
                    />
                  ) : (
                    <VerticalInputSelectCustomCopy
                      titleName={"Status"}
                      options={statusOptions}
                      name="status"
                      type="text"
                      value={addItemData.status}
                      onChange={setAddItemDataHandleChange}
                    />
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    gap: "10px",
                  }}
                >
                  <BgThemeButton
                    onClick={(e) => updateFunction(selectedRoom)}
                    type="submit"
                    children={"Update"}
                    loader={buttonLoader}
                  />
                  <Button onClick={() => setEditRooms(false)}>Cancel</Button>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};
export default RoomViewNewUi;
